import * as Sentry from '@sentry/react';
import { fullStoryIntegration } from '@sentry/fullstory';
import { ExclusiveEventHintOrCaptureContext } from '@sentry/core/build/types/utils/prepareEvent';
import { Options } from '@sentry/types';
import { defaultSentryConfigs, SENTRY_ORG_SLUG } from '../constants';
import FullStoryManager from './FullStoryManager';

class SentryManager {
  constructor(sentryOptions: Options) {
    if (sentryOptions.dsn) {
      window.errorTracking = true; // FIXME: this is needed for some breadcrumbs, we should refactor code that uses this window variable
      Sentry.init({ ...defaultSentryConfigs, ...sentryOptions });
    }
  }

  captureException = (exception: unknown, hint?: ExclusiveEventHintOrCaptureContext): string => {
    if (exception instanceof Error) {
      return Sentry.captureException(exception, hint);
    }
    // we do not care about exceptions type at this point, just throwing an error is enough
    const exceptionAsString = exception as string;
    return Sentry.captureException(new Error(exceptionAsString), hint);
  };

  captureMessage = Sentry.captureMessage;

  configureScope = (fn: (scope: Sentry.Scope) => void): void => {
    const scope = Sentry.getCurrentScope();
    fn(scope);
  };

  configureGlobalScope = (fn: (scope: Sentry.Scope) => void): void => {
    const scope = Sentry.getGlobalScope();
    fn(scope);
  };

  captureEvent = Sentry.captureEvent;

  createReduxEnhancer = Sentry.createReduxEnhancer;

  addIntegration = Sentry.addIntegration;

  enableFSPlugin = (fsInstance: FullStoryManager | undefined): void => {
    if (!fsInstance) {
      return;
    }
    this.addIntegration(fullStoryIntegration(SENTRY_ORG_SLUG, { client: fsInstance }));
  };
}
export default SentryManager;
