import qs from 'querystring';
import axios from 'axios';

import { getCombinedInfoURLByProduct, getAPIURL } from './requestUtils';

export const getUserInfo = (resourceID, productType = 'form') => {
  const requestURL = getCombinedInfoURLByProduct(resourceID, productType);
  return axios.get(requestURL, { withCredentials: true })
    .then(({
      data: {
        responseCode, location, content: {
          type, credentials, pendingSubmission, permissions, saclSubmission
        } = {}
      }
    }) => {
      let isGuest = false;
      if (typeof credentials === 'object' && credentials.account_type) {
        if (typeof credentials.account_type === 'string') {
          // handling public responses like https://api.jotform.com/system/plan/GUEST
          isGuest = credentials.account_type.indexOf('GUEST') >= 0;
        } else if (credentials.account_type.name === 'GUEST') {
          isGuest = true;
        }
      }

      let host = '';
      switch (true) {
        case responseCode === 301 && location.indexOf('eu-api') !== -1:
          host = productType.toLowerCase() === 'form' ? 'form.jotformeu.com' : 'eu.jotform.com';
          window.location.href = window.location.href.replace(window.location.host, host);
          break;
        case responseCode === 301 && location.indexOf('hipaa-api') !== -1:
          host = 'hipaa.jotform.com';
          window.location.href = window.location.href.replace(window.location.host, host);
          break;
        case !type:
        case responseCode !== 200:
        case type === 'USER' && isGuest:
          return {};
        default:
          return {
            user: credentials,
            permissions,
            userType: type,
            draftInfo: pendingSubmission,
            saclSubmission
          };
      }
    });
};

export const logoutUserWithAPI = ({ isFormUser = false } = {}) => {
  const apiURL = getAPIURL();
  return axios.get(`${apiURL}/${isFormUser ? 'formuser' : ''}/logout?master=1`, { withCredentials: true });
};

export const activateAssignment = formID => new Promise(resolve => {
  const failResponse = {
    success: false,
    message: 'Assignment couldn\'t be activated'
  };
  const apiURL = getAPIURL();
  axios.get(`${apiURL}/formuser/${formID}/activate-assignment`, { withCredentials: true })
    .then(({ data: { responseCode, message } }) => {
      const response = responseCode === 200 ? {
        success: true,
        message: 'Assignment activated'
      } : {
        ...failResponse,
        error: message
      };

      resolve(response);
    })
    .catch(e => {
      console.error('Something wrong happened and assignment couldnt be activated!', e);
      // TODO log to the Sentry!
      resolve({ ...failResponse, error: e });
    });
});

export const updateUserSettings = async data => {
  return axios.post('/API/user/settings', qs.stringify(data), {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'jf-team-id': window.teamID
    }
  }).then(resp => {
    if (resp && resp.data && typeof resp.data.responseCode === 'number'
    && (resp.data.responseCode < 200 || resp.data.responseCode > 299)) {
      return Promise.reject(resp);
    }

    if (resp && resp.data) {
      return resp.data?.content || resp.data;
    }

    return resp;
  });
};
