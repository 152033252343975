import { RequestLayer, Interceptors } from '@jotforminc/request-layer';

const layer = new RequestLayer('/API', {
  interceptorConfig: {
    teamID: global.teamID,
    customResponseInterceptors: [Interceptors.requestManagerResponseNormalizer]
  }
});

export default layer;
