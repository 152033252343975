import React, {
  forwardRef,
  useImperativeHandle,
  useState
} from 'react';
import PropTypes from 'prop-types';
import { useAtomValue } from 'jotai';
import {
  Button,
  Modal,
  ModalBody
} from '@jotforminc/magnet';
import { IconXmark } from '@jotforminc/svg-icons';
import { projectAtom, userAtom } from '../store';
import OTP from './OTP';

const OTPModal = forwardRef(({ callback, onCloseCallback, texts }, ref) => {
  const user = useAtomValue(userAtom);
  const project = useAtomValue(projectAtom);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    onCloseCallback();
    setOpen(false);
  };

  useImperativeHandle(ref, () => ({
    showModal: () => setOpen(true),
    closeModal: handleClose
  }));

  return (
    <Modal
      open={open}
      onClose={handleClose}
      zIndex={9999}
      size='large'
    >
      <div className='flex items-center w-full p-4'>
        <Button
          className='ml-auto rtl:mr-auto'
          colorStyle='secondary'
          aria-label='Close Button'
          startIcon={IconXmark}
          onClick={handleClose}
          rounded={true}
        />
      </div>
      <ModalBody
        data-testid='otp-modal'
        className='otp-modal'
      >
        <OTP
          callback={callback}
          user={user}
          project={project}
          texts={texts}
          open={open}
        />
      </ModalBody>
    </Modal>
  );
});

OTPModal.propTypes = {
  callback: PropTypes.func.isRequired,
  onCloseCallback: PropTypes.func.isRequired,
  texts: PropTypes.object.isRequired
};

export default OTPModal;
