import { t } from '@jotforminc/translation';
import { Texts } from '@jotforminc/constants';

export const REGULAR_VALIDATOR_RULE_DEFAULTS = [{
  rule_name: 'characterCount',
  regex: '.{8,}',
  error_message: t(Texts.SECURE_PASS_CHAR_COUNT)
}, {
  rule_name: 'noLeadingOrTrailingWhitespace',
  // eslint-disable-next-line no-useless-escape
  regex: '^[^\s].*[^\s]$',
  error_message: t(Texts.SECURE_PASS_NO_LEADING_OR_TRAILING_WHITESPACE)
}];

export const SECURE_VALIDATOR_RULE_DEFAULTS = [
  ...REGULAR_VALIDATOR_RULE_DEFAULTS, {
    rule_name: 'number',
    regex: '[0-9]+',
    error_message: t(Texts.SECURE_PASS_NUMBER)
  }, {
    rule_name: 'lowercase',
    regex: '[a-z]+',
    error_message: t(Texts.SECURE_PASS_LOWERCASE)
  }, {
    rule_name: 'uppercase',
    regex: '[A-Z]+',
    error_message: t(Texts.SECURE_PASS_UPPERCASE)
  }, {
    rule_name: 'specialChar',
    regex: '[^a-zA-Z0-9]',
    error_message: t(Texts.SECURE_PASS_SPECIAL_CHAR)
  }];
