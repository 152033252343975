import { RequestLayer, Interceptors } from '@jotforminc/request-layer';
import { getCurrentFormID, getAPILink } from '../utils';

const MAIL_ENDPOINT = 'sendSaveAndContinueEmail';
const SACL_FORM_ENDPOINT = `${getCurrentFormID()}/draft`;

const layer = new RequestLayer(`${getAPILink()}/sacl`, {
  interceptorConfig: {
    teamID: global.teamID,
    customResponseInterceptors: [Interceptors.requestManagerResponseNormalizer]
  }
});

const layerPrivate = new RequestLayer(`${window.location.origin}/API/sacl/`, {
  interceptorConfig: {
    teamID: global.teamID,
    customResponseInterceptors: [Interceptors.requestManagerResponseNormalizer]
  }
});

const CREATE = `${SACL_FORM_ENDPOINT}/create`;
const GET_SAVED_DATA = `${SACL_FORM_ENDPOINT}/#uuid`;
const UPDATE = `${SACL_FORM_ENDPOINT}/#uuid/update`;
const UPDATE_FOR_AGENT = `${SACL_FORM_ENDPOINT}/#uuid/updateForAgent`;
const DELETE = '#formID/draft/#uuid';
const BULK_DELETE = '#formID/draft/bulkDeleteFromSheet';
const UPDATE_OWNER = `${SACL_FORM_ENDPOINT}/#uuid/updateOwner`;
const GET_DRAFT = `${SACL_FORM_ENDPOINT}/get`;
const IS_NEW_SACL = '#formID/isNewSaclAllowed';

export const isNewSACL = async formID => {
  return layer.get(IS_NEW_SACL.replace('#formID', formID));
};

export const create = async data => {
  return layer.post(`${CREATE}`, { data });
};

export const get = uuid => {
  return layer.get(`${GET_SAVED_DATA}`.replace('#uuid', uuid));
};

export const getDraft = () => {
  return layer.get(`${GET_DRAFT}`);
};

export const deleteDrafts = (formID, data) => {
  return layerPrivate.post(`${BULK_DELETE}`.replace('#formID', formID), data, { disableResponseNormalizer: true });
};

export const update = async (data, uuid) => {
  return layer.post(`${UPDATE}`.replace('#uuid', uuid), { data });
};

export const updateForAgent = async (data, uuid) => {
  return layer.post(`${UPDATE_FOR_AGENT}`.replace('#uuid', uuid), { data });
};

export const updateOwner = async uuid => {
  return layer.post(`${UPDATE_OWNER}`.replace('#uuid', uuid));
};

export const remove = async (formID, uuid) => {
  return layerPrivate.delete(`${DELETE}`.replace('#uuid', uuid).replace('#formID', formID));
};

export const sendMail = async ({ formID, shareLink, email }) => {
  return layer.post(`${getAPILink()}/${MAIL_ENDPOINT}`, { formID, shareLink, email });
};
