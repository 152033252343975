import { func, shape, string } from 'prop-types';
import React from 'react';

import { t } from '@jotforminc/translation';
import { Texts } from '@jotforminc/constants';
import { IconChevronLeft } from '@jotforminc/svg-icons';
import { handleCustomNavigation } from '@jotforminc/utils';

const AccountRecoveryViewTicket = ({
  onNavigationChangeRequest, tfaAccountRecoveryProps
}) => {
  const { ticket } = tfaAccountRecoveryProps;
  const handleViewTicket = () => {
    handleCustomNavigation(ticket, '_blank', true);
  };

  return (
    <div className='view-support-ticket-wrapper'>
      <div className='xcl-content xcl-nb-content'>
        <div>
          <p className='recover-2fa-request-text'>{t(Texts.RECOVER_ACCOUNT_MAIL_HAS_BEEN_SENT)}</p>
        </div>
        <button
          className='view-ticket-button'
          type='button'
          onClick={handleViewTicket}
        >
          {t(Texts.VIEW_SUPPORT_TICKET)}
        </button>

        <div className='divider-2fa' />
        <button
          className='back-to-login'
          type='button'
          onClick={() => onNavigationChangeRequest('twoFactorLogin')}
        >
          <div className='back-to-login-container'>
            <IconChevronLeft />
            <span>
              {t(Texts.BACK_TO_LOGIN)}
            </span>
          </div>
        </button>
      </div>
    </div>
  );
};

export default AccountRecoveryViewTicket;

AccountRecoveryViewTicket.propTypes = {
  onNavigationChangeRequest: func,
  tfaAccountRecoveryProps: shape({
    ticket: string
  })
};

AccountRecoveryViewTicket.defaultProps = {
  onNavigationChangeRequest: f => f,
  tfaAccountRecoveryProps: {
    ticket: ''
  }
};
