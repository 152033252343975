import React, { useState, useEffect } from 'react';
import {
  node, shape, bool, string, func, oneOf
} from 'prop-types';

import Popover from '../Popover';

const Tooltip = ({
  targetRef, useCustomHandlers, onDeactivate, onActivate, activationEvent, deactivationEvent, ...props
}) => {
  const [isTooltipVisible, setTooltipVisibility] = useState(useCustomHandlers);

  const handleHoverEvent = event => {
    if (useCustomHandlers) {
      onActivate(event);
    } else {
      setTooltipVisibility(true);
    }
  };
  const handleLeaverEvent = event => {
    if (useCustomHandlers) {
      onDeactivate(event);
    } else {
      setTooltipVisibility(false);
    }
  };

  useEffect(() => {
    if (targetRef.current) {
      targetRef.current.addEventListener(activationEvent, handleHoverEvent);
      targetRef.current.addEventListener(deactivationEvent, handleLeaverEvent);
    }

    return () => {
      if (targetRef.current) {
        targetRef.current.removeEventListener(activationEvent, handleHoverEvent);
        targetRef.current.removeEventListener(deactivationEvent, handleLeaverEvent);
      }
    };
  }, [targetRef.current]);

  return (
    <Popover {...props} targetRef={isTooltipVisible ? targetRef : {}} />
  );
};

Tooltip.propTypes = {
  usePortal: bool,
  children: node,
  targetRef: shape({}),
  popoverOptions: shape({}),
  style: shape({}),
  portalContainerSelector: string,
  onActivate: func,
  onDeactivate: func,
  useCustomHandlers: bool,
  activationEvent: oneOf(['mouseover', 'mousemove']),
  deactivationEvent: oneOf(['mouseleave', 'mouseout'])
};

Tooltip.defaultProps = {
  usePortal: false,
  targetRef: { current: null },
  popoverOptions: {},
  style: {},
  children: null,
  portalContainerSelector: undefined,
  onActivate: null,
  onDeactivate: null,
  useCustomHandlers: false,
  activationEvent: 'mouseover',
  deactivationEvent: 'mouseleave'
};

export default Tooltip;
