import {
  Config, Methods, RequestArgs, RequestParameters
} from '../layer/types';

export const argumentParser = (method: Methods, args: RequestArgs): RequestParameters => {
  switch (true) {
    case args.length === 1: {
      const [url] = args;
      return { url, config: {} };
    }
    case args.length === 2 && ['get', 'delete'].includes(method): {
      const [url, config] = args;
      return { url, config: config as Config };
    }

    case args.length === 2 && ['put', 'post'].includes(method): {
      const [url, data] = args;
      return { url, data, config: {} };
    }

    case args.length === 3: {
      const [url, data, config] = args;
      if (config) return { url, data, config };
      return { url, data, config: {} };
    }

    default:
      return { url: '' };
  }
};
