import {
  bool, func, shape, string
} from 'prop-types';
import React from 'react';

import { Texts } from '@jotforminc/constants';
import { t } from '@jotforminc/translation';
import { IconChevronLeft } from '@jotforminc/svg-icons';
import ErrorAlert from './ErrorAlert';

const RecoverAccountPage = ({
  onNavigationChangeRequest,
  handle2FARecoverAccount,
  tfaAccountRecoveryProps
}) => {
  const { isLoading, isErrored, errorMessage } = tfaAccountRecoveryProps;

  const handleRecoverButtonClick = () => {
    handle2FARecoverAccount();
  };

  return (
    <div className='recovery-2fa-wrapper'>
      <div className='xcl-content xcl-nb-content back-to-login-wrapper'>
        <div className='recovery-text'>
          <p>{t(Texts.RECOVER_ACCOUNT_TEXT)}</p>
        </div>
        <div className='recover-error-wrapper'>
          <button
            className='recover-2fa-button'
            type='button'
            onClick={handleRecoverButtonClick}
          >
            {isLoading ? (
              t(Texts.SENDING)
            ) : (
              t(Texts.RECOVER_YOUR_ACCOUNT)
            )}
          </button>
          {(isErrored && errorMessage) && <ErrorAlert message={errorMessage} /> }
        </div>
        <div className='divider-2fa' />
        <button
          className='back-to-login'
          type='button'
          onClick={() => onNavigationChangeRequest('twoFactorLogin')}
        >
          <div>
            <IconChevronLeft />
            <span>
              {t(Texts.BACK_TO_LOGIN)}
            </span>
          </div>
        </button>
      </div>
    </div>
  );
};

export default RecoverAccountPage;

RecoverAccountPage.propTypes = {
  onNavigationChangeRequest: func,
  handle2FARecoverAccount: func,
  tfaAccountRecoveryProps: shape({
    isErrored: bool,
    isLoading: bool,
    errorMessage: string
  })
};

RecoverAccountPage.defaultProps = {
  onNavigationChangeRequest: f => f,
  handle2FARecoverAccount: f => f,
  tfaAccountRecoveryProps: {
    isErrored: false,
    isLoading: false,
    errorMessage: ''
  }
};
