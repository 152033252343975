import { ComponentPropsWithoutRef } from 'react';
import { ColorStyle, Size } from '../../constants/common.types';

export type IndicatorProps = ComponentPropsWithoutRef<'span'> & {
  size?: Size,
  colorStyle?: ColorStyle | 'secondary',
  invert?: boolean
}

export const indicatorDefaultProps:Partial<IndicatorProps> = {
  invert: false,
  size: 'medium',
  colorStyle: 'primary'
};
