import React, { useRef } from 'react';
import { string } from 'prop-types';
import Styled from 'styled-components';
import { Button as UIKitButton, Tooltip as UIKitTooltip, Hooks } from '@jotforminc/uikit';

/* eslint-disable max-len */
const ScUIKitButton = Styled(UIKitButton)`
  &.bare {
    font-size: .875em;
    color: #4170e2;
    text-decoration: none;
    background: transparent;
    cursor: pointer;
    box-shadow: none;
    padding: 0;
    border: 0;

    &.forSettings {
      svg {
        vertical-align: text-bottom;
        margin-right: 2px;
        width: 18px;
        height: 18px;

        html[dir="rtl"] & {
          margin-right: 0;
          margin-left: 2px;
        }
      }

      span {
        font-size: 16px;
        margin-left: 4px;
      }

    }
  }

  &.taste {
    display: inline-block;
    padding: 12px 1em;
    border-radius: 4px;
    cursor: pointer;
    text-transform: uppercase;
    border: 0;
    font-weight: 500;
    font-size: 15px;
    line-height: 14px;

    &[disabled] { opacity: .6; cursor: initial; }
  }

  &.taste.isBold {
    font-weight: bold;
  }

  &.taste.isGhost {
    background-color: #FAFAFC;
    border: 1px solid #ADB4D2;
    color: #ADB4D2;
    transition: all .3s;

    &:hover {
      color: #6F76A7;
      border: 1px solid #ADB4D2;
      background-color: #F3F3FE;
    }
  }

  &.taste.isGreen {
    background-color: #78BB07;
    color: #fff;
    transition: background-color .3s;

    &:hover {
      background-color: #4EB100;
    }
  }

  &.taste.isRed {
    background-color: #DC2626;
    color: #fff;
  }

  &.taste.isGray {
    background-color: #dddfe9;
    color: #2c3345;
  }

  &.taste.isBlue {
    background-color: #4170e2;
    color: #fff;
  }

  &.taste.forCopy {
    padding: 0;
    white-space: nowrap;

    svg {
      margin-right: .5em;
      vertical-align: -3px;
      width: 17px;
      height: 17px;
    }
    > div { padding: .5em 1em; }
  }

  &.outline {
    display: inline-block;
    padding: .5em 1em;
    border-radius: 2px;
    cursor: pointer;
    background: transparent;
    border: 1px solid currentColor;
    font-size: .875em;
    line-height: 1rem;
    font-weight: 500;
  }
  &.forRevoke {
    font-size: .875em;
    color: #f9535c;
    margin: 0 .5rem 0 auto;
    padding: 0.5em 0.7em;
    border-radius: 4px;
    display: inline-flex;
    align-items: center;

    &:hover { background-color: #FEF2F2; }

    svg {
      width: 14px;
      height: 14px;
      margin-right: 4px;
    }
  }

  @media screen and (max-width: 480px) {
    .hom { display: none; }
  }
`;

/* eslint-enable max-len */

export const ScUIKitTooltip = Styled(props => <UIKitTooltip {...props} />)`
  font-size: .75rem;
  line-height: 1;
  padding: .5rem .5rem;
  text-transform: lowercase;
  border-radius: 2px;
  color: #fff;
  font-weight: 400;
  background-color: #2c3345;

  &:first-letter { text-transform: uppercase; }

  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 10px; height: 10px;
    background-color: #2c3345;
    border-radius: 1px;
    transform: rotate(45deg);
  }

  @keyframes c { to { opacity: 1; margin-top: 2px; }}
  @keyframes d { to { opacity: 1; margin-bottom: 2px; }}

  &[data-popper-placement] {
    z-index: 1;
  }

  &[data-popper-placement="bottom"] {
    opacity: 0; margin-top: 0px!important;

    &:before { top: -4px; left: 50%; margin-left: -4px; margin-top: 6px; }

    &, &:before { animation: c .1s cubic-bezier(.3,.71,.67,.58) 1 forwards; }
  }

  &[data-popper-placement="top"] {
    opacity: 0; margin-bottom: 0px!important;

    &:before { bottom: -4px; left: 50%; margin-left: -4px; margin-bottom: 6px; }

    &, &:before { animation: d .1s cubic-bezier(.3,.71,.67,.58) 1 forwards; }
  }
`;

const Button = React.forwardRef(({ name, ...props }, ref) => {
  const innerRef = useRef();
  const combinedRef = Hooks.useCombinedRefs(innerRef, ref);

  return (
    <>
      <ScUIKitButton
        ref={combinedRef}
        type="button"
        name={name}
        {...props}
      />
      {true && name && <ScUIKitTooltip targetRef={combinedRef}>{name}</ScUIKitTooltip>}
    </>
  );
});

Button.propTypes = {
  name: string
};

Button.defaultProps = {
  name: ''
};

export default Button;
