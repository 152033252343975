/* eslint-disable max-lines */
import { cva } from 'class-variance-authority';
import { primary, secondary } from '../../tokens/colors';
import { CVAType } from '../../types/system.types';
import {
  ButtonIconProps, ButtonIndicatorProps, ButtonProps, ButtonTextProps
} from './button.types';

// CVA Types
type ButtonHelperTypes = {
  hasLoaderText?: boolean,
  hasDoubleIcon?: boolean,
  hasOneIcon?: boolean,
  hasChildren?: boolean
}

type ButtonCVAType = (
  CVAType<Pick<ButtonProps, 'size' | 'rounded' | 'fullWidth' | 'variant' | 'showTextOnHover' | 'loader' | 'disabled' | 'colorStyle' | 'showTextOnly' | 'theme' | 'invert'> & ButtonHelperTypes>
)

type ButtonTextCVAType = CVAType<ButtonTextProps>
type ButtonIconCVAType = CVAType<Omit<ButtonIconProps, 'icon'>>
type ButtonIndicatorCVAType = CVAType<Pick<ButtonIndicatorProps, 'loader' | 'colorStyle'>>;

const buttonDefaultClassName = 'magnet-button inline-flex shrink-0 justify-center items-center font-medium duration-300 outline-2 outline-transparent outline-offset-0 focus:outline-opacity-50';

// Classes
export const buttonCVA = cva<ButtonCVAType>(buttonDefaultClassName, {
  variants: {
    size: {
      small: 'h-8 px-2',
      medium: 'h-10 px-2.5',
      large: 'h-12 px-3'
    },
    rounded: {
      true: 'radius-full',
      false: 'radius'
    },
    fullWidth: {
      true: 'w-full'
    },
    variant: {
      ghost: 'border-0',
      filled: 'border-0',
      outline: 'border'
    },
    showTextOnHover: {
      true: 'group'
    },
    loader: {
      true: 'pointer-events-none'
    },
    disabled: {
      true: 'cursor-not-allowed opacity-40',
      false: 'cursor-pointer'
    },
    colorStyle: {},
    hasChildren: {},
    hasDoubleIcon: {},
    hasLoaderText: {},
    hasOneIcon: {},
    invert: {},
    showTextOnly: {},
    theme: {}
  },
  compoundVariants: [
    /*
      Added `width` class for only outlined icon buttons depending by size because of border width
    */
    {
      variant: 'outline',
      hasChildren: false,
      hasOneIcon: true,
      size: 'small',
      className: 'w-8'
    },
    {
      variant: 'outline',
      hasChildren: false,
      hasOneIcon: true,
      size: 'medium',
      className: 'w-10'
    },
    {
      variant: 'outline',
      hasChildren: false,
      hasOneIcon: true,
      size: 'large',
      className: 'w-12'
    },
    /*
      Added `width` class for only outlined icon buttons depending by size and showTextOnly breakpoints because of border width
    */
    {
      variant: 'outline',
      hasOneIcon: true,
      showTextOnly: 'xs',
      size: 'small',
      className: 'w-8 xs:w-auto'
    },
    {
      variant: 'outline',
      hasOneIcon: true,
      showTextOnly: 'sm',
      size: 'small',
      className: 'w-8 sm:w-auto'
    },
    {
      variant: 'outline',
      hasOneIcon: true,
      showTextOnly: 'md',
      size: 'small',
      className: 'w-8 md:w-auto'
    },
    {
      variant: 'outline',
      hasOneIcon: true,
      showTextOnly: 'lg',
      size: 'small',
      className: 'w-8 lg:w-auto'
    },
    {
      variant: 'outline',
      hasOneIcon: true,
      showTextOnly: 'xl',
      size: 'small',
      className: 'w-8 xl:w-auto'
    },
    {
      variant: 'outline',
      hasOneIcon: true,
      showTextOnly: 'xxl',
      size: 'small',
      className: 'w-8 xxl:w-auto'
    },
    {
      variant: 'outline',
      hasOneIcon: true,
      showTextOnly: 'xs',
      size: 'medium',
      className: 'w-10 xs:w-auto'
    },
    {
      variant: 'outline',
      hasOneIcon: true,
      showTextOnly: 'sm',
      size: 'medium',
      className: 'w-10 sm:w-auto'
    },
    {
      variant: 'outline',
      hasOneIcon: true,
      showTextOnly: 'md',
      size: 'medium',
      className: 'w-10 md:w-auto'
    },
    {
      variant: 'outline',
      hasOneIcon: true,
      showTextOnly: 'lg',
      size: 'medium',
      className: 'w-10 lg:w-auto'
    },
    {
      variant: 'outline',
      hasOneIcon: true,
      showTextOnly: 'xl',
      size: 'medium',
      className: 'w-10 xl:w-auto'
    },
    {
      variant: 'outline',
      hasOneIcon: true,
      showTextOnly: 'xxl',
      size: 'medium',
      className: 'w-10 xxl:w-auto'
    },
    {
      variant: 'outline',
      hasOneIcon: true,
      showTextOnly: 'xs',
      size: 'large',
      className: 'w-12 xs:w-auto'
    },
    {
      variant: 'outline',
      hasOneIcon: true,
      showTextOnly: 'sm',
      size: 'large',
      className: 'w-12 sm:w-auto'
    },
    {
      variant: 'outline',
      hasOneIcon: true,
      showTextOnly: 'md',
      size: 'large',
      className: 'w-12 md:w-auto'
    },
    {
      variant: 'outline',
      hasOneIcon: true,
      showTextOnly: 'lg',
      size: 'large',
      className: 'w-12 lg:w-auto'
    },
    {
      variant: 'outline',
      hasOneIcon: true,
      showTextOnly: 'xl',
      size: 'large',
      className: 'w-12 xl:w-auto'
    },
    {
      variant: 'outline',
      hasOneIcon: true,
      showTextOnly: 'xxl',
      size: 'large',
      className: 'w-12 xxl:w-auto'
    },
    /*
      Added `width` class for only outlined icon buttons depending by size and showTextOnHover because of border width
    */
    {
      variant: 'outline',
      hasOneIcon: true,
      showTextOnHover: true,
      size: 'small',
      className: 'w-8 hover:w-auto'
    },
    {
      variant: 'outline',
      hasOneIcon: true,
      showTextOnHover: true,
      size: 'medium',
      className: 'w-10 hover:w-auto'
    },
    {
      variant: 'outline',
      hasOneIcon: true,
      showTextOnHover: true,
      size: 'large',
      className: 'w-12 hover:w-auto'
    },
    /*
      Added `relative` class to wrapping the loader overlay element
    */
    {
      loader: true,
      hasLoaderText: false,
      className: 'relative'
    },
    /*
      Added `bg-transparent` class for `ghost` and `outline` variants
      because they don't have a background in the static state
    */
    {
      variant: ['ghost', 'outline'],
      className: 'bg-transparent'
    },
    /*
      Added `bg-white` for those with
      `filled` variant
      "darker" colorStyle
    */
    {
      variant: 'filled',
      colorStyle: [
        'primary', 'success', 'error', 'neutral',
        'analytics', 'pages', 'teams', 'sign', 'pdf', 'apps',
        'reports', 'forms', 'sign', 'tables', 'inbox', 'approvals'
      ],
      invert: false,
      className: 'color-white'
    },
    {
      variant: 'filled',
      colorStyle: [
        'primary', 'success', 'error', 'neutral',
        'analytics', 'pages', 'teams', 'sign', 'pdf', 'apps',
        'reports', 'forms', 'sign', 'tables', 'inbox', 'approvals'
      ],
      invert: true,
      className: 'bg-white'
    },
    {
      variant: 'filled',
      colorStyle: [
        'neutral', 'analytics', 'pages', 'teams', 'sign', 'pdf', 'apps',
        'reports', 'forms', 'sign', 'tables', 'inbox', 'approvals'
      ],
      disabled: false,
      invert: true,
      className: 'hover:bg-gray-25'
    },
    /*
      Added core classes for those with
      `filled` variant
      `primary` colorStyle
    */
    {
      variant: 'filled',
      colorStyle: 'primary',
      invert: false,
      className: primary.accent.default.bg.base
    },
    {
      variant: 'filled',
      colorStyle: 'primary',
      disabled: false,
      invert: false,
      className: primary.accent.dark.bg.hover
    },
    {
      variant: 'filled',
      colorStyle: 'primary',
      invert: true,
      className: primary.accent.default.color.base
    },
    {
      variant: 'filled',
      colorStyle: 'primary',
      disabled: false,
      invert: false,
      className: primary.accent.dark.bg.hover
    },
    {
      variant: 'filled',
      colorStyle: 'primary',
      disabled: false,
      invert: true,
      className: 'hover:bg-blue-100'
    },
    /*
      Added core classes for those with
      `filled` variant
      `secondary` colorStyle
    */
    {
      variant: 'filled',
      colorStyle: 'secondary',
      invert: false,
      className: [
        primary.background.light.base,
        primary.text.dark.base
      ]
    },
    {
      variant: 'filled',
      colorStyle: 'secondary',
      invert: true,
      className: ['bg-navy-600', primary.text.light.base]
    },
    {
      variant: 'filled',
      colorStyle: 'secondary',
      disabled: false,
      className: primary.background.medium.hover
    },
    {
      variant: 'filled',
      colorStyle: 'secondary',
      disabled: false,
      invert: true,
      className: 'hover:bg-navy-700'
    },
    /*
      Added core classes for those with
      `filled` variant
      `success` colorStyle
    */
    {
      variant: 'filled',
      colorStyle: 'success',
      invert: false,
      className: primary.success.default.bg.base
    },
    {
      variant: 'filled',
      colorStyle: 'success',
      invert: true,
      className: 'color-green-600'
    },
    {
      variant: 'filled',
      colorStyle: 'success',
      disabled: false,
      invert: false,
      className: primary.success.dark.bg.hover
    },
    {
      variant: 'filled',
      colorStyle: 'success',
      disabled: false,
      invert: true,
      className: 'hover:bg-green-100'
    },
    /*
      Added core classes for those with
      `filled` variant
      `error` colorStyle
    */
    {
      variant: 'filled',
      colorStyle: 'error',
      invert: false,
      className: primary.error.default.bg.base
    },
    {
      variant: 'filled',
      colorStyle: 'error',
      invert: true,
      className: primary.error.default.color.base
    },
    {
      variant: 'filled',
      colorStyle: 'error',
      disabled: false,
      invert: false,
      className: primary.error.dark.bg.hover
    },
    {
      variant: 'filled',
      colorStyle: 'error',
      disabled: false,
      invert: true,
      className: primary.error.lightest.bg.hover
    },
    /*
      Added core classes for those with
      `filled` variant
      `neutral` colorStyle
    */
    {
      variant: 'filled',
      colorStyle: 'neutral',
      invert: false,
      className: secondary.background.medium.base
    },
    {
      variant: 'filled',
      colorStyle: 'neutral',
      invert: true,
      className: 'color-gray-600'
    },
    {
      variant: 'filled',
      colorStyle: 'neutral',
      disabled: false,
      invert: false,
      className: secondary.background.dark.hover
    },
    /*
      Added core classes for those with
      `filled` variant
      `analytics` colorStyle
    */
    {
      variant: 'filled',
      colorStyle: 'analytics',
      invert: false,
      className: 'bg-analytics-default'
    },
    {
      variant: 'filled',
      colorStyle: 'analytics',
      invert: true,
      className: 'color-analytics-default'
    },
    {
      variant: 'filled',
      colorStyle: 'analytics',
      disabled: false,
      invert: false,
      className: 'hover:bg-analytics-dark'
    },
    /*
      Added core classes for those with
      `filled` variant
      `pages` colorStyle
    */
    {
      variant: 'filled',
      colorStyle: 'pages',
      invert: false,
      className: 'bg-pages-default'
    },
    {
      variant: 'filled',
      colorStyle: 'pages',
      invert: true,
      className: 'color-pages-default'
    },
    {
      variant: 'filled',
      colorStyle: 'pages',
      disabled: false,
      invert: false,
      className: 'hover:bg-pages-dark'
    },
    /*
      Added core classes for those with
      `filled` variant
      `teams` colorStyle
    */
    {
      variant: 'filled',
      colorStyle: 'teams',
      invert: false,
      className: 'bg-teams-default'
    },
    {
      variant: 'filled',
      colorStyle: 'teams',
      invert: true,
      className: 'color-teams-default'
    },
    {
      variant: 'filled',
      colorStyle: 'teams',
      disabled: false,
      invert: false,
      className: 'hover:bg-teams-dark'
    },
    /*
      Added core classes for those with
      `filled` variant
      `sign` colorStyle
    */
    {
      variant: 'filled',
      colorStyle: 'sign',
      invert: false,
      className: 'bg-sign-default'
    },
    {
      variant: 'filled',
      colorStyle: 'sign',
      invert: true,
      className: 'color-sign-default'
    },
    {
      variant: 'filled',
      colorStyle: 'sign',
      disabled: false,
      invert: false,
      className: 'hover:bg-sign-dark'
    },
    /*
      Added core classes for those with
      `filled` variant
      `pdf` colorStyle
    */
    {
      variant: 'filled',
      colorStyle: 'pdf',
      invert: false,
      className: 'bg-pdf-default'
    },
    {
      variant: 'filled',
      colorStyle: 'pdf',
      invert: true,
      className: 'color-pdf-default'
    },
    {
      variant: 'filled',
      colorStyle: 'pdf',
      disabled: false,
      invert: false,
      className: 'hover:bg-pdf-dark'
    },
    /*
      Added core classes for those with
      `filled` variant
      `apps` colorStyle
    */
    {
      variant: 'filled',
      colorStyle: 'apps',
      invert: false,
      className: 'bg-apps-default'
    },
    {
      variant: 'filled',
      colorStyle: 'apps',
      invert: true,
      className: 'color-apps-default'
    },
    {
      variant: 'filled',
      colorStyle: 'apps',
      disabled: false,
      invert: false,
      className: 'bg-apps-default hover:bg-apps-dark'
    },
    /*
      Added core classes for those with
      `filled` variant
      `reports` colorStyle
    */
    {
      variant: 'filled',
      colorStyle: 'reports',
      invert: false,
      className: 'bg-reports-default'
    },
    {
      variant: 'filled',
      colorStyle: 'reports',
      invert: true,
      className: 'color-reports-default'
    },
    {
      variant: 'filled',
      colorStyle: 'reports',
      disabled: false,
      invert: false,
      className: 'hover:bg-reports-dark'
    },
    /*
      Added core classes for those with
      `filled` variant
      `forms` colorStyle
    */
    {
      variant: 'filled',
      colorStyle: 'forms',
      invert: false,
      className: 'bg-forms-default'
    },
    {
      variant: 'filled',
      colorStyle: 'forms',
      invert: true,
      className: 'color-forms-default'
    },
    {
      variant: 'filled',
      colorStyle: 'forms',
      disabled: false,
      invert: false,
      className: 'hover:bg-forms-dark'
    },
    /*
      Added core classes for those with
      `filled` variant
      `tables` colorStyle
    */
    {
      variant: 'filled',
      colorStyle: 'tables',
      invert: false,
      className: 'bg-tables-default'
    },
    {
      variant: 'filled',
      colorStyle: 'tables',
      invert: true,
      className: 'color-tables-default'
    },
    {
      variant: 'filled',
      colorStyle: 'tables',
      disabled: false,
      invert: false,
      className: 'hover:bg-tables-dark'
    },
    /*
      Added core classes for those with
      `filled` variant
      `approvals` colorStyle
    */
    {
      variant: 'filled',
      colorStyle: 'approvals',
      invert: false,
      className: 'bg-approvals-default'
    },
    {
      variant: 'filled',
      colorStyle: 'approvals',
      invert: true,
      className: 'color-approvals-default'
    },
    {
      variant: 'filled',
      colorStyle: 'approvals',
      disabled: false,
      invert: false,
      className: 'hover:bg-approvals-dark'
    },
    /*
      Added core classes for those with
      `filled` variant
      `inbox` colorStyle
    */
    {
      variant: 'filled',
      colorStyle: 'inbox',
      invert: false,
      className: 'bg-inbox-default'
    },
    {
      variant: 'filled',
      colorStyle: 'inbox',
      invert: true,
      className: 'color-inbox-default'
    },
    {
      variant: 'filled',
      colorStyle: 'inbox',
      disabled: false,
      invert: false,
      className: 'hover:bg-inbox-dark'
    },
    /*
      Added border-color class for those with
      `outline` variant
      `primary` colorStyle
    */
    {
      variant: 'outline',
      colorStyle: 'primary',
      theme: 'light',
      className: primary.accent.default.border.base
    },
    {
      variant: 'outline',
      colorStyle: 'primary',
      theme: 'dark',
      className: 'border-blue-300'
    },
    /*
      Added border-color class for those with
      `outline` variant
      `secondary` colorStyle
    */
    {
      variant: 'outline',
      colorStyle: 'secondary',
      theme: 'light',
      className: primary.border.medium.base
    },
    {
      variant: 'outline',
      colorStyle: 'secondary',
      theme: 'dark',
      className: 'border-gray-300'
    },
    /*
      Added border-color class for those with
      `outline` variant
      `neutral` colorStyle
    */
    {
      variant: 'outline',
      colorStyle: 'neutral',
      theme: 'light',
      className: 'border-gray-600'
    },
    {
      variant: 'outline',
      colorStyle: 'neutral',
      theme: 'dark',
      className: 'border-white'
    },
    /*
      Added border-color class for those with
      `outline` variant
      `success` colorStyle
    */
    {
      variant: 'outline',
      colorStyle: 'success',
      theme: 'light',
      className: primary.success.default.border.base
    },
    {
      variant: 'outline',
      colorStyle: 'success',
      theme: 'dark',
      className: 'border-green-300'
    },
    /*
      Added border-color class for those with
      `outline` variant
      `error` colorStyle
    */
    {
      variant: 'outline',
      colorStyle: 'error',
      theme: 'light',
      className: primary.error.default.border.base
    },
    {
      variant: 'outline',
      colorStyle: 'error',
      theme: 'dark',
      className: 'border-red-300'
    },
    /*
      Added color and background-hover classes for those with
      `ghost` or `outline` variant
      `primary` colorStyle
    */
    {
      variant: ['ghost', 'outline'],
      colorStyle: 'primary',
      theme: 'light',
      className: primary.accent.default.color.base
    },
    {
      variant: ['ghost', 'outline'],
      colorStyle: 'primary',
      theme: 'dark',
      className: 'color-blue-300'
    },
    /*
      Added color and background-hover classes for those with
      `ghost` or `outline` variant
      `primary` colorStyle
      `disabled` false
    */
    {
      variant: ['ghost', 'outline'],
      colorStyle: 'primary',
      disabled: false,
      theme: 'light',
      className: primary.accent.lightest.bg.hover
    },
    {
      variant: ['ghost', 'outline'],
      colorStyle: ['primary', 'secondary', 'success', 'error', 'neutral'],
      disabled: false,
      theme: 'dark',
      className: 'hover:bg-gray-700'
    },
    /*
      Added color and background-hover classes for those with
      `ghost` or `outline` variant
      `secondary` or `neutral` colorStyle
    */
    {
      variant: ['ghost', 'outline'],
      colorStyle: ['secondary', 'neutral'],
      theme: 'light',
      class: primary.text.dark.base
    },
    {
      variant: ['ghost', 'outline'],
      colorStyle: ['secondary', 'neutral'],
      theme: 'dark',
      class: 'color-white'
    },
    /*
      Added color and background-hover classes for those with
      `ghost` or `outline` variant
      `secondary` or `neutral` colorStyle
      `disabled` false
    */
    {
      variant: ['ghost', 'outline'],
      colorStyle: ['secondary', 'neutral'],
      disabled: false,
      theme: 'light',
      className: primary.background.lightest.hover
    },
    /*
      Added color and background-hover classes for those with
      `ghost` or `outline` variant
      `success` colorStyle
      `disabled` false
    */
    {
      variant: ['ghost', 'outline'],
      colorStyle: 'success',
      disabled: false,
      theme: 'light',
      className: [primary.success.dark.color.base, primary.success.lightest.bg.hover]
    },
    {
      variant: ['ghost', 'outline'],
      colorStyle: 'success',
      disabled: false,
      theme: 'dark',
      className: 'color-green-300'
    },
    /*
      Added color and background-hover classes for those with
      `ghost` or `outline` variant
      `error` colorStyle
    */
    {
      variant: ['ghost', 'outline'],
      colorStyle: 'error',
      theme: 'light',
      className: primary.error.default.color.base
    },
    {
      variant: ['ghost', 'outline'],
      colorStyle: 'error',
      theme: 'dark',
      className: 'color-red-300'
    },
    /*
      Added color and background-hover classes for those with
      `ghost` or `outline` variant
      `error` colorStyle
      `disabled` false
    */
    {
      variant: ['ghost', 'outline'],
      colorStyle: 'error',
      disabled: false,
      theme: 'light',
      className: primary.error.lightest.bg.hover
    },
    /*
      Added `focus:outline` classes for those with
      `primary` colorStyle
      `disabled` false
    */
    {
      colorStyle: 'primary',
      disabled: false,
      className: 'focus:outline-blue-200'
    },
    /*
      Added `focus:outline` classes for those with
      `secondary` colorStyle
      `disabled` false
    */
    {
      colorStyle: 'secondary',
      disabled: false,
      className: 'focus:outline-navy-50'
    },
    /*
      Added `focus:outline` classes for those with
      `success` colorStyle
      `disabled` false
    */
    {
      colorStyle: 'success',
      disabled: false,
      className: 'focus:outline-green-300'
    },
    /*
      Added `focus:outline` classes for those with
      `error` colorStyle
      `disabled` false
    */
    {
      colorStyle: 'error',
      disabled: false,
      className: 'focus:outline-red-300'
    },
    /*
      Added `focus:outline` classes for those with
      `neutral` colorStyle
      `disabled` false
    */
    {
      colorStyle: 'neutral',
      disabled: false,
      className: 'focus:outline-gray-300'
    },
    /*
      Added `focus:outline` classes for those with
      `analytics` colorStyle
      `disabled` false
    */
    {
      colorStyle: 'analytics',
      disabled: false,
      className: 'focus:outline-analytics-light'
    },
    /*
      Added `focus:outline` classes for those with
      `pages` colorStyle
      `disabled` false
    */
    {
      colorStyle: 'pages',
      disabled: false,
      className: 'focus:outline-pages-light'
    },
    /*
      Added `focus:outline` classes for those with
      `teams` colorStyle
      `disabled` false
    */
    {
      colorStyle: 'teams',
      disabled: false,
      className: 'focus:outline-teams-light'
    },
    /*
      Added `focus:outline` classes for those with
      `sign` colorStyle
      `disabled` false
    */
    {
      colorStyle: 'sign',
      disabled: false,
      className: 'focus:outline-sign-light'
    },
    /*
      Added `focus:outline` classes for those with
      `pdf` colorStyle
      `disabled` false
    */
    {
      colorStyle: 'pdf',
      disabled: false,
      className: 'focus:outline-pdf-light'
    },
    /*
      Added `focus:outline` classes for those with
      `apps` colorStyle
      `disabled` false
    */
    {
      colorStyle: 'apps',
      disabled: false,
      className: 'focus:outline-apps-light'
    },
    /*
      Added `focus:outline` classes for those with
      `reports` colorStyle
      `disabled` false
    */
    {
      colorStyle: 'reports',
      disabled: false,
      className: 'focus:outline-reports-light'
    },
    /*
      Added `focus:outline` classes for those with
      `forms` colorStyle
      `disabled` false
    */
    {
      colorStyle: 'forms',
      disabled: false,
      className: 'focus:outline-forms-light'
    },
    /*
      Added `focus:outline` classes for those with
      `tables` colorStyle
      `disabled` false
    */
    {
      colorStyle: 'tables',
      disabled: false,
      className: 'focus:outline-tables-light'
    },
    /*
      Added `focus:outline` classes for those with
      `inbox` colorStyle
      `disabled` false
    */
    {
      colorStyle: 'inbox',
      disabled: false,
      className: 'focus:outline-inbox-light'
    },
    /*
      Added `focus:outline` classes for those with
      `approvals` colorStyle
      `disabled` false
    */
    {
      colorStyle: 'approvals',
      disabled: false,
      className: 'focus:outline-approvals-light'
    },
    // Icon gaps
    // XS
    {
      showTextOnly: 'xs',
      hasDoubleIcon: true,
      size: 'small',
      hasChildren: true,
      className: 'gap-1 xs:gap-0'
    },
    {
      showTextOnly: 'xs',
      hasDoubleIcon: true,
      size: 'medium',
      hasChildren: true,
      className: 'gap-2 xs:gap-0'
    },
    {
      showTextOnly: 'xs',
      hasDoubleIcon: true,
      size: 'large',
      hasChildren: true,
      className: 'gap-3 xs:gap-0'
    },
    // SM
    {
      showTextOnly: 'sm',
      hasDoubleIcon: true,
      size: 'small',
      hasChildren: true,
      className: 'gap-1 sm:gap-0'
    },
    {
      showTextOnly: 'sm',
      hasDoubleIcon: true,
      size: 'medium',
      hasChildren: true,
      className: 'gap-2 sm:gap-0'
    },
    {
      showTextOnly: 'sm',
      hasDoubleIcon: true,
      size: 'large',
      hasChildren: true,
      className: 'gap-3 sm:gap-0'
    },
    // MD
    {
      showTextOnly: 'md',
      hasDoubleIcon: true,
      size: 'small',
      className: 'gap-1 md:gap-0'
    },
    {
      showTextOnly: 'md',
      hasDoubleIcon: true,
      size: 'medium',
      className: 'gap-2 md:gap-0'
    },
    {
      showTextOnly: 'md',
      hasDoubleIcon: true,
      size: 'large',
      className: 'gap-3 md:gap-0'
    },
    // LG
    {
      showTextOnly: 'lg',
      hasDoubleIcon: true,
      size: 'small',
      className: 'gap-1 lg:gap-0'
    },
    {
      showTextOnly: 'lg',
      hasDoubleIcon: true,
      size: 'medium',
      className: 'gap-2 lg:gap-0'
    },
    {
      showTextOnly: 'lg',
      hasDoubleIcon: true,
      size: 'large',
      className: 'gap-3 lg:gap-0'
    },
    // XL
    {
      showTextOnly: 'xl',
      hasDoubleIcon: true,
      size: 'small',
      className: 'gap-1 xl:gap-0'
    },
    {
      showTextOnly: 'xl',
      hasDoubleIcon: true,
      size: 'medium',
      className: 'gap-2 xl:gap-0'
    },
    {
      showTextOnly: 'xl',
      hasDoubleIcon: true,
      size: 'large',
      className: 'gap-3 xl:gap-0'
    },
    // XXL
    {
      showTextOnly: 'xxl',
      hasDoubleIcon: true,
      size: 'small',
      className: 'gap-1 xxl:gap-0'
    },
    {
      showTextOnly: 'xxl',
      hasDoubleIcon: true,
      size: 'medium',
      className: 'gap-2 xxl:gap-0'
    },
    {
      showTextOnly: 'xxl',
      hasDoubleIcon: true,
      size: 'large',
      className: 'gap-3 xxl:gap-0'
    },
    {
      size: 'small',
      hasOneIcon: false,
      hasDoubleIcon: true,
      hasChildren: false,
      className: 'gap-1'
    },
    {
      size: 'medium',
      hasOneIcon: false,
      hasDoubleIcon: true,
      hasChildren: false,
      className: 'gap-2'
    },
    {
      size: 'large',
      hasOneIcon: false,
      hasDoubleIcon: true,
      hasChildren: false,
      className: 'gap-3'
    }
  ]
});

export const buttonTextCVA = cva<ButtonTextCVAType>('', {
  variants: {
    size: {
      small: 'px-1 text-sm',
      medium: 'px-2 text-sm',
      large: 'px-3 text-lg'
    },
    showTextOnHover: {
      true: 'hiddenjf group-hover:block whitespace-nowrap'
    },
    showTextOnly: {
      xs: 'xs:inline',
      sm: 'sm:inline',
      md: 'md:inline',
      lg: 'lg:inline',
      xl: 'xl:inline',
      xxl: 'xxl:inline'
    },
    loader: {},
    hasLoaderText: {},
    hasChildren: {}
  },
  compoundVariants: [
    {
      showTextOnly: ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'],
      className: 'hiddenjf'
    },
    {
      loader: true,
      hasLoaderText: false,
      hasChildren: true,
      className: 'opacity-0'
    }
  ]
});

export const buttonIconCVA = cva<ButtonIconCVAType>('magnet-button-icon shrink-0', {
  variants: {
    size: {
      small: 'w-4 h-4',
      medium: 'w-5 h-5',
      large: 'w-6 h-6'
    },
    loader: {},
    hasLoaderText: {}
  },
  compoundVariants: [
    {
      loader: true,
      hasLoaderText: false,
      className: 'opacity-0'
    },
    {
      loader: true,
      hasLoaderText: true,
      className: 'hiddenjf'
    }
  ]
});

export const buttonIndicatorCVA = cva<ButtonIndicatorCVAType>('shrink-0', {
  variants: {
    loader: {},
    colorStyle: {}
  },
  compoundVariants: [
    {
      colorStyle: undefined,
      className: 'color-navy-300'
    },
    {
      loader: true,
      className: 'opacity-0'
    }
  ]
});
