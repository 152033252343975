import React from 'react';
import { string, func, element } from 'prop-types';
import { t } from '@jotforminc/translation';
import { Texts } from '@jotforminc/constants';

import GoogleIcon from '../temporary-duplicate/assets/svg/icon_gl.svg';

export default function GoogleButton({
  text,
  onClick,
  classNames,
  icon,
  ariaLabel
}) {
  return (
    <button
      className={classNames}
      type="button"
      onClick={onClick}
      aria-label={ariaLabel}
    >
      {icon}
      <span className="button-text">{text || t(Texts.CONNECT_GOOGLE)}</span>
    </button>
  );
}

GoogleButton.propTypes = {
  text: string,
  onClick: func.isRequired,
  classNames: string,
  icon: element,
  ariaLabel: string
};

GoogleButton.defaultProps = {
  text: '',
  classNames: 'xcl-button-gl test_gl_login',
  icon: <GoogleIcon />,
  ariaLabel: t(Texts.SIGN_UP_GOOGLE)
};
