import { createPortal } from 'react-dom';
import { bool, string, node } from 'prop-types';
import { forwardRef, useImperativeHandle } from 'react';

import { usePortalRef } from '../../utils/hooks';

const Portal = forwardRef(({
  children,
  usePortal,
  portalAttribute,
  containerSelector,
  initialPortalSelector
}, ref) => {
  const portalRef = usePortalRef(containerSelector, usePortal, portalAttribute, initialPortalSelector);

  useImperativeHandle(ref, () => ({
    portalID: portalRef.current ? portalRef.current.id : ''
  }));

  return usePortal && portalRef.current ? createPortal(
    children,
    portalRef.current
  ) : children;
});

Portal.propTypes = {
  children: node.isRequired,
  usePortal: bool,
  containerSelector: string,
  portalAttribute: string,
  initialPortalSelector: string
};

Portal.defaultProps = {
  usePortal: true,
  containerSelector: 'body',
  portalAttribute: undefined,
  initialPortalSelector: ''
};

export default Portal;
