import React, { useRef } from 'react';
import { func } from 'prop-types';
import { Modal } from '@jotforminc/uikit';
import '../styles/standalone-modal.scss';
import EmailAuthLogin from './EmailAuthLogin';

const EmailAuthLoginModal = ({
  removeModalFromDOM,
  ...props
}) => {
  const uikitModalRef = useRef(null);

  // eslint-disable-next-line react/prop-types
  const DialogRenderer = ({ children }) => <div className="login-flow-standalone-modal-dialog email-auth-modal-dialog">{children}</div>;

  // eslint-disable-next-line react/prop-types
  const ContentRenderer = ({ children }) => (
    <div className="login-flow-standalone-modal-content email-auth-modal-content">
      <button
        className="close-button" type="button" aria-label="close"
        onClick={() => {
          if (typeof window.__accountSharingLogger === 'function') {
            window.__accountSharingLogger({ action: 'click', target: 'closeButton' });
          }
          removeModalFromDOM();
        }}
      />
      {children}
    </div>
  );

  return (
    <Modal
      usePortal={true}
      ref={uikitModalRef}
      defaultVisible={true}
      closeOnEscPress={false}
      closeOnOutsideClick={false}
      DialogRenderer={DialogRenderer}
      ContentRenderer={ContentRenderer}
    >
      <EmailAuthLogin {...props} />
    </Modal>
  );
};

EmailAuthLoginModal.propTypes = {
  removeModalFromDOM: func.isRequired
};

export default EmailAuthLoginModal;
