import { ComponentPropsWithoutRef, ElementType, ReactElement } from 'react';
import { PolymorphicProps } from '../../types/system.types';
import {
  Breakpoint, ColorStyle, Icon, ProductName, Size
} from '../../constants/common.types';

export type AcceptableButtonTypes = 'button' | 'a';

export const defaultButtonType:AcceptableButtonTypes = 'button';

export type ButtonProps = {
  colorStyle?: ColorStyle | ProductName | 'secondary' | 'neutral',
  disabled?: boolean,
  endIcon?: Icon,
  fullWidth?: boolean,
  href?: string,
  indicatorText?: string,
  invert?: boolean,
  loader?: boolean,
  loaderText?: string,
  rounded?: boolean,
  showTextOnHover?: boolean,
  showTextOnly?: Breakpoint | null,
  size?: Size,
  startIcon?: Icon,
  theme?: 'dark' | 'light',
  type?: 'submit' | 'button' | 'reset',
  variant?: 'filled' | 'outline' | 'ghost'
}

export type ButtonTextProps = Pick<ButtonProps, 'size' | 'loader' | 'showTextOnHover' | 'showTextOnly'> & {
  hasLoaderText?: boolean,
  hasChildren?: boolean
}

export type ButtonIconProps = Pick<ButtonProps, 'size' | 'loader'> & {
  icon: ElementType,
  hasLoaderText?: boolean
}

export type ButtonIndicatorProps = Pick<ButtonProps, 'indicatorText' | 'variant' | 'size' | 'loader' | 'colorStyle'>

export type ButtonComponentType = <Element extends ElementType>(
  props: PolymorphicProps<Element extends AcceptableButtonTypes ? Element : typeof defaultButtonType, ButtonProps> & { ref?: ComponentPropsWithoutRef<Element>['ref'] }
) => ReactElement | null

export const buttonDefaultProps:Partial<ButtonProps> = {
  colorStyle: 'primary',
  disabled: false,
  fullWidth: false,
  invert: false,
  loader: false,
  rounded: false,
  showTextOnHover: false,
  showTextOnly: null,
  size: 'medium',
  theme: 'light',
  type: 'button',
  variant: 'filled'
};
