import React from 'react';
import {
  shape, bool, string, func
} from 'prop-types';
import { t, translationRenderer } from '@jotforminc/translation';
import { Texts, twoFactorTypeKeys } from '@jotforminc/constants';
import { useTimeoutMessage } from '@jotforminc/hooks';
import { ErrorContainer } from '@jotforminc/two-factor-settings';
import {
  IconMessageFilled, IconKeyFilled, IconScanFilled, IconChevronLeft
} from '@jotforminc/svg-icons';
import { isEnterprise } from '@jotforminc/enterprise-utils';

const TwoFactorMethods = ({
  handleTFALogin,
  lastTFAResponse: {
    token: {
      type
    },
    activeMethods
  },
  isSocialTFA,
  onNavigationChangeRequest
}) => {
  const hasRecoveryMail = false;

  const typeMap = {
    phone: {
      typeCode: twoFactorTypeKeys.sms,
      icon: () => <IconMessageFilled width='22px' height='20px' color='#6C73A8' />
    },
    totp: {
      typeCode: twoFactorTypeKeys.authenticatorApp,
      icon: () => <IconScanFilled width='20px' height='20px' color='#6C73A8' />
    }
  };

  const [errorMessage, setErrorMessage] = useTimeoutMessage(false);
  const handleLogin = async args => {
    try {
      const response = await handleTFALogin(args);
      const { error } = response[0]?.data;
      if (error) setErrorMessage("Login failed - You've reached the daily token request limit.");
    } catch (e) {
      // TO DO handleError;
    }
  };

  const handleAccountRecoveryButton = () => {
    if (hasRecoveryMail) {
      onNavigationChangeRequest('accountRecoveryViewTicket');
    } else {
      onNavigationChangeRequest('recoverAccountPage');
    }
  };

  return (
    <div className="xcl-content xcl-nb-content twoFactorLogin forMethod">
      <h3 className="m2bt tfa-title">{t(Texts.VERIFY_ITS_YOU)}</h3>
      <div className="xcl-cw tfa-description">
        {t(Texts.CHOOSE_A_METHOD_DESCRIPTION)}
      </div>
      <div className="xcl-cw">
        <div className="xcl-form forLogin js-loginForm">
          <div className="xcl-field-wr tfa-forMethods">
            {t(Texts.CHOOSE_A_METHOD)}
          </div>
          <div className="xcl-field-wr tfa-otherMethodButtonsWrapper" style={{ paddingBottom: errorMessage ? '0' : '20px' }}>
            {Object.entries(activeMethods).filter(([methodType]) => typeMap[methodType]?.typeCode !== type).map(([methodType, { data: target }]) => {
              const {
                typeCode,
                icon: Icon
              } = typeMap[methodType];

              return (
                <button
                  key={typeCode}
                  className={`tfa-methodButton ${type === typeCode ? 'selected' : ''}`}
                  onClick={() => handleLogin({ newType: typeCode, isSocialTFA, shouldNavigate: true })}
                  type="button"
                >
                  <Icon />
                  <span className='tfa-methodText'>
                    { translationRenderer(Texts.SEND_CODE_TO[typeCode])({
                      renderer1: () => <b>{target}</b>
                    })}
                  </span>
                </button>
              );
            })}
            <button
              className="tfa-methodButton"
              onClick={() => handleTFALogin({ newType: 'recovery' })}
              type="button"
            >
              <IconKeyFilled width='18px' height='18px' color='#6C73A8' />
              <span className='tfa-methodText'>
                {t(Texts.USE_YOUR_RECOVERY_CODES)}
              </span>
            </button>
          </div>
          { errorMessage && (
            <ErrorContainer>{t(errorMessage)}</ErrorContainer>
          )}
          <button
            className='method-buttons'
            type='button'
            onClick={() => handleTFALogin({ newType: type, isSocialTFA })}
          >
            <IconChevronLeft />
            {t(Texts.BACK_TO_PRIMARY_METHOD)}
          </button>
          <div className='divider-2fa' />
          {!isEnterprise() && (
            <button
              className='recovery-account-button'
              type='button'
              onClick={handleAccountRecoveryButton}
            >
              {t(Texts.CAN_NOT_ACCESS_MY_DEVICE)}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

TwoFactorMethods.propTypes = {
  handleTFALogin: func.isRequired,
  lastTFAResponse: shape({
    type: string.isRequired,
    activeMethods: shape({}).isRequired,
    token: shape({
      type: string.isRequired
    }).isRequired
  }).isRequired,
  isSocialTFA: bool.isRequired,
  onNavigationChangeRequest: func
};

TwoFactorMethods.defaultProps = {
  onNavigationChangeRequest: f => f
};

export default TwoFactorMethods;
