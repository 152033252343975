import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { t } from '@jotforminc/translation';
import { IconExclamationCircleFilled } from '@jotforminc/svg-icons';

const ErrorBadge = ({ text, className = '' }) => (
  <span
    data-testid='otp-error'
    className={cx('inline-flex items-center w-auto p-2 break-word radius bg-red-400 color-white text-xs text-left rtl:text-right inline-block w-full', className)}
  >
    <div className='shrink-0 w-4 h-4 mr-1 rtl:mr-0 rtl:ml-1'>
      <IconExclamationCircleFilled className='color-white w-full h-full' />
    </div>
    {t(text)}
  </span>
);

ErrorBadge.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string
};

export default ErrorBadge;
