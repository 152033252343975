import React from 'react';
import { createRoot } from 'react-dom/client';
import { StorageHelper } from '@jotforminc/storage-helper';
import { TranslateProvider } from '@jotforminc/translation';
import { SCLManager, SACLNavigation } from '@jotforminc/save-and-continue-later';
import SaveAndContinue, { UnauthorizedScreen, NotFoundScreen } from '@jotforminc/save-and-continue';
import {
  getUserInfo,
  emailAnswerFinder,
  activateAssignment,
  getUrlParameter,
  generateSessionLink
} from '@jotforminc/utils';
import {
  getAPIURL,
  getDomainURL
} from '@jotforminc/request-layer';
import { RenderAccountBox } from '@jotforminc/render-account-box';
import DraftSelectionModal from './components/DraftSelectionModal';

class FormUserHelper {
  constructor() {
    this.apiURL = getAPIURL();
    this.user = false;
    this.testDeployment = false;
    this.formID = window.JFForm ? window.JFForm.id : document.forms[0]?.id;
    this.FORM_TYPE = window.CardForm ? 'CARD' : 'LEGACY';
    this.skippable = window.JFForm ? window.JFForm.skippable : true;
    this.enforceHIPAARuleSet = window.JFForm ? window.JFForm.enforceHIPAARuleSet : false;
    this.localSessionKey = `${SCLManager.SACL_DETAILS_KEY}_${this.formID}`;

    this.emailAnswerFinder = emailAnswerFinder.bind(this);

    const searchParams = new URLSearchParams(window.location.search);
    this.isOfflineForms = searchParams?.get('offline_forms') || false;

    if (this.formID) this.initUser();
  }

  renderErrorScreen = (type, text, subText) => {
    const container = document.createElement('div');
    document.body.appendChild(container);

    const Comp = type === 'UnauthorizedScreen' ? UnauthorizedScreen : NotFoundScreen;
    const root = createRoot(container);

    root.render(<Comp
      text={text}
      subText={subText}
    />);
  };

  initUser = async () => {
    const session = document.get && document.get.session;
    const isOldSaclDraft = session && session.includes('JF-S4L-');
    let newSACL = window.JotForm?.isNewSACL;

    if (window.JFForm?.draftID) {
      newSACL = true;
    }

    const response = await getUserInfo(this.formID).catch(e => console.error('User initialization failed', e));
    const userInfo = response || {};
    const {
      user, draftInfo, userType, permissions, saclSubmission: saclId
    } = userInfo;
    this.setUser(user, userType, permissions);
    if (user) { // TODO add an extra prop to user like isActivated in order to prevent unecessary activation request.
      activateAssignment(this.formID);
    }

    this.initDraftFlow(draftInfo, saclId);

    if (!isOldSaclDraft && newSACL) {
      this.initNewSclFlow(saclId);
    } else {
      this.initSCLFlow();
    }
  };

  initNewSclFlow = saclId => {
    const isRevisionHistory = document.get && document.get.revisionMode;

    if (window.JotForm.isEditMode()
      || window.JotForm.isAdvancedDesigner
      || isRevisionHistory || window.JotForm.isJotFormNext === 1
      || getUrlParameter('jf_createPrefill') === '1'
      || this.isOfflineForms) return;

    const { draftID, isHipaa, id: formID } = window.JFForm;
    const container = document.createElement('div');
    container.className = 'formUserSCLNavigationWrapper';
    document.body.appendChild(container);
    const root = createRoot(container);

    root.render(<SaveAndContinue
      user={this.user} enforceHIPAARuleSet={this.enforceHIPAARuleSet}
      continueKey={draftID || getUrlParameter('draft')}
      isHipaa={isHipaa}
      formID={formID}
      logout={this.logoutUser}
      saclId={saclId}
    />);
  };

  initDraftFlow = (draftInfo, newSaclId) => {
    if (!window.JotForm) return;
    if (!window.___JFsclActive) return;
    if (window.JotForm.isEditMode() || window.JotForm.isAdvancedDesigner || window.JotForm.isJotFormNext === 1 || this.isOfflineForms) return;
    if (window.location.pathname.includes('draft') || newSaclId) return;

    const session = document.get && document.get.session;
    switch (true) {
      case Boolean(session):
        if (draftInfo && draftInfo.session_id === session) {
          window.JotForm.setSubmissionID(draftInfo.id);
        }
        break;
      case Boolean(draftInfo):
        this.openDraftSelection(draftInfo);
        break;
      case Boolean(this.getLocalDraft()):
        this.openDraftSelection(this.getLocalDraft());
        break;
      default:
        break;
    }
  };

  setUser = (user, userType, permissions) => {
    if (!user) return;

    this.user = user;
    RenderAccountBox({
      ...user, userType, permissions, linkTarget: '_blank'
    }, this.logoutUser);
  };

  logoutUser = async () => {
    StorageHelper.removeLocalStorageItem({ key: this.localSessionKey }); // Remove details first
    if (window?.JFAppsManager?.isOpenedInPortal()) {
      await window.JFAppsManager.logUserOut();
    }
    const logoutURL = window.FormUserLogoutURL ? window.FormUserLogoutURL : window.location.href;
    window.location.href = `${getDomainURL()}/logout.php?backTo=${logoutURL}`;
  };

  getLocalDraft = () => {
    const localSession = StorageHelper.getLocalStorageItem({ key: this.localSessionKey });
    if (localSession && (document.get && !document.get.session)) {
      if ((new Date() - new Date(localSession.created_at)) / (1000 * 60 * 60 * 24) > 29) {
        StorageHelper.removeLocalStorageItem({ key: this.localSessionKey });
        return;
      }

      return localSession;
    }

    return false;
  };

  openDraftSelection = ({ session_id: sessionId, token } = {}) => {
    if (!sessionId) {
      return;
    }

    if (window?.JFAppsManager?.isProductListForm) return;

    if (
      window?.JFAppsManager?.isOpenedInPortal() // Opened in app
      && !window?.JFAppsManager?.isEmbeddedForm // form is NOT embedded
      && !!this.user // has valid user
    ) return;

    // dont show this modal in mobile apps
    if (window.sendMessageToJFMobile) {
      return;
    }

    const localSession = StorageHelper.getLocalStorageItem({ key: this.localSessionKey });
    const extraParams = localSession?.extraParams || {};

    const draftSelectionContainer = document.createElement('div');
    let draftLink = generateSessionLink(sessionId, token, this.formID);
    if (draftLink) {
      Object.keys(extraParams).forEach(paramKey => {
        if (typeof extraParams[paramKey] !== 'undefined') {
          draftLink += `&${paramKey}=${extraParams[paramKey]}`;
        }
      });
      draftSelectionContainer.className = 'formUserDraftSelectionContainer';
      document.body.appendChild(draftSelectionContainer);
      const root = createRoot(draftSelectionContainer);

      root.render(
        <TranslateProvider>
          <DraftSelectionModal
            onContinueWithDraft={() => {
              if (window?.JFAppsManager?.isOpenedInPortal()) {
                window.JFAppsManager.continueWithDraftOnApp(this.formID, sessionId, token);
                return;
              }
              window.location = draftLink;
            }}
            onContinueEmpty={() => root.unmount()}
          />
        </TranslateProvider>
      );
    }
  };

  // / SCL Methods

  createSCLManager = ({
    formID, onStateChange
  }) => {
    if (this.SCLManager) {
      console.log('SCL Manager already created');
      return;
    }
    this.SCLManager = new SCLManager({
      onStateChange,
      apiURL: this.apiURL,
      formID
    });

    return this.SCLManager;
  };

  setSaveAndContinueLater = ({
    formID, triggerButtonSelector, localConfiguration = {}, onStateChange
  }) => {
    const manager = this.createSCLManager({ formID, onStateChange });
    const SCLNavigationWrapper = document.createElement('div');
    SCLNavigationWrapper.className = 'formUserSCLNavigationWrapper';
    document.body.appendChild(SCLNavigationWrapper);
    const root = createRoot(SCLNavigationWrapper);
    const handleSCLButtonClick = e => {
      // eslint-disable-next-line no-param-reassign
      localConfiguration.active = true; // Lets mutate local config, in order to make cardforms working
      e.preventDefault();
      e.stopPropagation();
      root.render(
        <TranslateProvider>
          <SACLNavigation
            navigationWrapper={SCLNavigationWrapper}
            user={this.user}
            onUserLogout={this.logoutUser}
            manager={manager}
            apiURL={this.apiURL}
            skippable={this.skippable}
            enforceHIPAARuleSet={this.enforceHIPAARuleSet}
          />
        </TranslateProvider>
      );
    };

    document.querySelectorAll(triggerButtonSelector).forEach(target => {
      target.addEventListener('click', handleSCLButtonClick);
    });
    return manager;
  };

  initSCLFlow = () => {
    if (!window.JotForm) {
      return;
    }
    if (window.JotForm.isEditMode() || window.JotForm.isAdvancedDesigner) return;

    if (!window.___JFsclActive) return;
    delete window.___JFsclActive;

    if (this.FORM_TYPE === 'CARD') {
      const asyncInitFn = window.CardForm.asyncInitSaveAndContinueLaterFlow || (() => { console.warn('SCL Async init not found'); });
      asyncInitFn(this.setSaveAndContinueLater);
    } else {
      const manager = this.setSaveAndContinueLater({
        formID: document.forms[0].id,
        localConfiguration: {},
        triggerButtonSelector: '.js-new-sacl-button, .jfFormUser-header-saveAndContinueLater-button',
        onStateChange: nextState => console.warn('will do', nextState)
      });

      const sessionID = ('session' in document.get) && (document.get.session.length > 0) ? document.get.session : false;
      const submissionToken = ('stoken' in document.get) && (document.get.stoken.length > 0) ? document.get.stoken : false;
      if (sessionID && submissionToken) {
        window.onEditModeCompleted = () => {
          window?.JFAppsManager?.formReadyToContinue();

          manager.continue({
            sessionID,
            submissionToken,
            lastAnswers: manager.getInputNamesAndValues(document.forms[0]),
            email: this.emailAnswerFinder(manager)
          });

          manager.initiate();
        };
      }
    }
  };
}

window.JFFormUserHelper = new FormUserHelper();
