import React from 'react';
import ReactDOM from 'react-dom';
import SocialTermsModal from '../components/SocialTermsModal';

const ROOT_ID = 'terms-modal-root';

const createRootEl = () => {
  const rootEl = document.createElement('div');
  rootEl.setAttribute('id', ROOT_ID);

  document.body.appendChild(rootEl);
  return rootEl;
};

const getRootEl = () => {
  const termsModalRoot = document.querySelector(`#${ROOT_ID}`);
  if (termsModalRoot) {
    return termsModalRoot;
  }
  return createRootEl(ROOT_ID);
};

const removeModalFromDOM = () => {
  const termsModalRoot = getRootEl();
  if (termsModalRoot) {
    ReactDOM.unmountComponentAtNode(termsModalRoot);
  }
};

const renderStandaloneSocialTermsModal = props => {
  const rootEl = getRootEl();
  ReactDOM.render(
    <SocialTermsModal {...props} rootEl={rootEl} removeModalFromDOM={removeModalFromDOM} />,
    rootEl
  );
};

export default renderStandaloneSocialTermsModal;
