/* eslint-disable max-len */
// import React from 'react';
import {
  string, bool, number, oneOf
} from 'prop-types';
import styled, { css, keyframes } from 'styled-components';
import {
  darken, lighten, rgba
} from 'polished';
import { OptionGroup } from '@jotforminc/option-group';
import { colors } from '../helpers/constants';

const onMobile = '@media (max-width: 480px)';

export const ButtonUnstyled = styled.button` && {
  border: none;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;

  &,
  &:focus,
  &:hover {
    background-color: transparent;
  }
}`;

export const MethodList = styled(OptionGroup)`
  padding: 0;
  color: ${colors.navy};

  li {
    & + li {
      margin: 0;
    }

    > div {
      padding: 14px 12px;
      // margin-left: -8px; // to align radio with text
      border: 1px solid transparent;
      font-size: 14px;

      ${onMobile} {
        padding: 14px 6px;
      }

      &[data-selected=true] {
        border-color: #4573E3;
        border-radius: 4px;
        background-color: ${rgba('#E9EEFF', 0.4)};
      }
    }
  }

  .o-wrapper {
    position: relative;
    padding-left: 102px;
    height: 50px;
    margin-left: -6px;
    display: flex;
    justify-content: center;
    flex-direction: column;

    ${onMobile} {
      padding-left: 51px;
    }

    html[dir="rtl"] & {
      padding-left: 0;
      padding-right: 102px;

      ${onMobile} {
        padding-right: 51px;
      }
    }

    .o-label,
    .o-desc {
      line-height: 1.4;
    }

    .o-label {
      font-size: 18px;
      font-weight: 700;

      ${onMobile} {
        font-size: 15px;
      }

      svg {
        position: absolute;
        left: 12px;
        top: 50%;
        transform: translateY(-50%);
        max-height: 40px;
        max-width: 50px;
        width: 100%;
        height: 100%;

        ${onMobile} {
          left: 6px;
          max-height: 20px;
          max-width: 20px;
        }

        html[dir="rtl"] & {
          left: unset;
          right: 12px;

          ${onMobile} {
            right: 6px;
          }
        }
      }
    }

    .o-desc {
      font-size: 15px;
      font-weight: 400;

      ${onMobile} {
        font-size: 13px;
      }
    }
  }
`;

const rotate = initial => keyframes`
  from {
    transform: ${initial} rotate(0deg);
  }

  to {
    transform: ${initial} rotate(360deg);
  }
`;

export const TextCode = styled.input`
  height: 38px;
  font-size: 15px;
  text-align: center;
  border: 1px solid #C9C8C9;
  border-radius: 4px;
  margin-top: 8px;

  ${onMobile} {
    font-size: 13px;
  }
`;

export const QRCodeWrapper = styled.div`
  height: 124px;
  width: 124px;
  flex-shrink: 0;

  svg {
    display: block;
    height: 100%;
    width: 100%;
    opacity: 1;
    transition: opacity 333ms cubic-bezier(.55,.06,.68,.19);
  }

  ${({ color, loading }) => (loading ? css`
    position: relative;
    cursor: wait;

    svg {
      opacity: 0;
      pointer-events: none;

      &,
      &::before {
        font-size: 0;
        color: transparent;
      }
    }

    &::after {
      content: '' !important;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 16px;
      height: 16px;
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='8' cy='8' r='7.25' stroke='${encodeURIComponent(color)}' stroke-opacity='.5' stroke-width='1.5'/%3E%3Cmask id='a' maskUnits='userSpaceOnUse' x='0' y='0' width='16' height='16'%3E%3Cpath d='M8.27429 7.38462V0H0v16h16V8.53333h-2.6667L8.27429 7.38462z' fill='%23C4C4C4'/%3E%3C/mask%3E%3Cg mask='url(%23a)'%3E%3Ccircle cx='8' cy='8' r='7.25' stroke='${encodeURIComponent(color)}' stroke-width='1.5'/%3E%3C/g%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: center;
      animation: ${rotate(css`translate(-50%,-50%)`)} 1.5s linear infinite;
      pointer-events: none;
      z-index: 1;
    }
  ` : null)}
`;

QRCodeWrapper.propTypes = {
  color: string,
  loaded: bool
};

QRCodeWrapper.defaultProps = {
  color: '#4277FF',
  loaded: true
};

export const Image = styled.div`
  margin-top: 10px;
  margin-bottom: 12px;
`;

export const Text = styled.div`
  text-align: ${({ align }) => align};

  &:empty {
    display: none;
  }

  a {
    text-decoration: none;
  }

  &,
  d { color: ${colors.navy} };
  a,
  hl {
    &:not([color]) { color: ${colors.blue}; }
    &[color='green'] { color: ${colors.green}; }
    &[color='red'] { color: ${colors.red}; }
  }
  b { font-weight: 500; }
  b2 { font-weight: 700; }

  section {
    margin-top: 0;
    margin-bottom: 8px;
    display: flex;
    flex-direction: column;

    html[dir="rtl"] & {
      text-align: right;
    }

  }

  p {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 8px;
  }

`;

Text.defaultProps = {
  align: 'left'
};

export const Heading = styled(Text)`
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  color: ${colors.navy};
  margin-bottom: 20px;
`;

export const Description = styled(Text)`
  color: #8D8FA8;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Button = styled.button` && {
  position: relative;
  height: ${({ height }) => height}px;
  padding: 0 ${({ type }) => (['naked'].includes(type) ? 0 : 24)}px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-transform: ${({ letterCase }) => letterCase};
  user-select: none;
  transition-duration: 300ms;
  transition-property: color, background-color, border-color;
  transition-timing-function: ease-in-out;

  border-width: 1px;
  border-radius: 4px;
  border-style: solid;

  ${onMobile} {
    padding: 0 12px;
  }

  &,
  &:hover {
    border-color: ${({ type, color }) => (['naked'].includes(type) ? 'transparent' : color)};
    background-color: ${({ type, color }) => (['stroke', 'naked'].includes(type) ? 'transparent' : color)};
    background-clip: padding-box;
  }

  color: ${({ type, color, foregroundColor }) => (['stroke', 'naked'].includes(type) ? color : foregroundColor)};

  font-weight: 500;
  font-size: ${({ fontSize }) => fontSize}px;

  svg {
    max-height: 20px;
    width: 16px;
    height: 16px;
    margin-right: 8px;
    margin-left: -${({ type }) => (['naked'].includes(type) ? 0 : 12)}px;

    html[dir="rtl"] & {
      margin-right: -${({ type }) => (['naked'].includes(type) ? 0 : 12)}px;
      margin-left: 10px;
    }

    ${onMobile} {
      max-height: 16px;
      width: 16px;
      margin-left: -${({ type }) => (['naked'].includes(type) ? 0 : 6)}px;
      margin-right: 5px;

      html[dir="rtl"] & {
        margin-left: 5px;
        margin-right: -${({ type }) => (['naked'].includes(type) ? 0 : 6)}px;
      }
    }
  }

  ${({ onClick }) => (onClick ? css`
    cursor: pointer;

    &:hover {
      color: ${({ type, color, foregroundColor }) => lighten(0.05, (['stroke', 'naked'].includes(type) ? color : foregroundColor))};
      border-color: ${({ type, color }) => (['naked'].includes(type) ? 'transparent' : lighten(0.05, color))};
      background-color: ${({ type, color }) => (['stroke', 'naked'].includes(type) ? 'transparent' : lighten(0.05, color))};
    }

    &:focus {
      color: ${({ type, color, foregroundColor }) => darken(0.05, (['stroke', 'naked'].includes(type) ? color : foregroundColor))};
      border-color: ${({ type, color }) => (['naked'].includes(type) ? 'transparent' : darken(0.05, color))};
      background-color: ${({ type, color }) => (['stroke', 'naked'].includes(type) ? 'transparent' : darken(0.05, color))};
    }
  ` : null)}

  ${({
    loading, type, color, foregroundColor
  }) => (loading ? css`
    color: transparent !important;
    transition-duration: 0ms !important;
    cursor: wait;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 16px;
      height: 16px;
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='8' cy='8' r='7.25' stroke='${encodeURIComponent(['stroke', 'naked'].includes(type) ? color : foregroundColor)}' stroke-opacity='.5' stroke-width='1.5'/%3E%3Cmask id='a' maskUnits='userSpaceOnUse' x='0' y='0' width='16' height='16'%3E%3Cpath d='M8.27429 7.38462V0H0v16h16V8.53333h-2.6667L8.27429 7.38462z' fill='%23C4C4C4'/%3E%3C/mask%3E%3Cg mask='url(%23a)'%3E%3Ccircle cx='8' cy='8' r='7.25' stroke='${encodeURIComponent(['stroke', 'naked'].includes(type) ? color : foregroundColor)}' stroke-width='1.5'/%3E%3C/g%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: center;
      animation: ${rotate(css`translate(-50%,-50%)`)} 1.5s linear infinite;
      pointer-events: none;
      z-index: 1;
    }
  ` : null)}
}`;

Button.propTypes = {
  color: string,
  foregroundColor: string,
  height: number,
  fontSize: number,
  letterCase: oneOf(['none', 'lowercase', 'uppercase', 'capitalize']),
  type: oneOf(['stroke', 'naked'])
};

Button.defaultProps = {
  color: '#0099FF',
  foregroundColor: '#ffffff',
  height: 36,
  fontSize: 14,
  letterCase: 'uppercase',
  type: null
};

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${({ align }) => align};
  justify-content: center;
  padding: 16px ${({ wide }) => (wide ? 64 : 32)}px 26px;

  ${onMobile} {
    padding-left: 16px;
    padding-right: 16px;
  }

`;

Body.defaultProps = {
  align: 'stretch',
  wide: null
};

export const Footer = styled.div`
  display: flex;
  justify-content: ${({ align }) => align};
  flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};
  padding: 12px 32px;
  border-top: 1px solid #EAEBF2;
  align-self: stretch;

  ${onMobile} {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

Footer.propsTypes = {
  align: string,
  reverse: bool
};

Footer.defaultProps = {
  align: 'space-between',
  reverse: false
};

export const Container = styled.div`
  font-family: 'Circular', sans-serif;
  font-size: 15px;
  line-height: 24px;
  display: flex;
  flex-direction: column;

  ${({ center }) => (center ? css`
    align-items: center;
    justify-content: center;
  ` : null)}

  flex: 1;
  height: 100%;
  width: 100%;
  // max-width: 510px;

  ${Body} {
    // margin: auto 0;
  }

  ${Footer} {
    margin-top: auto;
  }
`;

const MessageContaiener = styled.div`
  font-family: 'Circular', sans-serif;
  font-size: 11px;
  line-height: 1.28;
  display: flex;
  flex-direction: column;
  background-color: ${colors.grey};
  color: #fff;
  border-radius: 4px;
  background-size: 12px;
  background-position: 6px;
  text-align: left;
  background-repeat: no-repeat;
  padding: 5px 5px 5px 24px;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.1683 1.6843a6.0016 6.0016 0 011.8315 4.2636 6.0002 6.0002 0 01-8.368 5.565 6.0001 6.0001 0 116.5365-9.8286zm-3.642 3.642v-2.4h-1.2v3.6h1.2v-1.2zm0 3.6v-1.2h-1.2v1.2h1.2z' fill='%23fff'/%3E%3C/svg%3E");
  flex: 1;
  height: 100%;
  width: 100%;
  // max-width: 510px;
`;

export const ErrorContainer = styled(MessageContaiener)`
  background-color: ${colors.red};
`;

export const InfoContainer = styled(MessageContaiener)`
  background-color: ${colors.blue};
`;
