/* eslint-disable react/prop-types, react/react-in-jsx-scope */
import React from 'react';
import PropTypes from 'prop-types';
import { t } from '@jotforminc/translation';
import { getDomainURL } from '@jotforminc/request-layer';

import { handleCustomNavigation } from '@jotforminc/utils';
import { Utils } from '@jotforminc/uikit';

import onClickOutsideHOC from './HOCs/OnClickOutside';
import { Texts } from '../constants';
import '../styles/formUserAccountBox.scss';

class FormUserAccountBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isTooltipOpen: false
    };
  }

  componentDidMount() {
    const { isTooltipOpen } = this.state;
    const { onTooltipToggle } = this.props;
    onTooltipToggle(isTooltipOpen);
  }

  componentDidUpdate(_, prevState) {
    const { isTooltipOpen } = this.state;
    const { onTooltipToggle } = this.props;
    if (isTooltipOpen !== prevState.isTooltipOpen) onTooltipToggle(isTooltipOpen);
  }

  handleAvatarBoxClick = () => {
    const { overrideAvatarBoxClick } = this.props;
    if (overrideAvatarBoxClick && overrideAvatarBoxClick instanceof Function) {
      overrideAvatarBoxClick();
    } else {
      const { isTooltipOpen } = this.state;
      this.setState({
        isTooltipOpen: !isTooltipOpen
      });
    }
  };

  handleClickOutside() {
    const { isTooltipOpen } = this.state;
    if (isTooltipOpen) {
      this.setState({ isTooltipOpen: false });
    }
  }

  get avatarUrl() {
    const { avatarUrl } = this.props;
    return avatarUrl;
  }

  render() {
    const {
      name, email, onUserLogout, userType, allowMyForms, isPreview,
      linkTarget, additionalLinks
    } = this.props;
    const { isTooltipOpen } = this.state;
    return (
      <div className={`formAccountBox-wrapper ${isPreview ? 'forPreview' : ''}`}>
        <div
          className="avatarBox"
          role='button'
          tabIndex="0"
          aria-label={t('Open Account Box')}
          onKeyDown={e => (Utils.isPressedKeyEnter(e) && this.handleAvatarBoxClick(e))}
          onClick={this.handleAvatarBoxClick}
          style={{ backgroundImage: `url("${this.avatarUrl}")` }}
        />
        <div
          className={`formAccountBox-tooltip ${isTooltipOpen ? 'isOpen' : 'isClosed'}`}
          role="tooltip"
        >
          <div className="formAccountBox-line forUserInfo">
            <span>{t(Texts.HELLO_TEXT)}</span>
            {' '}
            <span className="formAccountBox-userInfo" title={name || email}>{name || email}</span>
          </div>
          {!additionalLinks?.append && additionalLinks?.links?.map(({ text, url, LinkRenderer }) => (
            <div className="formAccountBox-line" key={url}>
              <LinkRenderer text={text} url={url} />
            </div>
          ))}
          {allowMyForms && (
            <div className="formAccountBox-line">
              <a
                target={linkTarget}
                href={userType === 'USER' ? `${getDomainURL()}/myforms` : `${getDomainURL()}/listings`}
                onClick={e => {
                  e.preventDefault();
                  handleCustomNavigation(userType === 'USER' ? `${getDomainURL()}/myforms` : `${getDomainURL()}/listings`, linkTarget, true);
                }}
                className="fab-link"
                name="See my forms"
              >
                {t(Texts.MY_FORMS)}
              </a>
            </div>
          )}
          <div className="formAccountBox-line">
            <a
              target={linkTarget}
              href={`${getDomainURL()}/myaccount`}
              onClick={e => {
                e.preventDefault();
                handleCustomNavigation(`${getDomainURL()}/myaccount`, linkTarget, true);
              }}
              className="fab-link"
              name="See my profile"
            >
              {t(Texts.PROFILE)}
            </a>
          </div>
          {additionalLinks?.append && additionalLinks?.links?.map(({ text, url, LinkRenderer }) => (
            <div className="formAccountBox-line" key={url}>
              <LinkRenderer text={text} url={url} />
            </div>
          ))}
          <div className="formAccountBox-line forLogout">
            <div
              className="formAccountBox-logout"
              role="button"
              tabIndex="0"
              aria-label={t(Texts.LOG_OUT)}
              onKeyDown={e => (Utils.isPressedKeyEnter(e) && onUserLogout(e))}
              onClick={onUserLogout}
            >
              {t(Texts.LOG_OUT)}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

FormUserAccountBox.propTypes = {
  onUserLogout: PropTypes.func.isRequired,
  allowMyForms: PropTypes.bool,
  userType: PropTypes.string,
  avatarUrl: PropTypes.string,
  isPreview: PropTypes.bool,
  overrideAvatarBoxClick: PropTypes.func,
  linkTarget: PropTypes.string,
  onTooltipToggle: PropTypes.func,
  additionalLinks: PropTypes.shape({
    links: PropTypes.arrayOf(PropTypes.shape({
      text: PropTypes.string,
      url: PropTypes.string,
      LinkRenderer: PropTypes.elementType
    })),
    append: PropTypes.bool
  })
};

FormUserAccountBox.defaultProps = {
  userType: 'FORM_USER',
  allowMyForms: false,
  avatarUrl: 'JOTFORM_ENV' in window && window.JOTFORM_ENV === 'ENTERPRISE' ? 'https://cdn.jotfor.ms/images/enterprise_empty_avatar.png' : 'https://cdn.jotfor.ms/assets/img/v4/avatar/Podo-Guest.png',
  isPreview: false,
  overrideAvatarBoxClick: null,
  linkTarget: '_self',
  onTooltipToggle: f => f,
  additionalLinks: {
    append: false,
    links: []
  }
};

export default onClickOutsideHOC(FormUserAccountBox);
