import React from 'react';
import PropTypes from 'prop-types';

import '../../styles/style.scss';

const ErrorScreen = props => {
  const {
    Icon, text, subText, type, buttonText, handleButtonClick
  } = props;

  return (
    <div className="sacl-modal sacl__full-page-error">
      {!window.isEnterprise ? (
        <a href="https://www.jotform.com" className="sacl-jotform-link">
          <img
            className="sacl-logo-image" border="0" height="55"
            src="https://cdn.jotfor.ms/assets/img/logo2021/jotform-logo-white.svg" alt="Back to Jotform.com"
          />
        </a>
      ) : null}
      <div className="sacl-modal__content">
        <div className='sacl-modal__center pb-32 pt-44'>
          <div className={`sacl-modal__logo sacl-form-message-icon ${type === 'UnauthorizedScreen' ? 'sacl-form-access-message-icon' : 'sacl-form-error-message-icon'}`}>
            {Icon}
          </div>
        </div>
        <div className="sacl-modal__texts">
          <h1>{text}</h1>
          {subText ? (
            <span>{subText}</span>
          ) : null}
        </div>
        <div className="sacl-modal__center pb-40 pt-24">
          {window.formID ? (
            <button
              className="sacl-btn sacl-btn--error" type="button" onClick={handleButtonClick}
            >
              {buttonText}
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

ErrorScreen.propTypes = {
  Icon: PropTypes.node.isRequired,
  text: PropTypes.string.isRequired,
  subText: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  buttonText: PropTypes.object.isRequired,
  handleButtonClick: PropTypes.func.isRequired
};

export default ErrorScreen;
