import React, {
  useRef, useImperativeHandle, forwardRef, useEffect
} from 'react';
import {
  arrayOf, oneOfType, string, number, shape, elementType, bool, node, func
} from 'prop-types';

import OptionGroup from '../OptionGroup';

import { useSelectionState, useEffectIgnoreFirst, useCombinedRefs } from '../../utils/hooks';

const SelectionGroup = forwardRef(({
  options,
  disabled,
  defaultValue,
  isMultiSelect,
  GroupRenderer,
  OptionRenderer,
  ContainerRenderer,
  OptionContainerRenderer,
  OptionContainerRendererProps,
  onSelectionChange,
  autoFocusToFirstChild
}, ref) => {
  const [selectedOptionList, toggleSelectedOption, setSelectedOptionList] = useSelectionState(defaultValue, isMultiSelect);

  const optionGroupRef = useRef();
  const rootRef = useCombinedRefs(ref, optionGroupRef);
  useImperativeHandle(rootRef, () => ({
    current: rootRef.current,
    setSelectedOptionList
  }));

  const changeSelectedOptions = value => !disabled && toggleSelectedOption(value);
  const checkOptionSelectionState = option => selectedOptionList.indexOf(option.value) > -1;
  const onOptionSelection = option => !disabled && !option.disabled && changeSelectedOptions(option.value);

  useEffectIgnoreFirst(() => {
    onSelectionChange(isMultiSelect ? selectedOptionList : selectedOptionList[0]);
  }, [selectedOptionList]);

  useEffect(() => {
    if (autoFocusToFirstChild) rootRef?.current?.current?.firstChild?.focus();
  }, [autoFocusToFirstChild]);

  return (
    <OptionGroup
      ref={rootRef}
      options={options}
      disabled={disabled}
      role="radiogroup"
      tabIndex="0"
      aria-disabled={disabled}
      GroupRenderer={GroupRenderer}
      setSelected={onOptionSelection}
      OptionRenderer={OptionRenderer}
      OptionContainerRendererProps={OptionContainerRendererProps}
      ContainerRenderer={ContainerRenderer}
      OptionContainerRenderer={OptionContainerRenderer}
      checkOptionSelectionState={checkOptionSelectionState}
    />
  );
});

SelectionGroup.propTypes = {
  disabled: bool,
  options: arrayOf(shape({
    text: oneOfType([string, node]),
    value: oneOfType([string, number])
  })),
  defaultValue: oneOfType([string, number, arrayOf(oneOfType([string, number]))]),
  isMultiSelect: bool,
  GroupRenderer: elementType,
  OptionRenderer: elementType,
  OptionContainerRenderer: elementType,
  OptionContainerRendererProps: oneOfType([
    func,
    shape({})
  ]),
  ContainerRenderer: elementType,
  onSelectionChange: func,
  autoFocusToFirstChild: bool
};

/* eslint react/prop-types: "off" */
SelectionGroup.defaultProps = {
  disabled: false,
  options: [],
  defaultValue: undefined,
  isMultiSelect: false,
  GroupRenderer: ({ text, children, ...props }) => (
    <div {...props}>
      <b>{text}</b>
      <div>{children}</div>
    </div>
  ),
  ContainerRenderer: forwardRef(({ children }, ref) => <ul aria-label="options" role="listbox" ref={ref}>{children}</ul>),
  OptionRenderer: ({ option: { text, value }, isSelected }) => <div data-value={value} data-selected={isSelected}>{`${text}${isSelected ? '- SELECTED' : ''}`}</div>,
  OptionContainerRenderer: props => <li {...props} />,
  OptionContainerRendererProps: {},
  onSelectionChange: f => f,
  autoFocusToFirstChild: false
};

export default SelectionGroup;
