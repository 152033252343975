import React from 'react';
import PropTypes from 'prop-types';
import { t } from '@jotforminc/translation';
import { Texts } from '@jotforminc/constants';

import LinkIcon from '../assets/svg/icon_link.svg';

export default class SharableLinkButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      link: ''
    };
  }

  componentWillUnmount() {
    if (this.infoTimeout) {
      clearTimeout(this.infoTimeout);
    }
  }

  handleCopyLink = () => {
    const { manager } = this.props;
    const { link } = manager.generateLink();
    this.setState({
      link
    }, () => {
      this.copyTextToClipboard(link);
    });
  };

  messages = {
    copied: t(Texts.COPIED_CLIPBOARD),
    error: t(Texts.DOESNT_SUPPORT_BROWSER)
  };

  afterCopy = result => {
    if (result) {
      this.setState({
        copied: true
      });
      this.infoTimeout = setTimeout(() => {
        this.setState({
          copied: false
        });
      }, 3000);
    } else {
      this.setState({
        error: true
      });
    }
  };

  fallbackCopyTextToClipboard(text) {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    textArea.style.position = 'fixed';
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    let retVal = false;
    try {
      const successful = document.execCommand('copy');
      retVal = !!successful;
    } catch (err) {
      retVal = false;
    }

    document.body.removeChild(textArea);
    return retVal;
  }

  copyTextToClipboard(text) {
    if (!window.navigator.clipboard) {
      const result = this.fallbackCopyTextToClipboard(text);
      return this.afterCopy(result);
    }
    window.navigator.clipboard.writeText(text).then(() => {
      return this.afterCopy(true);
    }, () => {
      return this.afterCopy(false);
    });
  }

  render() {
    const { buttonMode } = this.props;
    const { link, error, copied } = this.state;
    return (
      <>
        {error && (
        <div>
          <span>Your browser does not support copy text feature. You can manually copy it.</span>
          <input type="text" className="jfInput u-tooltipTrigger" value={link} />
        </div>
        )}
        {!error && (
        <button className={`xcl-button-li ${buttonMode || ''} u-tooltipTrigger forLink ${(copied || error) ? 'isActive' : ''}`} type="button" onClick={this.handleCopyLink}>
          <LinkIcon />
          {t(Texts.GET_SHARE_LINK)}
          {(copied || error) && (
          <span className="jfTooltip u-neutral u-southWest u-tethered saclLinkButton-tooltip">
            {copied ? t(this.messages.copied) : t(this.messages.error)}
          </span>
          )}
        </button>
        )}
      </>
    );
  }
}

SharableLinkButton.propTypes = {
  buttonMode: PropTypes.string.isRequired,
  manager: PropTypes.shape().isRequired
};
