import { getPossibleTimeZone } from '@jotforminc/utils';
import { INITIATOR_TYPES } from '@jotforminc/constants';

import { getScreenResolution } from '../utils/loginSignup';
import LoginFlowRequestManager from './manager';

const ServerManager = new LoginFlowRequestManager({ baseURI: '/server.php', serverRequest: true });
const APIManager = new LoginFlowRequestManager();

export const registerNewUser = ({
  email,
  password,
  name,
  username = '',
  buttonName,
  refApp,
  isAgreedTerms = '0',
  recaptcha = ''
}) => ServerManager.rsPost({
  action: 'registerNewUser',
  email,
  password,
  name,
  isAgreedTerms,
  timezone: getPossibleTimeZone(),
  screen_resolution: getScreenResolution(),
  location: window.location.pathname,
  username,
  button_name: buttonName,
  ref_app: refApp,
  return: 1,
  recaptcha
});

export const isEmailEligibleForRegister = ({ email, verificationHash = '' }) => ServerManager.rsPost({
  action: 'isEmailEligibleForRegister',
  email,
  ...(verificationHash && { form_user_hash: verificationHash })
});

export const formUserPretendCreate = ({
  email,
  password,
  name,
  verificationHash,
  isHIPAA = '',
  isAgreedTerms = '0'
}) => APIManager.post('formuser/pretendCreate?master=1', new URLSearchParams({
  email,
  password,
  name,
  isAgreedTerms,
  hash: verificationHash,
  ...(isHIPAA && { isHIPAA })
}));

export const formUserCreate = ({
  email,
  password,
  name,
  sendVerification,
  initiator,
  isHIPAA = '',
  appName = '',
  redirectURL = '',
  isAgreedTerms = '0'
}) => APIManager.post('formuser/register?master=1', new URLSearchParams({
  email,
  password,
  confirm_password: password,
  name,
  sendVerification,
  isAgreedTerms,
  ...(isHIPAA && { isHIPAA }),
  disablePasswordConstraints: '1',
  saclSignup: initiator === INITIATOR_TYPES.SACL,
  ...(initiator && { initiator }),
  ...(appName === 'portal-apps' && window) && { jotform_pwa: '1', referrerURL: window.location.href },
  ...(redirectURL && window) && { redirectUrl: redirectURL }
}));

export const getEnterpriseConfigs = () => APIManager.get('enterprise/customized-configs');

/**
 * Given a language code, return the full
 * locale code.
 * @param {string} langCode ISO 639-1 language code.
 * @returns langCode-countryCode locale code.
 */
export const getFullLangCode = langCode => APIManager.get(`translation/full-lang-code/${langCode}`);

/**
 * Atttempt to fetch current user, if that fails,
 * attempt to fetch a formuser. combinedinfo does not
 * work here, as its return value does not fit the
 * requested parameter of the onLoginSuccess.
 */
export const fetchUser = () => APIManager.get('user')
  .catch(() => APIManager.get('formuser'));

/**
 * Check if the user should login to the server via SSO.
 *
 * @param {string} usernameOrEmail Email or username belonging to user.
 * @returns SSO status of the user.
 */
export const getUserSSOStatus = usernameOrEmail => APIManager.get(`users/${usernameOrEmail}/ssoStatus`);

export const send2FAAccountRecoveryEmail = () => APIManager.post('mfa/account-recovery/send-email');

export const dispatchAction = ({ actor, action, target }) => APIManager.post('t/jfrev', {
  project: 'login-flow',
  actor,
  location: window.location && window.location.href,
  oldActions: {
    action,
    target
  }
});
