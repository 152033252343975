import React from 'react';
import PropTypes from 'prop-types';
import { t, translationRenderer } from '@jotforminc/translation';
import {
  isEmail,
  listenEnter,
  emailAnswerFinder
} from '@jotforminc/utils';
import { Texts } from '@jotforminc/constants';

import SavedDraftIcon from '../assets/svg/sacl_saved_draft.svg';
import BackIcon from '../assets/svg/icon_back.svg';
import SendIcon from '../assets/svg/sacl_send.svg';
import SharableLinkButton from './SharableLinkButton';

export default class WithoutSignup extends React.Component {
  constructor(props) {
    super(props);

    const email = emailAnswerFinder();
    this.state = {
      email: email || ''
    };
  }

  componentDidMount() {
    const { manager } = this.props;
    manager.initiate();
  }

  handleSendLinkClick = () => {
    const { onNavigationChangeRequest, manager } = this.props;
    const { email } = this.state;
    if (isEmail(email)) {
      manager.setHiddenEmailSentTo(email);
      manager.sendEmail(email);
      onNavigationChangeRequest('epilogue');
    } else {
      this.setState({
        emailError: 'Please enter valid email'
      });
    }
  };

  handleEmailChange = e => {
    const { value } = e.target;
    const { emailError } = this.state;
    const nextState = {
      email: value.trim()
    };
    if (emailError && isEmail(nextState.email)) {
      nextState.emailError = false;
    }
    this.setState(nextState);
  };

  handleKeyDown = e => listenEnter(e, this.handleSendLinkClick);

  render() {
    const { onNavigationChangeRequest, manager } = this.props;
    const { email, emailError } = this.state;
    return (
      <div className="xcl-content">
        <div className="xcl-wrp">
          <button className="xcl-button-back" onClick={() => onNavigationChangeRequest('signupOptions')} type="button">
            <BackIcon />
            {t(Texts.BACK_KEY)}
          </button>
          <span className="icon_savedDraft">
            <SavedDraftIcon />
          </span>
          <h3 className="m2ct">{t(Texts.SUBMISSIONS_SAVED_DRAFT)}</h3>
          <p className="forSavedDraftDesc">{t(Texts.ENTER_EMAIL)}</p>
          <div className="xcl-sew">
            <input
              className={`xcl-inp ${emailError ? 'errored' : ''}`}
              type="email"
              name="email"
              placeholder={t(Texts.ENTER_YOUR_EMAIL)}
              onChange={this.handleEmailChange}
              onKeyDown={this.handleKeyDown}
              value={email}
            />
            <button className="xcl-button-re forSaclSend" type="button" onClick={this.handleSendLinkClick}>
              <SendIcon />
              {t(Texts.SEND)}
            </button>
            {emailError
              ? (
                <div className="xcl-lbl-err forWs isVisible">
                  {emailError}
                </div>
              ) : null}
            {(emailError === Texts.EMAIL_ERROR || emailError === Texts.EMAIL_ERROR_ENTERPRISE) && (
              <>
                &nbsp;
                {translationRenderer(Texts.PLEASE_LOGIN)({
                  renderer1: text => (<a className='error-login-button' onClick={() => onNavigationChangeRequest('loginOptions')}>{text}</a>)
                })}
              </>
            )}
          </div>
          <div className="forSaclSharableLink">
            <SharableLinkButton buttonMode="light" manager={manager} />
          </div>
        </div>
      </div>
    );
  }
}

WithoutSignup.propTypes = {
  onNavigationChangeRequest: PropTypes.func.isRequired,
  manager: PropTypes.shape().isRequired
};
