export const KEY_KEYCODE_LIST = {
  Enter: 13,
  Backspace: 8,
  Comma: 188,
  Tab: 9,
  Space: 32,
  Escape: 27
};

export const SERVER_ERROR = 'Server error';
export const FILE_TYPE_ERROR = 'Unsupported file type';
