import { makeConstant } from './utils';

export const SC = makeConstant({
  PARAMETER: 'draft',
  STATES: {
    IDLE: 'IDLE',
    SAVE: 'SAVE',
    DRAFT: 'DRAFT'
  }
});

export const DOM = makeConstant({
  SAVE_BUTTON_SELECTOR: '.js-new-sacl-button, .jfFormUser-header-saveAndContinueLater-button',
  NEXT_BUTTON_SELECTOR: '[data-component="pagebreak-next"]',
  FORM_FIELD_SELECTOR: 'li.form-line',
  DRAFT_SELECTION_MODAL_CONTAINER_CLASS_NAME: 'formUserDraftSelectionContainer',
  SC_MODAL_CONTAINER_CLASS_NAME: 'formUserSCLNavigationWrapper'
});

export const excludedFields = ['control_button', 'control_head', 'control_appointment', 'control_autoincrement'];
/* Weekly Appointment Planner: giftRegistry  */
export const excludedWidgets = ['inventory', 'giftRegistry', 'inventoryV2', 'giftRegistryV2'];

export const Texts = {
  DRAFT_SAVED: 'Submission saved as draft',
  INCOMPLETE_SUBMISSION: 'You have an incomplete submission for this form',
  DRAFT_TEXT: 'Discard this submission draft to start over, or continue with your saved draft.',
  DISCARD_DRAFT: 'Discard and start over',
  CONTINUE_WITH_DRAFT: 'Continue with draft',
  SAVE_AS_DRAFT: 'Sign up to save submission as a draft',
  PLEASE_WAIT_SAVE: 'Please wait while we save your submission.',
  SAVE_ERROR: "Your submission couldn't be saved",
  PLEASE_TRY_AGAIN: 'Please try again.',
  TRY_AGAIN: 'Try Again',
  GET_DRAFT_LINK: 'Get Draft Link',
  DRAFT_BEING_SAVED: 'Your submission is being saved',
  ENTER_EMAIL_ADDRESS: 'Enter your email address',
  PLEASE_ENTER_VALID_EMAIL: 'Please enter valid email',
  CONTINUE_FORM_LATER: 'If you want to continue your form later, please enter your email address.',
  TO_KEY: 'To',
  PLEASE_WAIT: 'Please wait',
  LOGIN_SAVE: 'Login and Save Submission',
  SAVE_SUBMISSION: 'Save Submission',
  COPIED_CLIPBOARD: 'Link copied to clipboard!',
  DOESNT_SUPPORT_BROWSER: 'Your browser doesn\'t support this operation',
  CONTINUE_LATER: 'We’ve emailed you a link you can use to continue it later.',
  SENT_TO: 'Email sent to',
  RE_EMAIL: 'Resend Email',
  SEND: 'Send',
  DONT_ACCESS_DRAFT: 'You don’t have access to this submission draft',
  FILL_OUT_DRAFT: 'Fill out the form to create a new submission.',
  CHANGE_ACCOUNT: 'Change Account',
  START_OVER_FORM: 'Start Over',
  NOT_FOUND: 'Submission draft not found',
  DELETED_DRAFT: 'This draft was deleted or has already been submitted.'
};
