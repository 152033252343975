import React, { useEffect } from 'react';
import { shape, string, func } from 'prop-types';
import { Loading } from '@jotforminc/loading';

const SocialInit = ({
  socialInitData: {
    type,
    token,
    response = {}
  },
  socialLoginRequest,
  handle2FASocialLoginRequest,
  handleSocialEAResponse
}) => {
  useEffect(() => {
    const { primaryMethod = '' } = response;
    if (primaryMethod && !response?.emailAuthToken) {
      handle2FASocialLoginRequest(response);
    } else if (response?.emailAuthToken) {
      handleSocialEAResponse(response, type);
    } else {
      socialLoginRequest(type, token);
    }
  }, []);

  return (
    <div className="xcl-content twoFactorLogin">
      <Loading />
    </div>
  );
};

SocialInit.propTypes = {
  socialInitData: shape({
    type: string.isRequired,
    token: string.isRequired,
    response: shape({}).isRequired
  }).isRequired,
  socialLoginRequest: func.isRequired,
  handle2FASocialLoginRequest: func,
  handleSocialEAResponse: func
};

SocialInit.defaultProps = {
  handle2FASocialLoginRequest: f => f,
  handleSocialEAResponse: f => f
};

export default SocialInit;
