const brand = {
  yellow: {
    bg: {
      base: 'bg-yellow-400',
      hover: 'hover:bg-yellow-400'
    },
    border: {
      base: 'border-yellow-400',
      hover: 'hover:border-yellow-400'
    },
    color: {
      base: 'color-yellow-400',
      hover: 'hover:color-yellow-400'
    }
  },
  orange: {
    bg: {
      base: 'bg-orange-500',
      hover: 'hover:bg-orange-500'
    },
    border: {
      base: 'border-orange-500',
      hover: 'hover:border-orange-500'
    },
    color: {
      base: 'color-orange-500',
      hover: 'hover:color-orange-500'
    }
  },
  blue: {
    bg: {
      base: 'bg-blue-400',
      hover: 'hover:bg-blue-400'
    },
    border: {
      base: 'border-blue-400',
      hover: 'hover:border-blue-400'
    },
    color: {
      base: 'color-blue-400',
      hover: 'hover:color-blue-400'
    }
  },
  dark: {
    bg: {
      base: 'bg-navy-700',
      hover: 'hover:bg-navy-700'
    },
    border: {
      base: 'border-navy-700',
      hover: 'hover:border-navy-700'
    },
    color: {
      base: 'color-navy-700',
      hover: 'hover:color-navy-700'
    }
  }
} as const;

const text = {
  lightest: {
    base: 'color-navy-50',
    hover: 'hover:color-navy-50'
  },
  light: {
    base: 'color-navy-100',
    hover: 'hover:color-navy-100'
  },
  medium: {
    base: 'color-navy-300',
    hover: 'hover:color-navy-300'
  },
  dark: {
    base: 'color-navy-500',
    hover: 'hover:color-navy-500'
  },
  darkest: {
    base: 'color-navy-700',
    hover: 'hover:color-navy-700'
  }
} as const;

const background = {
  lightest: {
    base: 'bg-navy-25',
    focus: 'focus:bg-navy-25',
    focusWithin: 'focus-within:bg-navy-25',
    hover: 'hover:bg-navy-25'
  },
  light: {
    base: 'bg-navy-75',
    focus: 'focus:bg-navy-75',
    focusWithin: 'focus-within:bg-navy-75',
    hover: 'hover:bg-navy-75'
  },
  medium: {
    base: 'bg-navy-100',
    focus: 'focus:bg-navy-100',
    focusWithin: 'focus-within:bg-navy-100',
    hover: 'hover:bg-navy-100'
  },
  dark: {
    base: 'bg-navy-300',
    focus: 'focus:bg-navy-300',
    focusWithin: 'focus-within:bg-navy-300',
    hover: 'hover:bg-navy-300'
  },
  darkest: {
    base: 'bg-navy-700',
    focus: 'focus:bg-navy-700',
    focusWithin: 'focus-within:bg-navy-700',
    hover: 'hover:bg-navy-700'
  }
} as const;

const border = {
  light: {
    base: 'border-navy-50',
    hover: 'hover:border-navy-50'
  },
  medium: {
    base: 'border-navy-100',
    hover: 'hover:border-navy-100'
  },
  dark: {
    base: 'border-navy-500',
    hover: 'hover:border-navy-500'
  },
  darkest: {
    base: 'border-navy-700',
    hover: 'hover:border-navy-700'
  }
} as const;

const accent = {
  lightest: {
    bg: {
      base: 'bg-blue-100',
      focus: 'focus:bg-blue-100',
      focusWithin: 'focus-within:bg-blue-100',
      hover: 'hover:bg-blue-100'
    },
    border: {
      base: 'border-blue-100',
      focus: 'focus:border-blue-100',
      focusWithin: 'focus-within:border-blue-100',
      hover: 'hover:border-blue-100'
    },
    color: {
      base: 'color-blue-100',
      focus: 'focus:color-blue-100',
      focusWithin: 'focus-within:color-blue-100',
      hover: 'hover:color-blue-100'
    }
  },
  light: {
    bg: {
      base: 'bg-blue-300',
      focus: 'focus:bg-blue-300',
      focusWithin: 'focus-within:bg-blue-300',
      hover: 'hover:bg-blue-300'
    },
    border: {
      base: 'border-blue-300',
      focus: 'focus:border-blue-300',
      focusWithin: 'focus-within:border-blue-300',
      hover: 'hover:border-blue-300'
    },
    color: {
      base: 'color-blue-300',
      focus: 'focus:color-blue-300',
      focusWithin: 'focus-within:color-blue-300',
      hover: 'hover:color-blue-300'
    }
  },
  default: {
    bg: {
      base: 'bg-blue-500',
      focus: 'focus:bg-blue-500',
      focusWithin: 'focus-within:bg-blue-500',
      hover: 'hover:bg-blue-500'
    },
    border: {
      base: 'border-blue-500',
      focus: 'focus:border-blue-500',
      focusWithin: 'focus-within:border-blue-500',
      hover: 'hover:border-blue-500'
    },
    color: {
      base: 'color-blue-500',
      focus: 'focus:color-blue-500',
      focusWithin: 'focus-within:color-blue-500',
      hover: 'hover:color-blue-500'
    }
  },
  dark: {
    bg: {
      base: 'bg-blue-600',
      focus: 'focus:bg-blue-600',
      focusWithin: 'focus-within:bg-blue-600',
      hover: 'hover:bg-blue-600'
    },
    border: {
      base: 'border-blue-600',
      focus: 'focus:border-blue-600',
      focusWithin: 'focus-within:border-blue-600',
      hover: 'hover:border-blue-600'
    },
    color: {
      base: 'color-blue-600',
      focus: 'focus:color-blue-600',
      focusWithin: 'focus-within:color-blue-600',
      hover: 'hover:color-blue-600'
    }
  }
} as const;

const success = {
  lightest: {
    bg: {
      base: 'bg-green-100',
      hover: 'hover:bg-green-100'
    },
    border: {
      base: 'border-green-100',
      hover: 'hover:border-green-100'
    },
    color: {
      base: 'color-green-100',
      hover: 'hover:color-green-100'
    }
  },
  light: {
    bg: {
      base: 'bg-green-200',
      hover: 'hover:bg-green-200'
    },
    border: {
      base: 'border-green-200',
      hover: 'hover:border-green-200'
    },
    color: {
      base: 'color-green-200',
      hover: 'hover:color-green-200'
    }
  },
  default: {
    bg: {
      base: 'bg-green-500',
      hover: 'hover:bg-green-500'
    },
    border: {
      base: 'border-green-500',
      hover: 'hover:border-green-500'
    },
    color: {
      base: 'color-green-500',
      hover: 'hover:color-green-500'
    }
  },
  dark: {
    bg: {
      base: 'bg-green-600',
      hover: 'hover:bg-green-600'
    },
    border: {
      base: 'border-green-600',
      hover: 'hover:border-green-600'
    },
    color: {
      base: 'color-green-600',
      hover: 'hover:color-green-600'
    }
  }
} as const;

const error = {
  lightest: {
    bg: {
      base: 'bg-red-100',
      focus: 'focus:bg-red-100',
      focusWithin: 'focus-within:bg-red-100',
      hover: 'hover:bg-red-100'
    },
    border: {
      base: 'border-red-100',
      focus: 'focus:border-red-100',
      focusWithin: 'focus-within:border-red-100',
      hover: 'hover:border-red-100'
    },
    color: {
      base: 'color-red-100',
      focus: 'focus:color-red-100',
      focusWithin: 'focus-within:color-red-100',
      hover: 'hover:color-red-100'
    }
  },
  light: {
    bg: {
      base: 'bg-red-200',
      focus: 'focus:bg-red-200',
      focusWithin: 'focus-within:bg-red-200',
      hover: 'hover:bg-red-200'
    },
    border: {
      base: 'border-red-200',
      focus: 'focus:border-red-200',
      focusWithin: 'focus-within:border-red-200',
      hover: 'hover:border-red-200'
    },
    color: {
      base: 'color-red-200',
      focus: 'focus:color-red-200',
      focusWithin: 'focus-within:color-red-200',
      hover: 'hover:color-red-200'
    }
  },
  default: {
    bg: {
      base: 'bg-red-400',
      focus: 'focus:bg-red-400',
      focusWithin: 'focus-within:bg-red-400',
      hover: 'hover:bg-red-400'
    },
    border: {
      base: 'border-red-400',
      focus: 'focus:border-red-400',
      focusWithin: 'focus-within:border-red-400',
      hover: 'hover:border-red-400'
    },
    color: {
      base: 'color-red-400',
      focus: 'focus:color-red-400',
      focusWithin: 'focus-within:color-red-400',
      hover: 'hover:color-red-400'
    }
  },
  dark: {
    bg: {
      base: 'bg-red-600',
      focus: 'focus:bg-red-600',
      focusWithin: 'focus-within:bg-red-600',
      hover: 'hover:bg-red-600'
    },
    border: {
      base: 'border-red-600',
      focus: 'focus:border-red-600',
      focusWithin: 'focus-within:border-red-600',
      hover: 'hover:border-red-600'
    },
    color: {
      base: 'color-red-600',
      focus: 'focus:color-red-600',
      focusWithin: 'focus-within:color-red-600',
      hover: 'hover:color-red-600'
    }
  }
} as const;

export default {
  brand,
  text,
  background,
  border,
  accent,
  success,
  error
};
