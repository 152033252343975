import React, { useEffect, useState } from 'react';
import { bool, string } from 'prop-types';
import { t } from '@jotforminc/translation';

const ErrorAlert = ({
  message,
  enableScreenReaderAlert
}) => {
  const [readAlertMessage, setReadAlertMessage] = useState(true);

  useEffect(() => {
    if (!enableScreenReaderAlert) return;

    const timer = setTimeout(
      () => {
        setReadAlertMessage(false);
      }, 5000
    );

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div role={readAlertMessage ? 'alert' : ''} className="xcl-lbl-err isVisible">
      {t(message)}
    </div>
  );
};

ErrorAlert.propTypes = {
  message: string.isRequired,
  enableScreenReaderAlert: bool
};

ErrorAlert.defaultProps = {
  enableScreenReaderAlert: true
};

export default ErrorAlert;
