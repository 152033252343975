import Adapter from './Adapter';
import { keyGrapper } from '../utils';

function dataParser(values) {
  const data = {
    ...keyGrapper(values)
  };

  const getValuesDateTime = _dt => {
    const u = _dt.map(_ => {
      const key = Object.keys(_)[0];
      return {
        name: key,
        value: _[key]
      };
    });
    const _vals = keyGrapper(u);
    return _vals;
  };

  const keys = Object.keys(keyGrapper(values));

  const filtered = keys.filter(key => ['date', 'time'].some(v => key.includes(v)));

  filtered.forEach(key => {
    const d = values.filter(v => v.name.includes(key));
    const y = d.map(_d => {
      const _name = _d.name;
      return {
        [_name.replace(`[${key}]`, '')]: _d.value
      };
    });
    data[key] = getValuesDateTime(y);
  });

  return data;
}

function infoExtractor(elements, qid, index) {
  if (!elements) return {};

  const { tagName } = elements[0];

  if (tagName === 'INPUT') {
    switch (elements[0].type) {
      case 'checkbox':
      case 'radio':
        const elem = elements[0];
        return {
          id: elem.id.split('-')[1],
          type: elem.type === 'radio' ? 'control_radio' : 'control_checkbox',
          label: elem.type === 'radio' ? 'Single Choice' : 'Multiple Choice',
          options: elements.map((el, i) => {
            return {
              type: elem.type === 'radio' ? 'radiobox' : 'checkbox',
              label: document.querySelector(`label[for=${el.id}]`)?.innerHTML || '',
              name: `option${i + 1}`,
              id: `option${i + 1}-${elem.id.split('-')[1]}`,
              qid
            };
          }),
          index
        };
      case 'tel':
        if (elements[0].id.includes('date')) {
          const id = elements[0].id.split('-')[elements[0].id.split('-').length - 1];
          const container = document.getElementById(`id_${qid}-date-${id}`);
          const label = container.querySelector('label')?.innerHTML || 'Date';
          const type = container?.dataset.type || 'datebox';
          const { format, seperator } = container?.dataset;
          return {
            id,
            type: 'control_datetime',
            label,
            options: [
              {
                type,
                label,
                name: 'Date',
                id: `date-${id}`,
                qid
              }
            ],
            format: format || 'mmddyyyy',
            dateSeparator: seperator || '-',
            index: '2'
          };
        }
        break;
      case 'text':
        if (elements[0].id.includes('time')) {
          return {
            timeFormat: 'AM/PM',
            showDayPeriods: 'both',
            defaultTime: 'none',
            step: '10',
            id: elements[0].id.split('-')[1],
            type: 'control_time',
            label: 'Time',
            options: [
              {
                type: 'timebox',
                label: 'Time',
                name: 'Time',
                id: `time-${elements[0].id.split('-')[1]}`,
                qid
              }
            ],
            index: '7'
          };
        }
        // short-text
        return {
          type: 'control_textbox',
          id: elements[0].id.split('-')[1],
          required: elements[0].required ? 'Yes' : 'No',
          validation: 'None',
          label: 'blanks',
          options: [
            {
              id: elements[0].id.split('-')[1],
              type: 'textbox',
              label: 'blanks',
              name: 'Short Text'
            }
          ],
          index: '8'
        };
      case 'number':
        const id = elements[0].id.split('-')[1];
        return {
          id,
          type: 'control_number',
          label: 'Number',
          options: [
            {
              type: 'textbox',
              label: '',
              name: 'Number',
              inputType: 'number',
              id: `number-${id}`,
              qid
            }
          ],
          index: '5'
        };
      case 'hidden':
        return {
          id: elements[0].id.split('-')[1],
          index: '5',
          label: 'Signature',
          options: [
            {
              id: `signature-${elements[0].id.split('-')[1]}`,
              label: 'Signature',
              name: 'Signature',
              qid,
              type: 'signaturebox'
            }
          ],
          type: 'control_signature'
        };
      default:
        // default
    }
  } else if (tagName === 'SELECT') {
    const elem = elements[0];
    return {
      id: elem.id.split('-')[1],
      type: 'control_dropdown',
      label: 'Please Select',
      options: Array.from(elem.options).slice(1).map((option, i) => {
        return {
          type: 'selectbox',
          label: option.innerHTML,
          name: `option${i + 1}`,
          qid,
          id: `option${i + 1}-${elem.id.split('-')[1]}`
        };
      }),
      index: '6'
    };
  }
}

export default class FillinBlanksAdapter extends Adapter {
  getValue(values) {
    const _index = 1;
    const fields = values.map(value => {
      const elements = document.getElementsByName(value.name);
      const info = infoExtractor(Array.from(elements), this.qid, _index);
      return info;
    }).filter(v => !!v);

    return {
      extraData: {
        inputType: null,
        fields
      },
      data: dataParser(values)
    };
  }

  setValue(value) {
    return super.setValue(value);
  }
}
