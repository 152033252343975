import React from 'react';
import PropTypes from 'prop-types';
import { t } from '@jotforminc/translation';

import FilledWarningIcon from '../assets/svg/iconWarningFilled.svg';
import RetryIcon from '../assets/svg/icon_retry.svg';

export default class SaclError extends React.Component {
  render() {
    const { onUserLogout, isLoggedIn, onNavigationChangeRequest } = this.props;
    return (
      <div className="xcl-content">
        <textarea contentEditable className="js-continueLaterLink sacl-textarea isHidden" />
        <span className="icon_error">
          <FilledWarningIcon />
        </span>
        <h3 className="m4t">{t('Oops!')}</h3>
        <p>{t('Something went wrong.')}</p>
        <div className="xcl-esb">
          <button className="xcl-button-er" type="button" onClick={() => onNavigationChangeRequest('epilogue')}>
            <RetryIcon />
            {t('Try Again')}
          </button>
          {isLoggedIn && (
            <a
              className="xcl-link-lg"
              role="button"
              href="#"
              onClick={onUserLogout}
            >
              {t('Logout')}
            </a>
          )}
        </div>
      </div>
    );
  }
}

SaclError.propTypes = {
  onUserLogout: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  onNavigationChangeRequest: PropTypes.func.isRequired
};
