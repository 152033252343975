import React from 'react';
import PropTypes from 'prop-types';
import { INITIATOR_TYPES } from '@jotforminc/constants';
import { LoginFlow } from '@jotforminc/login-flow';
import { t } from '@jotforminc/translation';
import { getAPIURL } from '@jotforminc/request-layer';

import { Texts } from '../constants';

const Login = ({
  onLogin, onSkip, navigationWrapper, enforceHIPAARuleSet, isHipaa
}) => {
  return (
    <LoginFlow
      mode='default'
      isHIPAA={isHipaa}
      initiator={INITIATOR_TYPES.SACL}
      enforceHIPAARuleSet={enforceHIPAARuleSet}
      onUserLogin={onLogin}
      apiURL={getAPIURL()}
      loginButtonText={t(Texts.LOGIN_SAVE)}
      signupButtonText={t(Texts.SAVE_SUBMISSION)}
      navigationWrapper={navigationWrapper}
      greetingMessage={t(Texts.SAVE_AS_DRAFT)}
      onSkip={onSkip}
      skippable={!window.JFForm?.requireLogin && !enforceHIPAARuleSet}
      forceLogin={window.JFForm?.requireLogin}
      ssoLoginOptions={window.JFForm?.ssoLoginOptions}
      ssoNormalLoginEnabled={!!window.JFForm?.ssoLoginOptions}
      buttonNames={{
        google: 'sacl-google-signup',
        facebook: 'sacl-facebook-signup',
        apple: 'sacl-apple-signup',
        emailLogin: 'sacl-standart-login-button',
        emailSignup: 'sacl-header-createmyaccount'
      }}
    />
  );
};

Login.propTypes = {
  onLogin: PropTypes.func.isRequired,
  onSkip: PropTypes.func.isRequired,
  navigationWrapper: PropTypes.element.isRequired,
  enforceHIPAARuleSet: PropTypes.bool.isRequired,
  isHipaa: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]).isRequired
};

export default Login;
