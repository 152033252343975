import React, { useRef } from 'react';
import { func } from 'prop-types';
import { Modal } from '@jotforminc/uikit';
import '../styles/standalone-modal.scss';
import SocialTermsStep from './SocialTermsStep';

const SocialTermsModal = ({
  removeModalFromDOM,
  onSocialTermsContinue,
  onSocialTermsClose,
  ...props
}) => {
  const uikitModalRef = useRef(null);

  // eslint-disable-next-line react/prop-types
  const DialogRenderer = ({ children }) => <div className="login-flow-standalone-modal-dialog social-terms-modal-dialog">{children}</div>;

  // eslint-disable-next-line react/prop-types
  const ContentRenderer = ({ children }) => (
    <div className="login-flow-standalone-modal-content social-terms-modal-content">
      <button
        className="close-button" type="button" aria-label="close"
        onClick={() => {
          onSocialTermsClose();
          removeModalFromDOM();
        }}
      />
      {children}
    </div>
  );

  const handleSocialTermsContinue = isTermsAgreed => {
    onSocialTermsContinue(isTermsAgreed);
    if (isTermsAgreed) {
      removeModalFromDOM();
    }
  };

  return (
    <Modal
      usePortal={true}
      ref={uikitModalRef}
      defaultVisible={true}
      closeOnEscPress={false}
      closeOnOutsideClick={false}
      DialogRenderer={DialogRenderer}
      ContentRenderer={ContentRenderer}
    >
      <SocialTermsStep {...props} onSocialTermsContinue={handleSocialTermsContinue} />
    </Modal>
  );
};

SocialTermsModal.propTypes = {
  onSocialTermsClose: func,
  removeModalFromDOM: func.isRequired,
  onSocialTermsContinue: func.isRequired
};

SocialTermsModal.defaultProps = {
  onSocialTermsClose: f => f
};

export default SocialTermsModal;
