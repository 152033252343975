import React from 'react';
import PropTypes from 'prop-types';

import '../styles/index.scss';
import Provider from '../store';
import OTPModal from './OTPModal';
import OTPStandalone from './OTPStandalone';

const OTP = ({
  user, otpRef = null, project = '', callback = f => f, onCloseCallback = f => f, texts = { description: '', title: '' }, show = false, mode = 'modal'
}) => {
  return (
    <Provider
      user={user}
      project={project}
    >
      {mode === 'modal' && (
        <OTPModal
          ref={otpRef}
          callback={callback}
          onCloseCallback={onCloseCallback}
          texts={texts}
        />
      )}
      {mode === 'standalone' && (
        <OTPStandalone
          callback={callback}
          texts={texts}
          show={show}
        />
      )}
    </Provider>
  );
};

OTP.propTypes = {
  user: PropTypes.object.isRequired,
  project: PropTypes.string,
  otpRef: PropTypes.object,
  callback: PropTypes.func,
  onCloseCallback: PropTypes.func,
  texts: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string
  }),
  show: PropTypes.bool,
  mode: PropTypes.string
};

export default OTP;
