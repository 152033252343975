import { isAxiosError } from 'axios';
import { RequestFunction, Responses } from './types';

export type AwaitedEngineResponse = Awaited<ReturnType<RequestFunction>>

export const mapAxiosResponse = (awaitedEngineResp: AwaitedEngineResponse): Responses<unknown> => {
  if (isAxiosError(awaitedEngineResp) && awaitedEngineResp.response) {
    return awaitedEngineResp.response;
  }

  return awaitedEngineResp as Responses<unknown>;
};
