import Adapter from './Adapter';

export default class DropdownAdapter extends Adapter {
  getValue(values) {
    // is multiple
    return { data: values.map(value => value.value) };
  }

  setValue(value) {
    return super.setValue(value);
  }
}
