/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid, react/sort-comp */
import React, { createRef } from 'react';
import {
  shape, bool, string, func, number, object
} from 'prop-types';
import Axios from 'axios';
import qs from 'querystring';
import classNames from 'classnames';
import ReCAPTCHA from 'react-google-recaptcha';
import { t, translationRenderer } from '@jotforminc/translation';
import { Loading } from '@jotforminc/loading';
import { getEnterpriseLogo } from '@jotforminc/request-layer';
import {
  listenEnter,
  validateEmail,
  getPossibleTimeZone
} from '@jotforminc/utils';
import { isEnterprise } from '@jotforminc/enterprise-utils';
import { Texts } from '@jotforminc/constants';
import { IconEnvelopeClosedFilled } from '@jotforminc/svg-icons';

import {
  isTurkishLanguage,
  getScreenResolution,
  handleSignupCompletedEvents,
  pushDataLayerEvent
} from '../utils/loginSignup';
import SalesforceButton from './SalesforceButton';
import FacebookButton from './FacebookButton';
import GoogleButton from './GoogleButton';
import Password from './Password';
import TermsAndConditionsUrls from './TermsAndConditionsUrls';
import MicrosoftButton from './MicrosoftButton';
import EmailIcon from '../temporary-duplicate/assets/svg/icon_em.svg';
import ErrorAlert from './ErrorAlert';
import BonusSignupInfoBox from './BonusSignupInfoBox';
import { openOFACModal } from './OFACModal';
import { salesforceSocialLoginLogger, errorNormalizer } from '../utils/helper';

import GoogleIcon36 from '../assets/icon_gl_36.svg';
import FacebookIcon36 from '../assets/icon_fb_36.svg';
import RenderSocialButtons from './SocialButtons';
import { dispatchAction } from '../api';

const errorMessages = {
  required: Texts.FIELD_REQUIRED,
  validEmail: Texts.VALID_EMAIL,
  termsRequired: Texts.AGREE_TERMS_CONDITIONS,
  hipaaPass: {
    minCharCount: Texts.PASS_MIN_CHAR_COUNT,
    includeChars: Texts.PASS_INCL_CHARS,
    differentThanPrev: Texts.PASS_DIFF_PREV
  }
};
const securePasswordTestEnabled = true;

class SignupOptions extends React.Component {
  constructor(props) {
    super(props);

    this.isTurkish = isTurkishLanguage(props.user);
    this.captchaRef = createRef();
    this.state = {
      name: '',
      email: props.signupPredefinedEmail,
      isAggreedTerms: false,
      isSignupLoading: false,
      screenHeight: window.innerHeight,
      user: props.user,
      showRecaptcha: false,
      recaptcha: null
    };
    this.emailRef = React.createRef();
    this.passwordRef = React.createRef();
    this.termsRef = React.createRef();
    this.isEnterpriseEnv = isEnterprise();
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

    // Accessibility validation controls
    this.invalidFieldRefs = {};
    this.invalidFieldFocusOrder = ['email', 'password', 'terms'];
  }

  componentDidMount() {
    const { shrinkConfirmOnMobile, registerMsAbTestAction } = this.props;
    registerMsAbTestAction({ action: 'seen', target: 'signupOptions' });
    if (shrinkConfirmOnMobile) window.addEventListener('resize', this.updateWindowDimensions);
    if (window.sendMessageToJFMobile) {
      window.sendMessageToJFMobile({ signupOptions: true });
    }
  }

  componentWillUnmount() {
    const { shrinkConfirmOnMobile } = this.props;
    if (shrinkConfirmOnMobile) window.removeEventListener('resize', this.updateWindowDimensions);
  }

  getMsSignupTestClassName() {
    const { isMsSignupControlVariant, isMsSignupTestBVariant, isMsSignupTestCVariant } = this.props;

    switch (true) {
      case isMsSignupControlVariant:
        return 'xcl-button-control';
      case isMsSignupTestBVariant:
        return 'xcl-button-b';
      case isMsSignupTestCVariant:
        return 'xcl-button-c';
      default:
        return 'xcl-button-control';
    }
  }

  handleFieldChange = propName => e => {
    const { value } = e.target;
    const erroredName = `${propName}Error`;
    const showRequiredMessage = propName !== 'name' && !value;
    this.setState({
      [propName]: value,
      [erroredName]: showRequiredMessage ? t(errorMessages.required) : ''
    });
  };

  handleTermsChange = () => {
    const { isAggreedTerms, termsError } = this.state;
    this.setState({
      isAggreedTerms: !isAggreedTerms,
      termsError: (termsError && !isAggreedTerms) ? '' : termsError
    });
  };

  resetPasswordError = () => {
    this.setState({ passwordError: '', securePassErrors: [] });
  };

  setSecurePaswordError = errorCodes => {
    this.setState({ securePassErrors: errorCodes });
  };

  callbackConfirmPassError = confirmPassErrorCode => {
    this.setState({ confirmPassErrorCode });
  };

  focusFirstInvalidField = () => {
    let firstInvalidFieldByOrder = '';
    this.invalidFieldFocusOrder.forEach(fieldName => {
      if (!firstInvalidFieldByOrder && this.invalidFieldRefs[fieldName]) {
        firstInvalidFieldByOrder = fieldName;
      }
    });

    if (firstInvalidFieldByOrder && firstInvalidFieldByOrder === 'password') {
      this.invalidFieldRefs.password.current.inputRef.current.focus();
    } else if (firstInvalidFieldByOrder) {
      this.invalidFieldRefs[firstInvalidFieldByOrder]?.current?.focus();
    }
    this.invalidFieldRefs = {};
  };

  makeAllValidations = () => {
    return new Promise(resolve => {
      const {
        isAggreedTerms, email
      } = this.state;
      const nextState = {};
      let emailErrCode = '';
      if (!email) {
        nextState.emailError = t(errorMessages.required);
        emailErrCode = 'emailRequired';
        this.invalidFieldRefs.email = this.emailRef;
      } else if (!validateEmail(email)) {
        nextState.emailError = t(errorMessages.validEmail);
        emailErrCode = 'emailInvalid';
        this.invalidFieldRefs.email = this.emailRef;
      }
      nextState.emailErrorCode = emailErrCode;

      if (!this.isEnterpriseEnv && !isAggreedTerms) { // Aytekin's Request, hiding terms on enterprise signup flow.
        nextState.termsError = t(errorMessages.termsRequired);
        this.invalidFieldRefs.terms = this.termsRef;
      }

      this.passwordRef.current.validate().then(({ isValid }) => {
        if (!isValid) {
          this.invalidFieldRefs.password = this.passwordRef;
        }
      }).then(() => this.focusFirstInvalidField());

      this.setState({
        ...nextState
      }, resolve);
    }).then(() => this.passwordRef.current.validate());
  };

  handleKeyDown = e => listenEnter(e, this.handleSubmit);

  handleCaptchaSubmit = hash => {
    if (!hash) {
      dispatchAction({
        actor: window?.user?.username || window?.__formInfo?.username || 'unknown',
        target: 'so',
        action: 'captcha-expired'
      });
      return this.setState({ isSignupLoading: false });
    }

    this.setState({
      recaptcha: hash
    });

    dispatchAction({
      actor: window?.user?.username || window?.__formInfo?.username || 'unknown',
      target: 'so',
      action: 'captcha-passed'
    });
  };

  forceUserSignup = async (name, email, password, isAggreedTerms, recaptcha) => {
    const {
      onLoginSuccess,
      appName,
      buttonNames,
      ofacModalRef
    } = this.props;

    const defaultButtonName = appName ? `emailSignup-${appName}` : '';
    const buttonName = buttonNames.emailSignup ? buttonNames.emailSignup : defaultButtonName;
    const signedFromDocusignApp = new URLSearchParams(global.location.search).has('signedFromDocusignApp');
    try {
      const response = await Axios.post(
        '/server.php',
        qs.stringify({
          action: 'registerNewUser',
          email,
          password,
          name,
          isAgreedTerms: isAggreedTerms ? '1' : '0',
          timezone: getPossibleTimeZone(),
          screen_resolution: getScreenResolution(),
          location: global.location.pathname,
          username: '',
          button_name: buttonName,
          ref_app: appName,
          return: 1,
          recaptcha,
          ...(signedFromDocusignApp && { signedFromDocusignApp: 'Yes' })
        }),
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      );

      if (response.status === 200) {
        handleSignupCompletedEvents(response.data.user.username);
        response.data.newUser = true;
        onLoginSuccess(response, true);
      } else {
        this.setState({
          emailError: t('Something went wrong'),
          isSignupLoading: false,
          passwordError: ''
        });
      }
    } catch (error) {
      const { response: { data: { error: message, error_type: errorType } = {} } = {} } = error;
      const hasPasswordWarning = message.includes('PasswordWarning::');
      if (message && securePasswordTestEnabled && (hasPasswordWarning || errorType === 'password')) {
        const removedErrorCode = hasPasswordWarning ? message.replace('PasswordWarning::', '') : message;
        this.setState({
          passwordError: t(removedErrorCode),
          isSignupLoading: false
        });
      } else {
        if (this.captchaRef) {
          this.captchaRef.current?.reset();
        }

        if (message === "Sorry, you can't signup from this country.") {
          openOFACModal(ofacModalRef, email);
        }

        if (message === 'REQUIRE_CAPTCHA') {
          dispatchAction({
            actor: window?.user?.username || window?.__formInfo?.username || 'unknown',
            target: 'so',
            action: 'captcha-required'
          });
          return this.setState({
            isSignupLoading: false,
            showRecaptcha: true
          });
        }

        this.setState({
          emailError: t(message || 'Something went wrong, please try again.'),
          isSignupLoading: false,
          passwordError: ''
        });
      }
    }
  };

  handleSubmit = e => {
    if (e) {
      e.preventDefault();
    }

    pushDataLayerEvent({
      event: 'Signup',
      eventAction: 'Click'
    });

    const {
      name, email, isAggreedTerms, recaptcha
    } = this.state;
    const {
      apiURL,
      forceUser,
      isHIPAA,
      onLoginSuccess,
      signupPredefinedEmail,
      verificationHash,
      ofacModalRef
    } = this.props;

    this.handleFieldChange('name')({ target: { value: name } });
    this.makeAllValidations().then(({ isValid: isPasswordValid, password }) => {
      if (securePasswordTestEnabled) {
        const {
          emailError, emailErrorCode, termsError, confirmPassErrorCode, securePassErrors
        } = this.state;
        const currentErrors = [
          { emailError: emailError !== '' ? emailErrorCode : '' },
          { termsError: (termsError !== '' && termsError) ? 'termsRequired' : '' },
          { confirmPassErrorCode }
        ];
        securePassErrors.forEach(errCode => {
          currentErrors.push({ passwordError: errCode });
        });
        currentErrors.forEach(type => {
          const errObj = Object.entries(type)[0];
          if (errObj[1] && errObj[1] !== '') {
            if (typeof window.JotFormActions === 'function') {
              const actor = window?.user?.username || window?.__formInfo?.username || 'unknown';
              const logger = new window.JotFormActions('strongPasswordonSignupsWithNew');
              logger.tick({ actor, action: 'signUpClick', target: errObj[1] });
            }
          }
        });
      }

      if (!isPasswordValid) return;

      const errorProps = ['emailError', 'nameError', 'termsError'];
      const firstError = errorProps.find(errorProp => {
        // eslint-disable-next-line react/destructuring-assignment
        const error = this.state[errorProp];
        return !!error;
      });
      if (firstError || !isPasswordValid) {
        this.setState({ isSignupLoading: false });
        return;
      }
      const isHIPAARequestPart = isHIPAA ? '&isHIPAA=1' : '';
      this.setState({ isSignupLoading: true });
      if (forceUser) {
        this.forceUserSignup(name, email, password, isAggreedTerms, recaptcha);
      } else if (signupPredefinedEmail && verificationHash) { // TODO: need another prop
        Axios.post(
          `${apiURL}/formuser/pretendCreate?master=1`,
          qs.stringify({
            email,
            password,
            name,
            isAgreedTerms: isAggreedTerms ? '1' : '0',
            hash: verificationHash,
            isHIPAA: isHIPAA ? '1' : ''
          }),
          {
            withCredentials: true,
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          }
        ).then(({ data: resp }) => {
          if (resp && resp.responseCode === 200) {
            onLoginSuccess(resp);
          } else if (resp && resp.errorType) {
            if (resp.errorType === 'E_TYPE_EMAIL') {
              this.setState({ emailError: resp.message });
            }

            if (resp?.message === "Sorry, you can't signup from this country.") {
              openOFACModal(ofacModalRef, email);
            }

            this.setState({ isSignupLoading: false });
          } else if (resp && typeof resp.message === 'string') {
            // Shitty error handling
            // we need to change api's error results to human readable format.
            const splitted = resp.message.split('-');
            const lastPart = splitted[splitted.length - 1];
            this.setState({ emailError: lastPart.trim(), isSignupLoading: false });
          }
        });
      } else {
        const { productType, appName } = this.props;
        const sendVerification = productType === 'form' ? '1' : '0';
        const referrerURL = appName === 'portal-apps' && window ? `&jotform_pwa=1&referrerURL=${window.location.href}` : '';
        const encodedEmail = encodeURIComponent(email);
        const encodedPW = encodeURIComponent(password);
        Axios.post(`${apiURL}/formuser/register?master=1`,
          `email=${encodedEmail}&password=${encodedPW}&confirm_password=${encodedPW}&disablePasswordConstraints=1&name=${name}&isAgreedTerms=${isAggreedTerms ? '1' : ' 0'}&sendVerification=${sendVerification}${isHIPAARequestPart}${referrerURL}`,
          {
            withCredentials: true,
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          }).then(({ data: resp }) => {
          if (resp && resp.responseCode === 200) {
            const { content: user } = resp;
            if (window?.JFAppsManager?.isOpenedInPortal()) {
              window.JFAppsManager.userChanged(user);
            }

            onLoginSuccess(resp);
          } else if (resp && resp.errorType) {
            if (resp.errorType === 'E_TYPE_EMAIL') {
              let newRespMessage = (resp.message.split(':'));
              newRespMessage = t(`${newRespMessage[0]}${newRespMessage[1] ? ':' : ''}`).concat(newRespMessage[1] ? newRespMessage[1] : '').toString();
              this.setState({ emailError: newRespMessage });
            }

            if (resp?.message === "Sorry, you can't signup from this country.") {
              openOFACModal(ofacModalRef, email);
            }

            this.setState({ isSignupLoading: false });
          }
        });
      }
    });
  };

  updateWindowDimensions() {
    this.setState({ screenHeight: window.innerHeight });
  }

  // eslint-disable-next-line complexity
  render() {
    const enterpriseEnv = isEnterprise();
    // const isEmbed = window && window.parent && window.parent !== window;
    const {
      onNavigationChangeRequest, onSalesforceLoginClick, onGoogleLoginClick, onFBLoginClick, onMicrosoftLoginClick, enterpriseLogo,
      skippable, onSkip, greetingMessage, thereSocialError, thereErrorMessage, showLogoOnSignupOptions, greetingDescription, showFormOnSignupOptions,
      isHIPAA, includeConfirm, signupButtonText, shrinkConfirmOnMobile, shrinkConfirmHeight, disableEmail, logoSource,
      registerMsAbTestAction, isMsSignupControlVariant, isMsSignupTestBVariant, onAppleLoginClick, areSocialButtonsLoading, horizontalSocialButtons,
      squareSocialButtonStyle, hideGreetingMessageOnSignupOptions, useSwsoButtons
    } = this.props;

    const jfLogoSource = logoSource || 'https://cdn.jotfor.ms/assets/img/v4/logo-branding21.png';
    const jfLogo = enterpriseEnv ? getEnterpriseLogo() : jfLogoSource;

    const {
      email, name, emailError, nameError, isAggreedTerms, termsError, isSignupLoading, screenHeight, passwordError, user, showRecaptcha
    } = this.state;
    const userCampaign = user?.campaign || {};
    const isGuestUser = user?.account_type?.name === 'GUEST';
    const isMobileTexts = (((window.innerHeight > shrinkConfirmHeight) || !showFormOnSignupOptions) && !horizontalSocialButtons);

    const shouldShowBonusSignupBox = isGuestUser && userCampaign?.type === 'NEWUSER' && userCampaign?.status && userCampaign?.expires;
    return (
      <div className='xcl-content xcl-nb-content test_signupOptionsWrapper' id="registrationBox">
        {showLogoOnSignupOptions && <img src={enterpriseLogo === null ? jfLogo : enterpriseLogo} alt="Logo" className={`xcl-companyLogo ${logoSource ? 'logoSource' : null}`} />}
        {!enterpriseEnv && !hideGreetingMessageOnSignupOptions && (<h2 className="m1t isGreeting">{t(greetingMessage)}</h2>)}
        {Boolean(greetingDescription) && (<p className={`isDescription ${!isMsSignupControlVariant && 'test-variant'}`}>{t(greetingDescription)}</p>)}
        { (false && shouldShowBonusSignupBox) && <BonusSignupInfoBox user={user} campaign={userCampaign} /> }
        <div className={classNames('xcl-cw signupContainer', { signupWithSocialButtonsWrapper: useSwsoButtons })} id="registrationForm">
          {!enterpriseEnv
         && (
           <>
             <div className={`socialButtonWrapper ${(isMobileTexts ? 'vertical' : ' ')}`}>
               {/* TODO: enable areSocialButtonsLoading flag to prevent flick effect */}
               {/* loading */}
               {areSocialButtonsLoading && (
               <div className='social-button-loader'>
                 <Loading />
               </div>
               )}
               {/* control variant */}
               {!areSocialButtonsLoading && squareSocialButtonStyle && isMsSignupControlVariant && (
                 <div className='microsoft-signup-test-wrapper horizontal'>
                   <SalesforceButton
                     classNames={classNames({ 'xcl-square-button': squareSocialButtonStyle })}
                     onClick={() => {
                       onSalesforceLoginClick();
                       salesforceSocialLoginLogger({ action: 'click', target: 'salesforce-signup-button' });
                       // registerMsAbTestAction({ action: 'click', target: 'salesforceSignupButton' });
                     }}
                     text={isMobileTexts
                       ? t(Texts.SIGN_UP_SALESFORCE)
                       : t(Texts.SIGN_UP_SALESFORCE_MOBILE)}
                   />
                   <GoogleButton
                     classNames={classNames({ 'test_gl_login xcl-square-button button-gl': squareSocialButtonStyle })}
                     icon={<GoogleIcon36 />}
                     onClick={() => {
                       onGoogleLoginClick();
                       registerMsAbTestAction({ action: 'click', target: 'googleSignupButton' });
                       salesforceSocialLoginLogger({ action: 'click', target: 'google-signup-button' });
                     }}
                     text={isMobileTexts
                       ? t(Texts.SIGN_UP_GOOGLE)
                       : t(Texts.SIGN_UP_GOOGLE_MOBILE)}
                   />
                   <FacebookButton
                     classNames={classNames({ 'test_fb_login xcl-square-button button-fb': squareSocialButtonStyle })}
                     icon={<FacebookIcon36 />}
                     onClick={() => {
                       onFBLoginClick();
                       registerMsAbTestAction({ action: 'click', target: 'facebookSignupButton' });
                       salesforceSocialLoginLogger({ action: 'click', target: 'facebook-signup-button' });
                     }}
                     text={isMobileTexts
                       ? t(Texts.SIGN_UP_FACEBOOK)
                       : t(Texts.SIGN_UP_FACEBOOK_MOBILE)}
                   />
                 </div>
               )}
               {!areSocialButtonsLoading && !squareSocialButtonStyle && isMsSignupControlVariant && (
                 <>
                   <GoogleButton
                     onClick={() => {
                       onGoogleLoginClick();
                       registerMsAbTestAction({ action: 'click', target: 'googleSignupButton' });
                     }}
                     text={isMobileTexts
                       ? t(Texts.SIGN_UP_GOOGLE)
                       : t(Texts.SIGN_UP_GOOGLE_MOBILE)}
                   />
                   <FacebookButton
                     onClick={() => {
                       onFBLoginClick();
                       registerMsAbTestAction({ action: 'click', target: 'facebookSignupButton' });
                     }}
                     text={isMobileTexts
                       ? t(Texts.SIGN_UP_FACEBOOK)
                       : t(Texts.SIGN_UP_FACEBOOK_MOBILE)}
                   />
                 </>
               )}
               {/* test variants */}
               {useSwsoButtons && (
               <div className='social-buttons-container' id='signupWithSocialOptionsContainer'>
                 <RenderSocialButtons
                   showSalesforceLogin={!this.isEnterpriseEnv}
                   onAppleLoginClick={onAppleLoginClick}
                   onMicrosoftLoginClick={onMicrosoftLoginClick}
                   onFBLoginClick={onFBLoginClick}
                   onSalesforceLoginClick={onSalesforceLoginClick}
                   onGoogleLoginClick={onGoogleLoginClick}
                 />
               </div>
               )}
               {!areSocialButtonsLoading && !isMsSignupControlVariant && !useSwsoButtons && (
               <div className={`microsoft-signup-test-wrapper ${isMsSignupTestBVariant && (window.innerHeight < shrinkConfirmHeight) && showFormOnSignupOptions && 'b-signup-wrapper'}`}>
                 <GoogleButton
                   classNames={`${this.getMsSignupTestClassName()} button-gl`}
                   text={(isMsSignupTestBVariant && (window.innerHeight < shrinkConfirmHeight) && showFormOnSignupOptions)
                     ? t(Texts.SIGN_UP_GOOGLE_MOBILE)
                     : t(Texts.SIGN_UP_GOOGLE)}
                   onClick={() => {
                     onGoogleLoginClick();
                     registerMsAbTestAction({ action: 'click', target: 'googleSignupButton' });
                   }}
                 />
                 <MicrosoftButton
                   classNames={`${this.getMsSignupTestClassName()} button-ms`}
                   text={(isMsSignupTestBVariant && (window.innerHeight < shrinkConfirmHeight) && showFormOnSignupOptions)
                     ? t(Texts.SIGN_UP_MICROSOFT_MOBILE)
                     : t(Texts.SIGN_UP_MICROSOFT)}
                   onClick={() => {
                     onMicrosoftLoginClick();
                     registerMsAbTestAction({ action: 'click', target: 'microsoftSignupButton' });
                   }}
                 />
                 {!isMsSignupTestBVariant
                      && (
                      <FacebookButton
                        classNames={`${this.getMsSignupTestClassName()} button-fb`}
                        text={t(Texts.SIGN_UP_FACEBOOK)}
                        onClick={() => {
                          onFBLoginClick();
                          registerMsAbTestAction({ action: 'click', target: 'facebookSignupButton' });
                        }}
                      />
                      )}
               </div>
               )}
             </div>
             {thereSocialError
             && (
             <div role="alert" className="xcl-lbl-err xcl-lbl-error-social isVisible">
               {t(errorNormalizer(thereErrorMessage))}
             </div>
             )}
             <div className="xcl-divider"><span>{t(Texts.OR_KEY)}</span></div>
           </>
         )}
          {!showFormOnSignupOptions ? (
            <button
              className={classNames('xcl-button-em test_createAccount signup-with-email-button', { 'xcl-square-button': squareSocialButtonStyle })}
              onClick={() => onNavigationChangeRequest('signupWithEmail')}
              type="button"
              id="signup-with-email"
            >
              { squareSocialButtonStyle ? <IconEnvelopeClosedFilled /> : <EmailIcon /> }
              { squareSocialButtonStyle ? t(Texts.SIGN_UP_EMAIL_LOWERCASE) : t(Texts.SIGN_UP_EMAIL) }
            </button>
          ) : (
            <form onSubmit={this.handleSubmit} noValidate>
              <div className="xcl-form">
                <div className="xcl-field-wr" id="fullnameField">
                  <label className="xcl-lbl" htmlFor="fullname">
                    {' '}
                    {t(Texts.NAME_TEXT)}
                  </label>
                  <input
                    className={`xcl-inp ${nameError ? 'errored' : ''}`}
                    type="text"
                    name="name"
                    onChange={this.handleFieldChange('name')}
                    onKeyDown={this.handleKeyDown}
                    value={name}
                    id="fullname"
                  />
                  {nameError && <ErrorAlert message={nameError} /> }
                </div>

                <div className="xcl-field-wr" id="emailField">
                  <label className="xcl-lbl" htmlFor="suEmail">
                    {' '}
                    {t(Texts.EMAIL_TEXT)}
                  </label>
                  <input
                    ref={this.emailRef}
                    className={`xcl-inp ${emailError ? 'errored' : ''}`}
                    type="email"
                    name="email"
                    readOnly={disableEmail}
                    onChange={this.handleFieldChange('email')}
                    onKeyDown={this.handleKeyDown}
                    value={email}
                    id="suEmail"
                    aria-label={(!email || !validateEmail(email)) ? t(Texts.VALID_EMAIL) : null}
                  />
                  <div role="alert" className={`xcl-lbl-err ${emailError ? 'isVisible' : null}`} id="emailFieldMessage">
                    {emailError || null}
                    {(emailError === Texts.EMAIL_ERROR || emailError === Texts.EMAIL_ERROR_ENTERPRISE) && (
                    <>
                    &nbsp;
                      {translationRenderer(Texts.PLEASE_LOGIN)({
                        renderer1: text => (<a className='error-login-button' onClick={() => onNavigationChangeRequest('loginOptions')}>{text}</a>)
                      })}
                    </>
                    )}
                  </div>
                </div>

                <Password
                  className="signupOptionsPassword"
                  ref={this.passwordRef}
                  secure={isHIPAA}
                  checkReuse={false}
                  onKeyDown={this.handleKeyDown}
                  securePasswordTestEnabled={securePasswordTestEnabled}
                  passwordErrorServer={passwordError}
                  resetPasswordError={this.resetPasswordError}
                  setSecurePaswordError={this.setSecurePaswordError}
                  callbackConfirmPassError={this.callbackConfirmPassError}
                  includeConfirm={includeConfirm && !(shrinkConfirmOnMobile && screenHeight < shrinkConfirmHeight)}
                />

                {!this.isEnterpriseEnv && (
                <div className="xcl-field-wr" id="termsField">
                  <div className="xcl-terms-wr noTranslate">
                    <input
                      ref={this.termsRef}
                      type="checkbox"
                      id="terms"
                      className="terms-checkbox"
                      checked={isAggreedTerms}
                      onChange={this.handleTermsChange}
                      aria-label={!this.isEnterpriseEnv && !isAggreedTerms ? t(Texts.AGREE_TERMS_CONDITIONS) : null}
                    />
                    <TermsAndConditionsUrls
                      isTurkish={this.isTurkish}
                      LabelRenderer={({ children }) => <label className="noTranslate sacl-terms" htmlFor="terms">{children}</label>}
                    />
                  </div>
                  <div role="alert" className={`xcl-lbl-err ${termsError ? 'forTerms isVisible' : null}`}>
                    {termsError || null}
                  </div>
                </div>
                ) }
                {showRecaptcha && (
                  <div id="recaptcha-container" className='lf-recaptcha-container'>
                    <ReCAPTCHA
                      ref={this.captchaRef}
                      isolated={true}
                      sitekey="6LdU3CgUAAAAAB0nnFM3M3T0sy707slYYU51RroJ"
                      onChange={hash => this.handleCaptchaSubmit(hash)}
                    />
                  </div>
                )}
                <button
                  className="xcl-button-gr button-signup test_signupRegular" disabled={isSignupLoading} type="submit"
                  id="signupButton"
                >
                  {isSignupLoading
                    ? (
                      <div className="button-loader">
                        <div className="button-loader-item" />
                        <div className="button-loader-item" />
                        <div className="button-loader-item" />
                      </div>
                    )
                    : t(signupButtonText)}
                </button>
                <div className="m1b">
                  {t(Texts.ALREADY_HAVE_ACCOUNT)}
                  <button
                    type="button"
                    className="test_loginButton"
                    onClick={() => onNavigationChangeRequest('loginOptions')}
                    id="login-link"
                  >
                    {t(Texts.LOGIN_TEXT)}
                  </button>
                </div>
              </div>
            </form>
          )}
        </div>
        {!showFormOnSignupOptions ? (
          <div className="m1b">
            {t(Texts.ALREADY_HAVE_ACCOUNT)}
            <button
              type="button"
              className="test_loginButton"
              onClick={() => onNavigationChangeRequest('loginOptions')}
              id="login-link"
            >
              {t(Texts.LOGIN_TEXT)}
            </button>
          </div>
        ) : null}
        {skippable ? (
          <a
            type="button"
            className="m1ba"
            onClick={() => {
              onNavigationChangeRequest('withoutSignup');
              if (typeof onSkip === 'function') {
                onSkip();
              }
            }}
          >
            {t(Texts.SKIP_CREATE_ACCOUNT)}
          </a>
        ) : null}
      </div>
    );
  }
}

SignupOptions.propTypes = {
  onNavigationChangeRequest: func.isRequired,
  thereSocialError: bool,
  onSalesforceLoginClick: func.isRequired,
  onFBLoginClick: func.isRequired,
  onGoogleLoginClick: func.isRequired,
  onMicrosoftLoginClick: func.isRequired,
  onAppleLoginClick: func.isRequired,
  enterpriseLogo: string,
  skippable: bool,
  onSkip: func,
  greetingMessage: string,
  hideGreetingMessageOnSignupOptions: bool,
  showLogoOnSignupOptions: bool,
  greetingDescription: string,
  horizontalSocialButtons: bool,
  squareSocialButtonStyle: bool,
  showFormOnSignupOptions: bool,
  onLoginSuccess: func.isRequired,
  apiURL: string.isRequired,
  signupPredefinedEmail: string,
  disableEmail: bool,
  verificationHash: string,
  productType: string,
  isHIPAA: bool,
  appName: string,
  forceUser: bool,
  includeConfirm: bool,
  shrinkConfirmOnMobile: bool,
  shrinkConfirmHeight: number,
  user: shape(),
  signupButtonText: string,
  buttonNames: shape({
    google: string,
    facebook: string,
    apple: string,
    microsoft: string,
    emailLogin: string,
    emailSignup: string
  }),
  logoSource: string,
  registerMsAbTestAction: func,
  isMsSignupControlVariant: bool,
  isMsSignupTestBVariant: bool,
  isMsSignupTestCVariant: bool,
  areSocialButtonsLoading: bool,
  useSwsoButtons: bool,
  ofacModalRef: object,
  thereErrorMessage: string
};

SignupOptions.defaultProps = {
  enterpriseLogo: null,
  skippable: false,
  onSkip: f => f,
  greetingMessage: Texts.SIGN_UP_NOW,
  hideGreetingMessageOnSignupOptions: false,
  thereSocialError: false,
  showLogoOnSignupOptions: true,
  greetingDescription: '',
  horizontalSocialButtons: false,
  squareSocialButtonStyle: false,
  showFormOnSignupOptions: false,
  productType: 'form',
  signupPredefinedEmail: '',
  disableEmail: false,
  verificationHash: '',
  isHIPAA: false,
  appName: '',
  forceUser: false,
  includeConfirm: false,
  user: null,
  signupButtonText: Texts.CREATE_MY_ACCOUNT,
  buttonNames: {},
  shrinkConfirmOnMobile: false,
  shrinkConfirmHeight: 750,
  logoSource: '',
  registerMsAbTestAction: f => f,
  isMsSignupControlVariant: true,
  isMsSignupTestBVariant: false,
  isMsSignupTestCVariant: false,
  areSocialButtonsLoading: true,
  useSwsoButtons: true,
  ofacModalRef: null,
  thereErrorMessage: Texts.ERROR_TRY_AGAIN
};

export default SignupOptions;
