import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import classNames from 'classnames';

import {
  IconCheck, IconExclamationTriangleFilled, IconLinkDiagonalBroken, IconTrashExclamationFilled, IconXmark
} from '@jotforminc/svg-icons';
import { t } from '@jotforminc/translation';
import IconHIPAAWarning from '../assets/svg/iconHipaaWarning.svg';

import '../styles/_jfDialog.scss';

class Dialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingState: 'default'
    };
  }

  onSubmit = e => {
    e.preventDefault();

    const { onSubmit } = this.props;
    onSubmit(e.target.doNotShowAgain ? { doNotShowAgain: e.target.doNotShowAgain.checked } : null);
  };

  onCancel = e => {
    if (e) {
      e.preventDefault();
    }
    const { onClose, modalID } = this.props;

    onClose(modalID);
  };

  onOtherButtonClickHandle = async (loadingButtonEnable, onOtherButtonClick) => {
    if (loadingButtonEnable) {
      this.setState({ loadingState: 'loading' });
      await onOtherButtonClick();
      setTimeout(() => {
        this.setState({ loadingState: 'default' });
      }, 2000);
      return;
    }
    onOtherButtonClick();
  };

  get headerIcon() {
    const { type } = this.props;
    switch (type) {
      case 'warning':
        return {
          svg: <IconExclamationTriangleFilled />,
          class: 'warningIcon'
        };
      case 'success':
        return {
          svg: <span><IconCheck /></span>,
          class: 'successIcon'
        };
      case 'removeConnection':
        return {
          svg: <IconLinkDiagonalBroken />,
          class: 'removeConnectionIcon'
        };
      case 'trash':
        return {
          svg: <IconTrashExclamationFilled />,
          class: 'dialogTrashIcon'
        };
      case 'hipaaWarning':
        return {
          svg: <IconHIPAAWarning />,
          class: 'dialogHIPAAWarningIcon'
        };
      // case 'error':
      //   return {
      //     svg: errorSVG,
      //     class: 'error'
      //   };
      default:
        return null;
    }
  }

  render() {
    const {
      closable, isOpen, modalID, submitButtonText, cancelButtonText, oneButtonDialog, isOneButtonColor,
      title, subTitles, hasCheckboxForDisabling, otherButtonText, onOtherButtonClick, dialogClass, renderFields, wrapperClassName, headerImg, isSolidButtonGreen,
      loadingButtonEnable, parentSelector
    } = this.props;

    const { loadingState } = this.state;
    return (
      <ReactModal
        key={modalID}
        isOpen={isOpen}
        overlayClassName={`jfDialog-wrapper ${wrapperClassName}`}
        className={`jfDialog ${dialogClass}`}
        role="dialog"
        ariaHideApp={true}
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        onRequestClose={this.onCancel}
        aria={{
          labelledby: 'heading',
          describedby: 'full_description'
        }}
        parentSelector={parentSelector}
      >
        <form onSubmit={this.onSubmit}>
          <div className="jfDialog-header">
            {this.headerIcon && <span className={classNames('jfDialog-header-icon', this.headerIcon.class)}>{this.headerIcon.svg}</span>}
            {headerImg && <span className='jfDialog-header-img'>{headerImg}</span>}
            {title && <h2 className="jfDialog-title">{title}</h2>}
            {subTitles && subTitles.map(subTitle => <p key={subTitle} className="jfDialog-subTitle" dangerouslySetInnerHTML={{ __html: subTitle }} />)}
            {hasCheckboxForDisabling && (
              <label htmlFor="doNotShowConfirmation" className="jfDialog-checkBox">
                <input
                  type="checkbox"
                  name="doNotShowAgain"
                  className="jfDialog-checkBoxInput"
                  id="doNotShowConfirmation"
                />
                <span className="jfDialog-checkBoxLabel">
                  {t('Do not show this message again')}
                </span>
              </label>
            )}
            {closable && (
              <button
                tabIndex="-1" type="button" className="jfDialog-closeButton"
                onClick={this.onCancel}
                aria-label="Close dialog"
              >
                <IconXmark />
              </button>
            )}
          </div>
          <div className="jfDialog-main">
            {renderFields !== null ? renderFields().map((item, index) => {
              return (
                <div
                  key={index.toString()} // eslint-disable-line react/no-array-index-key
                  className="jfDialog-row"
                >
                  <h2 className="jfDialog-title">{item.fieldTitle}</h2>
                  <p className="jfDialog-subTitle">{item.fieldSubTitle}</p>
                  {item.element}
                </div>
              );
            }) : null}
          </div>
          <div className={classNames('jfDialog-footer', { hasHideButton: otherButtonText })}>
            {!oneButtonDialog && <button type="submit" className={classNames('jfDialog-solidButton', { solidButtonGreen: isSolidButtonGreen })}>{submitButtonText}</button>}
            {otherButtonText && (
              <button
                type="button"
                tabIndex="-1"
                data-test-id="jfDialog-outlineButton"
                className={classNames(
                  'jfDialog-outlineButton',
                  {
                    solidButtonGreen: isOneButtonColor === 'green',
                    solidButtonBlue: isOneButtonColor === 'blue',
                    isLoading: loadingState === 'loading'
                  }
                )}
                onClick={() => this.onOtherButtonClickHandle(loadingButtonEnable, onOtherButtonClick)}
              >
                {loadingState === 'default' && (otherButtonText)}
                {loadingButtonEnable && loadingState === 'loading' && ('PLEASE WAIT...')}
                {loadingButtonEnable && loadingState === 'completed' && ('REQUEST COMPLETED')}
              </button>
            )}
            {cancelButtonText && (
              <button type="button" className="jfDialog-outlineButton" onClick={this.onCancel}>{cancelButtonText}</button>
            )}
          </div>
        </form>
      </ReactModal>
    );
  }
}

Dialog.propTypes = {
  isOpen: PropTypes.bool,
  closable: PropTypes.bool,
  modalID: PropTypes.string,
  type: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  hasCheckboxForDisabling: PropTypes.bool,
  title: PropTypes.string,
  subTitles: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  onOtherButtonClick: PropTypes.func,
  submitButtonText: PropTypes.string,
  otherButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  dialogClass: PropTypes.string,
  renderFields: PropTypes.func,
  wrapperClassName: PropTypes.string,
  headerImg: PropTypes.node,
  isSolidButtonGreen: PropTypes.bool,
  oneButtonDialog: PropTypes.bool,
  isOneButtonColor: PropTypes.string,
  loadingButtonEnable: PropTypes.bool,
  parentSelector: PropTypes.func
};

Dialog.defaultProps = {
  isOpen: false,
  closable: true,
  modalID: '',
  type: '',
  hasCheckboxForDisabling: false,
  title: '',
  subTitles: '',
  onOtherButtonClick: () => { },
  submitButtonText: '',
  otherButtonText: '',
  cancelButtonText: '',
  dialogClass: 'isDefault',
  renderFields: null,
  wrapperClassName: '',
  headerImg: null,
  isSolidButtonGreen: false,
  oneButtonDialog: false,
  isOneButtonColor: null,
  loadingButtonEnable: false,
  parentSelector: () => document.body
};

export default Dialog;
