import {
  prepareAddDictionary,
  prepareControlModeChange,
  prepareAddTranslationToDictionary,
  sendUsedTranslationsInterval
} from './hof';

import { isProduction, collectionTimer } from './utils';

/* eslint-disable no-param-reassign, no-extend-native */
export function initializeTranslationFromGlobal(translation, t) {
  const addDictionary = prepareAddDictionary(translation);
  const controlModeChange = prepareControlModeChange(translation);
  const addTranslationToDictionary = prepareAddTranslationToDictionary(translation);

  // Overriding all strings and adding locale method
  String.prototype.locale = function locale() {
    return t(this);
  };

  if (!global.Translations) {
    global.__t = t;
    global.Translations = translation;
    global.Translations.t = t;
    global.Translations.addDictionary = addDictionary;
    global.Translations.addTranslationToDictionary = addTranslationToDictionary;
    global.Translations.controlModeChange = controlModeChange;
    global.Translations.processUsedTranslationsInterval = null;
    global.Translations.encodedAndProcessedStrings = [];
    global.Translations.startCollectUsedTranslationsInterval = sendUsedTranslationsInterval;
    global.Translations.forceStopCollecting = false;
  }

  if (global.window && global.window.useLocaleDictionary && global.window?.Locale?.language) {
    addTranslationToDictionary(global.window.currentLocale, global.window.Locale.language);
  }

  if (isProduction() && collectionTimer() && global.Translations.currentLocale !== 'en-US') {
    sendUsedTranslationsInterval();
  }
}
