import Adapter from './Adapter';

export default class PaymentAdapter extends Adapter {
  getValue(values) {
    const products = {};
    const donationField = document.querySelector(`input[id='input_${this.qid}_donation']`);
    if (donationField) {
      return {
        data: { price: donationField.value }
      };
    }
    values.forEach((val, key, copyValues) => {
      const { name, value } = val;
      if (name.includes('cc_')) return;
      const hasBrackets = /\[\]/.test(name);
      const { name: copyName } = copyValues[key + 1] || {};
      if (hasBrackets && (copyName?.match(/special_(\d+)/)?.[1] === value || !!value.split('_')[1])) {
        return;
      }

      if (/special_(\d+)/.test(name)) {
        const nameParts = name.match(/\[(.*?)\]/g);
        const hasCopyBrackets = /\[\]/.test(copyName);
        const hasExpandedOption = !!nameParts[0].split('_')[2];
        const pid = name.match(/special_(\d+)/)?.[1];
        const id = hasExpandedOption ? nameParts[0] : pid;
        const container = document.querySelector(`span[pid="${pid}"]`);
        const checkbox = container.querySelector('.form-checkbox.form-product-input, .form-radio.form-product-input');
        if (!checkbox?.checked) return;

        const specialId = nameParts[1].replace(/[[\]]/g, '');
        const sort = parseInt(specialId.match(/\d+/)[0], 10);
        const typeId = document.getElementsByName(name)[0].id;
        const type = typeId.split('_')[2] || 'custom';

        if (id && !products[id]) {
          const options = [];
          options[0] = {
            selected: value,
            type,
            expanded: hasCopyBrackets ? '1' : '',
            sort
          };
          products[id] = {
            options,
            hasExpandedOption,
            pid: pid
          };
          return;
        }
        products[id].options.push({
          selected: value, type, expanded: hasCopyBrackets ? '1' : '', sort
        });
      } else {
        const pid = value;
        const id = value;
        const container = document.querySelector(`span[pid="${pid}"]`);
        const checkbox = container.querySelector('.form-checkbox.form-product-input, .form-radio.form-product-input');
        if (!checkbox?.checked) return;

        const sort = 0;
        const type = 'custom';
        const hasExpandedOption = false;
        if (id && !products[id]) {
          const options = [];
          options[0] = {
            selected: value,
            type,
            expanded: '',
            sort
          };
          products[id] = {
            options,
            hasExpandedOption,
            pid: pid
          };
          return;
        }
        products[id].options.push({
          selected: value, type, expanded: '', sort
        });
      }
    });

    Object.keys(products).forEach(key => {
      const { options } = products[key];
      options.sort((a, b) => {
        return a.sort - b.sort;
      });
    });

    return { data: Object.values(products) };
  }

  setValue(value) {
    return super.setValue(value);
  }
}
