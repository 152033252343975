import React from 'react';
import PropTypes from 'prop-types';
import { t } from '@jotforminc/translation';
import { IconArrowsRotate, IconFloppyDisk, IconXmarkCircleFilled } from '@jotforminc/svg-icons';

import { Texts } from '../constants';

const Error = props => {
  const { onTryAgain } = props;

  return (
    <>
      <div className='sacl-modal__center pb-32 pt-44'>
        <div className='sacl-modal__logo'>
          <IconXmarkCircleFilled className='sacl-modal--error otherIcon' />
          <IconFloppyDisk />
        </div>
      </div>
      <div className="sacl-modal__texts">
        <h1>{t(Texts.SAVE_ERROR)}</h1>
        <span>{t(Texts.PLEASE_TRY_AGAIN)}</span>
      </div>
      <div className="sacl-modal__center pb-40 pt-24">
        <button
          className="sacl-btn sacl-btn--error" type="button" onClick={onTryAgain}
        >
          <IconArrowsRotate />
          <span>{t(Texts.TRY_AGAIN)}</span>
        </button>
      </div>
    </>
  );
};
export default Error;

Error.propTypes = {
  onTryAgain: PropTypes.func.isRequired
};
