import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  useLayoutEffect
} from 'react';
import { moment as Moment, nodeContains } from '@jotforminc/utils';
import debounce from 'lodash/debounce';
import { v4 as uuid } from 'uuid';
import throttle from 'lodash/throttle';
import Fuse from 'fuse.js';
import { useResize } from './useResize';

export const useDebounce = (fn, waitTime = 400, options = {}) => {
  const funcRef = useRef(fn);
  if (funcRef.current !== fn) {
    funcRef.current = fn;
  }

  return useCallback(debounce(fn, waitTime, options), [funcRef.current]);
};

const baseFuseOptions = {};
const baseKeys = ['name', 'email'];
export const useFuse = (list, searchTerm, keys = baseKeys, fuseOptions = baseFuseOptions) => {
  const fuse = useMemo(() => new Fuse(list, {
    shouldSort: true,
    location: 0,
    threshold: 0.4,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    ...fuseOptions,
    keys
  }), [list, keys, fuseOptions]);

  const results = useMemo(() => (!searchTerm
    ? list
    : fuse.search(searchTerm).map(fuseResult => ({
      ...fuseResult.item
    }))
  ), [fuse, searchTerm]);
  return results;
};

export const useLongPress = (callback, ms = 500) => {
  const timer = useRef();

  const start = useCallback(event => {
    timer.current = setTimeout(() => callback(event), ms);
  }, [ms, callback]);

  const cancel = useCallback(() => {
    if (timer.current !== undefined) clearTimeout(timer.current);
  });

  useEffect(() => () => {
    if (timer.current !== undefined) clearTimeout(timer.current);
  }, []);

  return useMemo(() => ({
    onMouseDown: start,
    onMouseUp: cancel,
    onMouseLeave: cancel,
    onTouchStart: start,
    onTouchEnd: cancel,
    onTouchMove: cancel,
    onTouchCancel: cancel
  }), [start, cancel]);
};

export const useSwitch = (value, cases) => useMemo(() => {
  const firstMatchingCase = cases.find(([caseValue]) => caseValue === value);
  if (firstMatchingCase) {
    const [, result] = firstMatchingCase;
    return result;
  }
}, [value, cases]);

export const useStateWithAutoReset = (initialState = '', timeoutDuration = 3000) => {
  const [state, setState] = useState(initialState);

  useEffect(() => {
    if (state !== initialState) {
      const delayedClearStateTimeout = global.setTimeout(() => setState(initialState), timeoutDuration);
      return () => global.clearTimeout(delayedClearStateTimeout);
    }
  }, [state]);

  return [state, setState];
};

export const useTimeout = (callback, delay) => {
  const callbackRef = useRef(callback);
  const timeoutRef = useRef();

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  const set = useCallback(() => {
    timeoutRef.current = setTimeout(() => callbackRef.current(), delay);
  }, [delay]);

  const clear = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }, []);

  useEffect(() => {
    set();
    return clear;
  }, [delay, set, clear]);

  const reset = useCallback(() => {
    clear();
    set();
  }, [clear, set]);

  return { reset, clear };
};

export const useDebounceWithTimeout = (callback, delay, dependencies) => {
  const { reset, clear } = useTimeout(callback, delay);
  useEffect(reset, [...dependencies, reset]);
  useEffect(clear, []);
};

export const useTimeoutMessage = (initialValue, timeout = 3000) => {
  const timeoutRef = useRef(null);
  const [message, setMessage] = useState(initialValue);

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  return [
    message, data => {
      clearTimeout(timeoutRef.current);
      setMessage(data);
      timeoutRef.current = setTimeout(() => setMessage(false), timeout);
    }
  ];
};

export const usePropState = initalValue => {
  const [state, setState] = useState(initalValue);
  useEffect(() => {
    if (initalValue !== state) {
      setState(initalValue);
    }
  }, [initalValue]);

  return [state, setState];
};

const fetchers = {};

export const useRequest = (fetcher, name = fetcher.toString()) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(false);
  const [error, setError] = useState(false);

  const load = async (...args) => {
    setLoading(true);
    setError(false);

    try {
      const req = fetcher(...args);
      fetchers[name] = req;

      const newData = await req;

      if (fetchers[name] !== req) return;

      setData(newData);
    } catch (e) {
      setError(e);
    }

    setLoading(false);

    return data;
  };

  return {
    error, load, loading, data, setData
  };
};
export const useQuery = (fetcher, query, name) => {
  const request = useRequest(fetcher, name);
  const { load, setData } = request;

  const reload = () => load(query);

  useEffect(() => {
    setData(false);
    reload();
  }, [JSON.stringify(query)]);

  return { ...request, reload };
};

export const useInViewport = (elementRef, callback = f => f, threshold = 0.2, callOnce = true, scrollRef = { current: window }) => {
  const isCalledRef = useRef(false);

  const isInViewport = elRef => {
    const rect = elRef.current?.getBoundingClientRect();
    const innerHeight = window.innerHeight || document.documentElement.clientHeight;
    const innerWidth = window.innerWidth || document.documentElement.clientWidth;
    const topCalculation = rect.top + (rect.height * threshold);
    const bottomCalculation = rect.bottom + (rect.height * threshold);
    const fromTop = topCalculation <= innerHeight && topCalculation >= 0;
    const fromBottom = bottomCalculation <= innerHeight && bottomCalculation >= 0;
    return (
      rect.left >= 0
      && (fromTop || fromBottom)
      && rect.right <= innerWidth
    );
  };

  const handleScroll = () => {
    if (!elementRef.current) return;
    const inViewport = isInViewport(elementRef);
    const shouldCall = callOnce ? (inViewport && !isCalledRef.current) : inViewport;
    if (shouldCall) {
      callback();
      isCalledRef.current = true;
    }
  };

  useEffect(() => {
    handleScroll();
    const throttledHandleScroll = throttle(handleScroll, 400);
    scrollRef.current?.addEventListener('scroll', throttledHandleScroll);
    return () => {
      scrollRef.current?.removeEventListener('scroll', throttledHandleScroll);
    };
  }, [elementRef, scrollRef]);
};

// from usehooks.com
export const useHover = (ms = 0) => {
  const [value, setValue] = useState(false);

  const ref = useRef(null);
  let timeout;
  const handleMouseOver = () => {
    if (ms > 0) {
      timeout = setTimeout(() => setValue(true), ms);
    } else {
      setValue(true);
    }
  };
  const handleMouseOut = () => {
    if (timeout) {
      timeout = clearTimeout(timeout);
    }
    setValue(false);
  };

  useEffect(
    () => {
      const node = ref.current;
      if (node) {
        node.addEventListener('mouseover', handleMouseOver);
        node.addEventListener('mouseout', handleMouseOut);

        return () => {
          if (timeout) {
            timeout = clearTimeout(timeout);
          }
          node.removeEventListener('mouseover', handleMouseOver);
          node.removeEventListener('mouseout', handleMouseOut);
        };
      }
    },
    [ref.current] // Recall only if ref changes
  );

  return [ref, value];
};

export const useMomentDate = (date, format) => {
  const [momentDate, setMomentDate] = useState(Moment.dateToMoment(date, format));
  const renewMomentDate = () => {
    setMomentDate(Moment.dateToMoment(date, format));
  };

  useEffect(() => {
    const listenerKey = `renewMomentDate_${uuid()}`;
    Moment.addListener('setLocale', listenerKey, renewMomentDate);
    return () => Moment.removeListener('setLocale', listenerKey);
  }, []);

  useEffect(() => {
    renewMomentDate();
  }, [date, format]);

  return [momentDate, setMomentDate];
};

export const useIsMobile = (mobileBreakdownWidth = 580) => {
  const { width } = useResize();
  return width <= mobileBreakdownWidth;
};

export const useMoveOutsideState = (initialState, refs = []) => {
  const [isVisible, setVisibility] = useState(initialState);

  useEffect(() => {
    const hideMenu = ({ target }) => {
      return !refs.find(ref => ref?.current && nodeContains(ref.current, target)) && setVisibility(false);
    };

    global.window.addEventListener('mouseout', hideMenu);
    return () => global.window.removeEventListener('mouseout', hideMenu);
  }, [refs]);

  return [isVisible, setVisibility];
};

export const useSetAttributeToElement = (className, setAttributeName, setAttributeValue, useEffectDependency) => {
  useEffect(() => {
    let index;
    const dates = document.getElementsByClassName(className);
    for (index = 0; index < dates.length; ++index) {
      dates[index]?.setAttribute(setAttributeName, setAttributeValue);
    }
  }, [useEffectDependency]);
};

export const useDelayUnmount = (isMounted, delayTime) => {
  const [shouldRender, setShouldRender] = useState(false);
  const [className, setClassName] = useState('closed');
  useEffect(() => {
    let timeoutIdOut;
    if (isMounted && !shouldRender) {
      setShouldRender(true);
      setTimeout(() => setClassName('opened'));
    } else if (!isMounted && shouldRender) {
      setClassName('closed');
      timeoutIdOut = setTimeout(() => setShouldRender(false), delayTime);
    }
    return () => {
      clearTimeout(timeoutIdOut);
    };
  }, [isMounted, delayTime, shouldRender]);
  return [shouldRender, className];
};

export const useKeyPress = targetKey => {
  const [keyPressed, setKeyPressed] = useState(false);
  function downHandler({ key }) {
    if (key === targetKey) {
      setKeyPressed(true);
    }
  }
  const upHandler = ({ key }) => {
    if (key === targetKey) {
      setKeyPressed(false);
    }
  };
  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);
    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, []);
  return keyPressed;
};

export const useMeasure = () => {
  const ref = useRef(null);
  const [forceRedraw, setForceRedraw] = useState(1);
  const rect = useRef(null);

  useLayoutEffect(() => {
    rect.current = ref.current && ref.current.getBoundingClientRect();
    if (!ref.current) return;
    if (rect.current.height) {
      setForceRedraw(0);
    }
  }, [forceRedraw, rect]);
  return [ref, rect.current && rect.current.height];
};

export const useFocus = () => {
  const htmlElRef = useRef(null);
  // eslint-disable-next-line no-unused-expressions
  const setFocus = () => { htmlElRef?.current && htmlElRef?.current?.focus(); };

  return [htmlElRef, setFocus];
};

export const useDelayed = (fn, ms) => {
  const ready = useRef(false);
  const timeout = useRef();
  const callback = useRef(fn);

  const isReady = useCallback(() => ready.current, []);

  const set = useCallback(
    (...args) => {
      ready.current = false;
      if (timeout.current) clearTimeout(timeout.current);

      timeout.current = setTimeout(() => {
        ready.current = true;
        callback.current(...args);
      }, ms);
    },
    [ms]
  );

  const clear = useCallback(() => {
    ready.current = null;
    if (timeout.current) clearTimeout(timeout.current);
  }, []);

  // update ref when function changes
  useEffect(() => {
    callback.current = fn;
  }, [fn]);

  // clear on unmount
  useEffect(() => {
    return clear;
  }, [ms]);

  return [isReady, clear, set];
};

export const useOnClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = event => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
};

export const usePrevious = value => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

const defaultOptions = {
  isPassive: true, condition: true, element: global, capture: false
};

export const useEventListener = (
  eventName,
  handler,
  options
) => {
  const savedHandler = useRef();
  const {
    isPassive, condition, element, capture
  } = { ...defaultOptions, ...options };

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const eventListener = event => savedHandler.current(event);
    if (condition) {
      element.addEventListener(eventName, eventListener, { passive: isPassive, capture });
    }
    return () => {
      if (condition) {
        element.removeEventListener(eventName, eventListener, { passive: isPassive, capture });
      }
    };
  }, [eventName, condition, element, isPassive]);
};
