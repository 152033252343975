import React, { useRef, useEffect, useState } from 'react';
import {
  bool, func, shape, string
} from 'prop-types';

import {
  IconCheckSm,
  IconXmarkSm,
  IconInfoCircleFilled
} from '@jotforminc/svg-icons';

const icons = {
  isGood: <IconCheckSm />,
  isBad: <IconXmarkSm />
};

const RequirementsList = ({
  checkReuse,
  getSecureRuleClass,
  hasSecureTextIcon,
  securePassErrors,
  securePassText,
  securePassContText,
  securePassDescText,
  secureRules,
  passwordSecurityRules
}) => {
  const [totalHeight, setTotalHeight] = useState(0);
  const descTextRef = useRef(null);
  const requirementListRef = useRef(null);

  useEffect(() => {
    const validatedSecuresValues = Object.values(securePassErrors);
    if (!validatedSecuresValues.includes(false) && !validatedSecuresValues.includes(null)) {
      setTotalHeight(0);
    } else if (descTextRef.current && requirementListRef.current) {
      setTotalHeight(descTextRef.current.clientHeight + requirementListRef.current.clientHeight);
    }
  }, [securePassErrors]);

  return (
    <div
      className='hPass-reqs'
      style={{ maxHeight: totalHeight, visibility: totalHeight === 0 ? 'hidden' : 'visible' }}
    >
      <p className="hPass-reqs-info-text" ref={descTextRef}>
        {checkReuse ? (
          <>
            <IconInfoCircleFilled className="hPass-reqs-info-icon" />
            {securePassText}
            {' '}
            {securePassContText}
          </>
        ) : (
          <>
            <IconInfoCircleFilled className="hPass-reqs-info-icon" />
            {securePassDescText}
          </>
        )}
      </p>
      <ul
        ref={requirementListRef}
        {...hasSecureTextIcon ? {
          className: 'has-icon'
        } : {}}
      >
        {Object.entries(securePassErrors)
          .map(([ruleKey, ruleValue], index) => {
            if (ruleKey === 'reuse') return null;
            const isPasswordSecurityRule = passwordSecurityRules[ruleKey];
            const isVisible = (!isPasswordSecurityRule || (ruleValue === false && isPasswordSecurityRule));

            return isVisible && (
              <li
                key={ruleKey}
                className={['hPass-req', getSecureRuleClass(ruleValue)].filter(Boolean).join(' ')}
                style={{ animationDelay: `${(index + 1) * 0.1}s` }}
              >
                {hasSecureTextIcon && (
                  <span>
                    {icons[getSecureRuleClass(ruleValue)]}
                  </span>
                )}
                {secureRules[ruleKey]}
              </li>
            );
          })}
      </ul>
    </div>
  );
};

RequirementsList.propTypes = {
  checkReuse: bool,
  getSecureRuleClass: func,
  hasSecureTextIcon: bool,
  securePassErrors: shape({}),
  securePassText: string,
  securePassContText: string,
  securePassDescText: string,
  secureRules: shape({}),
  passwordSecurityRules: shape({})
};

RequirementsList.defaultProps = {
  checkReuse: false,
  getSecureRuleClass: f => f,
  hasSecureTextIcon: true,
  securePassErrors: {},
  securePassText: '',
  securePassContText: '',
  securePassDescText: '',
  secureRules: {},
  passwordSecurityRules: {}
};

export default RequirementsList;
