import React, { ComponentPropsWithRef, ElementType, forwardRef } from 'react';
import { cx } from 'class-variance-authority';

import '@jotforminc/jotform.css';
import { buttonCVA } from './button.cva';
import {
  ButtonComponentType, ButtonProps, buttonDefaultProps
} from './button.types';

import { ButtonIcon, ButtonIndicator, ButtonText } from './ButtonContent';
import { Loader } from '../Loader/Loader';

import { PolymorphicProps } from '../../types/system.types';
import { PRODUCT_NAME_VALUES } from '../../constants/common.values';

export const Button:ButtonComponentType = forwardRef(
  // eslint-disable-next-line complexity
  <Element extends ElementType>(
    props: PolymorphicProps<Element, ButtonProps>,
    ref?: ComponentPropsWithRef<Element>['ref']
  ): JSX.Element => {
    const {
      children,
      className,
      colorStyle = buttonDefaultProps.colorStyle,
      disabled = buttonDefaultProps.disabled,
      endIcon: EndIcon,
      fullWidth = buttonDefaultProps.fullWidth,
      href,
      loader = buttonDefaultProps.loader,
      loaderText,
      indicatorText,
      invert = buttonDefaultProps.invert,
      rounded = buttonDefaultProps.rounded,
      showTextOnHover = buttonDefaultProps.showTextOnHover,
      size = buttonDefaultProps.size,
      startIcon: StartIcon,
      showTextOnly = buttonDefaultProps.showTextOnly,
      theme = buttonDefaultProps.theme,
      type = buttonDefaultProps.type,
      variant = buttonDefaultProps.variant,
      as: Component = href ? 'a' : 'button',
      ...rest
    } = props;

    const hasOneIcon = !!((StartIcon && !EndIcon) || (!StartIcon && EndIcon));
    const hasDoubleIcon = !!(StartIcon && EndIcon);
    const hasIndicator = !!indicatorText;

    const hasChildren = !!children;
    const hasLoaderText = !!loaderText;
    const isProductButton = colorStyle ? (PRODUCT_NAME_VALUES as ReadonlyArray<string>).includes(colorStyle) : false;

    // If color style is a product color, its variant always should be `filled`
    const buttonVariant = isProductButton ? 'filled' : variant;

    // The `loader` state should work only if it is not `disabled`.
    const buttonLoader = !disabled ? loader : false;

    // Update button text according the loader state
    const buttonText = hasLoaderText && buttonLoader ? loaderText : children;

    // Check button text
    const hasText = hasChildren || (hasLoaderText && buttonLoader);

    // Button class list
    const buttonClassName = cx(className, buttonCVA({
      colorStyle,
      disabled,
      fullWidth,
      hasDoubleIcon,
      hasLoaderText,
      hasOneIcon,
      hasChildren,
      invert,
      loader: buttonLoader,
      rounded,
      showTextOnHover,
      showTextOnly,
      size,
      theme,
      variant: buttonVariant
    }));

    // Loader content
    const loaderComponent = <Loader className='shrink-0' size={size} />;
    const loaderContent = hasLoaderText ? loaderComponent : <span className="absolute inset-0 flex justify-center items-center p-1 z-1">{loaderComponent}</span>;

    // Button content
    const buttonContent = (
      <>
        {StartIcon && (
          <ButtonIcon
            icon={StartIcon}
            size={size}
            hasLoaderText={hasLoaderText}
            loader={buttonLoader}
          />
        )}
        {buttonLoader && loaderContent}
        {hasText && (
          <ButtonText
            showTextOnHover={showTextOnHover}
            size={size}
            loader={buttonLoader}
            showTextOnly={showTextOnly}
            hasLoaderText={hasLoaderText}
            hasChildren={hasChildren}
          >
            {buttonText}
          </ButtonText>
        )}
        {hasIndicator && !hasLoaderText && (
          <ButtonIndicator
            colorStyle={colorStyle}
            size={size}
            loader={buttonLoader}
            indicatorText={indicatorText}
            variant={buttonVariant}
          />
        )}
        {EndIcon && !hasIndicator && (
          <ButtonIcon
            icon={EndIcon}
            size={size}
            hasLoaderText={hasLoaderText}
            loader={buttonLoader}
          />
        )}
      </>
    );

    return (
      <Component
        {...rest}
        href={href || undefined}
        type={!href && type ? type : undefined}
        disabled={disabled}
        className={buttonClassName}
        ref={ref}
      >
        {buttonContent}
      </Component>
    );
  }
);
