import Styled from 'styled-components';

export const ScPieTimerWrapper = Styled.div`
  display: flex;
  align-items: center;

  div {
    margin-right: 6px;
    html[dir="rtl"] & {
      margin-right: 0;
      margin-left: 6px;
    }
    #loader {
      fill: #fff;
    }

    svg {
      width: 20px;
      display: block;
      border-radius: 50%;
      border: 2px solid #cfd8dc;
    }
  }
`;
