import React, { useImperativeHandle } from 'react';
import {
  array, bool, func, elementType, object, shape
} from 'prop-types';

import '../../styles/verification-modal.scss';
import Verification from './Verification';

const VerifyAccountModal = React.forwardRef(({
  socialLogins, onAppleLoginClick, onFBLoginClick, onGoogleLoginClick, onMicrosoftLoginClick, onSalesforceLoginClick, thereSocialError,
  user, AfterVerificationComponent, BeforeVerificationComponent, VerificationWrapper, isVerified, setIsVerified
}, ref) => {
  const approveVerification = () => setIsVerified(true);

  useImperativeHandle(ref, () => (
    {
      approveVerification
    }
  ));

  const VerificationStep = () => (VerificationWrapper
    ? (
      <VerificationWrapper.Component {...VerificationWrapper.props}>
        <Verification
          socialLogins={socialLogins} onAppleLoginClick={onAppleLoginClick} onFBLoginClick={onFBLoginClick}
          onGoogleLoginClick={onGoogleLoginClick} onMicrosoftLoginClick={onMicrosoftLoginClick} onSalesforceLoginClick={onSalesforceLoginClick}
          thereSocialError={thereSocialError}
          user={user} handleVerification={approveVerification}
        />
      </VerificationWrapper.Component>
    )
    : (
      <Verification
        socialLogins={socialLogins} onAppleLoginClick={onAppleLoginClick} onFBLoginClick={onFBLoginClick}
        onGoogleLoginClick={onGoogleLoginClick} onMicrosoftLoginClick={onMicrosoftLoginClick} onSalesforceLoginClick={onSalesforceLoginClick}
        thereSocialError={thereSocialError}
        user={user} handleVerification={approveVerification}
      />
    ));

  if (BeforeVerificationComponent) return <BeforeVerificationComponent />;

  return (
    <>{!isVerified ? <VerificationStep /> : <AfterVerificationComponent />}</>
  );
});

VerifyAccountModal.propTypes = {
  socialLogins: array,
  onAppleLoginClick: func,
  onFBLoginClick: func,
  onGoogleLoginClick: func,
  onMicrosoftLoginClick: func,
  onSalesforceLoginClick: func,
  thereSocialError: bool,
  user: object,
  AfterVerificationComponent: elementType.isRequired,
  BeforeVerificationComponent: elementType,
  VerificationWrapper: shape({
    Component: elementType,
    props: shape({})
  }),
  isVerified: bool,
  setIsVerified: func
};

VerifyAccountModal.defaultProps = {
  socialLogins: [],
  onAppleLoginClick: () => {},
  onFBLoginClick: () => {},
  onGoogleLoginClick: () => {},
  onMicrosoftLoginClick: () => {},
  onSalesforceLoginClick: () => {},
  thereSocialError: false,
  user: {},
  BeforeVerificationComponent: null,
  VerificationWrapper: null,
  isVerified: false,
  setIsVerified: f => f
};

export default VerifyAccountModal;
