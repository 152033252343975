import React, {
  useState, useImperativeHandle, useEffect
} from 'react';
import { func, object } from 'prop-types';
import { OTP, isOTPRequired } from '@jotforminc/otp';

import Loader from './Loader';
import DeleteAccountConfirm from './DeleteAccountConfirm';
import DeleteAccountFinal from './DeleteAccountFinal';

import '../../styles/verification-modal.scss';
import '../../styles/delete-my-account-modal.scss';

const otpProject = 'delete-account-my-account';

const DeleteAccountModal = React.forwardRef(({ user, handleClose }, ref) => {
  const [step, setStep] = useState(1);
  const [ready, setReady] = useState(false);
  const [isVerified, setIsVerified] = useState(false);

  const { credentials: { username } } = user;

  const handleStep = nextStep => {
    if (nextStep === 0) {
      return handleClose();
    }

    if (nextStep === 2 && isVerified) {
      return setStep(3);
    }

    setStep(nextStep);
  };

  useImperativeHandle(ref, () => ({
    handleStep
  }));

  useEffect(() => {
    (async () => {
      try {
        const isRequired = await isOTPRequired({ username, project: otpProject });
        setIsVerified(!isRequired);
      } catch (error) {
        console.debug('[Login Flow] - Delete Account OTP error', error);
        setIsVerified(true);
      } finally {
        setReady(true);
      }
    })();
  }, []);

  if (!ready) {
    return (
      <Loader />
    );
  }

  return (
    <>
      {step === 1 && (
        <DeleteAccountConfirm handleStep={handleStep} username={username} />
      )}
      {step === 2 && !isVerified && (
        <div className="verify-account-container">
          <OTP
            mode='standalone'
            user={user?.credentials}
            project={otpProject}
            callback={() => handleStep(3)}
            texts={{ title: 'Verify it’s you to delete your acccount' }}
            show
          />
        </div>
      )}
      {step === 3 && (
        <DeleteAccountFinal username={username} />
      )}
    </>
  );
});

DeleteAccountModal.propTypes = {
  user: object,
  handleClose: func
};

DeleteAccountModal.defaultProps = {
  user: {},
  handleClose: () => {}
};

export default DeleteAccountModal;
