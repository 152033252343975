import React from 'react';
import PropTypes from 'prop-types';
import { t } from '@jotforminc/translation';
import { IconPaperFilled, IconFilePencilFilled } from '@jotforminc/svg-icons';

import { Texts } from '../constants';

const DraftModal = ({ onDiscard, onContinue }) => {
  return (
    <div className="sacl-modal" data-mode="modal" data-testid="saclDraftWrapper">
      <div className="sacl-modal__content">
        <div className="sacl-modal__texts pb-32 pt-40 px-32">
          <h1>{t(Texts.INCOMPLETE_SUBMISSION)}</h1>
          <span>{t(Texts.DRAFT_TEXT)}</span>
        </div>
        <div className="sacl-modal__draft__buttons">
          <button onClick={onDiscard} type="button">
            <IconPaperFilled />
            <h5>{t(Texts.DISCARD_DRAFT)}</h5>
          </button>
          <button onClick={onContinue} type="button">
            <IconFilePencilFilled />
            <h5>{t(Texts.CONTINUE_WITH_DRAFT)}</h5>
          </button>
        </div>
      </div>
    </div>
  );
};

export default DraftModal;

DraftModal.propTypes = {
  onDiscard: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired
};
