/* eslint-disable max-len */
export const Texts = {
  SIGN_UP_NOW: 'Sign Up Now!',
  CREATE_MY_ACCOUNT: 'CREATE MY ACCOUNT',
  HELLO_TEXT: 'Hello,',
  LOG_OUT: 'Log out',
  MY_FORMS: 'My Forms',
  PROFILE: 'Profile',
  YOU_HAVE_REACHED_YOUR_LIMIT_ENTERPRISE: '[1[Get rid off all your limits!]] Start using all exclusive Jotform Enterprise features with your team. ',
  YOU_HAVE_REACHED_YOUR_LIMIT_DANGER: 'You have reached your {limitType} limit, please [1[upgrade your plan]] to extend it.',
  YOU_HAVE_REACHED_YOUR_LIMIT_WARNING: 'You are about to reach the {limitType} limit, please [1[upgrade your plan]] to extend it.',
  USAGE_INFO: '[1[{usage}]] of [2[{limit}]] used',
  USAGE_INFO_UNLIMITED: '[1[{usage}]] of unlimited used',
  UPLOAD_SPACE_USAGE_INFO_BYTE: '[1[{usage}]] [2[byte]] of [3[{limit}]] [4[{limitUnit}]] used',
  UPLOAD_SPACE_USAGE_INFO_BYTES: '[1[{usage}]] [2[bytes]] of [3[{limit}]] [4[{limitUnit}]] used',
  UPLOAD_SPACE_USAGE_INFO_DEFAULT: '[1[{usage}]] [2[{usageUnit}]] of [3[{limit}]] [4[{limitUnit}]] used',
  UNLIMITED: 'unlimited',
  UPGRADE_YOUR_PLAN: 'Upgrade Your Plan',
  SALUTE: 'Hello,',
  STARTER: 'STARTER',
  VIEW_ALL: 'View all',
  VIEW_LIMIT_USAGE: 'View limit usage',
  CLOSE_LIMIT_USAGE: 'Close limit usage',
  VIEW_LESS: 'View less',
  SETTINGS: 'Settings',
  ADMIN_CONSOLE: 'Admin Console',
  GET_THE_APP: 'Get the App',
  GET_FIFTY_PERC_OFF: 'GET 50% OFF',
  SAVE_FIFTY_PERC_NOW: 'SAVE 50% Now',
  SAVE_FIFTY_PERCENTAGE: 'SAVE [1[50%]]',
  SWITCH_TO_OLD_VERSION: 'Switch to the Old Version',
  BYTE: 'byte',
  BYTES: 'bytes',
  LOGOUT: 'Logout',
  VIEW_INVOICE: 'View',
  INVOICE_STATUS: 'Invoice [1[{status}]]',
  INVOICE_DUE_DATE: 'Pay Before [1[{date}]]',
  SINCE_RECENTLY_DELETED: 'Due to recent submission deletions, your available upload space will be updated in [1[{predictedTime}]].'

};
