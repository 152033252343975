import { keyGrapper } from '../utils';

export default class Adapter {
  constructor(qid, type) {
    this.qid = qid;
    this.type = type;
  }

  getValue(values) {
    const _values = keyGrapper(values);

    if (!Object.keys(_values).some(_val => _val.includes(`q${this.qid}`))) {
      return { data: _values };
    }
    return { data: values[0].value };
  }

  setValue(value) {
    const { qid } = this;

    const label = document.getElementById(`label_${qid}`);
    const text = label?.innerText.trim() || '';

    const _result = {
      ...value?.extraData,
      value: value.data,
      text,
      type: this.type
    };

    return _result;
  }
}
