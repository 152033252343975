import React from 'react';
import { IconUserFilled, IconLockFilled, IconArrowsRotate } from '@jotforminc/svg-icons';
import { t, TranslateProvider } from '@jotforminc/translation';
import { handleCustomNavigation } from '@jotforminc/utils';

import ErrorScreen from './ErrorScreen.jsx';
import { Texts } from '../../constants.js';
import PropTypes from "prop-types";

const UnauthorizedScreen = props => {
  const changeAccount = () => {
    const logoutHref = `${window.location.origin}/logout.php?backTo=${window.location.href}`;
    handleCustomNavigation(logoutHref, '_self', true);
  };



  return (
    <TranslateProvider translationTexts={Texts}>
    <ErrorScreen
      Icon={(
        <>
          <IconUserFilled />
          <IconLockFilled />
        </>
      )}
      text={t(props.text)}
      subText={t(props.subText)}
      type='UnauthorizedScreen'
      buttonText={(
        <>
          <IconArrowsRotate />
          <span>{t(Texts.CHANGE_ACCOUNT)}</span>
        </>
      )}
      handleButtonClick={changeAccount}
    />
    </TranslateProvider>
  );
};

UnauthorizedScreen.propTypes = {
  text: PropTypes.string,
  subText: PropTypes.string
};

export default UnauthorizedScreen;
