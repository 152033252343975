import React from 'react';
import { t } from '@jotforminc/translation';
import {
  loadJS,
  initGoogleSignin,
  initFBSignin,
  initAppleSignin,
  randomString
} from '@jotforminc/utils';
import { twoFactorTypeKeys } from '@jotforminc/constants';

import { IconMessageFilled, IconScanFilled } from '@jotforminc/svg-icons';

// import SVGEmailMethod from '../assets/svg/emailMethod.svg';

import GoogleIcon from '../assets/svg/icon_gl.svg';
import FBIcon from '../assets/svg/icon_fb.svg';
import AppleIcon from '../assets/svg/icon_apple_white.svg';

export const USER_TYPES = {
  ADMIN: 'ADMIN'
};

/* eslint-disable max-len */
export const TEXTS = {
  ENABLE_2FA_TITLE: 'Enable Two-Factor Authentication (2FA)',
  DISABLE_2FA_TITLE: 'Disable Two-Factor Authentication (2FA)',
  TWO_FACTOR_DESCRIPTION: "Protect your account with additional security by enabling two-factor authentication for [1[{companyName}]] logins. You'll be required to enter both your password and an authentication code to sign in.",
  LEARN_MORE: 'Learn More',
  AUTHENTICATION_METHODS: 'Authentication Methods',
  ENABLE_AN_AUTHENTICATOR_APP: 'Enable an Authenticator App',
  PRIMARY_METHOD: 'Primary Method',
  SET_PRIMARY: 'Set as Primary',
  PHONE_NUMBER_AUTHENTICATION: 'Text Authentication',
  EMAIL_AUTHENTICATION: 'Email Authentication',
  RECOVERY_CODES: 'Recovery Codes',
  GENERATE_NEW_CODES: 'Generate new codes',
  GENERATE_RECOVERY_CODES: 'Generated Recovery Codes',
  CANT_DISABLE_PRIMARY: 'This method is primary. You can\'t disable it.',
  CANT_ENABLE_TEXT_AUTHENTICATION: 'This method is available for paid users. Please upgrade your account to enable it.',
  CANT_DISABLE_MANDATORY: 'This method is mandatory. You can\'t disable it.',
  SETTING_TITLE: 'Two-Factor Authentication',
  AUTHAPP_SCANQRCODE_INFO: 'This method requires you to install a third-party authenticator app (e.g., Google Authenticator, Authy, Duo).',
  LATEST_RECOVERY_CODES: 'Latest recovery codes generated: From [1[{location}]] on [2[{date}]] at [3[{time}]]',
  WAS_NOT_YOU: 'If this activity wasn\'t you, generate new codes immediately in order to invalidate the previous ones.',
  USED_ALL: 'You have [1[used all of your recovery codes]], hence they are invalid. Please generate new ones in order to use recovery codes as a 2FA method for your Jotform logins.',
  SELECT_A_VERIFICATION_METHOD: 'Select a method to receive the authentication code',
  // SEND_A_CODE_TO_MY_PHONE_NUMBER: 'Text the authentication code to my [1[phone number]]',
  // SEND_ME_AN_EMAIL: 'Email the authentication code to my [1[email address]]',
  // SEND_ME_AN_AUTHENTICATOR_APP: 'Use an [1[Authentication app]] to receive the authentication code',
  AUTHENTICATOR_APP: 'Authenticator App',
  AUTHENTICATOR_APP_DESCRIPTION: 'Set up an authenticator app to receive verification codes from the authenticator app on your mobile device.',
  YOUR_RECOVERY_CODES: 'Your recovery codes',
  SMS_LABEL: 'Text a code',
  SMS_DESCRIPTON: 'Send the code to my mobile phone',
  EMAIL_LABEL: 'Email',
  EMAIL_DESCRIPTON: 'Send the code to my email address',
  APP_LABEL: 'Use an app',
  APP_DESCRIPTON: 'Use an authenticator app to receive the code',
  NEXT: 'NEXT',
  BACK: 'BACK',
  DOWNLOAD: 'DOWNLOAD',
  COPY: 'COPY',
  COPIED: 'COPIED',
  DONE: 'DONE',
  DISABLE: 'DISABLE',
  CANCEL: 'CANCEL',
  CLOSE: 'CLOSE',
  ENTER_YOUR_PHONE_NUMBER: 'Enter your mobile phone number',
  PLEASE_ENTER_PHONE: 'Please enter your mobile phone number to enable 2FA. [1[companyName]] will send you a six-digit verification code.',
  PHONE_INPUT_PLACEHOLDER: 'Enter phone number',
  ENTER_YOUR_VERIFICATION_CODE: 'Authenticate your [1[method]]',
  PLEASE_ENTER: {
    [twoFactorTypeKeys.sms]: "We've sent an authentication code to [1[receiver]]. Please check your messages and enter the six-digit code to enable 2FA"
  },
  NO_CODE: 'No code?',
  SEND_AGAIN: 'Send again',
  JOTFORM_WILL_SEND: 'A new verification code has been sent to your [1[{targetType}]].',
  TEXT_MESSAGE_ENABLED: "You've successfully enabled 2FA.",
  TEXT_MESSAGE_ENABLED_DESC: "From now on, you'll be asked to enter an authentication code after your [1[companyName]] login.",
  NEW_RECOVERY_CODES_GENERATED: 'Your new recovery codes are now generated.',
  YOUR_RECOVERY_CODES_DESC: 'Please save and secure these codes down below. You can use these codes for [1[companyName]] Logins in the event you cannot reach to your mobile phone or email address. Your previously generated codes are now invalidated.',
  ERROR_BLANK_PHONE: 'Phone number cannot be blank.',
  ERROR_BLANK_VERIFICATION_CODE: 'Code cannot be blank.',
  ERROR_INVALID_PHONE: 'Invalid phone number',
  ERROR_PASSWORD: 'Incorrect password, please try again',
  VERIFY_WITH_GOOGLE: 'Verify with Google',
  ERROR_GENERIC: 'Oops! Something went wrong',
  VERIFY_WITH_FACEBOOK: 'Verify with Facebook',
  VERIFY_WITH_APPLE: 'Verify with Apple',
  VERIFY_TO_CONFIRM: 'Verify it\'s you to confirm',
  PASSWORD_TO_CONFIRM: 'Enter your password to confirm',
  PASSWORD_PLACEHOLDER: 'Your password',
  CONFIGURE_AUTH_APP: 'Configure the authenticator app',
  AUTH_APP_OPEN_DESCRIPTION: 'Open your authenticator app and add your [1[companyName]] account by scanning the QR code below.',
  MANUAL_KEY_CODE: 'If you can\'t scan the QR code, manually enter the code below.',
  ENTER_THE_CODE: 'Enter the code',
  DISABLE_WARNING_MESSAGE: 'When you disable 2FA, you\'ll need to enter only your password to sign into your [1[companyName]] account.',
  VERIFY_EMAIL_ADDRESS: 'Please verify your email address to continue',
  THE_EMAIL_ADDRESS: 'The email for your account is:',
  SENT_TO_VERIFICATION_EMAIL: 'Verification email has been sent to ',
  CHECK_YOUR_EMAIL_ADDRESS: 'Please check your inbox to verify your email address by following the instructions.',
  RESEND_VERIFICATION_EMAIL: 're-send verification email',
  EMAIL_SENT: 'email sent',
  SENDING_EMAIL: 'sending...',
  UNUSED_CODES: 'Unused Codes',
  FORCE_2FA_TOOLTIP: 'You are not allowed to disable 2FA verification. If you need any assistance, kindly get in touch with your admin.',

  // Force 2FA
  FORCE_2FA_TITLE: 'Configure Two-Factor Authentication (2FA) Settings',
  FORCE_2FA_DESC: 'Your system administrator has been forced to enable 2FA for you. By following the instructions below, you can configure your settings. If you need assistance, kindly get in touch with your admin.',
  FORCE_2FA_INSTALL_TITLE: 'Install a third-party authenticator app',
  FORCE_2FA_INSTALL_DESC: 'You need to download and install an authenticator app such as Google Authenticator, Authy, or Duo.',
  FORCE_2FA_SCAN_QR_CODE_TITLE: 'Scan QR code or type given code',
  FORCE_2FA_SCAN_QR_CODE_DESC: 'After opening your authenticator app, you need to scan below QR code or type given code to see your verification code.',
  FORCE_2FA_ENTER_TEXT_CODE: 'Enter text code',
  FORCE_2FA_ENTER_TEXT_CODE_DESC: 'Open the authenticator app and enter the text code below.',
  FORCE_2FA_VERIFICATION_INPUT_TITLE: 'Enter the generated code',
  FORCE_2FA_VERIFICATION_INPUT_DESC: 'Enter the generated code by authenticator app to complete your 2FA configuration.',
  SCAN_QR_CODE: 'Scan QR code',
  TYPE_THE_CODE_BELOW: 'Type the Code Below',
  VERIFY: 'Verify'
};

export const colors = {
  blue: '#0075E3',
  green: '#007B2A',
  red: '#DD3E3E',
  navy: '#0A1551',
  grey: '#8D8FA8',
  selago: '#DDE1FA',
  blueHover: '#EDF8FF',
  white: '#FFFFFF'
};

export const methodOptions = [
  {
    text:
  <>
    <IconMessageFilled />
    <div>
      {t(TEXTS.SMS_LABEL)}
    </div>
  </>,
    description: TEXTS.SMS_DESCRIPTON,
    value: twoFactorTypeKeys.sms
  },
  // {
  //   text:
  // <>
  //   <SVGEmailMethod />
  //   <div>
  //     {t(TEXTS.EMAIL_LABEL)}
  //   </div>
  // </>,
  //   description: TEXTS.EMAIL_DESCRIPTON,
  //   value: twoFactorTypeKeys.email,
  // },
  {
    text:
  <>
    <IconScanFilled />
    <div>
      {t(TEXTS.APP_LABEL)}
    </div>
  </>,
    description: TEXTS.APP_DESCRIPTON,
    value: twoFactorTypeKeys.authenticatorApp
  }
];

export const flagURL = 'https://cdn.jotfor.ms/assets/img/v4/country-flags/{xx}.svg';

export const socialButtonConstants = {
  google: {
    color: '#0076FF',
    icon: <GoogleIcon />,
    text: TEXTS.VERIFY_WITH_GOOGLE,
    handler: ({
      successCallback = f => f,
      errorCallback = f => f
    }) => {
      const googleSignin = initGoogleSignin();
      if (googleSignin) {
        googleSignin
          .then(googleUser => {
            const { id_token: token } = googleUser.getAuthResponse();
            successCallback({ password: token, isSocialLogin: 'google' });
          })
          .catch(errorCallback);
      }
    }
  },
  facebook: {
    color: '#3C5A99',
    icon: <FBIcon />,
    text: TEXTS.VERIFY_WITH_FACEBOOK,
    handler: ({
      successCallback = f => f,
      errorCallback = f => f
    }) => {
      const fbLogin = initFBSignin();
      if (!fbLogin) return;
      fbLogin(({ status, authResponse }) => {
        if (status === 'connected' && authResponse) {
          successCallback({ password: authResponse.accessToken, isSocialLogin: 'facebook' });
        } else {
          // TODO: handle error
          errorCallback();
        }
      }, { scope: 'public_profile,email' });
    }
  },
  apple: {
    color: '#000',
    icon: <AppleIcon />,
    text: TEXTS.VERIFY_WITH_APPLE,
    handler: ({
      successCallback = f => f,
      errorCallback = f => f
    }) => {
      const nonce = randomString(32);
      const appleSignin = initAppleSignin(nonce);
      if (appleSignin) {
        appleSignin.then(({
          user: {
            name: {
              firstName = '',
              lastName = ''
            } = {}
          } = {},
          authorization: {
            code,
            id_token: idToken
          }
        }) => {
          successCallback({
            password: {
              authorizationCode: code,
              identityToken: idToken,
              realNonce: nonce,
              name: `${firstName} ${lastName}`
            },
            isSocialLogin: 'apple'
          });
        }).catch(() => {
        // TODO: handle errors if necessary
          errorCallback();
        });
      }
    }
  }
};

export const socialLoginScripts = {
  facebook: () => loadJS('facebook-jssdk', 'https://connect.facebook.net/en_US/sdk.js'),
  google: () => loadJS('google-jssdk-new', 'https://accounts.google.com/gsi/client'),
  apple: () => loadJS('apple-jssdk', 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js')
};
