import React from 'react';
import PropTypes from 'prop-types';
import { Provider as JotaiProvider, createStore, atom } from 'jotai';

export const projectAtom = atom();
export const userAtom = atom({});

const Provider = ({
  user, project, children
}) => {
  const store = createStore();
  store.set(projectAtom, project);
  store.set(userAtom, user);

  return (
    <JotaiProvider store={store}>
      {children}
    </JotaiProvider>
  );
};

Provider.propTypes = {
  user: PropTypes.object.isRequired,
  project: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export default Provider;
