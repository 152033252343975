/* eslint-disable no-param-reassign */
import React, { createRef } from 'react';
import ReactDOM from 'react-dom';
import OFACModal from './OFACModal';
import { getPortalElement } from '../../utils/helper';

const openOFACModal = (ref, email) => {
  if (!ref) {
    ref = createRef();
  }

  const portalElement = getPortalElement('ofacModal');
  ReactDOM.render(<OFACModal isModal={true} ref={ref} email={email} />, portalElement);
};

export { OFACModal, openOFACModal };
