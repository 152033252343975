import React from 'react';
import { Loading } from '@jotforminc/loading';

const Loader = () => (
  <div className='delete-account-modal-step-1'>
    <div className='delete-step-1-body'>
      <div className='delete-step-1-icon-wrapper'>
        <div className='delete-step-1-icon-container'>
          <Loading size='50px' strokeWidth='2' />
        </div>
      </div>
    </div>
  </div>
);

export default Loader;
