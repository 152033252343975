import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { t } from '@jotforminc/translation';
import { IconLinkDiagonal } from '@jotforminc/svg-icons';

import { Texts } from '../constants';

const SharableLinkButton = ({ generateLink }) => {
  const [link, setLink] = useState(null);
  const [copied, setCopied] = useState(false);
  const [error, setError] = useState(false);

  const afterCopy = result => {
    if (result) {
      setCopied(true);
      const id = setTimeout(() => {
        setCopied(false);
        clearTimeout(id);
      }, 3000);
    } else {
      setError(true);
    }
  };

  const fallbackCopyTextToClipboard = text => {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    textArea.style.position = 'fixed';
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    let retVal = false;
    try {
      const successful = document.execCommand('copy');
      retVal = successful ? true : false;
    } catch (err) {
      retVal = false;
    }

    document.body.removeChild(textArea);
    return retVal;
  };

  const copyTextToClipboard = text => {
    const result = fallbackCopyTextToClipboard(text);
    if (!result) {
      window.navigator.clipboard.writeText(text).then(() => {
        return afterCopy(true);
      }, () => {
        return afterCopy(false);
      });
    }
    return afterCopy(result);
  };

  const handleCopyLink = () => {
    const url = generateLink();
    setLink(url);
    copyTextToClipboard(url);
  };

  if (error) {
    return (
      <div style={{ position: 'relative' }}>
        <span className="sacl-modal__tooltip sacl-modal__tooltip--error">{t('Your browser does not support copy text feature. You can manually copy it.')}</span>
        <input type="text" className="jfInput u-tooltipTrigger" value={link} />
      </div>
    );
  }

  return (
    <button className={`sacl-btn sacl-btn--second ${(copied || error) ? 'isActive' : ''}`} type="button" onClick={handleCopyLink}>
      <IconLinkDiagonal />
      {t(Texts.GET_DRAFT_LINK)}
      {(copied || error) && (
        <span className="sacl-modal__tooltip">
          {copied ? t(Texts.COPIED_CLIPBOARD) : t(Texts.DOESNT_SUPPORT_BROWSER)}
        </span>
      )}
    </button>
  );
};

export default SharableLinkButton;

SharableLinkButton.propTypes = {
  generateLink: PropTypes.func.isRequired
};
