import React from 'react';
import ReactDOM from 'react-dom';
import EmailAuthLoginModal from '../components/EmailAuthLoginModal';

const ROOT_ID = 'email-auth-root';

const createRootEl = () => {
  const rootEl = document.createElement('div');
  rootEl.setAttribute('id', ROOT_ID);

  document.body.appendChild(rootEl);
  return rootEl;
};

const getRootEl = () => {
  const emailAuthRoot = document.querySelector(`#${ROOT_ID}`);
  if (emailAuthRoot) {
    return emailAuthRoot;
  }
  return createRootEl(ROOT_ID);
};

const removeModalFromDOM = () => {
  const emailAuthRoot = getRootEl();
  if (emailAuthRoot) {
    ReactDOM.unmountComponentAtNode(emailAuthRoot);
  }
};

const renderStandaloneEmailAuthModal = props => {
  const rootEl = getRootEl();
  ReactDOM.render(
    <EmailAuthLoginModal {...props} rootEl={rootEl} removeModalFromDOM={removeModalFromDOM} />,
    rootEl
  );
};

export default renderStandaloneEmailAuthModal;
