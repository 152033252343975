/* eslint-disable react/prop-types */
import React from 'react';
import ReactDOM from 'react-dom';

import { FormUserAccountBox } from '@jotforminc/account-box';
import { TranslateProvider } from '@jotforminc/translation';

const checkShouldRender = () => {
  const searchParams = new URLSearchParams(document?.location?.search || '');
  const isMobileApp = searchParams.get('jotformNext') === '1' || !!global?.window?.mobileThankYouPage;
  const isJFApps = searchParams.get('jotform_pwa') === '1' && searchParams.get('hideAccountBox') === '1';
  return !(isMobileApp || isJFApps);
};

const RenderAccountBox = (
  {
    avatarUrl,
    name,
    email,
    userType,
    permissions,
    linkTarget = '_self'
  } = {},
  logoutFn
) => {
  const shouldRender = checkShouldRender();
  if (!shouldRender) return null;

  const accountBoxContainer = document.createElement('div');
  accountBoxContainer.className = 'formUserAccountBoxContainer';
  document.body.appendChild(accountBoxContainer);
  document.body.classList.add('hasFormUserAvatar');

  ReactDOM.render(
    <TranslateProvider>
      <FormUserAccountBox
        avatarUrl={avatarUrl}
        userType={userType}
        name={name}
        email={email}
        onUserLogout={logoutFn}
        allowMyForms={permissions && permissions.allowMyForms}
        linkTarget={linkTarget}
      />
    </TranslateProvider>, accountBoxContainer
  );
};

export default RenderAccountBox;
