import Tracking from '@jotforminc/tracking';
import { getDomainURL } from '@jotforminc/request-layer';
import { isEnterprise } from '@jotforminc/enterprise-utils';

export function getFileServer() {
  const fileServerElement = document.querySelector('#file_server');
  if (fileServerElement) return fileServerElement.value;
  return null;
}

export function getFileUploadChanges() {
  const deletedFiles = Array.from(document.querySelectorAll('input[id^="deletedFileForSACL"]'));
  const uploadedFiles = Array.from(document.querySelectorAll('input[id^="uploadNewForSACL"]'));

  const fileUploadChanges = {};

  const addDataIntoFileUploadChanges = (arr, name) => {
    arr.forEach(df => {
      const qid = df.id.split('-')[1];
      const { value } = df;
      if (value) {
        fileUploadChanges[name] = { ...fileUploadChanges[name], [qid]: JSON.parse(value) };
      }
    });
  };

  addDataIntoFileUploadChanges(deletedFiles, 'deletedFiles');
  addDataIntoFileUploadChanges(uploadedFiles, 'uploadedFiles');

  return fileUploadChanges;
}

export function objectHasAnyKey(o = {}) {
  return Object.keys(o).length > 0;
}

export function getSubstring(string, char1, char2) {
  const index1 = string.indexOf(char1);
  const index2 = string.indexOf(char2);
  if (index1 === -1 || index2 === -1) {
    return string;
  }
  return string.slice(index1 + 1, index2);
}

export function keyGrapper(values) {
  const result = {};
  values.forEach(({ name, value }) => {
    const key = getSubstring(name, '[', ']');
    result[key] = value;
  });
  return result;
}

export function getHiddenInputs() {
  return Array.from(document.querySelectorAll('input[data-component="hidden"]')).map(field => ({
    id: field.name.split('_')[0].substring(1),
    value: field.value
  }));
}

const generateQuerySelector = DOMObject => {
  let currentDOMObject = DOMObject;
  if (!currentDOMObject || !(currentDOMObject instanceof window.HTMLElement)) {
    // eslint-disable-next-line no-console
    console.log('No DOMObject provided or DOMObject is not an instance of HTMLElement.');
    return null;
  }

  const isStaticNode = searchNode => [
    global.HTMLDocument,
    global.HTMLBodyElement,
    global.HTMLHtmlElement
  ].some(staticNode => searchNode instanceof staticNode);

  const getIndex = node => {
    let i = 1;
    let currentNode = node;
    const { tagName } = currentNode;
    while (currentNode.previousSibling) {
      currentNode = currentNode.previousSibling;
      if (currentNode.nodeType === 1 && tagName.toLowerCase() === currentNode.tagName.toLowerCase()) {
        i++;
      }
    }
    return i;
  };

  let pathSelector;
  const index = getIndex(currentDOMObject);
  while (currentDOMObject.tagName) {
    const className = !isStaticNode(currentDOMObject) && currentDOMObject.className.split(' ').filter(Boolean).join('.');
    const idName = !isStaticNode(currentDOMObject) && currentDOMObject.id;
    pathSelector = [
      currentDOMObject.localName,
      className && `.${className}`,
      idName && `[id='${idName}']`,
      pathSelector && `>${pathSelector}`
    ].filter(Boolean).join('');
    currentDOMObject = currentDOMObject.parentNode;
  }
  pathSelector = `${pathSelector}:nth-of-type(${index})`;
  if (document.querySelector(pathSelector)) {
    return pathSelector;
  }
  console.log('Failed to proerly generate the query selector for given HTMLElement.');
  return null;
};

export function getCurrentFormID() {
  return window.JFForm ? window.JFForm.id : document.forms[0]?.id;
}

export function getAPILink() {
  return window.JotFormAPIEndpoint ? window.JotFormAPIEndpoint : 'https://api.jotform.com';
}

export function getCurrentForm() {
  return document.querySelector('.jotform-form');
}

export function getCurrentPage() {
  // window.JotForm.currentSection <- Has BUG (It gives inconsistent result)
  if (window.CardForm) {
    return window.CardForm.layoutParams.currentCardIndex + 1;
  }

  const getCurrentSectionIndex = () => {
    const sections = document.querySelectorAll('.page-section');
    return Array.from(sections).findIndex(section => section.style.display !== 'none');
  };

  const index = getCurrentSectionIndex();
  if (index > 0) {
    return index + 1;
  }
}

export function getCurrentBackStack() {
  if (!window.JotForm.backStack) {
    return '';
  }
  return window.JotForm.backStack?.map(val => generateQuerySelector(val));
}

export function getFolderName() {
  const elems = document.getElementsByName('temp_upload_folder');
  if (elems.length > 0) {
    return elems[0]?.value || null;
  }
}

export function getUploadServerUrl() {
  const elems = document.getElementsByName('uploadServerUrl');
  if (elems.length > 0) {
    return elems[0]?.value || null;
  }
}

export function jumpToPage(index) {
  const page = index;
  const sections = document.querySelectorAll('.form-section:not([id^=section_])');

  if (!(page && page > 1) || page > sections.length) return; // no page to jump to

  window.JotForm.hideFormSection(sections[0]);
  window.JotForm.showFormSection(sections[page - 1]);

  if (page > 1) window.JotForm.backStack = sections.splice(0, page - 1); // so the back button will go to the previous pages and not the first

  window.JotForm.runAllCalculations(true); // so newly hidden fields may be excluded
}

export const makeConstant = Object.freeze;

export const logToSentry = (logMessage, data) => {
  Tracking.captureException(logMessage, { extra: { ...data } });
};

export const getWidgetName = field => {
  return field.querySelector('iframe')?.src.split('/')[3];
};

export const getFieldType = field => {
  return field.dataset.type;
};

export const getFieldQuestionID = field => {
  return field.id.split('_')[1];
};

export const getAllUrlParams = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const params = {};
  searchParams.forEach((value, key) => {
    params[key] = value;
  });
  return params;
};

export const isPortal = () => {
  return window?.JFAppsManager?.isOpenedInPortal() && !window?.JFAppsManager?.isProductListForm;
};

export const isCardForm = () => {
  return !!window.CardForm;
};

export const createHiddenInput = (props = {}) => {
  const input = document.createElement('input');
  input.setAttribute('type', 'hidden');
  Object.keys(props).forEach(name => input.setAttribute(name, props[name]));
  return input;
};

export const appendToForm = element => {
  const form = getCurrentForm();
  if (form && element) {
    form.appendChild(element);
  }
};

export function setFolderName(name) {
  const elem = document.querySelector("input[name='temp_upload_folder']");
  if (elem) {
    elem.value = name;
  } else {
    console.log('temp_upload_folder element not found');
    const hiddenInput = createHiddenInput({ name: 'temp_upload_folder', value: name });
    appendToForm(hiddenInput);
    window.JotForm.tempUploadFolderInjected = true;
  }
}

export function setUploadServerUrl(name) {
  const elem = document.querySelector("input[name='uploadServerUrl']");
  if (elem) {
    elem.value = name;
  } else {
    console.log('temp_upload_folder element not found');
    const hiddenInput = createHiddenInput({ name: 'uploadServerUrl', value: name });
    appendToForm(hiddenInput);
    window.JotForm.uploadServerUrlInjected = true;
  }
}

export const appendDraftIDToForm = draftID => {
  if (draftID) {
    // change old draftID if exists
    const oldInput = document.getElementById('draftID');
    if (oldInput) {
      oldInput.value = draftID;
    } else {
      const hiddenInput = createHiddenInput({ name: 'draftID', id: 'draftID', value: draftID });
      appendToForm(hiddenInput);
    }
  }
};

export const appendCurrentPageToForm = pageNo => {
  if (pageNo) {
    const hiddenInput = createHiddenInput({ name: 'jumpToPage', id: 'jumpToPage', value: pageNo });
    appendToForm(hiddenInput);
  }
};

export const removeFileChangeInputs = () => {
  Array.from(document.querySelectorAll('input[id^="deletedFileForSACL"], input[id^="uploadNewForSACL"]')).forEach(e => e.remove());
};

export const generateSessionLink = ({
  sessionID, token, continueKey, formID, param
}) => {
  const pathnameClean = window.location.pathname.replace(/^\/|(?:(?=[&?]).*$)/g, '');
  if (isPortal()) {
    return window.JFAppsManager.generateAppContinueLink(sessionID, token, formID, continueKey);
  }

  if (continueKey && isEnterprise()) {
    return `${getDomainURL()}/${param}/${continueKey}`;
  }

  if (continueKey) {
    const domainURL = getDomainURL().replace(/^https:\/\//, '');
    const hostname = `https://${domainURL.replace(/^form\./, '')}`;
    return `${hostname}/${param}/${continueKey}`;
  }

  return `${window.location.origin}/${isPortal() ? `${pathnameClean}` : `${formID}`}?session=${sessionID}&stoken=${token}`;
};

export const sendSaveEventToAllWidgets = () => {
  document.querySelectorAll('li.form-line').forEach(field => {
    const id = field.id.split('_')[1];
    if (window.JotForm.getInputType(id) === 'widget') {
      const frame = document.getElementById(`customFieldFrame_${id}`);
      if (frame) {
        const referrer = frame.src;
        window.XD.postMessage(JSON.stringify({ type: 'save' }), referrer, frame);
      }
    }
  });
};
