import React from 'react';
import {
  array, func, object
} from 'prop-types';

import { t } from '@jotforminc/translation';

import AppleIcon from '../../assets/icon_ap_36.svg';
import FacebookIcon from '../../assets/icon_fb_36.svg';
import GoogleIcon from '../../assets/icon_gl_36.svg';
import MicrosoftIcon from '../../assets/icon_ms_36.svg';
import SalesforceIcon from '../../assets/icon_sf_36.svg';

import GoogleButton from '../GoogleButton';
import FacebookButton from '../FacebookButton';
import MicrosoftButton from '../MicrosoftButton';
import AppleButton from '../AppleButton';
import SalesforceButton from '../SalesforceButton';

const icons = {
  apple: AppleIcon,
  facebook: FacebookIcon,
  google: GoogleIcon,
  microsoft: MicrosoftIcon,
  salesforce: SalesforceIcon
};
const buttons = {
  apple: AppleButton,
  facebook: FacebookButton,
  google: GoogleButton,
  microsoft: MicrosoftButton,
  salesforce: SalesforceButton
};

const SocialsContainer = ({
  socialLogins,
  onAppleLoginClick,
  onFBLoginClick,
  onGoogleLoginClick,
  onMicrosoftLoginClick,
  onSalesforceLoginClick,
  user
}) => {
  const callbacks = {
    apple: onAppleLoginClick,
    facebook: onFBLoginClick,
    google: onGoogleLoginClick,
    microsoft: onMicrosoftLoginClick,
    salesforce: onSalesforceLoginClick
  };

  const connectedAccounts = socialLogins.map(login => (Object.hasOwn(buttons, login) ? {
    Component: buttons[login], Icon: icons[login], callback: callbacks[login], key: login
  } : null));

  const { username, email, avatarUrl } = user.credentials;

  return (
    <div className="social-logins-section">
      {connectedAccounts.map(({
        Component, Icon, callback, key
      }) => (
        <div className="social-login">
          <div className="social-login-info">
            <img
              className="social-login-info-avatar"
              src={avatarUrl}
              alt="user avatar"
            />
            <div className="social-login-info-credentials">
              <p className="social-login-info-credentials-username">{username}</p>
              <p className="social-login-info-credentials-email">{email}</p>
            </div>
          </div>
          <Component
            classNames='social-login-icon'
            text={t('Verify with')}
            icon={<Icon />}
            onClick={callback}
            key={key}
          />
        </div>
      ))}
    </div>
  );
};

SocialsContainer.propTypes = {
  socialLogins: array.isRequired,
  onAppleLoginClick: func,
  onFBLoginClick: func,
  onGoogleLoginClick: func,
  onMicrosoftLoginClick: func,
  onSalesforceLoginClick: func,
  user: object.isRequired
};

SocialsContainer.defaultProps = {
  onAppleLoginClick: () => {},
  onFBLoginClick: () => {},
  onGoogleLoginClick: () => {},
  onMicrosoftLoginClick: () => {},
  onSalesforceLoginClick: () => {}
};

export default SocialsContainer;
