import {
  useCallback, useEffect, useState, useRef, useMemo, useLayoutEffect
} from 'react';
import Fuse from 'fuse.js';
import { nodeContains } from '@jotforminc/utils';

import { generateShortID } from '.';

export const useClickOutsideState = (initialState, refs = [], eventTypes = ['mouseup']) => {
  const [isVisible, setVisibility] = useState(initialState);

  useEffect(() => {
    const hideMenu = ({ target }) => !refs.find(ref => nodeContains(ref.current, target)) && setVisibility(false);

    eventTypes.forEach(eventType => {
      global.addEventListener(eventType, hideMenu);
    });

    return () => eventTypes.forEach(eventType => global.removeEventListener(eventType, hideMenu));
  }, [refs]);

  return [isVisible, setVisibility];
};

export const useClickOutsideStateWithCallback = ({ refs = [], callback = null, eventType = 'mouseup' }) => {
  const _callback = typeof callback === 'function' ? callback : f => f;
  useEffect(() => {
    const hideMenu = ({ target }) => !refs.find(ref => nodeContains(ref.current, target)) && _callback(target);

    global.addEventListener(eventType, hideMenu);
    return () => global.removeEventListener(eventType, hideMenu);
  }, [refs]);
};

export const useClickOutsideStateMouseDown = (initialState, refs = []) => {
  const [isVisible, setVisibility] = useState(initialState);

  useEffect(() => {
    const hideMenu = ({ target }) => !refs.find(ref => nodeContains(ref.current, target)) && setVisibility(false);

    global.addEventListener('mousedown', hideMenu);
    return () => global.removeEventListener('mousedown', hideMenu);
  }, [refs]);

  return [isVisible, setVisibility];
};

export const useClickOutsideStateWithSelector = (initialState, selector) => {
  const [isVisible, setVisibility] = useState(initialState);
  const hideMenu = event => {
    const containerList = global.document.querySelectorAll(selector);
    const isContained = [...containerList].find(container => nodeContains(container, event.target));
    if (!isContained) {
      setVisibility(false);
    }
  };

  useEffect(() => {
    global.addEventListener('mouseup', hideMenu);
    return () => global.removeEventListener('mouseup', hideMenu);
  }, [selector]);

  return [isVisible, setVisibility];
};

export const useSelectionState = (initialState, isMultiSelect = false) => {
  // Instance methods
  const sanitizeInitialState = !Array.isArray(initialState) ? [initialState] : initialState;

  // States
  const [selectedOptions, setSelectedOptions] = useState(sanitizeInitialState);

  // State methods
  const toggleOptionSelection = selectedValue => {
    const isOptionExist = selectedOptions.indexOf(selectedValue) > -1;
    const newSelectionArray = isOptionExist ? selectedOptions.filter(value => selectedValue !== value) : [...selectedOptions, selectedValue];
    setSelectedOptions(newSelectionArray);
  };

  const singleToggleOptionSelection = selectedValue => {
    const isOptionExist = selectedOptions.indexOf(selectedValue) > -1;
    if (isOptionExist) {
      return;
    }

    setSelectedOptions([selectedValue]);
  };

  if (isMultiSelect) {
    return [selectedOptions, toggleOptionSelection, setSelectedOptions];
  }

  return [selectedOptions, singleToggleOptionSelection, setSelectedOptions];
};

export const useEffectIgnoreFirst = (effectHandler, effectDependedValues) => {
  const isFirstRun = useRef(true);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }

    return effectHandler();
  }, effectDependedValues);
};

export const useCombinedRefs = (...refs) => {
  const targetRef = useRef();

  useEffect(() => {
    refs.forEach(ref => {
      if (!ref) {
        return;
      }

      if (typeof ref === 'function') {
        ref(targetRef.current);
      } else {
        // eslint-disable-next-line no-param-reassign
        ref.current = targetRef.current;
      }
    });
  }, [refs]);

  return targetRef;
};

export const usePortalRef = (
  containerSelector = 'body',
  usePortal = false,
  portalAttribute = 'data-uikitGeneratedPortalContainer',
  initialPortalSelector = ''
) => {
  // Returns a ref to pass to React.createPortal, either a reference to an existing element or it creates a new element.
  const getPortalRefElement = useCallback(() => {
    if (!usePortal) return;

    const portalContainer = global.document.querySelector(containerSelector);
    if (portalContainer) {
      // Portal Container element already exists -> return that element
      const currentPortalDiv = portalAttribute ? portalContainer.querySelector(`[${portalAttribute}=true]`) : false;
      if (currentPortalDiv) return currentPortalDiv;

      // Otherwise create a new Portal Container element
      const id = generateShortID();
      const div = global.document.createElement('div');
      div.setAttribute(portalAttribute, 'true');
      div.setAttribute('id', id);
      portalContainer.appendChild(div);
      return div;
    }
  }, [containerSelector, usePortal, portalAttribute]);

  // if the parent component won't rerender, it won't be able to detect the ref.current change so initial selector may be needed for components like those
  const portalRef = useRef(initialPortalSelector ? document.querySelector(initialPortalSelector) : getPortalRefElement());

  useEffect(() => {
    portalRef.current = getPortalRefElement() || portalRef.current;
  }, [getPortalRefElement]);

  return portalRef;
};

export const useDragState = (initialState, {
  onDrop: onDropCallBack,
  onDragOver: onDragOverCallBack,
  onDragEnter: onDragEnterCallBack,
  onDragLeave: onDragLeaveCallBack
} = {}) => {
  const [isDragging, setDragState] = useState(initialState);

  const generateDragEventHandler = (state, callback) => event => {
    setDragState(state);
    event.stopPropagation();
    event.preventDefault();

    if (typeof callback === 'function') {
      callback(event);
    }

    return false;
  };

  const onDragEnter = generateDragEventHandler(true, onDragEnterCallBack);
  const onDragOver = generateDragEventHandler(true, onDragOverCallBack);
  const onDragLeave = generateDragEventHandler(false, onDragLeaveCallBack);
  const onDrop = generateDragEventHandler(false, onDropCallBack);

  return [isDragging, {
    onDragEnter, onDragOver, onDragLeave, onDrop
  }];
};

const cachedScripts = [];
export const useScript = src => {
  const [scriptState, setLoadState] = useState({ loaded: false, error: false });

  useEffect(() => {
    if (cachedScripts.indexOf(src) > -1) {
      setLoadState({ loaded: true, error: false });
      return f => f;
    }

    cachedScripts.push(src);

    const scriptTag = document.createElement('script');
    scriptTag.src = src;
    scriptTag.async = true;

    const onScriptLoad = () => setLoadState({ loaded: true, error: false });
    const onScriptError = () => {
      setLoadState({ loaded: true, error: true });
      scriptTag.remove();
    };

    scriptTag.addEventListener('load', onScriptLoad);
    scriptTag.addEventListener('error', onScriptError);
    document.body.appendChild(scriptTag);

    return () => {
      scriptTag.removeEventListener('load', onScriptLoad);
      scriptTag.removeEventListener('error', onScriptError);
    };
  }, [src]);

  return scriptState;
};

export const useFuse = (list, searchTerm, keys = ['text'], fuseOptions = {
  shouldSort: true,
  threshold: 0.4,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1
}) => {
  const fuse = useMemo(() => new Fuse(list, { ...fuseOptions, keys }), [list, fuseOptions, keys]);
  const results = useMemo(() => (!searchTerm ? list : fuse.search(searchTerm)), [fuse, searchTerm]);
  return results;
};

export const usePrev = value => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const useInView = onInView => {
  const ref = useRef();

  useEffect(() => {
    let observer;

    global.requestAnimationFrame(() => {
      if (!ref.current) return;

      if (global.IntersectionObserver) {
        observer = new global.IntersectionObserver(([entry]) => {
          if (entry.intersectionRatio === 0) {
            return;
          }

          onInView(true);

          observer.unobserve(ref.current);
        }, { threshold: 1 });

        observer.observe(ref.current);
      } else {
        onInView(true);
      }
    });

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [ref.current]);

  return [ref];
};

export const useContinueFocus = (isOpen, currentActiveElementRef) => {
  const panelRef = useRef();
  const activeElementRef = currentActiveElementRef || useRef();
  const isFirstRun = useRef(true);

  useLayoutEffect(() => {
    if (isFirstRun.current && !isOpen) {
      isFirstRun.current = false;
      return;
    }
    panelRef.current?.setAttribute('tabindex', isOpen ? 0 : -1);
    if (isOpen) {
      if (!currentActiveElementRef) { // if current element ref is not given, focus last active element
        activeElementRef.current = document.activeElement;
      }
      panelRef.current?.focus();
    } else {
      activeElementRef.current?.focus();
    }
    return () => activeElementRef.current?.focus();
  }, [isOpen]);

  return panelRef;
};
