import { getBaseURL, getEnv } from '@jotforminc/router-bridge';
import { t } from '@jotforminc/translation';
import { handleCustomNavigation } from './platform';
import { getUrlParameter } from './data';

export const shortenString = (str, maxLength = 15) => {
  const sanitizedString = str.replace(/(<([^>]+)>)/ig, '');
  return (
    sanitizedString.length > maxLength
      ? `${sanitizedString.substring(0, maxLength - 2)}...`
      : sanitizedString
  );
};

export const getTextForDuplicate = ({
  list,
  count = 1,
  text = '',
  baseKey = 'text'
}) => {
  const isExists = list.find(({ [baseKey]: tmpText }) => tmpText === text);
  if (!isExists) {
    return text;
  }

  const textParts = text.split('_');
  const originalText = textParts.length === 1 ? textParts[0] : textParts.slice(0, -1).join('_');
  return getTextForDuplicate({
    list,
    baseKey,
    count: count + 1,
    text: `${originalText}_${count}`
  });
};

export const tagDuplicateProperty = baseKey => values => {
  const sanitizedValues = Array.isArray(values) ? values : [];
  const updatedValues = sanitizedValues.reduce((list, property = {}) => {
    const text = property[baseKey];

    if (text === undefined) {
      return [...list, property];
    }

    const newText = getTextForDuplicate({ list, baseKey, text });
    const updatedProperty = { ...property, [baseKey]: newText };
    return [...list, updatedProperty];
  }, []);
  return updatedValues;
};

function getRandomColor() {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

const defaultPalette = [
  '#007862',
  '#F46A00',
  '#047C9E',
  '#0876DB',
  '#00871E',
  '#363E76',
  '#656A9B',
  '#69E8AB',
  '#7553A3',
  '#9A674A',
  '#B0ECFF',
  '#B94B9A',
  '#C1FCF1',
  '#C7DF68',
  '#CE1F73',
  '#D1DEE2',
  '#DCC2FF',
  '#DD304C',
  '#DEE1FF',
  '#E2D1D1',
  '#FDC1CA',
  '#FFCDFA',
  '#FFD6BC',
  '#FFE195'
];

export const colorifyFormArray = (formArray, palette = defaultPalette) => {
  return formArray.map((formObj, i) => {
    const color = palette[i] ? palette[i] : getRandomColor();
    return { ...formObj, color: formObj.color || color };
  });
};

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

export const isBright = sourceColor => {
  if (sourceColor && sourceColor.length > 0) {
    const color = (sourceColor.charAt(0) === '#') ? sourceColor.substring(1, 7) : sourceColor;
    const r = parseInt(color.substring(0, 2), 16); // hexToR
    const g = parseInt(color.substring(2, 4), 16); // hexToG
    const b = parseInt(color.substring(4, 6), 16); // hexToB
    return (((r * 0.299) + (g * 0.587) + (b * 0.114)) > 186);
  }
  return false;
};

/**
 * This function creates an object with keys given in array of string type
 * and makes the defaultValue parameter the value of all keys.
 */
export const createObjectWithKeys = (keys, defaultValue = true) => keys.reduce((accumulatorObject, key) => ({ ...accumulatorObject, [key]: defaultValue }), {});

export const filterObjectKeysByValue = (obj, value = true) => Object.keys(obj).filter(key => obj[key] === value);

export const getAccountTypeName = user => {
  if (user.accountType) {
    return user.accountType;
  }
  return user?.account_type?.name;
};

export const classNames = (...arr) => arr.flat().filter(Boolean).join(' ');

export const handleRedirect = formID => {
  const integrationParam = getUrlParameter('integration');

  handleCustomNavigation(`/sign/${formID}${integrationParam.length > 0 ? `/settings/integrations?integration=${integrationParam}` : ''}`, '_self');
};

export const generateSessionLink = (sessionID, token, formID) => {
  const pathnameClean = window.location.pathname.replace(/^\/|(?:(?=[&?]).*$)/g, '');
  if (window?.JFAppsManager?.isOpenedInPortal() && !window?.JFAppsManager?.isProductListForm) {
    return window.JFAppsManager.generateAppContinueLink(sessionID, token, formID);
  }
  return `${window.location.origin}/${pathnameClean}?session=${sessionID}&stoken=${token}`;
};

export const generateUniqueID = () => (Math.random().toString(36).substring(2));

export const openInNewTab = (url, target = '_blank') => {
  const dontOverwrite = true;
  handleCustomNavigation(url, target, dontOverwrite, 'noopener,noreferrer');
};

export const handlePublishPRLink = originalLink => {
  const regex = /https:\/\/(?:www\.)?jotform\.ooo(?:\/PR\/(\d+))?\/build\/(\d+)(\/publish)?/;
  const match = originalLink.match(regex);

  if (match) {
    const prNumber = match[1];
    const buildNumber = match[2];

    const newLink = prNumber ? `https://www.jotform.ooo/PR/${prNumber}/${buildNumber}` : `https://www.jotform.ooo/${buildNumber}`;
    return newLink;
  }

  return null;
};

export const isUserAllowedView = credentials => {
  const { account_type: { name } = {} } = credentials || {};
  const allowedUsers = ['ADMIN', 'SUPPORT'];

  if (allowedUsers.indexOf(name) > -1) return true;
  return false;
};

export const redirectToLoginPage = () => {
  window.location.href = `${getBaseURL()}/login`;
};

export const isProduction = () => {
  return getEnv() === 'PRODUCTION' || window.JOTFORM_ENV === 'PRODUCTION';
};

function numberFormat(number, decimals, decPoint, thousandsSep) {
  const n = number;
  const prec = decimals;
  const toFixedFix = (num, _prec) => {
    const k = 10 ** _prec;
    return (Math.round(num * k) / k).toString();
  };
  // n = !isFinite(+n) ? 0 : +n;
  // prec = !isFinite(+prec) ? 0 : Math.abs(prec);
  const sep = (typeof thousandsSep === 'undefined') ? ',' : thousandsSep;
  const dec = (typeof decPoint === 'undefined') ? '.' : decPoint;
  let s = (prec > 0) ? toFixedFix(n, prec) : toFixedFix(Math.round(n), prec);
  const abs = toFixedFix(Math.abs(n), prec);
  let _; let i;
  if (abs >= 1000) {
    _ = abs.split(/\D/);
    i = _[0].length % 3 || 3;
    _[0] = s.slice(0, i + (n < 0)) + _[0].slice(i).replace(/(\d{3})/g, `${sep}$1`);
    s = _.join(dec);
  } else {
    s = s.replace('.', dec);
  }

  if (+s > 0) {
    if (s.indexOf(dec) === -1 && prec > 1) {
      const preca = [];
      preca[prec - 1] = undefined;
      s += `${dec} ${preca.join(0)} 0`;
    } else if (s.indexOf(dec) === s.length - 2) { // incorrect: 2.7,  correct: 2.70
      s += '0';
    }
  }
  return s;
}

export const bytesToHuman = octets => {
  const units = ['B', 'kB', 'MB', 'GB', 'TB']; // ...etc
  let i = 0;
  let size = octets;
  for (i = 0; size > 1024; size /= 1024) { i++; }
  return numberFormat(size, 1) + units[Math.min(i, units.length - 1)];
};

export const saveBlobAsFile = (blob, fileName) => {
  // For ie and edge, use native msSaveOrOpenBlob method instead of dummy anchor and click
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, fileName);
  } else if (window.URL.createObjectURL) {
    const link = document.createElement('a');
    const blobURL = window.URL.createObjectURL(blob);
    link.href = blobURL;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(blobURL);
  }
};

/**
 * When send a GET request to a PDF file url which has nc query parameter with value 1,
 * Content-Disposition in response header's value becomes 'inline'
 * and this behaviour causes to open file in the current tab
 * To have the downloaded file as an attachment,
 * we should convert the url to a blob and download this blob.
 * @param {string} fileURL
 * @param {function} callback
 */
export const downloadPDFCheckingNoCache = async (fileURL, callback = f => f) => {
  const contentURL = new window.URL(fileURL);
  const { searchParams, pathname } = contentURL;
  const hasNoChacheFlag = searchParams.get('nc') === '1';
  if (hasNoChacheFlag) {
    const fileResponse = await fetch(contentURL.href);
    const blob = await fileResponse.blob();
    const paths = pathname.split('/');
    const filename = paths[paths.length - 1];
    saveBlobAsFile(blob, filename);
  } else {
    callback();
  }
};

export const sleep = ms => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

export const encodeURLs = context => {
  const urlRegex = /"https?:\/\/(.*?)"/g;
  return context.replace(urlRegex, (_, url) => `"https://${encodeURI(url)}"`);
};

export const getDimensionsAndPosition = node => {
  const rect = node.getBoundingClientRect();
  return {
    width: rect.width,
    height: rect.height,
    top: rect.top,
    right: rect.right,
    bottom: rect.bottom,
    left: rect.left
  };
};
export const openSocialMediaPopup = (url, callback) => {
  if (!url) return;

  const w = (window.innerWidth || window.screen.width) / 2;
  const h = (window.innerHeight || window.screen.height) / 2;
  let y = window.outerHeight / 2 + window.screenY - h / 2;
  let x = window.outerWidth / 2 + window.screenX - w / 2;
  try {
    y = window.top.outerHeight / 2 + window.top.screenY - h / 2;
    x = window.top.outerWidth / 2 + window.top.screenX - w / 2;
  } catch (err) {
    // error accessing to cross origin frame. Do nothing.
  }

  handleCustomNavigation(url, '_blank', true, `toolbar=0,location=0,menubar=0,width=${w},height=${h},top=${y},left=${x}`);

  if (typeof callback === 'function') {
    callback();
  }
};

export const getMissingPDFAttachmentMessage = (availablePDFIDs = [], currentSelectedPDFIDs = []) => {
  const diff = currentSelectedPDFIDs.filter(id => !availablePDFIDs.includes(id));
  if (diff.length) {
    if (diff.length > 1) {
      return t('Attached documents are missing.');
    }
    return t('Attached document is missing.');
  }
};

export const doesTargetLinkContainsProtocol = targetURL => {
  const protocolRegex = /^(https|http|ftp|tel|mailto|ms-excel):\/\//gi;
  return protocolRegex.test(targetURL);
};

export const checkAndAddProtocolToTargetLink = targetURL => {
  if (!doesTargetLinkContainsProtocol(targetURL)) return `https://${targetURL}`;

  return targetURL;
};
