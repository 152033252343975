import Axios from 'axios';
import qs from 'querystring';
import { loadJS, getPossibleTimeZone } from '@jotforminc/utils';
import isNil from 'lodash/isNil';
import { Texts } from '@jotforminc/constants';
import layer from './layer';

const trackAction = data => layer.post(
  't/jfrev',
  data,
  {
    headers: {
      'Content-Type': 'application/json'
    }
  }
);

export const actionTracker = ({
  username = '', action = '', target = ''
}) => {
  const log = {
    project: 'login-flow',
    oldActions: {
      action: action,
      target: target
    },
    actor: username,
    location: window.location && window.location.href
  };

  trackAction(log);
};

export const loadAppleClient = nonce => {
  loadJS(
    'jf-apple-id',
    'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js',
    () => {
      if (!window.AppleID) return;

      window.AppleID.auth.init({
        clientId: 'com.jotform.applesignin',
        scope: 'name email',
        redirectURI: `https://${window.location.host}/API/login/apple`,
        response_type: 'code',
        response_mode: 'form_post',
        nonce,
        usePopup: true
      });
    }
  );
};

export const loadFacebookClient = () => {
  loadJS('facebook-jssdk', 'https://connect.facebook.net/en_US/sdk.js');
};

export const loadGoogleClient = () => {
  loadJS('google-jssdk-new', 'https://accounts.google.com/gsi/client');
};

export const loadMicrosoftClient = () => {
  loadJS('microsoft-jssdk', 'https://cdn.jotfor.ms/js/msal/msal-browser.js');
};

export const handleGoogleAuth = ({ onSuccess = f => f, onError = f => f }) => {
  if (!window.google) return;
  const client = window.google.accounts.oauth2.initCodeClient({
    client_id: window.GOOGLE_SIGNON,
    scope: 'openid profile email',
    ux_mode: 'popup',
    cookiepolicy: 'single_host_origin',
    callback: async response => {
      await onSuccess(response);
    },
    error_callback: async response => {
      await onError(response);
    }
  });

  return client;
};

export const handleFacebookAuth = ({ onSuccess = f => f, onError = f => f }) => {
  // https://sentry.io/organizations/jotform/issues/1769596448/?project=4142374
  if (!window.FB) return;

  window.FB.init({
    appId: window.FACEBOOK_SIGNON_APP_ID,
    cookie: true,
    xfbml: true,
    version: 'v3.2'
  });

  window.FB.login(({ status, authResponse }) => {
    if (status === 'connected' && authResponse) {
      onSuccess(authResponse.accessToken);
      return;
    }

    onError(status);
  }, { scope: 'public_profile,email' });
};

export const handleAppleAuth = async ({
  hasUserLogin,
  nonce,
  onSuccess = f => f,
  onError = f => f
}) => {
  try {
    // https://sentry.io/organizations/jotform/issues/1769596448/?project=4142374
    if (!window.AppleID) return;

    const result = await window.AppleID.auth.signIn();

    const response = hasUserLogin ? await Axios.post(
      'https://www.jotform.com/API/login/apple',
      qs.stringify({
        authorizationCode: result.authorization.code,
        identityToken: result.authorization.id_token,
        nonce
      }),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    ) : {};

    onSuccess({
      response,
      authorizationCode: result.authorization.code,
      identityToken: result.authorization.id_token,
      nonce
    });
  } catch (err) {
    onError(err);
  }
};

export const handleMicrosoftAuth = () => {
  const myMSALObj = new window.msal.PublicClientApplication({
    auth: {
      clientId: window.MICROSOFT_SIGNON_CLIENT_ID,
      redirectUri: `https://${global.location.host}/login-redirect.html`
    }
  });
  return myMSALObj.loginPopup({
    scopes: ['user.read']
  });
};

export const handleSalesforceAuth = async () => {
  const openPopupWindowCentered = function openPopupWindowCentered(url, windowName, w, h) {
    const x = window.outerWidth / 2 + window.screenX - w / 2;
    const y = window.outerHeight / 2 + window.screenY - h / 2;
    let features = 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=';
    features = features.concat(w, ', height=').concat(h, ', top=').concat(y, ', left=').concat(x);
    return window.open(url, windowName, features);
  };

  let popUpWindow = null;

  const handleLogin = async () => {
    const response = await Axios.get(`https://${global.location.host}/API/user/salesforce-auth-provider`);
    popUpWindow = openPopupWindowCentered(response.data.content, 'login-flow', 600, 680);
  };

  await handleLogin();

  const data = await new Promise(resolve => {
    window.addEventListener('message', message => {
      if (!message || !message.data) {
        resolve('error');
      }

      if (message.data.source === 'jfsalesforce_login') {
        if (popUpWindow) {
          popUpWindow.close();
        }

        const res = {
          code: message.data.code,
          token: message.data.token
        };
        resolve(res);
      }
    });
  });

  return data;
};

export const handleUserLogin = async ({
  appName, config, token, type
}) => {
  try {
    const response = await Axios.post(
      '/server.php',
      qs.stringify({
        action: config.action,
        [config.tokenKey]: token,
        // eslint-disable-next-line no-undef
        language: navigator.language,
        timezone: getPossibleTimeZone(),
        ...appName ? {
          button_name: `${type}-${appName}`
        } : {}
      }),
      {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    );

    return response;
  } catch (err) {
    return err;
  }
};

export const handleUserVerify = async ({
  appName, type, config, token
}) => {
  const isGoogle = type === 'google';
  return layer.post(
    `user/login/${type}`,
    qs.stringify({
      onlyVerify: '1',
      action: config.action,
      [config.tokenKey]: token,
      // eslint-disable-next-line no-undef
      language: navigator.language,
      timezone: getPossibleTimeZone(),
      ...appName ? {
        button_name: `${type}-${appName}`
      } : {},
      ...(isGoogle && { newGoogleFlow: 1 })
    })
  );
};

export const randomString = value => {
  let length = value;
  const charset = '0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz-._';
  let result = '';

  const makeResult = item => {
    if (length === 0) {
      return;
    }

    if (item < charset.length) {
      result += charset[item];
      length--;
    }
  };

  while (length > 0) {
    const bytes = new Uint8Array(16);
    const cryptor = window.crypto || window.msCrypto;
    const random = cryptor.getRandomValues(bytes);

    Array.from(random).forEach(makeResult);
  }

  return result;
};

export const fetchUserCredentialRuleSet = isSecure => {
  const params = isSecure ? { hipaa: '1' } : {};
  return layer.get('user/credential-rule-set', null, { params });
};

export const checkPasswordReuse = (password, userType) => {
  if (typeof userType === typeof 'string' && userType.toLowerCase() === 'user') {
    return layer.post('user/checkPasswordReuseAttempt', qs.stringify({ password }));
  }
  return layer.post('formuser/checkPasswordReuseAttempt', qs.stringify({ password }));
};

const prepareVerificationSettings = (
  type, token, password,
  // for verifyAndDelete:
  isDeleteVerified = false, prepareForDeletionEndpoint = false
) => {
  const socialLogins = ['google', 'facebook', 'apple', 'microsoft', 'salesforce'];

  switch (true) {
    case type === 'email':
      return { type, password };
    case socialLogins.includes(type):
      return { type, token };
    case prepareForDeletionEndpoint === true:
      return { isDeleteVerified };
    default:
      return { };
  }
};

// verify first by type before deleting it, if you want to delete it just pass isDeleteVerified and user
export const verifyAndDelete = ({
  username, type, token, password, isDeleteVerified = false
}) => {
  const settings = prepareVerificationSettings(type, token, password, isDeleteVerified, true);
  return layer.post(`user/${username}/verify-and-delete`, qs.stringify({ ...settings, newGoogleFlow: 1 }));
};

export const verifyUser = ({
  username, type, token, password
}) => {
  const settings = prepareVerificationSettings(type, token, password);
  return layer.post(`user/${username}/verify`, qs.stringify({ ...settings, newGoogleFlow: 1 }));
};

export const getPrefillToken = () => {
  const path = window.location.pathname.split('/').splice(2);
  if (path[0] === 'prefill') return path[1].split('?')[0];

  return false;
};

export const isYes = val => ((typeof val === 'string') ? ['Yes', '1'].includes(val) : !!val);

export const getPortalElement = elementId => {
  const { body } = document;
  let targetElement = document.querySelector(`#${elementId}`);

  if (targetElement) {
    return targetElement;
  }

  targetElement = document.createElement('div');
  targetElement.setAttribute('id', elementId);
  body.appendChild(targetElement);
  return targetElement;
};

export const logActions = (project = 'Jotform', actor = 'unknown', action = '', target = '') => {
  if (typeof window.JotFormActions === 'function') {
    const logger = new window.JotFormActions(project);
    logger.tick({ actor, action, target });
  }
};

export const isPlatformSalesforce = () => !isNil(document.querySelector('[data-platform="salesforce"]'));

export const salesforceSocialLoginLogger = ({ username = '', action = '', target = '' }) => {
  if (!isPlatformSalesforce()) return;

  actionTracker({
    username,
    action,
    target
  });
};

export const preLoginCheck = async () => layer.get('mfa/pre-login-check');

export const objToQueryString = obj => `${Object.entries(obj).map(([key, value]) => `${key}=${value}`).join('&')}`;

// eslint-disable-next-line max-len
export const errorNormalizer = err => err?.response?.data?.error || err?.data?.error || err?.error || err?.response?.data?.content || err?.data?.content || err?.content || err?.response?.data?.message || err?.data?.message || err?.message || Texts.ERROR_TRY_AGAIN;
