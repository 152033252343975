import axios from 'axios';
import isNil from 'lodash/isNil';

import { encodeUsedTranslations, unlocalizeOriginHref } from './utils';
import { partialTranslations, DEFAULT_LANGUAGE } from './constants';

export const prepareT = translation => function t(str, params = {}) {
  if (!str) {
    return str;
  }
  if (typeof str !== 'string') {
    return str;
  }

  if ((global?.window?.location?.href || '')?.includes('rawTranslationTextMode=true')) {
    return str;
  }

  let translated = str;
  try {
    if (typeof translation.translate === 'function') {
      translated = translation.translate(str, params);
    } else if (typeof translation.t === 'function') {
      translated = translation.t(str, params);
    }
    if (typeof global?.window?.useTranslationScreenshots !== 'undefined') {
      return str;
    }
    if (translation.currentLocale !== DEFAULT_LANGUAGE && str !== translated && translation.controlMode) {
      return '1';
    }
  } catch (e) {
    console.error('Error on common/translate falling back with original str', e);
  }

  const resp = (params.branding21) ? translated.replace(/JotForm/g, 'Jotform') : translated;

  return resp;
};

export const preapreTtime = translation => function ttime(str, options = {}) {
  const { hour = 'numeric', minute = 'numeric' } = options;
  const formatOptions = { hour, minute };
  let [hourVal, minuteVal] = str.replace('/am|pm/', '').split(':').map(i1 => parseInt(i1, 10)); //eslint-disable-line

  if (str.indexOf('pm') > 0) {
    hourVal = hourVal !== 12 ? hourVal + 12 : hourVal;
  } else if (str.indexOf('am') > 0) {
    hourVal = hourVal === 12 ? 0 : hourVal;
  }

  const tempDate = new Date();
  tempDate.setHours(hourVal, minuteVal);
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(tempDate.getTime())) {
    return str;
  }
  return tempDate.toLocaleTimeString(translation.currentLocale, formatOptions);
};

export const prepareTDate = translation => function tdate(str, options = {}) {
  try {
    const ttime = preapreTtime(translation);
    if (options.timeOnly) {
      return ttime(str, options);
    }
    const dateObj = new Date(str);
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(dateObj.getTime())) {
      return str;
    }
    // Add undesired fields with null values to options object. Ex:{weekday : null}
    const formatOptions = {
      weekday: 'short', year: 'numeric', month: 'short', day: 'numeric'
    };
    if (options && typeof options === 'object') {
      Object.keys(options).forEach(optionName => {
        if (options[optionName] == null) {
          delete formatOptions[optionName];
        } else {
          formatOptions[optionName] = options[optionName];
        }
      });
    }
    return dateObj.toLocaleDateString(translation.currentLocale, formatOptions);
  } catch (err) {
    return str;
  }
};

// Example usage:
/*
    translationRenderer('This is an [1[example]] string [2[for]] translation')({
      renderer1: (str) => <b>{str}</b>; // str is "example"
      fallback: (str) => <AwesomeComponent>{str}</AwesomeComponent>;
    })
    - Since there is no render function given for second match, fallback function will be used as renderer.
    - Renderer functions can only have 1 parameter, which is sanitized version of delimeter.
  */
export const prepareTranslationRenderer = t => function translationRenderer(str, params = {}) {
  if ((global?.window?.location?.href || '')?.includes('rawTranslationTextMode=true')) {
    return () => str;
  }
  const translatedString = t(str, params);
  const getRendererIndex = delimiterStr => {
    const matches = delimiterStr.match(/\[(\d+)\[/);
    if (matches && matches[1]) {
      return matches[1];
    }
    throw new Error(`Delimeter string (${delimiterStr}) format is inappropriate! Correct format is [RENDERER_NUMBER[TEXT]]`);
  };

  return renderFunctions => {
    try {
      const matchTemplate = /\[\d+\[.*?\]{2}/g;
      const replaceTemplate = /\[(\d+)\[|\]{2}/g;
      const matches = (translatedString && translatedString.match) ? translatedString.match(matchTemplate) : null;
      if (matches && matches.length > 0) {
        const renderers = renderFunctions || {};
        // Set a fallback renderer if it is not given in renderFunctions
        if (typeof renderers.fallback !== 'function') {
          renderers.fallback = i1 => i1;
        }
        // Create an array which includes all render functions
        const matchedStrings = matches.map(matchItem => {
          const currentKey = `renderer${getRendererIndex(matchItem)}`;
          const sanitizedValue = matchItem.replace(replaceTemplate, '');
          if (typeof renderers[currentKey] === 'function') {
            return renderers[currentKey](sanitizedValue);
          }
          return renderers.fallback(sanitizedValue);
        });

        return translatedString.split(matchTemplate).map((normStr, index) => (
          typeof matchedStrings[index] !== 'undefined'
            ? [normStr, matchedStrings[index]]
            : [normStr]
        ));
      }
    } catch (err) {
      console.error(err); // eslint-disable-line
    }
    return translatedString;
  };
};

export const prepareControlModeChange = translation => function controlModeChange(controlMode) {
  return new Promise((resolve, reject) => {
    try {
      // eslint-disable-next-line no-param-reassign
      translation.controlMode = controlMode;
      resolve({ loaded: true, controlMode });
    } catch (err) {
      reject(new Error(err));
    }
  });
};

export const prepareAddTranslationToDictionary = translation => function addTranslationToDictionary(code, translationData) {
  const dictionary = translationData;
  // remove untranslated keys
  const clearedDic = Object.keys(dictionary).reduce((dic, key) => {
    const entry = dictionary[key];
    if (!entry) {
      return dic;
    }
    return Object.assign(dic, { [key]: entry });
  }, {});

  if (dictionary) {
    translation.add(code, clearedDic);
  }
  return ({ loaded: true, code });
};

export const generateDictionaryURL = ({ path, code = DEFAULT_LANGUAGE }) => {
  const sanitizedCode = code?.length !== 5 ? DEFAULT_LANGUAGE : code;
  return `/js/${path}/locale_${sanitizedCode}.js?${Math.floor((Math.random() * (9999 - 1001)) + 1000)}`;
};

export const loadDictionaryViaTag = ({ code, path }) => new Promise((resolve, reject) => {
  try {
    const tmpScriptTag = global.document.createElement('script');
    tmpScriptTag.src = generateDictionaryURL({ code, path });
    tmpScriptTag.onload = () => resolve(global.Locale.language);
    tmpScriptTag.onerror = () => reject(new Error('Language dictionary load failed.'));

    if (!global.Locale) {
      global.Locale = {};
    }

    global.document.body.appendChild(tmpScriptTag);
  } catch (e) {
    reject(e);
  }
});

export const loadDictionaryViaFetch = ({ code, path }) => fetch(generateDictionaryURL({ code, path }), {
  headers: { 'Content-Type': 'text/plain' }
}).then(r => r.text()).then(r => r.replace('Locale.language=', '')).then(r => JSON.parse(r));

export const prepareAddDictionary = translation => function addDictionary(code, partialDictionaryName) {
  const addTranslationToDictionary = prepareAddTranslationToDictionary(translation);
  const url = unlocalizeOriginHref(global?.window?.location?.href);
  const partialDictionaryPath = partialDictionaryName || Object.keys(partialTranslations).find(q => partialTranslations[q](url));
  const loadMethod = global?.document ? loadDictionaryViaTag : loadDictionaryViaFetch;
  const path = partialDictionaryPath ? `locale/partial-translations/${partialDictionaryPath}` : 'locale';
  return new Promise((resolve, reject) => {
    loadMethod({ path, code: code?.length !== 5 ? DEFAULT_LANGUAGE : code })
      .then(translationData => resolve(addTranslationToDictionary(code, translationData)))
      .catch(err => reject(new Error(err)));
  });
};

export const sendUsedTranslationsInterval = () => {
  if (isNil(global.Translations.processUsedTranslationsInterval) && !global.Translations.forceStopCollecting) {
    console.log('[Translations] :: Collecting used translation strings.');

    global.Translations.processUsedTranslationsInterval = setInterval(() => {
      const finishInterval = global.Translations.encodedAndProcessedStrings?.length >= global.Translations.usedTranslations?.length;

      if (global.Translations.forceStopCollecting) {
        return;
      }

      if (finishInterval) {
        clearInterval(global.Translations.processUsedTranslationsInterval);
        global.Translations.processUsedTranslationsInterval = null;
        global.Translations.forceStopCollecting = true;

        return axios.post('/API/translation/track-translated-strings', {
          url: global.location?.href,
          hashes: global.Translations.encodedAndProcessedStrings
        }).then(res => {
          if (res?.data?.responseCode === 200) {
            console.log(`[Translations] :: ${global.Translations.encodedAndProcessedStrings?.length} used translations collected.`);
          }
        }).catch(() => {
          console.log('[Translations] :: Error while collect used translations.');
        });
      }

      encodeUsedTranslations(global.Translations.usedTranslations);
    }, 1500);
  }
};
