// If the value is not a string or a Blob, append() will convert it to a string automatically
// Therefore using "any" as type, should be safe

import { BaseResponse, ResponseContext } from '../../types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function objToFormData(obj: Record<string, any>, prefix?: string, formData?: FormData): FormData {
  // eslint-disable-next-line no-param-reassign
  formData = formData ?? new FormData();

  if (obj) {
    Object.keys(obj).forEach(key => {
      const keyName = prefix ? `${prefix}[${key}]` : key;

      if (typeof obj[key] !== 'object') {
        formData?.append(keyName, obj[key]);
      } else {
        objToFormData(obj[key], keyName, formData);
      }
    });
  }

  return formData;
}

export const isCrossOrigin = (url?: string): boolean => {
  const { location: { origin } } = window || {};
  if (url?.startsWith('http') && !url.includes(origin)) {
    return true;
  }
  return false;
};

export const axiosNormalizer = (ctx: ResponseContext): void => {
  if (ctx.interceptorConfig?.disableResponseNormalizer) {
    return;
  }
  if ('data' in ctx.resp) {
    ctx.result = ctx.resp.data;
  }

  const resp = ctx.result as BaseResponse;

  if (resp && typeof resp.responseCode === 'number'
      && (resp.responseCode < 200 || resp.responseCode > 299)) {
    ctx.result = Promise.reject(resp);
    return;
  }

  if (resp && resp.content) {
    ctx.result = resp.content;
  }
};

export const engineResponseNormalizer = (ctx: ResponseContext): void => {
  switch (ctx.engineName) {
    default:
      axiosNormalizer(ctx);
  }
};
