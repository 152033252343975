import React from 'react';
import { cx } from 'class-variance-authority';

import '@jotforminc/jotform.css';
// eslint-disable-next-line no-restricted-imports
import '@jotforminc/jotform.css/animation.css';

import { indicatorCVA } from './indicator.cva';
import { IndicatorProps, indicatorDefaultProps } from './indicator.types';

export const Indicator = (props: IndicatorProps):JSX.Element => {
  const {
    className, children, colorStyle, invert, size, ...rest
  } = props;

  const hasChildren = !!children;

  const indicatorClassName = cx(className, indicatorCVA({
    colorStyle,
    hasChildren,
    invert,
    size
  }));

  return (
    <span className={indicatorClassName} {...rest}>
      {children}
    </span>
  );
};

Indicator.defaultProps = indicatorDefaultProps;
