import * as FullStory from '@fullstory/browser';

class FullStoryManager {
  constructor(
    fullStoryConfigs: FullStory.SnippetOptions
  ) {
    FullStory.init(fullStoryConfigs);
  }

  activeTrackings = new Set();

  setUserVars = (customVars: FullStory.UserVars): void => {
    const { displayName, email, ...projectVars } = customVars;
    Object.keys(projectVars).forEach(key => this.activeTrackings.add(key));

    FullStory.setUserVars({
      ...projectVars,
      email,
      displayName,
      activeTrackings: Array.from(this.activeTrackings.values())
    });
  };

  isInitialized = FullStory.isInitialized;

  event = FullStory.event;

  identify = (uid: string, customVars?: FullStory.UserVars): void => {
    const { displayName, email, ...projectVars } = customVars || {};
    Object.keys(projectVars).forEach(key => this.activeTrackings.add(key));
    FullStory.identify(uid, {
      ...projectVars,
      email,
      displayName,
      activeTrackings: Array.from(this.activeTrackings.values())
    });
  };

  shutdown = FullStory.shutdown;

  restart = FullStory.restart;

  getCurrentSessionURL = FullStory.getCurrentSessionURL;
}

export default FullStoryManager;
