import React from 'react';
import PropTypes from 'prop-types';
import { useAtomValue } from 'jotai';
import OTP from './OTP';
import { projectAtom, userAtom } from '../store';

const OTPStandalone = ({ callback, texts, show }) => {
  const user = useAtomValue(userAtom);
  const project = useAtomValue(projectAtom);

  if (!show) return <></>;

  return (
    <OTP
      callback={callback}
      user={user}
      project={project}
      texts={texts}
      open={show}
    />
  );
};

OTPStandalone.propTypes = {
  callback: PropTypes.func.isRequired,
  texts: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired
};

export default OTPStandalone;
