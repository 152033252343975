import React, { useState } from 'react';
import {
  array, bool, func, object
} from 'prop-types';

import { t } from '@jotforminc/translation';
import { Texts } from '@jotforminc/constants';

import { verifyUser } from '../../utils/helper';
import SocialsContainer from '../DeleteAccountModal/SocialsContainer';
import ErrorMessage from '../DeleteAccountModal/ErrorMessage';

const Verification = ({
  socialLogins, onAppleLoginClick, onFBLoginClick, onGoogleLoginClick, onMicrosoftLoginClick, onSalesforceLoginClick, thereSocialError, user, handleVerification
}) => {
  const [isPasswordErrored, setIsPasswordErrored] = useState(false);
  const [password, setPassword] = useState('');
  const [isPending, setIsPending] = useState(false);

  const handleChange = e => {
    const { target: { value } } = e;
    setPassword(value);
    setIsPasswordErrored(false);
  };

  const handleVerifyAccount = async e => {
    e.preventDefault(); // no refresh after submit
    if (!password) return;
    const { credentials: { username } } = user;

    setIsPending(true);
    try {
      const result = await verifyUser({ type: 'email', username, password });
      const isResultWithToken = (typeof result === 'object') && result.token;
      if (result === true || isResultWithToken) {
        setIsPasswordErrored(false);
        handleVerification();
        // TODO if (isResultWithToken) set token and remove 'result === true' check
      } else {
        setIsPasswordErrored(false);
      }
    } catch {
      setIsPasswordErrored(true);
    } finally {
      setIsPending(false);
    }
  };

  const handleVerifyButtonKeyDown = e => {
    if ([' ', 'Enter'].includes(e.key)) {
      handleVerifyAccount(e);
    }
  };

  return (
    <div className="verify-account-container">
      {socialLogins.length > 0 && (
        <>
          <SocialsContainer
            socialLogins={socialLogins}
            onAppleLoginClick={onAppleLoginClick}
            onFBLoginClick={onFBLoginClick}
            onGoogleLoginClick={onGoogleLoginClick}
            onMicrosoftLoginClick={onMicrosoftLoginClick}
            onSalesforceLoginClick={onSalesforceLoginClick}
            user={user}
          />
          {thereSocialError && (
            <ErrorMessage text={Texts.YOU_COULD_NOT_BE_VERIFIED} />
          )}
          <div className='xcl-divider verify-modal-divider'>
            <span>OR</span>
          </div>
        </>
      )}
      <div className='password-section'>
        <label htmlFor="password-for-verification">{t('Enter your account password')}</label>
        <form className="verify-form" onSubmit={handleVerifyAccount}>
          <input
            type="password"
            id="password-for-verification"
            placeholder='Type password'
            onChange={handleChange}
          />
          {isPasswordErrored && (
            <ErrorMessage text={Texts.YOU_HAVE_ENTERED_YOUR_CURRENT_PASSWORD_WRONG} />
          )}
        </form>
      </div>
      {/* TODO: Move below html inside the form tags and update the common ui file: verification-mnodal.scss */}
      <div className="submit-button-wrapper">
        <button
          onClick={handleVerifyAccount}
          onKeyDown={handleVerifyButtonKeyDown}
          disabled={password.length === 0 || isPending}
          className='submit-button green'
          type="button"
        >
          {t('VERIFY')}
        </button>
      </div>
    </div>
  );
};

Verification.propTypes = {
  socialLogins: array.isRequired,
  user: object.isRequired,
  onAppleLoginClick: func,
  onFBLoginClick: func,
  onGoogleLoginClick: func,
  onMicrosoftLoginClick: func,
  onSalesforceLoginClick: func,
  thereSocialError: bool,
  handleVerification: func
};

Verification.defaultProps = {
  onAppleLoginClick: () => {},
  onFBLoginClick: () => {},
  onGoogleLoginClick: () => {},
  onMicrosoftLoginClick: () => {},
  onSalesforceLoginClick: () => {},
  thereSocialError: false,
  handleVerification: () => {}
};

export default Verification;
