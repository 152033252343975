import { LATEST_ACTIVE_SERVERS_LIST, RANDOM_ACTIVE_200_SERVERS_LIST, RANDOM_ACTIVE_300_SERVERS_LIST } from './constants/latestActiveServers';

const isActive = (testEnvList = [], parameter) => {
  const { host, search } = window.location;
  return host.indexOf('jotform.biz') > -1 || host.indexOf('jotform.pro') > -1 || testEnvList.indexOf(host) > -1 || (parameter && search.indexOf(parameter) > -1);
};

export const isEnterprise = () => {
  if (!window) {
    return false;
  }

  if (window.JOTFORM_ENV === 'ENTERPRISE') {
    return true;
  }

  if (window.JotForm && window.JotForm.enterprise) {
    return true;
  }

  return false;
};

export const isTestAndDevelopmentEnvironment = () => isActive([
  'eeproduct2.jotform.com',
  'enterpriseqa.jotform.com',
  'enterpriseqahipaa.jotform.com',
  'demo.jotform.com',
  'acmetest.jotform.com',
  'uxlabs.jotform.com',
  'soc2-test1.jotform.com',
  'mobile-test.jotform.com',
  'enterpriseqamanual.jotform.com',
  'marketing.jotform.com',
  'passgp.jotform.com',
  'mcrcbiobank.jotform.com',
  'aat.jotform.com',
  'sso-multi-test.jotform.com'
]);

export const isOneOfTheMostActiveEnterpriseServers = () => {
  return isActive(LATEST_ACTIVE_SERVERS_LIST) || isActive(RANDOM_ACTIVE_200_SERVERS_LIST) || isActive(RANDOM_ACTIVE_300_SERVERS_LIST) || isTestAndDevelopmentEnvironment();
};

export const isNew2FAEnabled = () => window.location.search.indexOf('new2FA') > -1;

export const isNewSMTPFlow = (user = {}) => {
  const { isNewSMTPFlowEnabled = false } = user;
  return window?.user?.isNewSMTPFlowEnabled || isNewSMTPFlowEnabled;
};
