import { Texts } from '@jotforminc/constants';
import { t } from '@jotforminc/translation';
import { shape, string } from 'prop-types';
import React from 'react';

const RecoverAccountNotification = ({
  tfaAccountRecoveryProps
}) => {
  const { email } = tfaAccountRecoveryProps;

  return (
    <div className='verification-email-container'>
      <div className='xcl-content xcl-nb-content verification-email-text-wrapper'>
        <div className='email-sample-wrapper'>
          <p>{t(Texts.VERIFICATION_EMAIL_SENT_TO)}</p>
          <span>{email}</span>
        </div>
        <p className='security-options-text'>{t(Texts.RECOVER_ACCOUNT_EMAIL_INFO_TEXT)}</p>
        <p>{t(Texts.RECOVER_ACCOUNT_NOTIFICATION_TEXT)}</p>
      </div>
    </div>
  );
};

export default RecoverAccountNotification;

RecoverAccountNotification.propTypes = {
  tfaAccountRecoveryProps: shape({
    email: string
  })
};

RecoverAccountNotification.defaultProps = {
  tfaAccountRecoveryProps: {
    email: ''
  }
};
