import React from 'react';
import { bool, element } from 'prop-types';
import { translationRenderer } from '@jotforminc/translation';
import { Texts } from '@jotforminc/constants';

import { getTermsOfUseLinkByLanguage, getPrivacyLinkByLanguage } from '../utils/loginSignup';

const TermsAndConditionsUrls = ({ LabelRenderer, isTurkish }) => {
  return (
    <LabelRenderer>
      {translationRenderer(Texts.AGREE_SERVICE_POLICY)({
        renderer1: str => <a href={getTermsOfUseLinkByLanguage(isTurkish)} target="_blank">{str}</a>,
        renderer2: str => <a href={getPrivacyLinkByLanguage(isTurkish)} target="_blank">{str}</a>,
        renderer3: str => <a href='https://www.jotform.com/cookie-policy/' target="_blank">{str}</a>
      })}
      {' '}
      {/* additional terms only for the turkish users */}
      {isTurkish && (
        translationRenderer(Texts.AGREE_FOR_TURKISH_USERS)({
          renderer1: str => (
            <a href="https://www.jotform.com/kvkk/" target="_blank">
              {' '}
              {str}
            </a>
          ),
          renderer2: str => <a href="https://www.jotform.com/riza-metni/" target="_blank">{str}</a>
        })
      )}
    </LabelRenderer>
  );
};

TermsAndConditionsUrls.propTypes = {
  LabelRenderer: element,
  isTurkish: bool
};

TermsAndConditionsUrls.defaultProps = {
  // eslint-disable-next-line react/prop-types
  LabelRenderer: ({ children }) => <>{children}</>,
  isTurkish: false
};

export default TermsAndConditionsUrls;
