import Adapter from './Adapter';

export default class MixedAdapter extends Adapter {
  getValue(values) {
    const data = {};

    values.forEach(({ name, value }) => {
      const key = name.match(/\[(.*?)\]/)[1];

      if (name.includes('[]')) {
        if (!data[key]) {
          data[key] = [];
        }
        data[key].push(value);
      } else {
        data[key] = value;
      }
    });

    return { data };
  }

  setValue(value) {
    return super.setValue(value);
  }
}
