import { createDefaultInterceptors } from '../layer/interceptors/defaultInterceptors';
import {
  requestInterceptors,
  responseInterceptors,
  defaultRequestInterceptors,
  defaultResponseSuccessInterceptors,
  defaultResponseFailedInterceptors
} from '../layer/interceptors/defaultInterceptors/defaultInterceptors';
import { Interceptors } from '../layer/interceptors/types';
import {
  DefaultInterceptorsConfig
} from '../layer/types';

export const setInterceptors = (config: DefaultInterceptorsConfig | undefined)
: Interceptors | undefined => {
  if (config?.disableInterceptors) return undefined;

  let preRequestInterceptors = config?.customRequestInterceptors ?? requestInterceptors;
  let postRequestInterceptors = config?.customResponseInterceptors ?? responseInterceptors;
  const postRequestFailedInterceptors = [];

  if (config?.disableRequestInterceptors) {
    preRequestInterceptors = [];
  }

  if (config?.disableResponseInterceptors) {
    postRequestInterceptors = [];
  }

  if (!config?.disableDefaultInterceptors) {
    preRequestInterceptors.push(...defaultRequestInterceptors);
    postRequestInterceptors.push(...defaultResponseSuccessInterceptors);
    postRequestFailedInterceptors.push(...defaultResponseFailedInterceptors);
  }

  return createDefaultInterceptors(preRequestInterceptors, postRequestInterceptors, postRequestFailedInterceptors);
};
