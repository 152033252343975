// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.91.0_@swc+core@1.7.6_@swc+helpers@0.5.12__/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.91.0_@swc+core@1.7.6_@swc+helpers@0.5.12__/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/.pnpm/css-loader@6.8.1_webpack@5.91.0_@swc+core@1.7.6_@swc+helpers@0.5.12__/node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2716px%27 height=%2716px%27 viewBox=%270 0 16 16%27%3E%3Cpath fill=%27%23B0BEC5%27 d=%27M8,6.6l6.3-6.3c0.4-0.4,1-0.4,1.4,0c0.4,0.4,0.4,1,0,1.4L9.4,8l6.3,6.3c0.4,0.4,0.4,1,0,1.4c-0.4,0.4-1,0.4-1.4,0L8,9.4l-6.3,6.3c-0.4,0.4-1,0.4-1.4,0c-0.4-0.4-0.4-1,0-1.4L6.6,8L0.3,1.7c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0L8,6.6z%27%3E%3C/path%3E%3C/svg%3E", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".login-flow-standalone-modal-dialog{position:fixed;z-index:9999;left:0;top:0;width:100%;height:100%;overflow:auto;background-color:rgba(0,0,0,.4);display:flex;align-items:center;justify-content:center}.login-flow-standalone-modal-dialog.social-terms-modal-dialog div[role=dialog]{display:flex;flex-direction:column;justify-content:center;align-items:center}@media screen and (max-width: 480px){.login-flow-standalone-modal-dialog>div[role=dialog]{flex:1 1;height:100%}}.login-flow-standalone-modal-content{position:relative;height:100%}.login-flow-standalone-modal-content .close-button{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-position:center;background-size:21px;box-sizing:border-box;position:absolute;top:16px;right:16px;z-index:1;padding:0;width:44px;height:44px;border-radius:50%;background-color:#e6e6f5;display:flex;align-items:center;justify-content:center;border:1px solid rgba(0,0,0,0)}@media screen and (max-width: 480px){.login-flow-standalone-modal-content .close-button{background-size:16px;width:40px;height:40px;right:8px;top:8px}}html[dir=rtl] .login-flow-standalone-modal-content .close-button{right:inherit;left:16px}.login-flow-standalone-modal-content .close-button:before{content:\"\";position:absolute;right:-4px;top:-4px;display:block;width:48px;height:48px;z-index:-1}.login-flow-standalone-modal-content .close-button:focus{outline:none;border-color:#fff;box-shadow:0 0 0 3px #baceff}.email-auth-modal-content{position:relative;background:#fff;box-sizing:border-box;background-color:#fff;width:816px;min-height:475px;border-radius:10px;border:1px solid #d2dbf2;padding:48px 8px;margin:0 auto;overflow:auto}@media screen and (max-width: 816px){.email-auth-modal-content{width:96%;max-width:816px;min-width:480px}}@media screen and (max-width: 480px){.email-auth-modal-content{width:100%;max-width:480px;min-width:320px;border-radius:0}}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
