import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { IconCheckCircle, IconExclamationCircleFilled, IconFlagFilled } from '@jotforminc/svg-icons';
import { t, translationRenderer } from '@jotforminc/translation';
import { getUrlParameter } from '@jotforminc/utils';
import layer from '../../utils/layer';

import {
  RECEIVED_RESPONSE,
  CONTINUE,
  KEEP_ACCOUNT,
  NOT_CREATE_THIS_ACCOUNT, REPORT_ACCOUNT, REPORT_UNUSUAL_SIGNUP, THANKS_FOR_REPORTING, ERROR_MESSAGE
} from '../../constants';

import '../../styles/unusual-signup.scss';

const UnusualSignup = ({
  unusualSignupCode, unusualSignupToken, changeActiveMode
}) => {
  const [option, setOption] = useState('');
  const [isReported, setIsReported] = useState(false);
  const [error, setError] = useState(false);

  const handleClick = async () => {
    if (option === 'keep') {
      const isLoggedIn = !!window.user && window.user?.account_type?.name !== 'GUEST';
      return !isLoggedIn ? changeActiveMode('fullScreen') : window.location.replace('/myforms');
    }
    layer.post('user/change-email-unusual-account', new URLSearchParams({
      code: unusualSignupCode,
      token: unusualSignupToken
    }))
      .then(result => {
        if (typeof result === 'object' && result?.responseCode !== 200) {
          throw new Error(result?.content || result?.message);
        }

        setIsReported(true);
      }).catch(err => {
        setError(true);
        console.log(err);
      });
  };

  const handleChange = e => {
    setOption(e.target.value);
  };

  useEffect(() => {
    document.body.classList.add('hide-overflow');
    document.title = 'Report Unusual Signup · Jotform';

    return () => {
      document.body.classList.remove('hide-overflow');
      document.title = 'Login · Jotform';
    };
  }, []);

  return (
    <section id='unusualSignup' className='unusual-signup-container'>
      <div className='unusual-signup'>
        <div className='custom-header'>
          <div className='header-logo' />
          <h2>{t(REPORT_UNUSUAL_SIGNUP)}</h2>
        </div>
        <div className='unusual-signup-wrapper'>
          <div className='heading-container'>
            {isReported ? (
              <div className='icon-container check'>
                <IconCheckCircle className='check-icon' />
              </div>
            ) : (
              <div className='icon-container flag'>
                <IconFlagFilled className='flag-icon' />
              </div>
            )}
            <h2 className='heading'>
              {t(isReported ? RECEIVED_RESPONSE : REPORT_UNUSUAL_SIGNUP)}
            </h2>
            <p className='description'>
              {isReported ? (
                translationRenderer(THANKS_FOR_REPORTING)({
                  renderer1: () => <strong>{getUrlParameter('username')}</strong>,
                  renderer2: text => (
                    <a className='contact-link' href='/contact/?utm_source=force-change-email&utm_medium=website&utm_content=link_contactus&utm_campaign=general' target='_blank'>
                      {text}
                    </a>
                  )
                })
              ) : (
                translationRenderer(NOT_CREATE_THIS_ACCOUNT)({
                  renderer1: () => <strong>{getUrlParameter('username')}</strong>
                })
              )}
            </p>
          </div>
          {!isReported && (
          <>
            <div className='radio-buttons-wrapper'>
              <label htmlFor='report' className='radio-item'>
                <input
                  type='radio' name='unusualSignup' id='report'
                  value='report' onChange={value => handleChange(value)}
                />
                <span className='radio-content'>
                  <div className='radio-check' />
                  <p className='radio-title'>
                    {t(REPORT_ACCOUNT)}
                  </p>
                </span>
              </label>
              <label htmlFor='keep' className='radio-item'>
                <input
                  type='radio' name='unusualSignup' id='keep'
                  value='keep' onChange={value => handleChange(value)}
                />
                <span className='radio-content'>
                  <div className='radio-check' />
                  <p className='radio-title'>
                    {t(KEEP_ACCOUNT)}
                  </p>
                </span>
              </label>
            </div>
            {error && (
              <div className='error-wrapper'>
                <IconExclamationCircleFilled className='error-icon' />
                <span className='error-msg'>
                  {translationRenderer(ERROR_MESSAGE)({
                    renderer1: text => (
                      <a href='https://www.jotform.com/contact/?utm_source=change-email&utm_medium=email&utm_content=link_contactus&utm_campaign=general&username=%7Busername%7D' target='_blank'>
                        {text}
                      </a>
                    )
                  })}
                </span>
              </div>
            )}
            <button
              className='continue-button' type='button' disabled={option === ''}
              onClick={handleClick}
            >
              {t(CONTINUE)}
            </button>
          </>
          )}
        </div>
      </div>
    </section>
  );
};

UnusualSignup.propTypes = {
  unusualSignupCode: PropTypes.string.isRequired,
  unusualSignupToken: PropTypes.string.isRequired,
  changeActiveMode: PropTypes.func.isRequired
};

export default UnusualSignup;
