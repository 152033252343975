/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';
import { wrapWithComp } from '../../utils/index';

// not so pro but it does the trick
const getSomeString = el => {
  const defaultString = 'Button';
  let resultString = '';
  if (typeof el === 'string') {
    resultString = el;
  } else if (Array.isArray(el)) {
    const strArr = [];
    el.map(i => typeof i === 'string' && strArr.push(i));
    resultString = strArr.length === 0
      ? defaultString : strArr.join(' ');
  } else {
    resultString = defaultString;
  }

  return resultString;
};

const Button = React.forwardRef(({
  children,
  disabled,
  ariaLabel,
  buttonAriaLabelPrefix,
  ...others
}, ref) => {
  const defaultAriaLabel = (ariaLabel === 'Button' && others && others['aria-label']) ? others['aria-label'] : ariaLabel;
  const producedAriaLabel = defaultAriaLabel === 'Button' ? getSomeString(children) : defaultAriaLabel;
  const ariaLabelWithPrefix = buttonAriaLabelPrefix ? `${buttonAriaLabelPrefix} ${producedAriaLabel}` : producedAriaLabel;
  return (
    <button
      tabIndex={disabled ? '-1' : null}
      disabled={disabled}
      ref={ref}
      {...others}
      aria-label={ariaLabelWithPrefix}
    >
      {React.Children.map(children, child => wrapWithComp(child, 'btn-label'))}
    </button>
  );
});

Button.propTypes = {
  disabled: PropTypes.bool,
  ariaLabel: PropTypes.string,
  children: PropTypes.node.isRequired,
  buttonAriaLabelPrefix: PropTypes.string
};

Button.defaultProps = {
  disabled: false,
  ariaLabel: 'Button',
  buttonAriaLabelPrefix: ''
};

export default Button;
