import trimEnd from 'lodash/trimEnd';
import { isEnterprise } from '@jotforminc/enterprise-utils';

export const getEnterpriseUrl = (): string => {
  if (window.JotForm && window.JotForm.enterprise) {
    return `https://${window.JotForm.enterprise}`;
  }

  if (window.JOTFORM_ENV === 'ENTERPRISE') {
    return `https://${window.location.host}`;
  }

  return '/';
};

export const getEnterpriseLogo = (): string => {
  const url = getEnterpriseUrl();
  return `${trimEnd(url, '/')}/enterprise/logo.png`;
};

export const getPublicApiURL = (): string => {
  const isEUDomain = /(?:eu\.jotform)|(jotformeu\.com)/.test(window.location.host);
  const isHipaaDomain = /(?:hipaa\.jotform)/.test(window.location.host);
  switch (true) {
    case /jotform.pro/.test(window.location.host):
      return '/API';
    case isEUDomain:
      return 'https://eu-api.jotform.com';
    case isHipaaDomain:
      return 'https://hipaa-api.jotform.com';
    case Boolean(window.JotFormAPIEndpoint):
      return window.JotFormAPIEndpoint;
    default:
      return 'https://api.jotform.com';
  }
};

export const getAPIURL = (): string => {
  // !add temporarily production test check
  if (window.location.host === 'hipaa.jotformers.com') {
    return '/API';
  }

  if (isEnterprise()) {
    return '/API';
  }

  if (/(?:jotform\.ooo)/.test(window.location.host)) {
    return '/API';
  }

  if (/(?:alt\.jotfor\.ms)/.test(window.location.host)) {
    return '/API';
  }

  return getPublicApiURL();
};

export const getDomainFromAPI = (apiURL: string): string => {
  const liteURL = apiURL.replace(/^http:\/\/|^https:\/\/|^\//, '');
  const apiServerMap = {
    'api.jotform.com': 'https://www.jotform.com',
    'eu-api.jotform.com': 'https://eu.jotform.com',
    'hipaa-api.jotform.com': 'https://hipaa.jotform.com',
    API: window.location.origin
  };
  return apiServerMap[liteURL as keyof typeof apiServerMap] || 'https://www.jotform.com';
};

export const getDomainURL = (): string => {
  const isInternRDS = window.location.href.includes('.jotform.dev');
  if (isInternRDS) {
    return '';
  }

  if (!window.JotForm) {
    return getDomainFromAPI(getAPIURL());
  }

  const enterpriseEnv = isEnterprise();
  /* eslint-disable no-undef */
  switch (true) {
    case enterpriseEnv:
      return getEnterpriseUrl();
    case /jotform\.pro/.test(window.location.origin):
    case window.JotForm.hipaa:
      return window.location.origin;
    default:
      return `https://${window.location.hostname.replace(/^form./, '')}`; // TODO: better way.
  }
  /* eslint-enable no-undef */
};

export const getURLDomain = (url: string): string | boolean => {
  if (url && url.length > 0) {
    try {
      const urlObj = new URL(url);
      return urlObj.hostname;
    } catch (e) {
      return false;
    }
  }
  return false;
};

export const replaceURLDomain = (url: string, newDomain: string): string => {
  if (newDomain && newDomain.length > 0) {
    try {
      const urlObj = new URL(url);
      urlObj.hostname = newDomain;
      return urlObj.toString();
    } catch (e) {
      return url;
    }
  }
  return url;
};

export const getCombinedInfoURLByProduct = (resourceID: string, productType: string): string => {
  const apiURL = getAPIURL();
  let loginURL;
  switch (productType) {
    case 'form':
      loginURL = `${apiURL}/formuser/${resourceID}/combinedinfo?master=1`;
      break;
    default:
      loginURL = `${apiURL}/formuser/combinedinfo?master=1`;
      break;
  }
  return window?.JFAppsManager?.isOpenedInPortal() ? `${loginURL}&jotform_pwa=1` : loginURL;
};
