import React from 'react';
import { IconExclamationCircle, IconArrowRotateRight } from '@jotforminc/svg-icons';
import { t, TranslateProvider } from '@jotforminc/translation';

import ErrorScreen from './ErrorScreen.jsx';
import { Texts } from '../../constants.js';

const NotFoundScreen = () => {
  const startOver = () => {
    const url = new URL(window.location.href);
    const formUrl = `${window.location.origin}/${window.formID || url.pathname.split('/')[2]}`;
    window.location.href = formUrl;
  };

  return (
    <TranslateProvider translationTexts={Texts}>
    <ErrorScreen
      Icon={<IconExclamationCircle />}
      text={t(Texts.NOT_FOUND)}
      subText={t(Texts.DELETED_DRAFT)}
      type='NotFoundScreen'
      buttonText={(
        <>
          <IconArrowRotateRight />
          <span>{t(Texts.START_OVER_FORM)}</span>
        </>
      )}
      handleButtonClick={startOver}
    />
    </TranslateProvider>
  );
};

export default NotFoundScreen;
