import React, { useState, useRef, useEffect } from 'react';
import {
  shape, bool, string, func, number
} from 'prop-types';
import { t, translationRenderer } from '@jotforminc/translation';
import { Texts } from '@jotforminc/constants';
import { useTimeoutMessage } from '@jotforminc/hooks';
import { PieTimer } from '@jotforminc/pie-timer';
import { VerificationInput } from '@jotforminc/verification-input';
import { ErrorContainer, InfoBox } from '@jotforminc/two-factor-settings';

const EmailAuthLogin = ({
  handleEALogin,
  lastEAResponse: {
    emailAuthToken: {
      target,
      remaining_time: remainingTime,
      total_time: totalTime,
      expires_at: expiresAt,
      isForced
    }
  },
  thereSocialError,
  thereErrorMessage,
  isSocialEA
}) => {
  const [verificationCode, setVerificationCode] = useState('');
  const [timerRunning, setTimerRunning] = useState('');
  const [errorMessage, setErrorMessage] = useTimeoutMessage(false, 5000);
  const timerRef = useRef(null);

  useEffect(() => {
    if (typeof window.__accountSharingLogger === 'function') {
      window.__accountSharingLogger({ action: 'seen', target: 'emailAuthLogin' });
    }
  }, []);

  useEffect(() => {
    const handleTabChange = () => {
      if (document.visibilityState === 'visible') {
        const remainingTimeAfterTabChange = expiresAt - Math.floor(Date.now() / 1000);

        if (remainingTimeAfterTabChange > 0) {
          timerRef.current.start(remainingTimeAfterTabChange, totalTime);
        } else {
          timerRef.current.start(0, totalTime);
          timerRef.current.stop();
        }

        console.log('tab active');
        console.log('remainingTimeAfterTabChange', remainingTimeAfterTabChange);
      } else {
        timerRef.current.stop();
        console.log('tab inactive');
      }
    };

    document.addEventListener('visibilitychange', handleTabChange);
    return () => {
      document.removeEventListener('visibilitychange', handleTabChange);
    };
  }, [expiresAt]);

  useEffect(() => {
    timerRef.current.stop();
    timerRef.current.start(remainingTime, totalTime);

    return () => timerRef.current && timerRef.current.stop();
  }, [expiresAt]);

  const handleLogin = async args => {
    const { verificationCode: code = false } = args;

    if (typeof code === 'string' && code.length === 0) {
      setErrorMessage(t(Texts.BLANK_VERIFICATION_CODE));
      return;
    }

    if (code.length < 6) {
      setErrorMessage('');
      return;
    }

    try {
      const response = await handleEALogin(args);
      const { data: { error } } = response?.[0];
      if (error) {
        setErrorMessage(t(error));
      }
    } catch (e) {
      // TO DO handleError;
    }
  };

  return (
    <div className="xcl-content xcl-nb-content twoFactorLogin forAuth">
      <h3 className="m2bt tfa-title">{t(Texts.EMAIL_AUTHENTICATION)}</h3>
      {isForced && (
        <div className="xcl-cw">
          <InfoBox>
            <p>
              {t(Texts.REACH_DAILY_LIMIT_FORCE_TO_EMAIL)}
            </p>
          </InfoBox>
        </div>
      )}
      <div className="xcl-cw tfa-description">
        {translationRenderer(Texts.EMAIL_AUTHENTICATION_DESCRIPTION)({
          renderer1: () => <b>{target}</b>
        })}
      </div>
      <div className="xcl-cw">
        <div className="xcl-form forLogin js-loginForm">
          <div className="xcl-field-wr">
            <label className="xcl-lbl" htmlFor="eatoken">
              {t(Texts.VERIFICATION_CODE)}
              <VerificationInput
                validChars='0-9'
                onCodeChange={code => setVerificationCode(code.toUpperCase())}
                inputId="eatoken"
                characterClassName={`character ${errorMessage !== false ? 'hasError' : ''}`}
                onEnterPress={() => handleLogin({ verificationCode, isSocialEA })}
              />
            </label>
            { errorMessage && (
              <ErrorContainer style={{ marginTop: 6 }}>{errorMessage}</ErrorContainer>
            )}
            {thereSocialError && (
              <ErrorContainer style={{ marginTop: 6 }}>{thereErrorMessage.response.data.error}</ErrorContainer>
            )}
          </div>
          <button
            className="xcl-button-gr forReset"
            onClick={() => {
              handleLogin({ verificationCode, isSocialEA });
              if (typeof window.__accountSharingLogger === 'function') {
                window.__accountSharingLogger({ action: 'click', target: 'verifyButton' });
              }
            }}
            type="button"
          >
            {t(Texts.VERIFY)}
          </button>

          <div className="tfa-timerLine">
            <PieTimer
              startCallback={() => setTimerRunning(true)}
              stopCallback={() => setTimerRunning(false)}
              ref={timerRef}
            />
            <p>
              {t(Texts.NO_CODE)}
              <button
                className="test_sendAgainButton"
                type="button"
                onClick={() => {
                  handleLogin({ isSocialEA });
                  if (typeof window.__accountSharingLogger === 'function') {
                    window.__accountSharingLogger({ action: 'click', target: 'sendAgainButton' });
                  }
                }}
                disabled={timerRunning ? 'disabled' : ''}
              >
                {t(Texts.SEND_AGAIN)}
              </button>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

EmailAuthLogin.propTypes = {
  handleEALogin: func.isRequired,
  lastEAResponse: shape({
    emailAuthToken: shape({
      type: string,
      target: string,
      remaining_time: string,
      total_time: number,
      expires_at: number,
      isForced: bool
    })
  }).isRequired,
  thereSocialError: string,
  thereErrorMessage: string,
  isSocialEA: bool.isRequired
};

EmailAuthLogin.defaultProps = {
  thereSocialError: '',
  thereErrorMessage: ''
};

export default EmailAuthLogin;
