/* eslint-disable max-len */
import Styled, { css } from 'styled-components';

export const customFont = '-apple-system, BlinkMacSystemFont, "Segoe UI", roboto, oxygen-sans, ubuntu, cantarell, "Helvetica Neue", sans-serif';
// '"CircularStd", "Work Sans SemiBold", -apple-system, BlinkMacSystemFont, "Segoe UI", roboto, oxygen-sans, ubuntu, cantarell, "Helvetica Neue", sans-serif';

export const commonStyles = `
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-family: ${customFont};
  font-size: 16px;
`;

export const ScBox = Styled.div`
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 4px;
  margin: auto auto;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  .wBox-upper {
    position: absolute;
    left: 0;
    top: 0;
    right: 50px;
    height: 79px;
  }

  > div { flex: 1 1 auto; min-height: 0; }

  @media screen and (max-width: 480px) {
    border-radius: 0;
  }
`;

export const ScHeader = Styled.div`
  @font-face {
    font-family:"Circular";
    src: local("Circular Medium"), local("Circular-Medium"), url("https://cdn.jotfor.ms/fonts/circular/fonts/Circular-Medium.woff2") format("woff2"), url("https://cdn.jotfor.ms/fonts/circular/fonts/Circular-Medium.woff") format("woff");
    font-display:swap;
    font-style:normal;
    font-weight: 500;
  }

  border-bottom: ${p => (p.withHeader === true ? '1px solid #eaebf2' : 0)};
  display: flex;
  align-items: center;

  .header-content {
    flex: 1 1 auto;
    padding-left: 20px;
    .jShareModal-titleText {
      font-size: 20px;
      line-height: 24px;
      font-weight: 500;
    }

    html[dir="rtl"] & {
      padding-left: 0;
      padding-right: 20px;
    }
  }

  .header-title {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 500;
    font-family: "Circular",Work Sans Medium,-apple-system,Helvetica Neue,sans-serif;
    line-height: 20px;
    color: #141E46;
    height: 20px;
    margin: 0;

    .avatarHolder {
      margin-right: 10px;
      html[dir="rtl"] & {
        margin-right: 0;
        margin-left: 10px;
      }
    }
  }

  .close-modal {
    margin: 18px;
  }
  && { flex: 0 0 auto; }

  @media screen and (max-width: 480px) {
    .header-content { padding-left: 1.5rem; }
  }
`;

export const ScFooter = Styled.div`
  border-top: 1px solid #EDEEF5;
  border-radius: 0 0 4px 4px;
  background-color: #F8F8FA;
  min-height: 30px;
  padding: 12px 25px;

  && { flex: 0 0 auto; }

  @media screen and (max-width: 480px) {
    padding: 9px 18px;
  }
`;

export const ScOverlay = Styled.div`
  * { box-sizing: border-box; }
  ${commonStyles};
  font-family: "Circular", -apple-system, BlinkMacSystemFont, "Segoe UI", roboto, oxygen-sans, ubuntu, cantarell, "Helvetica Neue", sans-serif;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 7002;
  background-color: rgba(37, 45, 91, 0.7);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  div[data-uikit-modal-container] {
    max-width: ${p => (p.maxWidth ? p.maxWidth : 630)}px;
  }
  &.templateModal {
    div[data-uikit-modal-container] {
      max-width: 1440px;
      max-height: unset;
      height: 96%;
    }
    [data-sc="picker-body"] {
      max-height: unset;
    }
    [data-sc="footer"] {
      display: none;
    }
  }

  &.forWizard {
    div[data-uikit-modal-container] {
      max-width: 860px;
      min-height: 0;
    }
    .header-content {
      padding-left: 24px;
    }
    .jfTabs-tabs-button {
      color: #8D8FA8;
      border-bottom: 1px solid #EAEBF2;
      &[data-isactive=true] {
        color: #5e74e6;
      }
      &:after {
        background-color: #5e74e6;
      }
    }
  }

  &.contactsDeleteModal {
    .title {
      margin: 24px 0 12px 0;
    }
    .content {
      padding-bottom: 32px;
      p {
        max-width: 450px;
        width: 100%;
        background-color: #F3F3FE;
        border-radius: 4px;
        padding: 24px 32px;
        color: #0A1551;
        .contactNames {
          font-weight: 500;
        }
      }
      .subtitle {
        color: #8D8FA8;
        font-weight: 400;
        .undone {
          font-weight: 500;
        }
      }
    }
  }

  :not(:last-child) {
    display: none;
  }

  .forShareModal-assignee div[data-uikitgeneratedportalcontainer=true] {
    position: absolute;

    & > div {
      position: relative !important;
    }
  }

  & > div {
    ${p => (p.isFullScreen ? css`
      width: 100%;
      height: 100%;
    ` : css`
      width: 96%;
      max-width: 770px;
      max-height: 80vh;
      // min-height: 424px;
    `)};
    display: flex;
  }

  ${p => p.isFullScreen && css`
    ${ScBox} { border-radius: 0; }
  `};

  @media screen and (max-width: 480px), (max-height: 480px) {
    overflow: auto;
    display: block;
    &.is2FAModal {
      display: flex;
      height: auto !important; 
    }

    & > div {
      max-height: none;
      height: 100svh;
      width: 100%;
      margin: 0;
      max-width: none;
    }
  }

  @media screen and (max-width: 480px) and (max-height: 380px) {
    & > div {
      height: calc(100vh + 88px);
    }
  }

  @media screen and (max-height: 414px) and (orientation: landscape) {
    & > div {
      height: unset;
    }
  }
`;
