import React, {
  forwardRef, useRef, useEffect, useState, useMemo
} from 'react';
import {
  node, shape, bool, string, func
} from 'prop-types';
import { createPopper } from '@popperjs/core';

import Portal from '../Portal';

import { useCombinedRefs } from '../../utils/hooks';

const Popover = forwardRef(({
  style,
  children,
  usePortal,
  popoverOptions,
  targetRef: { current },
  portalContainerSelector,
  onPlacementChange,
  useMemoizedPopper,
  dontUpdate = false,
  ...props
}, ref) => {
  if (!current) {
    return null;
  }

  // States
  const [popperInstance, setPopperInstance] = useState(false);

  // detect placement changings
  useEffect(() => {
    onPlacementChange(popperInstance?.state?.placement);
  }, [popperInstance?.state, popperInstance?.state?.placement]);

  // Refs
  const innerRef = useRef();
  const rootRef = useCombinedRefs(ref, innerRef);

  const memoizedPopperInstance = useMemo(() => (
    useMemoizedPopper && current && rootRef.current ? createPopper(current, rootRef.current, popoverOptions) : null
  ), [rootRef.current, current, useMemoizedPopper]);

  useEffect(() => {
    if (rootRef.current && current) {
      setPopperInstance((useMemoizedPopper && memoizedPopperInstance) ? memoizedPopperInstance : createPopper(current, rootRef.current, popoverOptions));
    }

    function handleOrientation() {
      popperInstance.update();
    }

    if (window.screen.orientation) {
      window.screen.orientation.addEventListener('change', handleOrientation);
    }

    if (popperInstance && !dontUpdate) {
      popperInstance.update();
    }

    return () => {
      if (window.screen.orientation) {
        window.screen.orientation.removeEventListener('change', handleOrientation);
      }
      if (popperInstance) popperInstance.destroy();
    };
  }, [rootRef.current, current]);

  useEffect(() => {
    if (popperInstance) {
      popperInstance.setOptions(popoverOptions);
    }
  }, [popoverOptions, popperInstance]);

  return (
    <Portal usePortal={usePortal} containerSelector={portalContainerSelector}>
      <div {...props} ref={rootRef} style={popperInstance ? style : { display: 'none' }}>
        {popperInstance && children}
      </div>
    </Portal>
  );
});

Popover.propTypes = {
  usePortal: bool,
  children: node,
  targetRef: shape({}),
  popoverOptions: shape({}),
  style: shape({}),
  portalContainerSelector: string,
  onPlacementChange: func,
  useMemoizedPopper: bool,
  dontUpdate: bool
};

Popover.defaultProps = {
  usePortal: false,
  targetRef: { current: null },
  popoverOptions: {},
  style: {},
  children: null,
  portalContainerSelector: undefined,
  onPlacementChange: f => f,
  useMemoizedPopper: false,
  dontUpdate: false
};

export default Popover;
