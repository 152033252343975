import React from 'react';
import { t } from '@jotforminc/translation';
import { IconFloppyDisk } from '@jotforminc/svg-icons';

import { Texts } from '../constants';

const Loading = () => {
  return (
    <>
      <div className='sacl-modal__center pb-32 pt-44'>
        <div className='sacl-modal__logo'>
          <IconFloppyDisk />
        </div>
      </div>
      <div className="sacl-modal__texts">
        <h1>
          {t(Texts.DRAFT_BEING_SAVED)}
          <div className="dot-flashing" />
        </h1>
        <span>{t(Texts.PLEASE_WAIT_SAVE)}</span>
      </div>
      <div className="sacl-modal__center pb-40 pt-24">
        <button className="sacl-btn sacl-btn--first sacl-btn--disabled" type="button" disabled>
          {t(Texts.PLEASE_WAIT)}
        </button>
      </div>
    </>
  );
};

export default Loading;
