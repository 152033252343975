import React from 'react';
import { t } from '@jotforminc/translation';
import {
  string,
  arrayOf,
  shape,
  func,
  bool
} from 'prop-types';
import { Texts } from '@jotforminc/constants';
import SSOLoginOptions from './SSOLoginOptions';

const SSOOptions = ({
  logoSource,
  loginWelcomeText,
  greetingDescription,
  loginDescriptionText,
  ssoLoginOptions,
  onSSOLoginSuccess,
  onSSOLoginFail,
  fromSACL,
  translateWelcomeText
}) => {
  const showLogo = !!logoSource;

  const trimmedLoginWelcomeText = loginWelcomeText?.trim?.();
  const trimmedGreetingDescription = greetingDescription?.trim?.();
  const trimmedLoginDescriptionText = loginDescriptionText?.trim?.();
  const descriptionText = trimmedGreetingDescription || trimmedLoginDescriptionText;

  return (
    <div className="xcl-content xcl-nb-content">
      {showLogo && <img src={logoSource} alt="Logo" className="xcl-companyLogo" />}
      {trimmedLoginWelcomeText && (<h2 className="m2bt isLoginWelcomeText">{translateWelcomeText ? t(trimmedLoginWelcomeText) : trimmedLoginWelcomeText}</h2>)}
      {descriptionText && (<p className="isDescription test-variant">{t(descriptionText)}</p>)}
      <div className="xcl-cw loginContainer" id="loginBox">
        <div className="xcl-form forLogin js-loginForm" id="loginForm">
          {
            ssoLoginOptions.length > 0 && (
              <SSOLoginOptions
                ssoLoginOptions={ssoLoginOptions}
                onSSOLoginSuccess={onSSOLoginSuccess}
                onSSOLoginFail={onSSOLoginFail}
                fromSACL={fromSACL}
                hideDivider
              />
            )
          }
        </div>
      </div>
    </div>
  );
};

SSOOptions.propTypes = {
  logoSource: string,
  loginWelcomeText: string,
  greetingDescription: string,
  loginDescriptionText: string,
  ssoLoginOptions: arrayOf(shape({
    name: string,
    logoURL: string,
    configKey: string,
    idpName: string
  })),
  onSSOLoginSuccess: func,
  onSSOLoginFail: func,
  fromSACL: bool,
  translateWelcomeText: bool
};

SSOOptions.defaultProps = {
  logoSource: '',
  loginWelcomeText: '',
  greetingDescription: Texts.WELCOME_BACK,
  loginDescriptionText: '',
  ssoLoginOptions: [],
  onSSOLoginSuccess: f => f,
  onSSOLoginFail: f => f,
  fromSACL: false,
  translateWelcomeText: true
};

export default SSOOptions;
