import React from 'react';
import PropTypes from 'prop-types';
import '../styles/twoFactorLogin.scss';

const Checkbox = ({ children, disabled, ...props }) => {
  const { id } = props;

  return (
    <label
      htmlFor={id}
      className="jfCheckbox"
    >
      <input
        className="jfCheckbox-input"
        type="checkbox"
        {...props}
        disabled={disabled}
      />
      <div className={`jfCheckbox-input-state ${disabled ? 'jfCheckbox-disabled' : ''}`} />
      {children && <div className={`jfCheckbox-input-text ${disabled ? 'jfCheckbox-inputText-disabled' : ''}`}>{children}</div>}
    </label>
  );
};

Checkbox.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

Checkbox.defaultProps = {
  children: null,
  disabled: false
};

export default Checkbox;
