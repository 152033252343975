import React from 'react';
import { string, node } from 'prop-types';
import { IconInfoCircleFilled } from '@jotforminc/svg-icons';
import {
  ScInfoBox
} from '../sc/scTwoFactorSettings';

const InfoBox = ({ mode, children, ...rest }) => {
  return (
    <ScInfoBox mode={mode} {...rest}>
      <div>
        <IconInfoCircleFilled />
      </div>
      <div style={{ textAlign: 'left' }}>
        {children}
      </div>
    </ScInfoBox>
  );
};

InfoBox.propTypes = {
  mode: string,
  children: node.isRequired
};

InfoBox.defaultProps = {
  mode: ''
};

export default InfoBox;
