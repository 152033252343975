import Adapter from './Adapter';

export default class CheckboxAdapter extends Adapter {
  getValue() {
    const res = {};
    const questionLine = document.getElementById(`id_${this.qid}`);
    const inputs = questionLine.querySelectorAll('input');
    for (let i = 0; i < inputs.length; i++) {
      const input = inputs[i];
      if (input.checked) {
        const { name, value } = input;
        const key = name.indexOf('other') !== -1 ? 'other' : i;
        res[key] = value;
      }
    }
    return { data: res };
  }

  setValue(value) {
    return super.setValue(value);
  }
}
