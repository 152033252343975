/* eslint-disable max-len */
import React from 'react';
import { number } from 'prop-types';
import styled, { css } from 'styled-components';
import { Button } from '@jotforminc/common-button';
import { StyledModal as Modal } from '@jotforminc/styled-modal';
import Toggle from '@jotforminc/toggle';
import { Tooltip } from '@jotforminc/tooltip';

import { Button as ButtonSC } from '../components/styled';

const borderColor = '#E3E5F5';
const descColor = '#2C3345';
const primaryIndicatorColor = '#8A94A9';
const black = '#000';
const settingsBlue = '#0099FF';
const infoBlueTextBg = '#F4F7FF';
const infoBlueTextColor = '#001D68';
const infoOrange = '#F5A51C';
const infoOrangeTextBg = '#FFF5D2';
const infoOrangeTextColor = '#0A1551';

export const ScSettingsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;

  *, *::before, *::after {
    font-family: 'Circular', sans-serif;
    box-sizing: inherit;

  }
`;

export const ScTooltip = styled.div`
  pointer-events: none;
  opacity: 0;
  max-width: 145px;
  background-color: #444;
  color: #fff;
  font-size: 12px;
  border-radius: 2px;
  position: absolute;
  padding: 6px 12px;
  top: 25px;
  left: -4px;
  transform: translateY(5px);
  transition: .4s;
  transition-delay: 250ms;
  z-index: 9;

  html[dir="rtl"] & {
    left: unset;
    right: -4px;
  }

  :after {
    content: "";
    position: absolute;
    top: 0;
    left: 6px;
    transform: translateY(-100%);
    border-width: 8px;
    border-style: solid;
    border-color: transparent transparent #444 transparent;

    html[dir="rtl"] & {
      left: unset;
      right: 6px;
    }
  }
`;

export const ScToggleWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding-bottom: 20px;
  gap: 16px;


  p {
    flex-grow: 1;
    flex-basis: 60%;
    margin: 0 0 2px;
    max-width: 460px;
    color: ${descColor};
    font-size: 14px;
    text-align: left;

    html[dir="rtl"] & {
      text-align: right;
    }
    a {
      color: #0075E3;
      text-decoration: underline;
    }
  }

  div {
    flex-shrink: 0;
  }

  span {
    width: 100%;
    font-size: 14px;
  }
`;

export const ScTooltipV2Wrapper = styled.div`
  position: relative;
`;

export const ScTooltipV2 = styled(props => <Tooltip {...props} />)`
  width: 190px;
  text-align: center;
  
  &.jfTooltipNew {
    &.tooltipAttachTop {
      left: unset;
    }
  }
  [dir='rtl'] & {
    right: -210px;
  }
  @media screen and (max-width: 640px) {
      right: -100px;
  }
`;

export const ScToggle = styled(props => <Toggle {...props} />)`
  &:hover {
    opacity: 1;
  }
`;

export const ScMethodsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  h4 {
    color: ${black};
    font-size: 14px;
    margin: 12px 0 0 0;
    font-weight: 500;
    border-bottom: 1px solid ${borderColor};
    padding: 0 0 7px 0;
  }
`;

export const ScMethodButton = styled(ButtonSC)` && {
  &,
  &:hover {
    display: block;
    padding: 0 8px;
    min-width: 84px;
    
    ${({ isGenerateNewCodesButton }) => (isGenerateNewCodesButton ? css`
      @media screen and (max-width: 500px) { & {
        width: 100%;
      } }
    ` : null)}

    ${({ isPrimary, loading }) => (isPrimary && !loading ? css`
      &,
      &[disabled],
      &:disabled {
        cursor: default;
    ` : null)}
  }
}
}`;

ScMethodButton.propTypes = ButtonSC.propTypes;
ScMethodButton.defaultProps = ButtonSC.defaultProps;

export const ScMethodItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px solid ${borderColor};

  div {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    margin: 8px 0px 8px 0px;
    width: 100%;

    label {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: flex-start;
      flex-grow: 1;
      flex-basis: 50%;
      position: relative;

      input {
        position: relative;
        margin-left: 4px;
        margin-right: 8px;
        height: 16px;
        width: 16px;

        html[dir="rtl"] & {
          margin-right: 4px;
          margin-left: 8px;
        }

        &:hover + ${ScTooltip} {
          opacity: 100;
        }
      }

      span {
        font-size: 12px;
        font-weight: 500;
      }

      & + p {
        font-size: 11px;
        margin: 0;
        color: ${primaryIndicatorColor};
      }
    }
  }
`;

export const ScMethodTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  span {
    font-family: 'Circular';
    font-style: normal;
    line-height: 24px;
  }
`;

export const ScMethodItemTitle = styled.span`
  font-weight: 700 !important;
  font-size: 16px !important;
`;

export const ScMethodItemDesc = styled.span`
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #6C73A8;
`;

export const ScRecoveryCodesWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding-top: 6px;
  margin: 10px 0 0 0;

  @media screen and (max-width: 500px) { & {
    flex-direction: column;
  } }

  div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    
    @media screen and (max-width: 500px) { & {
      margin-bottom: 8px;
    } }
  }

  span {
    border-width: 1px;
  }

  h4 {
    color: ${black};
    font-size: 14px;
    margin: 0 12px 0 0;
    font-weight: 500;
  }
`;

export const ScInfoBox = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  width: 100%;
  border-radius: 4px;

  &:not(:first-child) {
    margin-top: 13px;
  }

  overflow: hidden;

  > div {
    position: relative;
    background: ${props => (props.mode === 'warning' ? infoOrange : settingsBlue)};
    width: 49px;

    svg {
      fill: white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 24px;
      height: 24px;
    }

    & + div {
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding: 15px 12px;
      font-size: ${({ fontSize }) => fontSize}px;
      background: ${props => (props.mode === 'warning' ? infoOrangeTextBg : infoBlueTextBg)};
      color: ${props => (props.mode === 'warning' ? infoOrangeTextColor : infoBlueTextColor)};

      p {
        margin: 0;
        font-family: 'Circular';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
      }
    }
  }
`;

ScInfoBox.defaultProps = {
  fontSize: 12
};

ScInfoBox.propTypes = {
  fontSize: number
};

export const ScModal = styled(props => <Modal {...props} />)``;

export const ScButton = styled(props => <Button {...props} />)``;
