import { RequestLayer } from '@jotforminc/request-layer';

const responseStatusInterceptor = ctx => {
  const { resp } = ctx;

  if (typeof resp?.data?.responseCode === 'number' && (resp?.data?.responseCode < 200 || resp?.data?.responseCode > 299)) {
    ctx.result = Promise.reject(new Error(resp?.data?.message));
    return;
  }

  if (resp?.data?.content) {
    ctx.result = resp?.data?.content;
  }

  return false;
};

const layer = new RequestLayer('/API', {
  interceptorConfig: {
    customResponseInterceptors: [responseStatusInterceptor]
  }
});

export const checkOTP = ({ username, project }) => layer.get(`user/${username}/project/${project}/otp-is-verified`);

export const validateOTP = ({ username, project, code = null }) => layer.post(`user/${username}/project/${project}/handle-otp`, code ? new URLSearchParams({
  otpToken: code
}) : null);

export default layer;
