import isNumber from 'lodash/isNumber';
import { EMAIL_PATTERN } from '@jotforminc/constants';
import { t } from '@jotforminc/translation';

import { safeJSONParse } from './data';

export const isFilled = value => {
  if (typeof value === 'string') {
    const val = value && value.replace(/^\s+|\s+$/g, '');
    return !!val;
  } if (Array.isArray(value)) {
    return value.length > 0;
  }
  return !!value;
};

export const isEmpty = value => {
  return !isFilled(value);
};

export const isValidUrl = value => {
  const URLPattern = /(http|ftp|https){0,1}:{0,1}[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&amp;:/~\+#]*[\w\-\@?^=%&amp;/~\+#])?/; // eslint-disable-line no-useless-escape
  const validUrlRegex = global?.JotForm?.validationRegexes?.url || URLPattern;
  return isFilled(value) && validUrlRegex.test(value);
};

export const isEmail = value => {
  return isFilled(value) && EMAIL_PATTERN.test(value);
};

export const isTeamEmail = value => {
  const teamRegex = /team:[0-9].{13,14}$/;
  return teamRegex.test(value);
};

/**
 * Hostname validation
 * @function
 * @param {String} hostname to validate
 */
export const validateHostname = hostname => /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]).)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9-]*[A-Za-z0-9])$/
  .test(String(hostname).toLowerCase());

export const isSafari = () => /^((?!chrome|android).)*safari/i.test(global.navigator.userAgent);

/**
 * E-mail validation
 * @function
 * @param {String} email to validate
 */
export const validateEmail = email => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  .test(String(email).toLowerCase());

const validationMessages = {
  required: t('This field is required'),
  invalidEmail: t('Invalid email address')
};

const validationsMap = {
  required: value => (!isFilled(value) ? validationMessages.required : false),
  required_withnone: value => (!isFilled(value) || value === 'none' ? validationMessages.required : false),
  email: value => {
    const questionPlaceholderRegex = /^\{(.*?)\}$/;
    if (typeof value === 'string' && questionPlaceholderRegex.test(value)) {
      return false;
    }

    if (typeof value === 'string') {
      const [, tagifyValue] = value.match(/^\[\[(.*?)\]\]$/) || [];
      if (tagifyValue) {
        const parsedTagifyValue = safeJSONParse(tagifyValue);
        if (parsedTagifyValue && parsedTagifyValue.name && questionPlaceholderRegex.test(parsedTagifyValue.name)) {
          return false;
        }
      }
    }

    if (Array.isArray(value) && value.length === 1 && questionPlaceholderRegex.test(value[0].value)) {
      return false;
    }

    const strEmail = (Array.isArray(value) && value.length === 1) ? value[0].value : value;
    return !isEmail(strEmail) ? validationMessages.invalidEmail : false;
  },
  numeric: val => (!isNumber(val) ? 'please write numeric' : false),
  content: val => {
    if (!isFilled(val)) {
      return validationMessages.required;
    }

    // Create dummy dom to check if the email content is empty or not
    const emailContent = new window.DOMParser().parseFromString(val, 'text/html').body.textContent.trim();
    return !emailContent ? validationMessages.required : false;
  }
};

// TODO: memoize this fn
export const generateIsErroredFromValidations = validations => {
  const fns = [];
  validations.forEach(name => {
    const validator = validationsMap[name];
    if (validator) {
      fns.push(validator);
    }
  });
  return value => {
    let errorMsg;
    fns.find(fn => {
      errorMsg = fn(value);
      return errorMsg;
    });
    return errorMsg || false;
  };
};

export const checkUserType = (userType, credentials) => {
  const { account_type: { name } } = credentials;
  return name === userType;
};
