/* eslint-disable max-len */
import { Texts } from '@jotforminc/constants';

// Unusual Signup Flow Texts
export const REPORT_UNUSUAL_SIGNUP = 'Report Unusual Signup';
export const NOT_CREATE_THIS_ACCOUNT = 'If you didn’t create an account using [1[{email}]], please report this so that we can take the necessary action.';
export const REPORT_ACCOUNT = 'Report this account';
export const KEEP_ACCOUNT = 'This is my account';
export const CONTINUE = 'Continue';
export const RECEIVED_RESPONSE = 'We’ve received your report';
export const THANKS_FOR_REPORTING = 'Thank you for reporting this. We’ve taken the necessary actions for the account associated with [1[{email}]]. If you need help with anything else, please [2[contact us.]]';
export const ERROR_MESSAGE = 'An error occured. Please [1[contact us]].';

// Signup With Login Opts Texts
export const TITLE_MESSAGE = 'Sign Up Now';
export const DESCRIPTION_MESSAGE = 'Collect information, payments, and signatures with custom online forms';
export const SIGN_UP_WITH = 'Sign up with';
export const OR = 'OR';
export const EMAIL = 'Email';
export const BACK = 'Back';
export const CONTINUE_SIGNUP = 'Continue Signing Up';
export const SIGNING_UP_WITH = 'You’re signing up with [1[{email}]]';
export const LOGIN_SESSION_EXPIRED_MESSAGE = 'Login timed out. Please refresh the page and try logging in again.';

// A/B Test: signupOnboardingIV
export const OR_SIGN_UP_WITH = 'or sign up with';
export const OR_LOGIN_WITH = 'or login with';
export const EMAIL_ADDRESS = 'Email address';
export const CREATE_ACCOUNT = 'Create Account';
export const ENTER_EMAIL_ADDRESS = 'Enter your email address';
export const ENTER_FULL_NAME = 'Enter your full name';

export const errorMessages = {
  tryAgain: Texts.ERROR_TRY_AGAIN,
  required: Texts.FIELD_REQUIRED,
  validEmail: Texts.VALID_EMAIL,
  termsRequired: Texts.AGREE_TERMS_CONDITIONS,
  hipaaPass: {
    minCharCount: Texts.PASS_MIN_CHAR_COUNT,
    includeChars: Texts.PASS_INCL_CHARS,
    differentThanPrev: Texts.PASS_DIFF_PREV
  }
};
