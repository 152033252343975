import qs from 'qs';
import Axios from 'axios';
import Polyglot from 'node-polyglot';
import domtoimage from 'dom-to-image-more';
import isUndefined from 'lodash/isUndefined';

import {
  isInViewport,
  getTextContents,
  searchTextInDOM,
  addTranslationMarkup,
  clearTranslationMarkup
} from './domHelper';

class Translation {
  constructor() {
    // Default value settings
    this.defaultLang = 'en-US';
    this.processedStrings = {};
    this.usedTranslations = [];
    this.untranslatedStrings = {};
    this.currentLocale = this.defaultLang;
    this.filterClasses = [
      'ace_editor',
      'ace-solarized-dark',
      'ace_dark',
      'input-group',
      'isAvatar',
      'jfHeader-userAvatar',
      'themesListItem-img',
      'themesListItem-imgAspect',
      'prevUploads-list',
      'card-leftSide',
      'jfQuestion-fields-contentVisible',
      'appBox-image'
    ];
    this.filterTags = [
      'IFRAME',
      'IMG'
    ];
    this.dictionaries = {
      [this.defaultLang]: new Polyglot({
        phrases: {},
        locale: this.defaultLang.split('-')[0],
        allowMissing: true
      })
    };

    this.interpolation = { templateSyntax: /\{.\}*\w+\}/g, eraseTemplate: /\{|\}/g };
    // Variables related to delayed submitNotTranslated function
    this.callTimer = null;

    // Number of elements to be processed and send to server at once
    this.processLimit = 1;

    // Debounce time for process function
    this.debounceTime = 1000;

    // Function binds
    this.setLocale = this.setLocale.bind(this);
    this.resetProcess = this.resetProcess.bind(this);
    this.limitElements = this.limitElements.bind(this);
    this.orderElements = this.orderElements.bind(this);
    this.takeScreenShot = this.takeScreenShot.bind(this);
    this.debounceProcess = this.debounceProcess.bind(this);
    this.handleMissingKey = this.handleMissingKey.bind(this);
    this.submitNotTranslated = this.submitNotTranslated.bind(this);
    this.processNotTranslated = this.processNotTranslated.bind(this);
    this.updateProcessedStrings = this.updateProcessedStrings.bind(this);
    this.rotateUntranslatedStrings = this.rotateUntranslatedStrings.bind(this);
    this.filterUntranslatedStrings = this.filterUntranslatedStrings.bind(this);
    this.translate = this.translate.bind(this);
    this.initServerSideDictionary = this.initServerSideDictionary.bind(this);
  }

  // Getter for getting untranslated string list of current language
  get currentLocaleUntranslated() {
    return Array.isArray(this.untranslatedStrings[this.currentLocale]) ? this.untranslatedStrings[this.currentLocale] : [];
  }

  // Get processed elements in this session
  get currentProcessed() {
    return Array.isArray(this.processedStrings[this.currentLocale]) ? this.processedStrings[this.currentLocale] : [];
  }

  // Get single array that contains all untranslated strings
  get allUntranslatedStrings() {
    return Object.keys(this.untranslatedStrings).reduce(
      (prev, curr) => prev.concat(this.untranslatedStrings[curr]),
      []
    );
  }

  initServerSideDictionary(langCode, dictionary) {
    this.add(langCode, dictionary);
  }

  // Sets current locale and fetch untranslated list if it still does not exist
  setLocale(code) {
    // Set locale of objectcurrentLocale
    this.currentLocale = code;
  }

  // Update untranslatedStrings array by removing sended strings
  filterUntranslatedStrings(langCode, strings) {
    this.untranslatedStrings = {

      ...this.untranslatedStrings,
      [langCode]: this.untranslatedStrings[langCode].filter(str => strings.indexOf(str) === -1)
    };
  }

  // Update unprocessedStrings by adding sended strings
  updateProcessedStrings(langCode, strings) {
    // Add processed words to processed list
    this.processedStrings = {

      ...this.processedStrings,
      [langCode]: this.processedStrings[langCode] ? this.processedStrings[langCode].concat(strings) : strings
    };
  }

  // Add sended str to the untranslatedStrIngs array of current locale
  addUntranslatedString(str) {
    this.untranslatedStrings = {

      ...this.untranslatedStrings,
      [this.currentLocale]: this.currentLocaleUntranslated.concat([str])
    };
  }

  // Move given strings to the end of their respective array
  rotateUntranslatedStrings(strings) {
    // For each key filter given strings and append found ones to the end
    this.untranslatedStrings = Object.keys(this.untranslatedStrings).reduce((prev, curr) => {
      // Get matched strings array of given locale
      const foundStrings = this.untranslatedStrings[curr].filter(str => strings.indexOf(str) > -1);

      // First filter given strings and then append them afterward
      return {

        ...prev,
        [curr]: this.untranslatedStrings[curr].filter(str => foundStrings.indexOf(str) < 0).concat(foundStrings)
      };
    }, {});
  }

  // Order given dom elements respect to their position as strings in untranslated array
  orderElements(elements) {
    // No need to order if only one is found
    if (elements.length <= 1) {
      return elements;
    }

    // Get strings of elements
    const textContents = getTextContents(elements);

    // Order elements by looking their string positions
    return this.allUntranslatedStrings.filter(str => textContents.indexOf(str) > -1).map(str => elements[textContents.indexOf(str)]);
  }

  // Initializes locale and the dictionary belongs to it
  add(code = '', dictionary = {}) {
    // Initialize locale
    this.setLocale(code);

    if (isUndefined(this.dictionaries[code])) {
      // Add sended dictionary to the current list
      this.dictionaries = {

        ...this.dictionaries,
        [code]: new Polyglot({
          phrases: dictionary,
          locale: code.split('-')[0],
          onMissingKey: this.handleMissingKey // This function will be triggered if the text does not exist in translation list
        })
      };
    }
  }

  // Translate string using Polyglot api
  translate(str, params) {
    let translated = str;
    if (typeof params === 'object' && Object.keys(params).length) {
      const variables = str.match(this.interpolation.templateSyntax);
      variables?.forEach(strVar => {
        // Remove template string characters and get variable name.
        const variableName = strVar.replace(this.interpolation.eraseTemplate, '');
        // If there is a value for given key, replace the key with given value
        if (params[variableName]) {
          translated = translated.replace(strVar, params[variableName]);
        }
      });
    }

    if (!this.usedTranslations.includes(str)) {
      this.usedTranslations.push(str);
    }

    return this.dictionaries[this.currentLocale].t(translated, params);
  }

  // Handler for texts that does not have translation
  handleMissingKey(str, options) {
    // Further process is limited to certain users
    if (!(global.window.useTranslationScreenshots === true)) {
      return Polyglot.transformPhrase(str, options);
    }

    // If it is already in processed list then skip processing it
    if (this.currentProcessed.indexOf(str) > -1) {
      return Polyglot.transformPhrase(str, options);
    }

    // Do not try to process if string is empty or only contains space
    if (/^\s+$/.test(str)) {
      return '';
    }

    // Add founded word to untranslated texts if it does not exist
    if (this.currentLocaleUntranslated.indexOf(str) === -1) {
      this.addUntranslatedString(str);
    }

    // Call debounced process (works every n seconds where n is this.processLimit)
    this.debounceProcess();
  }

  // Delays the call of submitNotTranslated
  debounceProcess() {
    // If a timer is already started
    if (this.callTimer !== null) {
      // Do not process any further
      return;
    }

    // Restart timer
    this.callTimer = setTimeout(this.processNotTranslated, this.debounceTime);
  }

  // Limits sended dom elements according to the constants set in class
  limitElements(elements) {
    // Limit the elements based on the processLimit variable
    return elements.slice(0, this.processLimit);
  }

  // Filters false positive translations by looking their screenshot values
  filterFalseTranslations(strings, screenshots) {
    // Indexes of false screenshots
    const emptyIndexes = screenshots.reduce((prev, curr, index) => { return curr === false ? prev.concat([index]) : prev; }, []);

    // Filter strings that matched with false screenshot results
    const filteredStrings = {

      ...strings,
      [this.currentLocale]: strings[this.currentLocale].filter((str, index) => emptyIndexes.indexOf(index) < 0)
    };

    // Filter false screenshots
    const filteredScreenshots = screenshots.filter((ss, index) => emptyIndexes.indexOf(index) < 0);

    // Return filtered strings and screenshots
    return {
      filteredStrings,
      filteredScreenshots
    };
  }

  // Prepares object for next translation process
  resetProcess(strings = []) {
    // Clear timer as the submit promises are resolved
    clearTimeout(this.callTimer);
    this.callTimer = null;

    // If there is still an untranslated string in the list
    if (this.currentLocaleUntranslated.length !== 0) {
      if (strings.length !== 0) {
        // Rotate strings that tried to be translated before if they still exist
        this.rotateUntranslatedStrings(strings);
      }

      // Try to call process again
      this.debounceProcess();
    }
  }

  // Filter and screenshot process
  processNotTranslated() {
    // Get body for dom earch
    let node = '';
    if (global.window.casperjsTestDomParam) {
      node = global.document.querySelector(decodeURI(global.window.casperjsTestDomParam));
      /* eslint-disable no-console */
      console.log('global.window.casperjsTestDomParam', global.window.casperjsTestDomParam);
      /* eslint-enable no-console */
    } else {
      node = global.document.body;
    }

    // Search all texts accross dom elements to find their containers
    const foundElements = searchTextInDOM(node, this.allUntranslatedStrings);

    /* eslint-disable no-console */
    console.log('this.allUntranslatedStrings', this.allUntranslatedStrings);
    console.log('foundElements', foundElements);
    /* eslint-enable no-console */

    // If could not found any element then reset the process
    if (!foundElements || foundElements.length === 0) {
      if (global.window.casperJsTest) {
        global.window.captureInProcess = false;
        /* eslint-disable no-console */
        console.log('capture process finished!');
        /* eslint-enable no-console */
      }
      // Clear timer
      return this.resetProcess();
    }

    // Order these elements to match with their respected string positions in untranslated strings array
    const orderedFoundElements = this.orderElements(foundElements);

    // Limit the number of elements to be processed
    const elementsToProcess = this.limitElements(orderedFoundElements);

    // Get strings of found elements
    const foundStrings = getTextContents(elementsToProcess);

    // Get only matched strings from unstranslated list
    const untranslatedToPost = Object.keys(this.untranslatedStrings).reduce((prev, curr) => ({

      ...prev,
      [curr]: this.untranslatedStrings[curr].filter(str => foundStrings.indexOf(str) > -1)
    }), {});

    // Create promise for each screenshot
    const ssPromises = Object.values(elementsToProcess).reduce((prev, curr) => { return typeof curr === 'object' ? prev.concat([this.takeScreenShot(node, curr)]) : prev; }, []);

    Promise.all(ssPromises) // After all screenshots taken
      .then(ssDataAll => this.filterFalseTranslations(untranslatedToPost, ssDataAll)) // Filter false positive translations
    // After resolving all of the promises just pass them to the not transalted submission
      .then(({ filteredStrings, filteredScreenshots }) => this.submitNotTranslated(filteredStrings, filteredScreenshots))
    // Reset required variables and do post process jobs to prepare object for next translation after submissions
      .then(submitPromises => Promise.all(submitPromises).then(() => this.resetProcess(foundStrings)));
  }

  // Submit not translated strings
  submitNotTranslated(untranslatedToPost, ssDataAll) {
    // Send translation request for each key separately
    return Object.keys(untranslatedToPost).map(langCode => {
      // Get strings to send
      const strings = untranslatedToPost[langCode];

      let depthCount = 0;
      let depthLabel = '';
      if (global.window.casperJsTest) {
        if (global.window.depthCount) {
          depthCount = global.window.depthCount;
        }
        if (global.window.depthLabel) {
          depthLabel = global.window.depthLabel;
        }
      }

      // No need to send data if one of them is empty or their lengths are not equal to each other
      if (strings.length === 0 || ssDataAll.length === 0 || strings.length !== ssDataAll.length) {
        if (global.window.casperJsTest) {
          global.window.captureInProcess = false;
          /* eslint-disable no-console */
          console.log('capture process finished!');
          /* eslint-enable no-console */
        }
        return false;
      }

      Axios.post('/server.php', qs.stringify({
        action: 'submitNotTranslated',
        lang_code: langCode,
        raw: untranslatedToPost[langCode].join('__NOT_TRANSLATED__'),
        autoSuggestion: false,
        depthCount,
        depthLabel,
        screenshot: ssDataAll.join('|||')
      }), {
        'Content-Type': 'application/x-www-form-urlencoded'
      })
        .then(() => {
        // Move processed strings to processed list
          this.updateProcessedStrings(langCode, untranslatedToPost[langCode]);

          // Filter them from untranslated list
          this.filterUntranslatedStrings(langCode, untranslatedToPost[langCode]);
        });

      return true;
    });
  }

  // Take screenshot of given node if foundElement is visible in that dom
  takeScreenShot(node, foundElement) {
    // Try to make every element visible on found elements
    const visibilityResult = addTranslationMarkup(foundElement);
    if (global.window.casperJsTest) {
      /* eslint-disable no-console */
      console.log('foundElement', foundElement.wholeText);
      /* eslint-enable no-console */
    }
    // Do not need to take ss if element is invisible or not in viewport
    if (!visibilityResult || !isInViewport(foundElement)) {
      /* eslint-disable no-console */
      console.log('the element is invisible or not in viewport');
      /* eslint-enable no-console */
      // Clear visibility
      clearTranslationMarkup();

      // Return false due to it does not exist on screen
      return false;
    }

    // If the page is loaded by casperjs
    if (global.window.casperJsTest) {
      // Send capture command to casperjs as error
      /* eslint-disable no-console */
      console.log('capture');
      /* eslint-enable no-console */

      // Resolve with base64 encoded ss data
      return new Promise(resolve => {
        setTimeout(() => {
          // Take ss data
          const capturedData = global.window.tempCaptureData;

          // Clear temporary data
          global.window.tempCaptureData = null;

          // Clear visibility classes
          clearTranslationMarkup();

          // Return screenshot
          resolve(capturedData);
        }, 2000);
      });
    }

    // Take screenshot and return base64 encoded form using visibility css
    return new Promise(resolve => domtoimage.toPng(
      node,
      {
        filter: el => {
          // Return if element is not valid to check
          if (!el || !el.className || !el.tagName || typeof el.className !== 'string') {
            return true;
          }

          // If element is one of the tags to be filtered from screenshot
          if (this.filterTags.indexOf(el.tagName) > -1) {
            return false;
          }

          const filteredNodeClass = el.className.split(' ').filter(classStr => this.filterClasses.indexOf(classStr) !== -1);

          return filteredNodeClass.length === 0;
        }
      }
    )
      .then(dataUrl => {
        // Clear visibility classes
        clearTranslationMarkup();

        // Return screenshot
        resolve(dataUrl);
      }));
  }
}

export const translation = global.Translations || new Translation();

export default Translation;
