import Adapter from './Adapter';

export default class FileAdapter extends Adapter {
  getValue() {
    let filenames = Array.from(document.querySelectorAll(`input[name*="temp_upload[q${this.qid}_"][name$="]"]`)).map(file => file.value);

    if (filenames.length === 0) {
      // For old theme
      filenames = Array.from(document.querySelector(`#cid_${this.qid}`)?.querySelectorAll('.qq-upload-file')).map(file => file.innerText);
    }

    return { data: filenames || [] };
  }

  setValue(value) {
    return super.setValue(value);
  }
}
