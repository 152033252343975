import axios from 'axios';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Texts, prepareLoginFlowDefaultGreetings } from '@jotforminc/constants';

import { translation } from '../Translation';

const TranslateContext = React.createContext();

export class TranslateProvider extends Component {
  constructor(props) {
    super(props);
    if (window.JotFormAPIEndpoint) { // populated in form.php jot3
      this.apiURL = window.JotFormAPIEndpoint;
    } else {
      this.apiURL = window.location.host.indexOf('jotform.pro') > -1 ? '/API' : 'https://api.jotform.com';
    }

    this.state = {
      // eslint-disable-next-line react/no-unused-state
      translatedText: [],
      translationFetched: false
    };
  }

  componentDidMount() {
    this.getRequest();
  }

  getRequest = async () => {
    const getFormLanguage = global.document.documentElement.lang;
    if (!getFormLanguage || getFormLanguage === 'en' || getFormLanguage === 'en-US') {
      this.setState({
        // eslint-disable-next-line react/no-unused-state
        translatedText: [],
        translationFetched: true
      });
      return;
    }

    const { translationTexts } = this.props;
    Texts.GREETING_DESCRIPTION = prepareLoginFlowDefaultGreetings();
    try {
      const formData = new FormData();
      formData.append('lang', getFormLanguage);
      formData.append('data', JSON.stringify(Object.values(translationTexts)));
      const fieldTranslation = await axios.post(`${this.apiURL}/translationList`, new URLSearchParams(formData).toString(), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });
      translation.add(getFormLanguage, fieldTranslation.data.content);

      this.setState({
        // eslint-disable-next-line react/no-unused-state
        translatedText: fieldTranslation.data.content,
        translationFetched: true
      });
    } catch (e) {
      console.error(e);
      this.setState({
        // eslint-disable-next-line react/no-unused-state
        translatedText: [],
        translationFetched: true
      });
    }
  };

  render() {
    const { children } = this.props;
    const { translationFetched } = this.state;

    return translationFetched && (
      <TranslateContext.Provider value={this.state}>
        { children }
      </TranslateContext.Provider>
    );
  }
}

TranslateProvider.defaultProps = {
  translationTexts: Texts
};

TranslateProvider.propTypes = {
  children: PropTypes.shape().isRequired,
  translationTexts: PropTypes.object
};
