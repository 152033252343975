// import path from 'path';

import get from 'lodash/get';

export const getRouterValue = (key, defaultValue) => {
  return get(window.__jfrouter, key, defaultValue);
};

export const getEnv = () => {
  return getRouterValue('JOTFORM_ENV', 'development');
};

export const getAppPath = () => {
  return getRouterValue('APP_PATH', '/');
};

export const getBaseURL = () => {
  return getRouterValue('BASE_URL', 'http://localhost');
};

export const getAssetPath = () => {
  return getRouterValue('ASSET_PATH', '/');
};

export const getRevision = () => {
  return getRouterValue('REVISION_HASH', '/');
};

export const getPublicURL = () => {
  return `${getBaseURL()}${getAssetPath()}`;
};

export const getCDNURL = () => {
  return getRouterValue('CDN_URL', getPublicURL());
};

// export const getAssetURL = assetPath => {
//   return `${getBaseURL()}${path.join(getAssetPath(), assetPath)}`;
// };

export const getAppURL = () => {
  return `${getBaseURL()}${getAppPath()}`;
};

export const getRoutePath = () => {
  return window.location.pathname.replace(getAppPath(), '');
};

export const getActiveAlias = () => {
  return getRouterValue('ACTIVE_ALIAS', '');
};

export const getActiveApp = () => {
  return getRouterValue('ACTIVE_APP', '');
};

export const getInitialRoutePath = () => {
  return getRouterValue('INITIAL_ROUTE_PATH', '');
};

export const getFullPath = () => {
  return getRouterValue('FULL_PATH', '');
};

export const isHIPAA = () => {
  return getRouterValue('IS_HIPAA', '0') === '1';
};

export const isEU = () => {
  return getRouterValue('IS_EU', '0') === '1';
};

export const isSSO = () => {
  return getRouterValue('IS_SSO', '0') === '1';
};

export const getRootPath = () => {
  return getRouterValue('ROOT_PATH', '');
};
