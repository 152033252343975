import {
  RequestInterceptor, ResponseInterceptor
} from '../../types';
import { engineResponseNormalizer, isCrossOrigin, objToFormData } from './utils';
import { salesforceResponseFailedInterceptors } from './platform/salesforceInterceptors';

export const requestUrlInterceptor: RequestInterceptor = ctx => {
  const { url, config } = ctx;

  // ignore base url if req starts with different origin
  // or it starts with a protocol
  if (url.startsWith('/') || url.startsWith('https://') || url.startsWith('http://')) {
    config.baseURL = '';
  }
  // attach apiKey header if it exists
  const apiKey = ctx.interceptorConfig?.apiKey;
  if (apiKey) {
    ctx.config.headers = {
      apiKey,
      ...ctx.config.headers
    };
  }

  if (config.method === 'get' && ctx.data) {
    ctx.config.params = { ...(ctx.config.params as Record<string, unknown>), ...(ctx.data as Record<string, unknown>) };
    ctx.data = null;
  }
};

// xml requests are heavily used in form-builder
// this interceptor does the job of reqwest library
export const requestXMLInterceptor: RequestInterceptor = ctx => {
  if (ctx.interceptorConfig?.isFormData) {
    ctx.config.headers = {
      Accept: '*/*',
      ...ctx.config.headers
    };

    if (ctx.data) {
      ctx.data = objToFormData(ctx.data as Record<string, Record<string, unknown>>);
    }
  }
};

export const requestNormalizer: RequestInterceptor = ctx => {
  const { config } = ctx;
  if (ctx.interceptorConfig?.teamID) {
    if (isCrossOrigin(ctx.url) || config.disableTeamIDHeader) {
      return;
    }

    config.headers = { 'jf-team-id': ctx.interceptorConfig.teamID, ...config.headers };
  }
};

export const responseNormalizer: ResponseInterceptor = ctx => {
  engineResponseNormalizer(ctx);
};

export const requestInterceptors = [requestUrlInterceptor, requestXMLInterceptor, requestNormalizer];
export const responseInterceptors = [responseNormalizer];

export const defaultRequestInterceptors = [];
export const defaultResponseSuccessInterceptors = [];
export const defaultResponseFailedInterceptors = [...salesforceResponseFailedInterceptors];
