import { cva } from 'class-variance-authority';
import { primary } from '../../tokens/colors';
import { CVAType } from '../../types/system.types';
import { IndicatorProps } from './indicator.types';

// // CVA Types
type IndicatorCVAType = CVAType<Pick<IndicatorProps, 'size' | 'colorStyle' | 'invert'> & { hasChildren?: boolean }>

// Classes
export const indicatorCVA = cva<IndicatorCVAType>(
  'magnet-pill inline-flex radius-full', {
    variants: {
      size: {},
      colorStyle: {},
      hasChildren: {
        true: 'justify-center items-center font-bold'
      },
      invert: {
        true: 'bg-white'
      }
    },
    compoundVariants: [
      /*
        Added width & height classes for sizes if component has children
      */
      {
        hasChildren: false,
        size: 'small',
        class: 'w-2 h-2'
      },
      {
        hasChildren: false,
        size: 'medium',
        class: 'w-2.5 h-2.5'
      },
      {
        hasChildren: false,
        size: 'large',
        class: 'w-3 h-3'
      },
      /*
        Added width, height, padding, text  classes for sizes if component has no children
      */
      {
        hasChildren: true,
        size: 'small',
        class: 'min-w-4 h-4 px-1 text-xxs'
      },
      {
        hasChildren: true,
        size: 'medium',
        class: 'min-w-5 h-5 px-1.5 text-xs'
      },
      {
        hasChildren: true,
        size: 'large',
        class: 'min-w-6 h-6 px-2 text-sm'
      },
      /*
        Added color classes for all colorStyles for those with
        `invert` false & `hasChildren` true
      */
      {
        colorStyle: ['primary', 'success', 'error'],
        invert: false,
        hasChildren: true,
        class: 'color-white'
      },
      {
        colorStyle: 'secondary',
        hasChildren: true,
        class: primary.text.darkest.base
      },
      /*
        Added bg classes for each colorStyles for those with
        `current` false
      */
      {
        colorStyle: 'primary',
        invert: false,
        class: primary.accent.default.bg.base
      },
      {
        colorStyle: 'secondary',
        invert: false,
        class: primary.background.light.base
      },
      {
        colorStyle: 'success',
        invert: false,
        class: primary.success.default.bg.base
      },
      {
        colorStyle: 'error',
        invert: false,
        class: primary.error.default.bg.base
      },
      /*
        Added color classes for each colorStyles for those with
        `invert` true (mostly for usage with button)
      */
      {
        colorStyle: 'primary',
        invert: true,
        class: primary.accent.default.color.base
      },
      {
        colorStyle: 'secondary',
        invert: true,
        class: primary.text.dark.base
      },
      {
        colorStyle: 'success',
        invert: true,
        class: primary.success.default.color.base
      },
      {
        colorStyle: 'error',
        invert: true,
        class: primary.error.default.color.base
      }
    ]
  }
);
