// Radio & Checkbox & ChoiceLabel
export const CHOICE_TRUNCATE_VALUES = ['both', 'description', 'label'] as const;

export const THEME_VALUES = ['dark', 'light'] as const;
export const SIZE_VALUES = ['small', 'medium', 'large'] as const;
export const BREAKPOINT_VALUES = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'] as const;
export const PRODUCT_NAME_VALUES = ['teams', 'pdf', 'apps', 'reports', 'forms', 'sign', 'tables', 'inbox', 'approvals', 'analytics', 'pages'] as const;
export const RESPONSIVE_BREAKPOINT_VALUES = ['base', ...BREAKPOINT_VALUES] as const;
export const COLOR_STYLE_VALUES = ['primary', 'success', 'error'] as const;

// Form Controls
export const FORM_CONTROL_COLOR_STYLE_VALUES = ['default', 'primary', 'success', 'warning', 'error'] as const;
export const BASE_INPUT_ADDON_DIRECTION_VALUES = ['start', 'end'] as const;
export const BASE_INPUT_ADDON_VARIANT_VALUES = ['filled', 'ghost'] as const;

// Flex & Grid
export const JUSTIFY_CONTENT_VALUES = ['start', 'end', 'center', 'between', 'around', 'evenly'] as const;
export const ALIGN_ITEMS_VALUES = ['start', 'end', 'center', 'stretch', 'baseline'] as const;
export const FLEX_DIRECTION_VALUES = ['row', 'column', 'row-reverse', 'column-reverse'] as const;
export const FLEX_WRAP_VALUES = ['wrap', 'nowrap'] as const;
export const FLEX_GROW_VALUES = ['0', '1'] as const;
export const FLEX_SHRINK_VALUES = ['0', '1'] as const;
export const ORDER_VALUES = ['none', 'first', 'last'] as const;
export const ALIGN_SELF_VALUES = ['auto', 'center', 'start', 'end', 'stretch', 'baseline'] as const;
export const JUSTIFY_SELF_VALUES = ['auto', 'center', 'start', 'end', 'stretch'] as const;

export const GRID_TRACK_VALUES = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'] as const;
export const GRID_TEMPLATE_COLUMNS_VALUES = ['none', ...GRID_TRACK_VALUES] as const;
export const GRID_ITEM_START_END_VALUES = ['auto', ...GRID_TRACK_VALUES] as const;
