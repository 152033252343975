import React from 'react';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import { t } from '@jotforminc/translation';
import { emailAnswerFinder } from '@jotforminc/utils';
import SavedDraftIcon from '../assets/svg/sacl_saved_draft.svg';
import ResendIcon from '../assets/svg/resend.svg';
import SharableLinkButton from './SharableLinkButton';

export default class SaclSuccess extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isTooltipVisible: false
    };
    this.handleResendEmail = debounce(this.handleResendEmail, 3000, { leading: true });
  }

  componentDidMount() {
    const { manager } = this.props;
    const initialEmail = manager.getEmailSentTo();
    const email = initialEmail || emailAnswerFinder();
    if (email) {
      manager.setHiddenEmailSentTo(email);
    }
    if (!initialEmail && email) {
      manager.sendEmail(email);
    }
  }

  componentWillUnmount() {
    if (this.tooltipTimeout) {
      clearTimeout(this.tooltipTimeout);
    }
  }

  handleResendEmail = () => {
    const { manager } = this.props;
    const emailAddress = emailAnswerFinder();
    if (!emailAddress) {
      console.error('Email not found on SCL, not possible to send.');
    }
    this.setState({
      isTooltipVisible: true,
      message: `${t('Email sent to')}: ${emailAddress}`// TODO: translate
    }, () => {
      this.tooltipTimeout = setTimeout(() => {
        this.setState({
          isTooltipVisible: false
        });
      }, 3000);
    });
    manager.sendEmail(emailAddress);
  };

  render() {
    const { isTooltipVisible, message } = this.state;
    const {
      manager, onUserLogout, isHIPAA, isLoggedIn
    } = this.props;
    const isOpenedInPortal = window?.JFAppsManager?.isOpenedInPortal();
    return (
      <div className="xcl-content">
        <textarea aria-hidden="true" contentEditable className="js-continueLaterLink sacl-textarea isHidden" />
        <span className="icon_savedDraft">
          <SavedDraftIcon role="presentation" />
        </span>
        <h3 className="m3t">{t('Your form has been saved as a draft.')}</h3>
        <p>{t('We’ve sent you an email with a link to continue your form.')}</p>
        {
          isHIPAA && (
            <div className="sacl-hipaa-warning">
              <svg
                xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                viewBox="0 0 18 18"
              >
                <g fill="none" fillRule="evenodd">
                  <circle
                    cx="9" cy="9" r="9"
                    fill="#48729A"
                  />
                  <g fill="#FFF" transform="translate(8.1 4.5)">
                    <path d="M0 0H2.373V5.932H0z" />
                    <circle cx="1.186" cy="8.305" r="1.186" />
                  </g>
                </g>
              </svg>
              {t('The form owner will be able to see your saved data.')}
            </div>
          )
        }
        <div className="xcl-esb">
          <button className={`xcl-button-re u-tooltipTrigger ${isTooltipVisible ? 'isActive' : ''}`} type="button" onClick={this.handleResendEmail}>
            <ResendIcon />
            {t('Resend Email')}
            {isTooltipVisible && (
              <span className="jfTooltip u-neutral u-southWest u-tethered saclLinkButton-tooltip">
                {message}
              </span>
            )}
          </button>
          <SharableLinkButton buttonMode="standard" manager={manager} />
          {isLoggedIn && !isOpenedInPortal && (
            <a
              className="xcl-link-lg"
              role="button"
              href="#"
              onClick={onUserLogout}
            >
              {t('Logout')}
            </a>
          )}
        </div>
      </div>
    );
  }
}

SaclSuccess.propTypes = {
  manager: PropTypes.shape().isRequired,
  onUserLogout: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  isHIPAA: PropTypes.bool.isRequired
};
