import React, { useState } from 'react';
import { func, shape } from 'prop-types';
import { t } from '@jotforminc/translation';

import '../styles/social-terms-step.scss';
import { isTurkishLanguage } from '../utils/loginSignup';
import TermsAndConditionsUrls from './TermsAndConditionsUrls';

const SocialTermsStep = ({
  user,
  socialUserProfile,
  onSocialTermsContinue
}) => {
  const [isTermsAgreed, setIsTermsAgreed] = useState(false);
  const [termsError, setTermsError] = useState(false);
  const { fullName, email, imageUrl } = socialUserProfile;
  const isTurkish = isTurkishLanguage(user);

  const handleContinue = async () => {
    if (isTermsAgreed) {
      onSocialTermsContinue(isTermsAgreed);
    } else {
      setTermsError(true);
    }
  };

  const handleCheckboxChange = ({ target: { checked = false } }) => {
    setIsTermsAgreed(checked);
    if (checked) {
      setTermsError(false);
    }
  };

  return (
    <div className='social-terms-content'>
      <h4 className='social-terms-content-title'>{t('Terms and Privacy')}</h4>
      <div className='content'>
        <div className='userWrapper'>
          <div className='userVisual'>
            {imageUrl && <img src={imageUrl} alt={fullName} className='userVisual' />}
          </div>
          <div className='userInfo'>
            <span className='userName'>
              <strong>
                {t('Name')}
                :
              </strong>
              <span>{fullName}</span>
            </span>
            <span className='userEmail'>
              <strong>
                {t('Email')}
                :
              </strong>
              <span>{email}</span>
            </span>
          </div>
        </div>
        <label className="acceptTerms" htmlFor="acceptTerms">
          <input
            id="acceptTerms"
            type="checkbox"
            checked={isTermsAgreed}
            onChange={handleCheckboxChange}
            className="acceptTerms-input"
          />
          <span className="acceptTerms-checkbox" />
          <span className="acceptTerms-text">
            <TermsAndConditionsUrls isTurkish={isTurkish} />
          </span>
        </label>
        {termsError && <span className='acceptTerms-error'>{t('Please agree to continue.')}</span>}
      </div>
      <div className='social-terms-footer'>
        <button type="button" className='btn' onClick={handleContinue}>{t('CONTINUE')}</button>
      </div>
    </div>
  );
};

SocialTermsStep.propTypes = {
  user: shape({}),
  socialUserProfile: shape({}),
  onSocialTermsContinue: func
};

SocialTermsStep.defaultProps = {
  user: {},
  socialUserProfile: {},
  onSocialTermsContinue: func
};

export default SocialTermsStep;
