import auth0 from './auth0-logo-icon.svg';
import azuread from './azure-logo-icon.svg';
import google from './google-logo-icon.svg';
import windowsad from './microsoft-logo-icon.svg';
import okta from './okta-logo-icon.svg';
import onelogin from './onelogin-logo-icon.svg';
import custom from './private-logo-icon.svg';

export const idpLogos = {
  auth0,
  azuread,
  google,
  windowsad,
  okta,
  onelogin,
  custom
};
