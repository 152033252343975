import Adapter from './Adapter';
import { isCardForm, keyGrapper } from '../utils';

function matrixGrapper(values, qid, isDynamic = false) {
  if (!values) return [];

  const MATRIX = {};

  if (isDynamic) {
    const getSize = () => {
      const container = document.getElementById(`id_${qid}`);
      if (!container) return 0;
      if (isCardForm()) {
        return Array.from(container.querySelectorAll('[role="row"]')).length - 1;
      }
      return Array.from(container.querySelectorAll('tr.form-matrix-value-tr')).length;
    };

    const SIZE = getSize();

    // Create Empty Matrix
    for (let i = 0; i < SIZE; i++) {
      const row = [];
      for (let j = 0; j < SIZE; j++) {
        row.push('');
      }
      MATRIX[i] = row;
    }

    const getIndexes = () => {
      return values.map(({ name }) => {
        const charIndex = char => name.lastIndexOf(char);
        return name.substring(charIndex(']'), charIndex('[')).slice(1);
      });
    };

    const INDEXES = getIndexes();

    values.forEach(({ name, value }, i) => {
      let key = name;
      if (name.includes('[')) {
        key = name.split('[')[1].slice(0, -1);
      }
      if (key === 'rowIds' || key === 'colIds') {
        MATRIX[key] = JSON.parse(value);
      } else if (!Number.isNaN(parseInt(key, 10))) {
        MATRIX[key][INDEXES[i]] = value;
      }
    });

    return MATRIX;
  }

  let valueArray = [];
  let lastKey = '0';
  // Extended key grapper

  values.forEach(({ name, value }) => {
    let key = name;
    if (name.includes('[')) {
      key = name.split('[')[1].slice(0, -1);
    }

    if (key !== lastKey) {
      MATRIX[lastKey] = valueArray;
      lastKey = key;
      valueArray = [];
      valueArray.push(value);
    } else {
      valueArray.push(value);
    }
  });

  MATRIX[lastKey] = valueArray;
  return MATRIX;
}

export default class InputTableAdapter extends Adapter {
  getValue(values) {
    // Card Form Input Table Data Types
    const InputTableTypes = {
      RADIO: 'Radio Button',
      DYNAMIC: 'Dynamic',
      CHECKBOX: 'Check Box',
      EMOJI_SLIDER: 'Emoji Slider',
      SLIDER: 'Slider',
      DROPDOWN: 'Drop Down',
      YESNO: 'Yes No',
      NUMERIC: 'Numeric Text Box',
      TEXT: 'Text Box',
      CURRENCY: 'Currency Box'
    };

    const getMatrixType = () => {
      const matrix = document.getElementById(`matrix_${this.qid}`);
      if (matrix) {
        return matrix.dataset?.type;
      }
      return '';
    };

    const isRadioTable = () => {
      const container = document.getElementById(`id_${this.qid}`);
      const table = container.querySelector('table');
      return table.querySelectorAll('td.form-matrix-values')?.length === table.querySelectorAll('input[type="radio"')?.length;
    };

    const isDynamicTable = () => {
      const container = document.getElementById(`id_${this.qid}`);
      const table = container.querySelector('table');
      return table.dataset.dynamic === 'true';
    };

    const makeResponse = (data, extraData) => ({ data, extraData });

    if (isCardForm()) {
      const {
        EMOJI_SLIDER, SLIDER, YESNO, RADIO, CHECKBOX, TEXT, CURRENCY, NUMERIC, DROPDOWN, DYNAMIC
      } = InputTableTypes;

      const matrixType = getMatrixType();
      switch (matrixType) {
        case EMOJI_SLIDER:
        case SLIDER:
          return makeResponse(keyGrapper(values), { inputType: matrixType });
        case YESNO:
        case RADIO:
          return makeResponse(keyGrapper(values));
        case CHECKBOX:
        case TEXT:
        case CURRENCY:
        case NUMERIC:
        case DROPDOWN:
        case DYNAMIC:
          const isDynamic = matrixType === DYNAMIC;
          return makeResponse(matrixGrapper(values, this.qid, isDynamic), isDynamic ? { inputType: 'Dynamic' } : {});
        default:
          return makeResponse([]);
      }
    } else if (isRadioTable() && !isDynamicTable()) {
      return makeResponse(keyGrapper(values));
    } else {
      return makeResponse(matrixGrapper(values, this.qid, isDynamicTable()), isDynamicTable() ? { inputType: 'Dynamic' } : {});
    }
  }

  setValue(value) {
    return super.setValue(value);
  }
}
