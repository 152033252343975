import React from 'react';
import { nanoid } from 'nanoid';

import { KEY_KEYCODE_LIST } from './constants';

export const wrapWithComp = (child, type, Comp = 'span') => {
  if (typeof child === 'string') {
    return <Comp data-type={type}>{child}</Comp>;
  }
  return child;
};

// Expects event as parameter
export const prepareKeyList = keyList => Object.keys(KEY_KEYCODE_LIST).filter(key => keyList.indexOf(key) > -1).map(key => ({ key, keyCode: KEY_KEYCODE_LIST[key] }));
export const isPressedKeyFN = keyList => {
  const preparedKeyList = prepareKeyList(keyList);
  return ({ key, keyCode }) => preparedKeyList.filter(({ key: expectedKey, keyCode: expectedKeyCode }) => (expectedKey === key || expectedKeyCode === keyCode)).length > 0;
};
export const isPressedKeyEnter = isPressedKeyFN(['Enter']);
export const isPressedKeyBackspace = isPressedKeyFN(['Backspace']);
export const isPressedKeyTab = isPressedKeyFN(['Tab']);
export const isPressedKeyPillboxAddKey = isPressedKeyFN(['Enter', 'Comma', 'Space', 'Tab']);
export const isPressedKeyEnterOrSpace = isPressedKeyFN(['Enter', 'Space']);
export const isPressedKeyEscape = isPressedKeyFN(['Escape']);
export const isPressedKeyOneOf = keyList => isPressedKeyFN(keyList);

export const flattenOptions = options => options.reduce((flattenedOptions, option) => flattenedOptions.concat(option.type === 'group' ? flattenOptions(option.options) : [option]), []);
export const findOptionByValue = (value, options, allowEmpty = false) => {
  if (options.length === 0) {
    return;
  }

  const flattenedOptions = flattenOptions(options);
  const optionsHaveSomeDisabledOptions = flattenedOptions.some(option => option.disabled);
  const dropdownMayBeEmpty = allowEmpty || optionsHaveSomeDisabledOptions; // if options contain any disabled option treat like allowEmpty is true
  const sanitizedOptions = dropdownMayBeEmpty ? flattenedOptions.filter(option => !option.disabled) : flattenedOptions;

  if (!value) {
    return dropdownMayBeEmpty ? undefined : sanitizedOptions[0];
  }
  return sanitizedOptions.find(({ value: optionValue }) => optionValue === value);
};

export const createFileMetadata = ({ name, type, size }, url) => {
  return {
    name, type, size, url
  };
};

export const generateShortID = () => nanoid().split(/^\d/).join('_');
