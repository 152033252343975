/* eslint-disable complexity */
import React, { useState, useRef, useEffect } from 'react';
import {
  shape, bool, string, func, number
} from 'prop-types';
import { t, translationRenderer } from '@jotforminc/translation';
import { Texts, twoFactorTypeKeys } from '@jotforminc/constants';
import { isEnterprise } from '@jotforminc/enterprise-utils';
import { PieTimer } from '@jotforminc/pie-timer';
import { VerificationInput } from '@jotforminc/verification-input';
import { ErrorContainer, InfoBox } from '@jotforminc/two-factor-settings';
import '../styles/twoFactorLogin.scss';
import Checkbox from './Checkbox';
import { LOGIN_SESSION_EXPIRED_MESSAGE } from '../constants';

const TwoFactorLogin = ({
  handleTFALogin,
  onNavigationChangeRequest,
  lastTFAResponse: {
    token: {
      type,
      target,
      remaining_time: remainingTime,
      total_time: totalTime,
      expires_at: expiresAt,
      isForced,
      username
    },
    showRememberDevice
  },
  thereSocialError,
  thereErrorMessage,
  isSocialTFA,
  tfaMethod
}) => {
  const [verificationCode, setVerificationCode] = useState('');
  const [timerRunning, setTimerRunning] = useState('');
  const [isCodeFilled, setCodeFilled] = useState(false);
  const [isLoginLoading, setIsLoginLoading] = useState(false);
  const [rememberDevice, setRememberDevice] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const wrongAttemptRef = useRef(0);
  const timerRef = useRef(null);
  const firstVerifyRef = useRef(true);
  const isPassiveTFAMethod = (tfaMethod === 'recovery' || type === twoFactorTypeKeys.authenticatorApp);
  const showRememberDeviceSection = (isEnterprise() ? showRememberDevice || false : true) && tfaMethod !== 'recovery';

  const ActionRecorder = window.actionRecorder ? window.actionRecorder : null;

  useEffect(() => {
    setVerificationCode('');
  }, [tfaMethod]);

  useEffect(() => {
    if (verificationCode.length < 6) {
      setCodeFilled(false);
    } else {
      setCodeFilled(true);
    }
  }, [verificationCode]);

  useEffect(() => {
    if (!isPassiveTFAMethod) {
      timerRef.current.stop();
      timerRef.current.start(remainingTime, totalTime);
    }

    return () => timerRef.current && timerRef.current.stop();
  }, [expiresAt, type]);

  const sendWrongAttempAction = (action, actionTarget) => {
    const actor = username || '';
    if (wrongAttemptRef.current > 0 && tfaMethod !== 'recovery' && ActionRecorder) {
      ActionRecorder.tick({ actor, action: action, target: actionTarget });
      wrongAttemptRef.current = 0;
    }
  };

  const handleLogin = async args => {
    const { verificationCode: code = false } = args;
    if (isLoginLoading) return;
    setErrorMessage('');

    if (typeof code === 'string' && code.length === 0) {
      setErrorMessage(t(Texts.BLANK_VERIFICATION_CODE));
      return;
    }

    if (code.length < 6) {
      setErrorMessage('');
      return;
    }

    sendWrongAttempAction(`wrongAttempt-${wrongAttemptRef.current}`, 'twoFactorLoginVerificationInput');

    try {
      setIsLoginLoading(true);
      const response = await handleTFALogin(args);
      const { data: { error = '' } = {}, message = '' } = response?.[0];

      if (error && /Login failed - The username or password you entered is incorrect. Please try again./.test(error)) {
        setErrorMessage(LOGIN_SESSION_EXPIRED_MESSAGE);
      } else if (error || message) {
        setErrorMessage(t(error || message));
      }
      setIsLoginLoading(false);
    } catch (e) {
      // TO DO handleError;
    }
  };

  const handleCodeChange = val => {
    setVerificationCode(val.toUpperCase());
    if (errorMessage) {
      setErrorMessage('');
    }
  };

  const handleKeyDown = event => {
    if (tfaMethod !== 'recovery' && /^[a-zA-Z]+$/.test(event.key) && event.key.length === 1 && !event.ctrlKey && !event.altKey && !event.metaKey) {
      wrongAttemptRef.current = wrongAttemptRef.current + 1;
    }
  };

  const handlePaste = event => {
    const clipboardData = event.clipboardData || window.clipboardData;
    if (/[A-Za-z]/.test(clipboardData.getData('Text'))) {
      wrongAttemptRef.current = wrongAttemptRef.current + 1;
    }
  };

  const clickOutsideEvent = event => {
    if (event.target.closest('#exitModal')) return false;
    if (event.target.closest('.modalView .jfHeader-modalViewContent')) return true;
    if (document.querySelector('.xcl-content') && !document.contains(event.target)) {
      return false;
    }
    sendWrongAttempAction(`wrongAttempt-${wrongAttemptRef.current}`, 'twoFactorLoginVerificationInput');
  };

  const keydownEscapeEvent = e => {
    if (e.key === 'Escape' || e.keyCode === 27) {
      sendWrongAttempAction(`wrongAttempt-${wrongAttemptRef.current}`, 'twoFactorLoginVerificationInput');
    }
  };

  useEffect(() => {
    if (verificationCode.length === 6 && firstVerifyRef.current) {
      firstVerifyRef.current = false;
      handleLogin({
        verificationCode, isSocialTFA, newType: tfaMethod, rememberDevice: rememberDevice ? '1' : '0'
      });
    }
  }, [verificationCode]);

  useEffect(() => {
    const listingPortal = document.getElementById('listing-portal-root');
    if (listingPortal) {
      listingPortal.classList.add('hide-for-2fa');
    }

    const authFormCloseEl = document.querySelector('.jfHeader-authForm-close.newModalInlineButton');
    if (authFormCloseEl) {
      authFormCloseEl.addEventListener('click', () => {
        sendWrongAttempAction(`wrongAttempt-${wrongAttemptRef.current}`, 'twoFactorLoginVerificationInput');
      });
      document.addEventListener('click', clickOutsideEvent, false);
      document.addEventListener('keydown', keydownEscapeEvent, false);
    }

    return () => {
      sendWrongAttempAction(`wrongAttempt-${wrongAttemptRef.current}`, 'twoFactorLoginVerificationInput');

      if (authFormCloseEl) {
        authFormCloseEl.removeEventListener('click', sendWrongAttempAction);
        document.removeEventListener('click', clickOutsideEvent, false);
        document.removeEventListener('keydown', keydownEscapeEvent, false);
      }
    };
  }, []);

  return (
    <div className="xcl-content xcl-nb-content twoFactorLogin forAuth">
      <h3 className="m2bt tfa-title">{t(Texts.VERIFY_ITS_YOU)}</h3>
      {isForced && (
        <div className="xcl-cw">
          <InfoBox>
            <p>
              {t(Texts.REACH_DAILY_LIMIT_FORCE_TO_EMAIL)}
            </p>
          </InfoBox>
        </div>
      )}
      <div className="xcl-cw tfa-description">
        { /* eslint-disable-next-line no-nested-ternary */}
        {tfaMethod === 'recovery' ? (
          t(Texts.ENTER_RECOVERY_CODES)
        ) : (
          translationRenderer(Texts.TWO_FACTOR_DESCRIPTION[type])({
            renderer1: () => <b>{target}</b>
          })
        )}
      </div>
      <div className="xcl-cw">
        <div className="xcl-form forLogin js-loginForm">
          <div className="xcl-field-wr">
            <label className="xcl-lbl" htmlFor="tfatoken">
              {t(tfaMethod === 'recovery' ? Texts.RECOVERY_CODE : Texts.VERIFICATION_CODE)}
              <VerificationInput
                value={verificationCode}
                inputType={tfaMethod === 'recovery' ? '' : 'numeric'}
                validChars={tfaMethod === 'recovery' ? 'A-Za-z0-9' : '0-9'}
                onCodeChange={handleCodeChange}
                inputId="tfatoken"
                characterClassName={`character ${errorMessage ? 'hasError' : ''}`}
                onEnterPress={() => handleLogin({
                  verificationCode, isSocialTFA, newType: tfaMethod, rememberDevice: rememberDevice ? '1' : '0'
                })}
                {...tfaMethod !== 'recovery' ? { handleKeyDown: handleKeyDown } : {}}
                {...(tfaMethod !== 'recovery') ? { inputProps: { onPaste: handlePaste } } : {}}
              />
            </label>
            {/* { tfaMethod === 'recovery' && (
              <InfoContainer style={{ marginTop: 6 }}>{t(Texts.USED_RECOVERY_CODES)}</InfoContainer>
            )} */}
            { errorMessage && (
              <ErrorContainer style={{ marginTop: 6 }}>{t(errorMessage)}</ErrorContainer>
            )}
            {thereSocialError && (
              <ErrorContainer style={{ marginTop: 6 }}>{t(thereErrorMessage.response.data.error)}</ErrorContainer>
            )}
          </div>
          <button
            className="xcl-button-gr forReset"
            onClick={() => {
              handleLogin({
                verificationCode, isSocialTFA, newType: tfaMethod, rememberDevice: rememberDevice ? '1' : '0'
              });
            }}
            type="button"
            disabled={!isCodeFilled || isLoginLoading}
          >
            {isLoginLoading
              ? (
                <div className="button-loader">
                  <div className="button-loader-item" />
                  <div className="button-loader-item" />
                  <div className="button-loader-item" />
                </div>
              )
              : t(Texts.VERIFY_BUTTON_TEXT)}
          </button>
          {showRememberDeviceSection && (
            <div className='remember-session-wrapper'>
              <Checkbox
                checked={rememberDevice}
                onChange={({ target: { checked } }) => setRememberDevice(checked)}
              />
              <span onClick={() => setRememberDevice(currVal => setRememberDevice(!currVal))} onKeyDown={() => {}}>
                {t("Don't ask again on this device.")}
              </span>
            </div>
          )}
          {!isPassiveTFAMethod && (
            <div className="tfa-timerLine">
              <PieTimer
                startCallback={() => setTimerRunning(true)}
                stopCallback={() => setTimerRunning(false)}
                ref={timerRef}
              />
              <p>
                {t(Texts.NO_CODE)}
                <button
                  className="test_sendAgainButton"
                  type="button"
                  onClick={() => handleLogin({ isSocialTFA })}
                  disabled={timerRunning ? 'disabled' : ''}
                >
                  {t(Texts.SEND_AGAIN)}
                </button>
              </p>
            </div>
          )}
          <div className="tfa-separator" />
          <div className="tfa-otherMethodsLine">
            <span className='issue-text'>
              {t(Texts.HAVING_PROBLEMS)}
            </span>
            <button
              className='test_otherMethodsButton'
              type='button'
              onClick={() => onNavigationChangeRequest('twoFactorMethods')}
            >
              {t(Texts.SEE_OTHER_AUTHENTICATION_METHODS)}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

TwoFactorLogin.propTypes = {
  onNavigationChangeRequest: func.isRequired,
  handleTFALogin: func.isRequired,
  lastTFAResponse: shape({
    token: shape({
      type: string,
      target: string,
      remaining_time: string,
      total_time: number,
      expires_at: number,
      isForced: bool,
      username: string
    }),
    showRememberDevice: bool
  }).isRequired,
  thereSocialError: string,
  thereErrorMessage: string,
  tfaMethod: string.isRequired,
  isSocialTFA: bool.isRequired
};

TwoFactorLogin.defaultProps = {
  thereSocialError: '',
  thereErrorMessage: ''
};

export default TwoFactorLogin;
