import React, { Component } from 'react';
import { func } from 'prop-types';
import { t, translationRenderer } from '@jotforminc/translation';
import { Texts } from '@jotforminc/constants';
import { isEnterprise } from '@jotforminc/enterprise-utils';

export default class ResetSuccess extends Component {
  render() {
    const { onNavigationChangeRequest } = this.props;
    return (
      <div className="xcl-content">
        <div className="xcl-cw iar-w">
          <h3 className="m2bt forForgotTitle">{t(Texts.CHECK_EMAIL)}</h3>
          <div className="js-loginForm">
            <div className="iar">
              <p>{t(Texts.PASSWORD_RESET_SENT)}</p>
              <p>{t(Texts.CHECK_SPAM_FOLDER)}</p>
              <p>
                {isEnterprise() ? t(Texts.MORE_HELP_CONTACT_ENTERPRISE) : translationRenderer(Texts.MORE_HELP_CONTACT)({
                  renderer1: str => <a target="_blank" href="https://www.jotform.com/contact/">{str}</a>
                })}
              </p>
            </div>
            <button
              className="xcl-button-gr"
              onClick={() => onNavigationChangeRequest('loginOptions')}
              type="button"
            >
              {t(Texts.OK_TEXT)}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

ResetSuccess.propTypes = {
  onNavigationChangeRequest: func.isRequired
};
