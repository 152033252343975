import { checkOTP } from '../api';
import { ENABLED_PROJECTS } from '../constants';

export const maskEmail = email => email.replace(/^(.{2})([^@]*)(@.+)$/, (m, p1, p2, p3) => (p1.length + p2.length < 3 ? '*'.repeat(p1.length + p2.length) + p3 : p1 + '*'.repeat(p2.length) + p3));

export const isOTPRequired = async ({ username, project }) => {
  if (!ENABLED_PROJECTS.includes(project)) return false;

  const status = await checkOTP({ username, project });
  return status === '0';
};
