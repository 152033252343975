export const PlatformTypes = {
  SALESFORCE: 'salesforce',
  WEEBLY: 'weebly',
  ANYWHERE: 'anywhere',
  DOCUSIGN: 'docusign',
  CANVA: 'canva'
};

export const AllowedPlatforms = [
  PlatformTypes.SALESFORCE,
  PlatformTypes.WEEBLY,
  PlatformTypes.ANYWHERE,
  PlatformTypes.DOCUSIGN,
  PlatformTypes.CANVA
];
