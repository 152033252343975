/* eslint-disable @jotforminc/no-native-button */
import React from 'react';
import '../styles/ssoLoginOptions.scss';

import { t } from '@jotforminc/translation';
import { handleCustomNavigation } from '@jotforminc/utils';
import { Texts, INITIATOR_TYPES } from '@jotforminc/constants';
import classNames from 'classnames';
import {
  arrayOf,
  bool,
  func,
  shape,
  string
} from 'prop-types';
import { fetchUser } from '../api';
import { idpLogos } from '../assets/idp-icons/icons';

const openPopupWindowCentered = (url, windowName, w, h) => {
  const x = window.outerWidth / 2 + window.screenX - w / 2;
  const y = window.outerHeight / 2 + window.screenY - h / 2;
  let features = 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=';
  features = features.concat(w, ', height=').concat(h, ', top=').concat(y, ', left=').concat(x);
  return handleCustomNavigation(url, windowName, true, features);
};

/**
 * Get the logo of the IDP provider.
 */
const getIdpLogo = idpName => {
  if (Object.prototype.hasOwnProperty.call(idpLogos, idpName)) {
    return idpLogos[idpName];
  }
  return idpLogos.custom;
};

/**
 * SSO login success redirection for popup flows
 */
const SUCCESS_REDIRECT = encodeURIComponent('/sso/login-success.html');

const SSOLoginOptions = ({
  initiator,
  ssoLoginOptions,
  onSSOLoginSuccess,
  onSSOLoginFail,
  hideDivider
}) => {
  const compact = ssoLoginOptions.length > 1;

  const onSSOLoginClick = configKey => () => {
    const currentPage = encodeURIComponent(window.location.href);
    const url = `${window.location.origin}/sso?SSOConfigKey=${configKey || ''}`;

    if (initiator === INITIATOR_TYPES.SACL) {
      const loginPopup = openPopupWindowCentered(`${url}&rp=${SUCCESS_REDIRECT}`, 'loginPopup', 400, 500);

      const id = setInterval(() => {
        try {
          if (loginPopup.closed) {
            clearInterval(id);
            fetchUser().then(result => {
              if (result?.content?.account_type?.name === 'GUEST') {
                // Fail state, weird check because Form User may not have
                // account_type. If a downstream issue occurs in the SSO
                // service provider the login will fail.
                onSSOLoginFail();
              } else {
                // Success state
                onSSOLoginSuccess(result);
              }
            }).catch(() => {
              onSSOLoginFail();
            });
          }
        } catch (error) {
          // mainly for CORS errors
        }
      }, 500);
    } else {
      handleCustomNavigation(`${url}&rp=${currentPage}`, '_self', true);
    }
  };

  return (
    <>
      {compact && <p className="multiple-sso-description">{t('Log in with')}</p>}
      <ul className={classNames('sso-option-list', { compact })}>
        {ssoLoginOptions.map(({ name, configKey, idpName }) => {
          const IDPLogo = getIdpLogo(idpName);
          return (
            <li key={name} className="sso-option-list-item">
              <button
                type="button"
                className="sso-option-link"
                onClick={onSSOLoginClick(configKey)}
              >
                <IDPLogo className='sso-option-logo' />
                {
                  compact ? (
                    <strong className="sso-option-link-text">{name}</strong>
                  ) : (
                    <span className="sso-option-link-text-wrapper">
                      {t('Log in with')}
                      <strong className="sso-option-link-text">{name}</strong>
                    </span>
                  )
                }
              </button>
            </li>
          );
        })}
      </ul>
      {!hideDivider && (
      <div className="xcl-divider">
        <span>{t(Texts.OR_KEY)}</span>
      </div>
      )}
    </>
  );
};

SSOLoginOptions.propTypes = {
  initiator: string.isRequired,
  ssoLoginOptions: arrayOf(shape({
    name: string,
    logoURL: string,
    configKey: string,
    idpName: string
  })).isRequired,
  onSSOLoginSuccess: func.isRequired,
  onSSOLoginFail: func.isRequired,
  hideDivider: bool
};

SSOLoginOptions.defaultProps = {
  hideDivider: false
};

export default SSOLoginOptions;
