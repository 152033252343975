// Clear translation visibility classes
export const clearTranslationMarkup = () => {
  // Get elements with the class
  const elements = global.document.body.querySelectorAll('.js-translationBlocker');

  // Get direct parents of translation texts
  const textParents = global.document.body.querySelectorAll('.js-translationText');

  // Get select parents of translation texts
  const selectParents = global.document.body.querySelectorAll('.js-translationSelect');

  // Convert node list to array and remove class from each element
  Object.values(elements).forEach(el => el && el.classList && el.classList.remove('js-translationBlocker'));
  // Object.values(textParents).forEach(el => el && el.classList && el.classList.remove('js-translationText'));
  Object.values(textParents).forEach(el => {
    // eslint-disable-next-line no-unused-expressions
    if (el && el.classList) {
      const elc = el;
      elc.style.outline = 'none';
      elc.style.position = 'initial';
      elc.style.zIndex = 'initial';
      el.classList.remove('js-translationText');
    }
  });
  Object.values(selectParents).forEach(el => {
    // Return if not a valid dom element
    if (!el || !el.classList) {
      return false;
    }

    // Get original size and multiple values of element if there were exist
    const originalSize = el.getAttribute('data-tempsize');
    const originalMultiple = el.getAttribute('data-tempmultiple');

    // Remove temporary attributes
    el.removeAttribute('data-tempsize');
    el.removeAttribute('data-tempmultiple');

    // If any one of them was setted then add them again; otherwise remove them
    if (originalSize !== 'null') {
      el.setAttribute('size', originalSize);
    } else {
      el.removeAttribute('size');
    }

    if (originalMultiple !== 'null') {
      el.setAttribute('multiple', originalMultiple);
    } else {
      el.removeAttribute('multiple');
    }

    // Lastly remove translation class
    el.classList.remove('js-translationSelect');
  });
};

export const addTranslationMarkup = (el, initial = true) => {
  // If element is body or global then no need to process further
  if (el === global || el === global.document || el === global.document.body) return true;

  // If element or style belongs to it is undefined then terminate the process
  if (!el) return false;

  // Put text class at initial call
  if (initial) {
    el.parentElement.classList.add('js-translationText');

    const parent = el.parentElement;
    parent.style.outline = '3px solid #F00';
    parent.style.position = 'relative';
    parent.style.zIndex = '1000';
  }

  // Process if style is defined
  if (el.style) {
    // Get current style values of the element
    const currentStyle = global.getComputedStyle(el);

    // Check if one of the invisibility requirements it satisfied
    if (currentStyle.display === 'none' || currentStyle.opacity === '0' || currentStyle.visibility === 'hidden' || currentStyle.maxHeight === '0px') {
      // Add visibility class of translation
      el.classList.add('js-translationBlocker');
    }
  }

  if (el.tagName === 'SELECT') { // Handle select to show all options
    // Keep size and multiple attributes temporarily
    el.setAttribute('data-tempsize', el.getAttribute('size'));
    el.setAttribute('data-tempmultiple', el.getAttribute('multiple'));

    // Add size attribute temporarly to show all options
    el.setAttribute('size', el.length);
    el.setAttribute('multiple', 'multiple');

    // Add translation class to set height and mark select as dirty to clear later
    el.classList.add('js-translationSelect');
  }

  return addTranslationMarkup(el.parentElement, false);
};

// Finds elements that contains one of the texts passed
export const searchTextInDOM = (el, texts) => {
  // No need to parse the dom if it belongs to one of the given tags
  if (['SCRIPT', 'SVG', 'IMG', 'STYLE', 'INPUT', 'PATH'].indexOf(el.tagName) !== -1) {
    return null;
  }

  // If classList is defined for given element
  if (el.classList && el.classList.value) {
    // Define classes that we do not want to search
    // const classesToBeFiltered = [/hideOnDesktop/, /hide-on-builder/, /tabMenu/, /toggleText-mobile/, /hide-on-crawler/];
    const classesToBeFiltered = [/hideOnDesktop/, /toggleText-mobile/, /hide-on-crawler/];

    // Check if classList contains one of the unwanted classes
    if (classesToBeFiltered.some(rgx => rgx.test(el.classList.value))) {
      return null;
    }
  }

  // If still a child exist then parse tree deeper
  if (el.childNodes && el.childNodes.length !== 0) {
    return Object.values(el.childNodes) // Convert nodeList to array
      .filter(child => typeof child === 'object') // Remove numbers and functions to take only nodes(objects)
      .map(child => searchTextInDOM(child, texts)) // Call every node element with parseTree (recursive)
      .reduce((prev, curr) => (curr !== null ? prev.concat(curr) : prev), []); // Reduce results to one array
  }

  // Check if text content is one of the strings we are searching
  if (el.textContent && texts.indexOf(el.textContent.trim()) > -1) {
    return el;
  }

  return null;
};

// Checks if element is in viewport
export const isInViewport = el => {
  if (el === null) {
    return false;
  }
  if (typeof el.offsetTop === 'undefined') {
    return isInViewport(el.parentNode);
  }

  // Get recteangle information of element
  const elementRect = el.getBoundingClientRect();

  // Get window height and width
  const windowHeight = global.window.innerHeight;
  const windowWidth = global.window.innerWidth;

  // Get scrolling x and y
  const scrollX = global.window.pageXOffset;
  const scrollY = global.window.pageYOffset;

  // eslint-disable-next-line max-len
  if (elementRect.top + elementRect.height < windowHeight + scrollY && elementRect.left + elementRect.width < windowWidth + scrollX && elementRect.top + elementRect.height > 0 && elementRect.left + elementRect.width > 0) {
    return true;
  // eslint-disable-next-line no-else-return
  } else {
    // eslint-disable-next-line no-lonely-if
    if (global.window.casperJsTest) {
      el.scrollIntoView({ behavior: 'auto', block: 'center' });
      return true;
    // eslint-disable-next-line no-else-return
    } else {
      return false;
    }
  }
};

// Get text contents of dom elements
export const getTextContents = elements => elements.map(el => el.textContent.trim());
