import { Interceptor } from '../types';

export class InterceptorHandler<T> {
  interceptors: Interceptor<T>[];

  constructor(interceptors: InterceptorHandler<T>['interceptors']) {
    this.interceptors = interceptors;
  }

  run(ctx: T): void {
    this.interceptors.forEach(interceptor => {
      interceptor(ctx);
    });
  }
}
