import axios, {
  AxiosInstance, AxiosRequestConfig, AxiosResponse, Method
} from 'axios';
import { CustomConfig, Engine, RequestFunction } from '../types';
import { defaultValidation } from './utils';

export class AxiosEngine implements Engine {
  instance: AxiosInstance;

  public get: RequestFunction;

  public post: RequestFunction;

  public put: RequestFunction;

  public delete: RequestFunction;

  constructor(baseURL: string, config?: CustomConfig) {
    this.instance = axios.create({
      baseURL,
      validateStatus: config?.disableEngineThrowOnError ? () => true : defaultValidation,
      ...config
    });

    this.get = this.createRequestMethod('get');

    this.post = this.createRequestMethod('post');

    this.put = this.createRequestMethod('put');

    this.delete = this.createRequestMethod('delete');
  }

  public createRequestMethod(method: Method): RequestFunction {
    return <T, D = void>(url: string, config?: AxiosRequestConfig, data?: D) => {
      return this.instance.request<T, AxiosResponse<T>, D>({
        url,
        method,
        data,
        ...config
      });
    };
  }
}
