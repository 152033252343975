export const safeJSONParse = (str, defVal = {}, allowObj = false) => {
  if (str) {
    if (typeof str === typeof {} && allowObj) {
      return str;
    }
    try {
      return JSON.parse(str);
    } catch (ex) {
      return defVal;
    }
  } else {
    return defVal;
  }
};

export const getUrlParameter = name => {
  const _name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
  const regex = new RegExp(`[\\?&]${_name}=([^&#]*)`);
  const results = regex.exec(global.location?.search || global.window?.location?.search);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

export const getUrlByCurrentLocale = url => {
  const { currentLocale, languageCodeURLPaths, JOTFORM_ENV } = global;
  if (currentLocale?.length === 5 && currentLocale?.include('-')) {
    const [languageCode] = currentLocale.split('-');
    if (typeof languageCodeURLPaths !== 'object' || Object.values(languageCodeURLPaths).includes(languageCode)) {
      return JOTFORM_ENV === 'PRODUCTION' ? `/${languageCode}${url}` : `https://www.jotform.com/${languageCode}${url}`;
    }
  }
  return JOTFORM_ENV === 'PRODUCTION' ? `${url}` : `https://www.jotform.com${url}`;
};

const arrayMoveMutate = (array, from, to) => {
  const startIndex = to < 0 ? array.length + to : to;
  const item = array.splice(from, 1)[0];
  array.splice(startIndex, 0, item);
};

export const arrayMove = (array, from, to) => {
  const newArray = array.slice();
  arrayMoveMutate(newArray, from, to);
  return newArray;
};

export const serialize = (obj, prefix) => {
  /* eslint-disable */
  const str = [];
  let p;
  for (p in obj) {
    if (obj.hasOwnProperty(p)) {
      const k = prefix ? `${prefix}[${p}]` : p;
      const v = obj[p];
      str.push((v !== null && typeof v === "object") ?
        serialize(v, k) :
        v !== null ? `${encodeURIComponent(k)}=${encodeURIComponent(v)}`: null) ;
    }
  }
  return str.filter(a => a).join("&");
  /* eslint-enable */
};

export const objectEntries = obj => {
  const ownProps = Object.keys(obj);
  let i = ownProps.length;
  const resArray = new Array(i);
  while (i--) {
    resArray[i] = [ownProps[i], obj[ownProps[i]]];
  }
  return resArray;
};

export const hasUrlParamOf = (key, value) => {
  return new URLSearchParams(document?.location?.search).get(key) === value;
};

export const getDeviceType = () => {
  const ua = global.window?.navigator?.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return 'tablet';
  } if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
    return 'mobile';
  }
  return 'desktop';
};
