import { isEnterprise } from '@jotforminc/enterprise-utils';

export const getPublicApiURL = () => {
  const isEUDomain = /(?:eu\.jotform)|(jotformeu\.com)/.test(window.location.host);
  const isHipaaDomain = /(?:hipaa\.jotform)/.test(window.location.host);
  switch (true) {
    case /jotform.pro/.test(window.location.host):
      return '/API';
    case isEUDomain:
      return 'https://eu-api.jotform.com';
    case isHipaaDomain:
      return 'https://hipaa-api.jotform.com';
    case Boolean(window.JotFormAPIEndpoint):
      return window.JotFormAPIEndpoint;
    default:
      return 'https://api.jotform.com';
  }
};

export const getAPIURL = () => {
  // !add temporarily production test check
  if (window.location.host === 'hipaa.jotformers.com') {
    return '/API';
  }

  if (isEnterprise()) {
    return '/API';
  }

  if (/(?:jotform\.ooo)/.test(window.location.host)) {
    return '/API';
  }

  if (/(?:alt\.jotfor\.ms)/.test(window.location.host)) {
    return '/API';
  }

  return getPublicApiURL();
};

export const getCombinedInfoURLByProduct = (resourceID, productType) => {
  const apiURL = getAPIURL();
  let loginURL;
  switch (productType) {
    case 'draft':
    case 'form':
      loginURL = `${apiURL}/formuser/${resourceID}/combinedinfo?master=1`;
      break;
    default:
      loginURL = `${apiURL}/formuser/combinedinfo?master=1`;
      break;
  }
  return window?.JFAppsManager?.isOpenedInPortal() ? `${loginURL}&jotform_pwa=1` : loginURL;
};
