import React, {
  forwardRef, useImperativeHandle, useRef, useState, useEffect
} from 'react';
import {
  bool, node, elementType, string, shape, func, arrayOf, oneOfType, any
} from 'prop-types';
import KeyboardJS from 'keyboardjs';
import { nodeContains } from '@jotforminc/utils';

import Portal from '../Portal';

import { useContinueFocus, useEffectIgnoreFirst } from '../../utils/hooks';
import { generateShortID } from '../../utils';

const Modal = forwardRef(({
  children,
  defaultVisible,
  usePortal,
  portalContainerSelector,
  initialPortalSelector,
  closeOnOutsideClick,
  DialogRenderer,
  HeaderRenderer,
  ContentRenderer,
  FooterRenderer,
  reffedDivProps,
  onModalClose,
  closeOnEscPress,
  exceptionalSelectorList,
  autoFocus,
  currentActiveElementRef,
  t,
  ariaLabel
}, ref) => {
  // Refs
  const portalRef = useRef();

  let selector = "[data-uikit-modal-container='true'],[data-uikitgeneratedportalcontainer='true']";

  if (closeOnOutsideClick && exceptionalSelectorList.length > 0) {
    const additionalSelector = exceptionalSelectorList.join(',');
    selector = `${selector},${additionalSelector}`;
  }
  // States
  const [isVisible, setVisibility] = useState(defaultVisible);
  const hideMenu = event => {
    const containerList = global.document.querySelectorAll(selector);
    const isContained = [...containerList].find(container => nodeContains(container, event.target));
    if (!isContained) {
      setVisibility(false);
    }
  };
  const contentRef = autoFocus ? useContinueFocus(isVisible, currentActiveElementRef) : useRef();
  const hide = () => setVisibility(false);
  const show = () => setVisibility(true);
  const toggleVisibility = () => setVisibility(!isVisible);
  useImperativeHandle(ref, () => ({
    ...portalRef.current,
    hide,
    show,
    toggleVisibility,
    isVisible,
    contentRef
  }));

  useEffect(() => {
    if (closeOnOutsideClick) {
      global.window.addEventListener('mouseup', hideMenu);
      return () => global.window.removeEventListener('mouseup', hideMenu);
    }
  }, [selector]);

  useEffect(() => {
    if (closeOnEscPress) {
      if (isVisible) {
        KeyboardJS.bind('esc', hide);
      } else {
        KeyboardJS.unbind('esc', hide);
      }
    }
    return () => KeyboardJS.unbind('esc', hide);
  }, [closeOnEscPress, isVisible]);

  useEffectIgnoreFirst(() => {
    if (!isVisible) {
      onModalClose();
    }
  }, [isVisible]);

  const headerTitleId = useRef(generateShortID()).current;
  const headerSubTitleId = useRef(generateShortID()).current;

  return (
    <Portal
      ref={portalRef}
      usePortal={usePortal}
      portalAttribute="data-uikit-modal-wrapper"
      containerSelector={portalContainerSelector}
      initialPortalSelector={initialPortalSelector}
    >
      {isVisible && (
        <DialogRenderer>
          <div
            ref={contentRef}
            {...reffedDivProps}
            role="dialog"
            aria-modal="true"
            data-uikit-modal-container
            {...(!ariaLabel ? {
              'aria-labelledby': headerTitleId,
              'aria-describedby': headerSubTitleId
            } : {
              'aria-label': ariaLabel
            })}
            tabIndex="-1"
          >
            <ContentRenderer>
              <HeaderRenderer
                headerTitleId={headerTitleId}
                headerSubTitleId={headerSubTitleId}
                t={t}
              />
              {children}
              <FooterRenderer />
            </ContentRenderer>
          </div>
        </DialogRenderer>
      )}
    </Portal>
  );
});

Modal.propTypes = {
  usePortal: bool,
  defaultVisible: bool,
  children: node.isRequired,
  closeOnOutsideClick: bool,
  DialogRenderer: elementType,
  HeaderRenderer: elementType,
  ContentRenderer: elementType,
  FooterRenderer: elementType,
  portalContainerSelector: string,
  reffedDivProps: shape({}),
  onModalClose: func,
  closeOnEscPress: bool,
  exceptionalSelectorList: arrayOf(string),
  autoFocus: bool,
  currentActiveElementRef: oneOfType([
    func,
    shape({ current: any })
  ]),
  initialPortalSelector: string,
  ariaLabel: string
};

/* eslint react/prop-types: "off" */
Modal.defaultProps = {
  usePortal: false,
  defaultVisible: false,
  closeOnOutsideClick: true,
  portalContainerSelector: 'body',
  DialogRenderer: ({ children }) => (
    <div style={{
      position: 'fixed',
      zIndex: 1,
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      overflow: 'auto',
      backgroundColor: 'rgb(0, 0, 0, 0.4)'
    }}
    >
      {children}
    </div>
  ),
  ContentRenderer: ({ children }) => (
    <div
      style={{
        backgroundColor: '#fefefe',
        margin: '15% auto',
        padding: '20px',
        border: '1px solud #888',
        width: '80%'
      }}
    >
      {children}
    </div>
  ),
  HeaderRenderer: ({ children }) => <div>{children}</div>,
  FooterRenderer: ({ children }) => <div>{children}</div>,
  reffedDivProps: {},
  onModalClose: () => {},
  closeOnEscPress: true,
  exceptionalSelectorList: [],
  autoFocus: true,
  currentActiveElementRef: null,
  initialPortalSelector: '',
  ariaLabel: ''
};

export default Modal;
