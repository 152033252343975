// type InterceptorKeys = keyof DefaultInterceptorsConfig;
// type InterceptorValues = DefaultInterceptorsConfig[InterceptorKeys];

import { CustomConfig, DefaultInterceptorsConfig } from '../layer/types';

export const constructInterceptorConfig = (
  config: CustomConfig,
  interceptorConfig: DefaultInterceptorsConfig | undefined
): DefaultInterceptorsConfig => {
  return { ...interceptorConfig, ...config };

  // later if we want to strict the usage of configs
  // we can only override the settings which are defined on request-layer
  // it can be used instead

  // return (Object.entries(interceptorConfig) as [InterceptorKeys, InterceptorValues][])
  //   .reduce((newConfig, props) => {
  //     const [key, value] = props;
  //     return { ...newConfig, [key]: config[key] ?? value };
  //   }, {} as DefaultInterceptorsConfig);
};
