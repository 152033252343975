import styled, { css } from 'styled-components';

const commonForRadioAndCheck = css`
  width: 16px;
  height: 16px;
  display: block;
  border: 1px solid #a8aab5;
  margin-right: 6px;
  cursor: pointer;
  html[dir="rtl"] & {
    margin-right: 0;
    margin-left: 6px;
  }
`;

export const ScRadio = styled.div`
  ${commonForRadioAndCheck}
  border-radius: 50%;
  transition: all .4s ease;
`;

export const ScCheckbox = styled.div`
  ${commonForRadioAndCheck}
  position: relative;
  border-radius: 2px;
  transition: all .25s ease;

  &:before, &:after {
    display: inline-block;
    content: "";
    position: absolute;
    border-radius: 2px;
    background-color: #fff;
    width: 2px;
    opacity: 0;
    transition: all .25s ease;
  }

  &:before {
    top: 6px; left: 3px; height: 5px;
    transform: rotate(-45deg);
  }

  &:after {
    top: 2px; left: 7px; height: 10px;
    transform: rotate(45deg);
  }
`;

export const ScOptionList = styled.ul`
  padding: .75em 0 0;
  margin: 0;
  list-style: none;

  li + li { margin-top: ${p => {
    if (p.narrowMargins === true) return '8px';
    return (p.multiline === true) ? '1.5em' : '.75em';
  }}; }
`;

export const ScOption = styled.div`
  cursor: pointer;
  display: flex;
  font-size: 1em;
  line-height: 1.5;
  align-items: center;

  .optionIcon {
    color: transparent;
    width: 28px;
    height: 28px;
    margin: 0 18px;
    margin-left: auto;
  }

  ${p => (p.fillColor && `
    background: #FFFFFF;
    border: 1px solid #BCBDD2;
    box-sizing: border-box;
    border-radius: 4px;

    .o-label {
      margin-top: 11px;
      font-size: 16px;
    }
    .o-desc {
      margin-bottom: 14px;
      font-size: 15px;
    }


    &[data-selected=true] {
      .o-label {
        color: ${p.fillColor};
      }
      background: ${p.backgroundColor};
      border-color: ${p.borderColor};
    }

    &[data-selected=true] > svg {
      fill: ${p.fillColor};
    }

    &[data-selected=true] > svg > circle {
      fill: ${p.fillColor};
    }
  `)}

  .o-label { font-weight: ${p => (p.multiline === true ? '700' : '400')}; }
  .o-desc span,
  .o-label span { font-weight: 700; }

  ${ScRadio}, ${ScCheckbox} { flex-shrink: 0; }

  &[data-disabled=true] {
    opacity: .5;

    & > ${ScRadio}, ${ScCheckbox} { cursor: not-allowed; }
  }

  &[data-selected=true] > ${ScRadio} {
    background-color: #4573e3;
    border-color: #4573e3;
    box-shadow: inset 0px 0 0px 3px #dfe8fa;
  }
  @media (hover: hover) {
    &:not([data-selected=true]):not([data-disabled=true]):hover {
      & > ${ScRadio} {
        background-color: rgba(68, 115, 227, 0.3);
        box-shadow: inset 0px 0 0px 3px #fff;
      }

      & > ${ScCheckbox} {
        background-color: rgba(68,115,227,0.3);
        border-color: rgba(68,115,227,0.3);
        &:before, &:after { opacity: 1; }
      }
    }
  }

  &[data-selected=true] > ${ScCheckbox} {
    background-color: #4573e3;
    border-color: #4573e3;

    &:before, &:after { opacity: 1; }
  }

  &:focus { outline: 0; }
  &:focus > ${ScRadio} {
    border-color: #4573e3;
    box-shadow: 0px 0 0px 5px #dfe8fa;
  }
  &[data-selected=true]:focus > ${ScRadio} {
    box-shadow: inset 0px 0 0px 3px #dfe8fa, 0px 0 0px 5px #dfe8fa;
  }

  &:focus > ${ScCheckbox} {
    border-color: #4573e3;
    box-shadow: 0px 0 0px 5px rgba(68,115,227,0.3);
  }

  /* With double line, we increase size for radio/text reatio */
  ${ScRadio} {
    ${p => (p.multiline && `
      width: 18px;
      height: 18px;
      margin-inline-end: 12px;
    `)}
  }
`;
