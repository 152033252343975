import React from 'react';
import {
  bool,
  elementType,
  node,
  string,
  oneOf,
  oneOfType,
  func,
  shape
} from 'prop-types';
import classNames from 'classnames';

import '../styles/_jfTooltip.scss';

const VerticalAttachments = ['Left', 'Right'];
const HorizontalAttachments = ['Top', 'Bottom'];

/**
 * Displays a tooltip
 */
export class Tooltip extends React.Component {
  get wrapperClassNames() {
    const {
      animated,
      wrapperClassName
    } = this.props;

    return classNames(
      'jfTooltip-wrapper',
      wrapperClassName,
      {
        withAnimation: animated
      }
    );
  }

  get containerClassNames() {
    const {
      align,
      attach
    } = this.props;

    return classNames('jfTooltip-container', align ? `align${align}` : null, attach ? `attach${attach}` : null);
  }

  get classNames() {
    const {
      icon
    } = this.props;

    return classNames('jfTooltip', { hasIcon: icon });
  }

  get stateClassNames() {
    const {
      isVisible,
      allowHover,
      noWrap,
      align,
      attach,
      renderInContainer
    } = this.props;

    return classNames(
      {
        isVisible,
        isInContainer: renderInContainer,
        tooltipAllowHover: allowHover,
        tooltipNoWrap: noWrap
      },
      align ? `tooltipAlign${align}` : null,
      attach ? `tooltipAttach${attach}` : null
    );
  }

  renderIcon() {
    const {
      icon
    } = this.props;
    return icon ? <div className="jfTooltip-icon">{icon}</div> : null;
  }

  renderV1() {
    const {
      text,
      onClick,
      backgroundColor,
      color,
      children,
      customProps
    } = this.props;

    return (text || children) ? (
      <div
        className={this.wrapperClassNames}
        onClick={onClick}
        onKeyUp={onClick}
        tabIndex="-1"
        {...customProps}
      >
        <div className={this.containerClassNames}>
          <div className={this.classNames} style={{ backgroundColor }}>
            {this.renderIcon()}
            <div className="jfTooltip-text" style={{ color }}>{children || text}</div>
          </div>
        </div>
        <div className="jfTooltip-arrow" style={{ borderColor: backgroundColor ? `transparent transparent ${backgroundColor} ${backgroundColor}` : null }} />
      </div>
    ) : null;
  }

  renderV2() {
    const {
      v2,
      animated,
      className,
      backgroundColor,
      wrapperClassName,
      isVisible,
      children,
      align,
      attach,
      customProps,
      style,
      allowHover,
      hideArrow,
      tooltipDistance,
      tooltipArrowDistance,
      noWrap,
      ...props
    } = this.props;

    const isVertical = VerticalAttachments.indexOf(attach) >= 0;

    return children ? (
      <div
        className={classNames('jfTooltipNew', className, this.stateClassNames)}
        style={{
          '--tooltipDistance': tooltipDistance,
          '--tooltipArrowDistance': tooltipArrowDistance
        }}
        {...props}
      >
        <div className={classNames('jfTooltipNew-arrow', { isHidden: hideArrow })}>
          <svg
            className="jfTooltipNew-arrow-svg"
            xmlns="http://www.w3.org/2000/svg"
            {...(isVertical ? {
              width: '7',
              height: '16',
              viewBox: '0 0 7 16'
            } : {
              width: '16',
              height: '7',
              viewBox: '0 0 16 7'
            })}
            fill="none"
          >
            <path fill={style?.backgroundColor || 'currentColor'} d={isVertical ? 'M0 8l7-8v16L0 8z' : 'M8 0l8 7H0l8-7z'} />
          </svg>
        </div>
        <div className="jfTooltipNew-body" style={style}>
          {children}
        </div>
      </div>
    ) : null;
  }

  render() {
    const { v2 } = this.props;
    return !v2 ? this.renderV1() : this.renderV2();
  }
}

Tooltip.propTypes = {
  v2: bool,
  className: string,
  children: node,
  icon: oneOfType([
    elementType,
    node
  ]),
  text: string,
  align: oneOf(['Left', 'Center', 'Right', 'Start', 'End']),
  attach: oneOf([...VerticalAttachments, ...HorizontalAttachments]),
  animated: bool,
  onClick: func,
  wrapperClassName: string,
  backgroundColor: string,
  color: string,
  isVisible: bool,
  allowHover: bool,
  hideArrow: bool,
  noWrap: bool,
  renderInContainer: bool,
  tooltipDistance: string,
  tooltipArrowDistance: string,
  style: shape({}),
  customProps: shape({})
};

Tooltip.defaultProps = {
  v2: false,
  className: null,
  children: null,
  icon: null,
  text: null,
  align: 'Center',
  attach: 'Top',
  animated: false,
  onClick: f => f,
  wrapperClassName: null,
  backgroundColor: null,
  color: null,
  isVisible: false,
  allowHover: false,
  hideArrow: false,
  noWrap: false,
  renderInContainer: false,
  tooltipDistance: null,
  tooltipArrowDistance: null,
  style: {},
  customProps: {}
};
