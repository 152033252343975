import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { IconXmarkSm } from '@jotforminc/svg-icons';
import {
  Login, Loading, Success, Error, Guest
} from '.';
import { DOM } from '../constants';

const SaveModal = props => {
  const {
    onClose, onLogin, save, generateLink, sendEmail, logout, user, skipLogin, isHipaa, enforceHIPAARuleSet
  } = props;
  const navigationWrapper = document.getElementsByClassName(DOM.SC_MODAL_CONTAINER_CLASS_NAME)[0];

  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [isSkipped, setIsSkipped] = useState(skipLogin);
  const [retry, setRetry] = useState(0);
  const [isGuestSave, setIsGuestSave] = useState(false);

  useEffect(() => {
    if (user || isSkipped) {
      setIsLoading(true);
      save().catch(() => setIsError(true)).finally(() => setIsLoading(false));
    } else {
      setIsLoading(false);
    }
  }, [user, isSkipped, retry]);

  const handleSkip = () => {
    setIsSkipped(true);
  };

  const handleLogin = userInfo => {
    setIsLoading(true);
    onLogin(userInfo);
  };

  const handleTryAgain = () => {
    setIsLoading(true);
    setIsError(false);
    setRetry(r => r + 1);
  };

  const handleBack = () => {
    setIsLoading(true);
    setIsSkipped(false);
  };

  const handleSendEmail = email => {
    setIsGuestSave(email);
  };

  return (
    <div className="sacl-modal" data-mode="modal">
      <div className="sacl-modal__content">
        <button
          aria-label="Close modal"
          className="sacl-modal__header-btn"
          onClick={onClose}
          type="button"
        >
          <IconXmarkSm />
        </button>
        {isLoading && <Loading /> }
        {isError && <Error onTryAgain={handleTryAgain} />}
        {(user || isGuestSave) && !isLoading && !isError && (
        <Success
          generateLink={generateLink}
          sendEmail={sendEmail}
          logout={logout}
          isHIPAA={isHipaa}
          email={isGuestSave}
        />
        )}
        {
          !user && !isGuestSave && !isLoading && !isError && (!isSkipped
            ? (
              <Login
                onLogin={handleLogin}
                onSkip={handleSkip}
                navigationWrapper={navigationWrapper}
                isHipaa={isHipaa}
                enforceHIPAARuleSet={enforceHIPAARuleSet}
              />
            )
            : <Guest generateLink={generateLink} sendEmail={handleSendEmail} onBack={handleBack} />)
        }
      </div>
    </div>
  );
};

export default SaveModal;

SaveModal.propTypes = {
  user: PropTypes.any.isRequired,
  onClose: PropTypes.func.isRequired,
  onLogin: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  generateLink: PropTypes.func.isRequired,
  sendEmail: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  skipLogin: PropTypes.string,
  isHipaa: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]).isRequired,
  enforceHIPAARuleSet: PropTypes.bool.isRequired
};

SaveModal.defaultProps = {
  skipLogin: null
};
