import {
  ComponentPropsWithoutRef, MutableRefObject, ReactNode
} from 'react';
import {
  Icon,
  Size,
  ColorStyle,
  ProductName
} from '../../constants/common.types';

export type ModalProps = {
  open: boolean,
  onClose: () => void,
  initialFocus?: MutableRefObject<HTMLElement>,
  zIndex?: number,
  ariaLabel: string,
  size?: Size | 'xlarge' | 'xxlarge',
  fitOnMobile?: boolean,
  noFade?: boolean,
};

export type ModalHeaderProps = {
  title: ReactNode,
  description?: ReactNode,
  closeButtonVisible?: boolean,
  closeButtonRef?: MutableRefObject<HTMLElement>,
  onClose: () => void,
  icon?: Icon,
  logoIcon?: boolean,
  iconColorStyle?: ColorStyle | ProductName | 'secondary',
  customIconColorStyle?: string
};

export type ModalBodyProps = ComponentPropsWithoutRef<'div'> & {
  noPadding?: boolean
}

export type ModalActionsProps = ComponentPropsWithoutRef<'div'>;

export const modalBodyDefaultProps:Partial<ModalBodyProps> = {
  noPadding: false
} as const;

export const modalDefaultProps:Partial<ModalProps> = {
  open: false,
  zIndex: 1004,
  size: 'medium',
  noFade: false
} as const;

export const modalHeaderDefaultProps:Partial<ModalHeaderProps> = {
  logoIcon: false
} as const;
