/* eslint-disable no-unused-vars */
import React, {
  forwardRef, useRef, useEffect
} from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { t } from '@jotforminc/translation';
import { IconExclamationCircle } from '@jotforminc/svg-icons';
import { useOnClickOutside } from '@jotforminc/hooks';
import cx from 'classnames';
import { getPortalElement } from '../../utils/helper';

import Close from '../../assets/close.svg';
import '../../styles/ofac-modal.scss';

const OFACModal = forwardRef(({ isModal, email }, ref) => {
  const innerRef = useRef();
  const actor = email || window.user?.username || window.__formInfo?.username || 'unknown';
  const logger = window.JotFormActions ? new window.JotFormActions('ofacModal') : null;

  const unmountModal = () => {
    ReactDOM.unmountComponentAtNode(getPortalElement('ofacModal'));
  };

  const onClose = () => {
    logger?.tick({ actor, action: 'close', target: 'ofacSignupModal' });
    unmountModal();
  };

  useEffect(() => {
    logger?.tick({ actor, action: 'seen', target: 'ofacSignupModal' });
  }, []);

  useOnClickOutside(innerRef, () => unmountModal());

  return (
    <div className={cx('ofac-modal', isModal && 'ofac-modal-wrapper')}>
      <div className="ofac-modal--inner" ref={innerRef}>
        {isModal && (
          <button
            type="button" className="ofac-modal--close" onClick={onClose}
          >
            <Close />
          </button>
        )}
        <div className="ofac-modal--icon">
          <IconExclamationCircle />
        </div>
        <h6 className="ofac-modal--title">
          {t('Sorry, you can’t signup from this country')}
        </h6>
        <div className="ofac-modal--content">
          <p>
            {t('We comply with OFAC’s (The Office of Foreign Assets Control) sanctions and under those sanctions, we no longer serve users from your country.')}
          </p>
          <p>
            {t('Thank you for your interest in Jotform!')}
          </p>
        </div>
        <div className="ofac-modal--buttons">
          <a
            className='ofac-modal--buttons-text-btn'
            type='button'
            href='https://www.jotform.com/contact/?utm_source=jotform&utm_medium=modal&utm_campaign=ofac_modal'
            target='_blank'
          >
            {t('Contact Us')}
          </a>
        </div>
      </div>
    </div>
  );
});

OFACModal.propTypes = {
  isModal: PropTypes.bool,
  email: PropTypes.string
};

OFACModal.defaultProps = {
  isModal: false,
  email: ''
};

export default OFACModal;
