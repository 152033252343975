import React from 'react';
import PropTypes from 'prop-types';
import { t } from '@jotforminc/translation';

import IndicatorIcon from '../assets/svg/iconIndicator.svg';

export default class SaclEpilogue extends React.Component {
  componentDidMount() {
    const { manager } = this.props;
    manager.initiate(this.onManagerSuccess, this.onManagerError, true);
  }

  onManagerSuccess = () => {
    const { onNavigationChangeRequest } = this.props;
    onNavigationChangeRequest('saclSuccess');
  };

  onManagerError = () => {
    const { onNavigationChangeRequest } = this.props;
    onNavigationChangeRequest('saclError');
  };

  render() {
    return (
      <div className="xcl-content">
        <textarea contentEditable className="js-continueLaterLink sacl-textarea isHidden" />
        <span className="icon_loading">
          <IndicatorIcon />
        </span>
        <h3 className="m5t">{t('Loading')}</h3>
      </div>
    );
  }
}

SaclEpilogue.propTypes = {
  manager: PropTypes.shape().isRequired,
  onNavigationChangeRequest: PropTypes.func.isRequired
};
