import React, { PropsWithChildren } from 'react';
import { cx } from 'class-variance-authority';
import { Indicator } from '../Indicator';
import { ButtonTextProps, ButtonIconProps, ButtonIndicatorProps } from './button.types';
import { buttonTextCVA, buttonIconCVA, buttonIndicatorCVA } from './button.cva';

export const ButtonText = (props: PropsWithChildren<ButtonTextProps>): JSX.Element => {
  const {
    size, showTextOnHover, showTextOnly, loader, hasLoaderText, hasChildren, children
  } = props;

  return (
    <span className={cx(buttonTextCVA({
      size, showTextOnHover, showTextOnly, loader, hasLoaderText, hasChildren
    }))}
    >
      {children}
    </span>
  );
};

export const ButtonIcon = (props: ButtonIconProps): JSX.Element => {
  const {
    size, hasLoaderText, loader, icon: Icon
  } = props;

  return <Icon className={cx(buttonIconCVA({ size, hasLoaderText, loader }))} />;
};

export const ButtonIndicator = (props: ButtonIndicatorProps): JSX.Element => {
  const {
    size, loader, indicatorText, colorStyle, variant
  } = props;

  // If color style is not `primary`, `success`, `error`, pill's color always should be `null`
  const indicatorColorStyle = (colorStyle === 'primary' || colorStyle === 'success' || colorStyle === 'error' || colorStyle === 'secondary') ? colorStyle : undefined;

  const indicatorVariant = variant === 'filled';

  return (
    <Indicator
      colorStyle={indicatorColorStyle}
      invert={indicatorVariant}
      className={cx(buttonIndicatorCVA({
        colorStyle,
        loader
      }))}
      size={size}
    >
      {indicatorText}
    </Indicator>
  );
};
