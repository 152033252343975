import { ComponentPropsWithoutRef } from 'react';
import { Size, Theme } from '../../constants/common.types';

export type LoaderProps = ComponentPropsWithoutRef<'div'> & {
  size?: Size,
  theme?: Theme | 'current'
}

export const loaderDefaultProps:Partial<LoaderProps> = {
  size: 'medium',
  theme: 'current'
};
