import React from 'react';
import PropTypes from 'prop-types';
import { t } from '@jotforminc/translation';
import { Texts } from '@jotforminc/constants';
import { IconSalesforceFilled } from '@jotforminc/svg-icons';

import { isPlatformSalesforce, salesforceSocialLoginLogger } from '../utils/helper';
import GoogleButton from './GoogleButton';
import SalesforceButton from './SalesforceButton';
import MicrosoftButton from './MicrosoftButton';
import FacebookButton from './FacebookButton';
import AppleButton from './AppleButton';

import GoogleIcon36 from '../assets/icon_gl_36.svg';
import MicrosoftIcon36 from '../assets/icon_ms_36.svg';
import FacebookIcon36 from '../assets/icon_fb_36.svg';
import AppleIcon36 from '../assets/icon_ap_36.svg';

const RenderSocialButtons = ({
  registerMsAbTestAction,
  showSalesforceLogin,
  onGoogleLoginClick,
  onSalesforceLoginClick,
  onMicrosoftLoginClick,
  onFBLoginClick,
  onAppleLoginClick
}) => {
  const isMicrosoftTeamsFormsLanding = window.location.pathname.includes('/microsoft-teams-forms/');
  const isSalesforceRelatedLanding = window.location.pathname.includes('/formassembly-alternative/');

  const getSocialBtnOrder = (socialPlatform = '') => {
    switch (socialPlatform) {
      case 'Google':
        if (isMicrosoftTeamsFormsLanding || isSalesforceRelatedLanding) return 2;
        return 1;
      case 'Microsoft':
        if (isMicrosoftTeamsFormsLanding) return 1;
        if (isSalesforceRelatedLanding) return 3;
        return 2;
      case 'Salesforce':
        if (isSalesforceRelatedLanding) return 1;
        return 5;
      default:
        break;
    }
  };

  const jfSocialOpts = [
    {
      order: getSocialBtnOrder('Google'),
      loginType: 'Google',
      renderCondition: true,
      component: <GoogleButton
        text={t(Texts.GOOGLE)}
        ariaLabel={t(Texts.LOGIN_GOOGLE)}
        icon={<GoogleIcon36 />}
        classNames="test_gl_login xcl-square-button button-gl"
        onClick={() => {
          onGoogleLoginClick();
          registerMsAbTestAction({ action: 'click', target: 'googleLoginButton' });
          salesforceSocialLoginLogger({ action: 'click', target: 'google-login-button' });
        }}
      />
    },
    {
      order: getSocialBtnOrder('Microsoft'),
      loginType: 'Microsoft',
      renderCondition: true,
      component: <MicrosoftButton
        text={t(Texts.MICROSOFT)}
        ariaLabel={t(Texts.LOGIN_MICROSOFT)}
        icon={<MicrosoftIcon36 />}
        classNames="xcl-square-button button-ms"
        onClick={() => {
          onMicrosoftLoginClick();
          registerMsAbTestAction({ action: 'click', target: 'microsoftLoginButton' });
          salesforceSocialLoginLogger({ action: 'click', target: 'microsoft-login-button' });
        }}
      />
    },
    {
      order: 3,
      loginType: 'Facebook',
      renderCondition: true,
      component: <FacebookButton
        text={t(Texts.FACEBOOK)}
        ariaLabel={t(Texts.LOGIN_FACEBOOK)}
        icon={<FacebookIcon36 />}
        classNames="test_fb_login xcl-square-button button-fb"
        onClick={() => {
          onFBLoginClick();
          registerMsAbTestAction({ action: 'click', target: 'facebookLoginButton' });
          salesforceSocialLoginLogger({ action: 'click', target: 'facebook-login-button' });
        }}
      />
    },
    {
      order: 4,
      loginType: 'Apple',
      renderCondition: true,
      component: <AppleButton
        text={t(Texts.APPLE)}
        ariaLabel={t(Texts.LOGIN_APPLE)}
        icon={<AppleIcon36 />}
        classNames="test_apple_login xcl-square-button button-ap"
        onClick={() => {
          onAppleLoginClick();
          registerMsAbTestAction({ action: 'click', target: 'appleLoginButton' });
          salesforceSocialLoginLogger({ action: 'click', target: 'apple-login-button' });
        }}
      />
    },
    {
      order: getSocialBtnOrder('Salesforce'),
      loginType: 'Salesforce',
      renderCondition: showSalesforceLogin === true,
      component: <SalesforceButton
        text={t(Texts.SALESFORCE)}
        ariaLabel={t(Texts.LOGIN_SALESFORCE)}
        icon={<IconSalesforceFilled style={{ color: '#00A1E0' }} />}
        classNames="test_sf_login xcl-square-button xcl-square-button-salesforce button-sf"
        onClick={() => {
          onSalesforceLoginClick();
          registerMsAbTestAction({ action: 'click', target: 'salesforceLoginButton' });
          salesforceSocialLoginLogger({ action: 'click', target: 'salesforce-login-button' });
        }}
      />
    }
  ].sort((socialOptA, socialOptB) => (socialOptA.order - socialOptB.order));

  return isPlatformSalesforce() ? [
    ...jfSocialOpts.filter(q => q.loginType === 'Salesforce'),
    ...jfSocialOpts.filter(q => q.loginType !== 'Salesforce')
  ].map(q => q.renderCondition && q.component) : jfSocialOpts.map(q => q.renderCondition && q.component);
};

RenderSocialButtons.propTypes = {
  registerMsAbTestAction: PropTypes.func,
  showSalesforceLogin: PropTypes.bool,
  onGoogleLoginClick: PropTypes.func.isRequired,
  onMicrosoftLoginClick: PropTypes.func.isRequired,
  onFBLoginClick: PropTypes.func.isRequired,
  onAppleLoginClick: PropTypes.func.isRequired,
  onSalesforceLoginClick: PropTypes.func.isRequired
};

RenderSocialButtons.defaultProps = {
  registerMsAbTestAction: f => f,
  showSalesforceLogin: false
};

export default RenderSocialButtons;
