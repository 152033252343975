import React from 'react';
import {
  shape, func, elementType, bool, oneOfType, string, number
} from 'prop-types';

import { isPressedKeyEnter } from '../../utils';

const Option = ({
  option, setSelected, ContainerRenderer, Renderer, isSelected, disabled, OptionContainerRendererProps
}) => {
  const handleSelection = opt => {
    if (disabled) return;
    setSelected(opt);
  };

  const onOptionClick = opt => () => handleSelection(opt);
  const onOptionKeyPress = opt => event => isPressedKeyEnter(event) && handleSelection(opt);
  const ContainerRendererProps = typeof OptionContainerRendererProps === 'function' ? OptionContainerRendererProps(option) : OptionContainerRendererProps;
  return (
    <ContainerRenderer
      role="option"
      tabIndex={0}
      aria-disabled={disabled}
      aria-selected={isSelected}
      onClick={onOptionClick(option)}
      {...ContainerRendererProps}
      onKeyPress={onOptionKeyPress(option)}
    >
      <Renderer option={option} isSelected={isSelected} />
    </ContainerRenderer>
  );
};

Option.prototype = {
  disabled: bool,
  isSelected: bool,
  setSelected: func,
  Renderer: elementType,
  ContainerRenderer: elementType,
  OptionContainerRendererProps: oneOfType([
    func,
    shape({})
  ]),
  option: shape({ text: string, value: oneOfType([string, number]) })
};

/* eslint react/prop-types: "off" */
Option.defaultProps = {
  option: {},
  disabled: false,
  isSelected: false,
  setSelected: f => f,
  ContainerRenderer: props => <li {...props} />,
  OptionContainerRendererProps: {},
  Renderer: ({ option: { text, value }, isSelected }) => <div data-value={value} data-selected={isSelected}>{text}</div>
};

export default Option;
