export const LANG_CODES = {
  tr: 'tr-TR',
  en: 'en-US',
  es: 'es-ES',
  pt: 'pt-PT',
  fr: 'fr-FR',
  de: 'de-DE',
  it: 'it-IT',
  bg: 'bg-BG',
  sr: 'sr-RS',
  hu: 'hu-HU',
  fi: 'fi-FI',
  ka: 'ka-GE',
  nl: 'nl-NL',
  uk: 'uk-UA',
  id: 'id-ID',
  ko: 'ko-KR',
  ar: 'ar-SA',
  ja: 'ja-JP',
  pl: 'pl-PL',
  ca: 'ca-ES',
  sv: 'sv-SE',
  no: 'no-NO',
  da: 'da-DK',
  ro: 'ro-RO',
  ru: 'ru-RU',
  zh: 'zh-CN',
  'zh-HK': 'zh-HK',
  af: 'af-ZA',
  he: 'he-IL'
};
