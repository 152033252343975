import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import Styled from 'styled-components';

const StyledSVG = Styled.svg`
  position: absolute;
  top: 50%;
  left: 50%;
  width: ${({ size = '70px' }) => size};
  height: ${({ size = '70px' }) => size};
  transform: translate(-50%, -50%);
  animation: jfLoading-svgRotate 2s linear 0s infinite normal none running;
  transform-origin: center center 0;

  .jfLoading-svgPath {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    animation: jfLoading-svgPath 1.5s ease-in-out 0s infinite normal none running;
    stroke-linecap: round;
  }

  @keyframes jfLoading-svgPath {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35px;
    }
    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124px;
    }
  }

  @keyframes jfLoading-svgRotate {
    100% {
      transform: rotate(360deg) translate(-50%, -50%);
    }
  }
`;

export const Loading = ({
  className = null,
  strokeWidth = '2',
  strokeColor = 'rgba(0,0,0,0.5)',
  ...props
}) => (
  <StyledSVG viewBox="25 25 50 50" className={classNames('jfLoading-loader', className)} {...props}>
    <circle
      className="jfLoading-svgPath"
      cx="50"
      cy="50"
      r="20"
      fill="none"
      strokeWidth={strokeWidth}
      stroke={strokeColor}
    />
  </StyledSVG>
);

Loading.propTypes = {
  className: string,
  strokeColor: string,
  strokeWidth: string
};
