export const ENABLED_PROJECTS = [
  'settings-tab-my-account',
  'security-tab-my-account',
  'form-transfer-my-account',
  'change-email-my-account',
  'delete-account-my-account'
];

export const TEXTS = {
  WE_SENT_EMAIL: 'Enter the six-digit code we sent to [1[{email}]].',
  VERIFY_ITS_YOU: 'Verify It’s You',
  AUTH_CODE: 'Authentication Code',
  RESEND_THE_EMAIL: 'Resend email',
  VERIFY: 'Verify'
};
