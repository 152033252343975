/* eslint-disable no-param-reassign */
import { normalizedKeyMap } from './constants';
import { CacheValue } from './types';

type RetryFunction = () => Promise<CacheValue<unknown> | undefined>;
const delay = (fn: RetryFunction, ms: number): ReturnType<RetryFunction> => new Promise(resolve => setTimeout(() => resolve(fn()), ms));

export const retry = async (fn: RetryFunction, maxAttempts = 3, delayMs = 10): ReturnType<RetryFunction> => {
  const execute = async (attempt: number): ReturnType<RetryFunction> => {
    try {
      return await fn();
    } catch {
      if (attempt <= maxAttempts) {
        const nextAttempt = attempt + 1;
        return delay(() => execute(nextAttempt), delayMs);
      }
      return undefined;
    }
  };
  return execute(1);
};

export const mapRequest = (obj: Record<string, unknown>): void => {
  Object.keys(obj).forEach(key => {
    if (normalizedKeyMap[key]) {
      const shouldMap = normalizedKeyMap[key].contains.includes(obj[key]);
      if (shouldMap) obj[key] = normalizedKeyMap[key].to;
    }
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      mapRequest(obj[key] as Record<string, unknown>);
    }
  });
};
