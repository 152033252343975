import { InterceptorHandler } from '..';
import {
  RequestContext,
  RequestInterceptor, ResponseContext, ResponseInterceptor
} from '../../types';

const createDefaultInterceptors = (
  reqInterceptors: RequestInterceptor[],
  resInterceptors: ResponseInterceptor[],
  resFailedInterceptors: ResponseInterceptor[]
): {preRequest: InterceptorHandler<RequestContext>, postRequest: InterceptorHandler<ResponseContext>, postFailedRequest: InterceptorHandler<ResponseContext>} => ({
  preRequest: new InterceptorHandler(reqInterceptors),
  postRequest: new InterceptorHandler(resInterceptors),
  postFailedRequest: new InterceptorHandler(resFailedInterceptors)
});

export { createDefaultInterceptors };
