import React, { useState } from 'react';
import { string } from 'prop-types';

import { t } from '@jotforminc/translation';
import { Texts } from '@jotforminc/constants';

import { IconCheckCircleFilled } from '@jotforminc/svg-icons';
import ModalHeader from './ModalHeader';

import { verifyAndDelete } from '../../utils/helper';

import ErrorMessage from './ErrorMessage';

const DeleteAccountFinal = ({ username }) => {
  const [isDeleteErrored, setIsDeleteErrored] = useState(false);

  const handleDelete = async () => {
    const result = await verifyAndDelete({ isDeleteVerified: 1, username });
    // TODO: remove 'result === true' check when api is merged
    if (result === true || (typeof result === 'object' && result.token)) {
      setIsDeleteErrored(false);
      window.location.href = '/login';
    } else {
      setIsDeleteErrored(true);
    }
  };

  return (
    <div className='verify-account-container'>
      <ModalHeader />
      <div className='verification-section'>
        <span className='verification-message'>
          <IconCheckCircleFilled />
          {t(Texts.YOU_HAVE_VERIFIED_SUCCESSFULLY)}
        </span>
        {isDeleteErrored && (
          <ErrorMessage text={Texts.YOUR_ACCOUNT_COULD_NOT_BE_DELETED} />
        )}
        <button className='submit-button' type='submit' onClick={handleDelete}>{t(Texts.DELETE_MY_ACCOUNT)}</button>
      </div>
    </div>
  );
};

DeleteAccountFinal.propTypes = {
  username: string.isRequired
};

export default DeleteAccountFinal;
