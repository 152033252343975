import { RequestLayer } from '@jotforminc/request-layer';

const serverRequestInterceptor = ctx => {
  const { resp } = ctx;

  if (!resp?.data?.success) {
    ctx.result = Promise.reject(new Error(resp?.data?.error, { cause: resp?.data?.error_type ?? '' }));
    return;
  }

  if (resp) {
    ctx.result = resp;
  }
};

const apiRequestInterceptor = ctx => {
  const { resp } = ctx;

  if (resp && resp.data && typeof resp.data.responseCode === 'number' && (resp.data.responseCode < 200 || resp.data.responseCode > 299)) {
    ctx.result = Promise.reject(new Error(resp?.data?.message, { cause: resp?.data?.errorType ?? '' }));
    return;
  }

  if (resp && resp.data) {
    ctx.result = resp.data;
  }
};

class LoginFlowRequestManager {
  constructor({ baseURI = '/API', serverRequest = false } = {}) {
    this.layer = new RequestLayer(baseURI, {
      interceptorConfig: {
        customResponseInterceptors: [!serverRequest ? apiRequestInterceptor : serverRequestInterceptor]
      },
      engineConfig: {
        disableEngineThrowOnError: true,
        validateStatus: () => true
      }
    });
  }

  rsPost = data => this.layer.post('', data, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  });

  post = (url, ...args) => this.layer.post(url, ...args);

  get = (url, ...args) => this.layer.get(url, ...args);
}

export default LoginFlowRequestManager;
