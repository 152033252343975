import Axios from 'axios';

export const initAppleSignin = nonce => {
  if (!window.AppleID) return;

  window.AppleID.auth.init({
    clientId: 'com.jotform.applesignin',
    scope: 'name email',
    redirectURI: `https://${global.location.host}/API/login/apple`,
    response_type: 'code',
    response_mode: 'form_post',
    nonce,
    usePopup: true
  });

  return window.AppleID.auth.signIn();
};

export const initFBSignin = () => {
  // https://sentry.io/organizations/jotform/issues/1769596448/?project=4142374
  if (!window.FB) return;

  window.FB.init({
    appId: window.FACEBOOK_SIGNON_APP_ID,
    cookie: true,
    xfbml: true,
    version: 'v3.2'
  });
  return window.FB.login;
};

export const initGoogleSignin = callback => {
  if (!window.google) return;

  const client = window.google.accounts.oauth2.initCodeClient({
    client_id: window.GOOGLE_SIGNON,
    scope: 'openid profile email',
    ux_mode: 'popup',
    cookiepolicy: 'single_host_origin',
    callback: async response => {
      await callback(response);
    }
  });

  return client;
};

export const initMicrosoftSignin = () => {
  const myMSALObj = new window.msal.PublicClientApplication({
    auth: {
      clientId: window.MICROSOFT_SIGNON_CLIENT_ID,
      redirectUri: `https://${global.location.host}/login-redirect.html`
    }
  });
  return myMSALObj.loginPopup({
    scopes: ['user.read']
  });
};

export const initSalesforceSignin = async () => {
  const openPopupWindowCentered = function openPopupWindowCentered(url, windowName, w, h) {
    const x = window.outerWidth / 2 + window.screenX - w / 2;
    const y = window.outerHeight / 2 + window.screenY - h / 2;
    let features = 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=';
    features = features.concat(w, ', height=').concat(h, ', top=').concat(y, ', left=').concat(x);
    return window.open(url, windowName, features);
  };

  let popUpWindow = null;

  const handleLogin = async () => {
    const response = await Axios.get(`https://${global.location.host}/API/user/salesforce-auth-provider`);
    popUpWindow = openPopupWindowCentered(response.data.content, 'login-flow-salesforce', 600, 680);
  };

  await handleLogin();

  const data = await new Promise(resolve => {
    window.addEventListener('message', message => {
      if (!message || !message.data) {
        resolve('error');
      }

      if (message.data.source === 'jfsalesforce_login') {
        if (popUpWindow) {
          popUpWindow.close();
        }

        const res = {
          code: message.data.code,
          token: message.data.token
        };
        resolve(res);
      }
    });
  });

  return data;
};

export const randomString = length => {
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let text = '';
  for (let i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
};

export const initGoogleMailSignin = async () => {
  const openPopupWindowCentered = (url, windowName, w, h) => {
    const x = window.outerWidth / 2 + window.screenX - w / 2;
    const y = window.outerHeight / 2 + window.screenY - h / 2;
    const features = `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`;
    return window.open(url, windowName, features);
  };

  let popUpWindow = null;

  const handleLogin = async () => {
    popUpWindow = openPopupWindowCentered('/api/smtp-oauth2/google.php?startFlow=1', 'smtp-oauth2-google', 600, 680);
    if (!popUpWindow) {
      throw new Error('Pop-up window could not be opened. It might be blocked.');
    }
  };

  const handlePostMessage = (event, resolve) => {
    if (event.origin !== window.location.origin) {
      return; // Only accept messages from the same origin
    }

    const { data: messageData = '' } = event;

    if (!messageData) {
      resolve(new Error('No message data received.'));
      return;
    }

    if (messageData?.source === 'smtp_oauth2_google') {
      if (popUpWindow) {
        popUpWindow.close();
        popUpWindow = null;
      }

      const res = { authToken: messageData?.authToken };
      resolve(res);
    }
  };

  await handleLogin();

  const data = await new Promise((resolve, reject) => {
    const listener = event => handlePostMessage(event, resolve);

    window.addEventListener('message', listener);

    const id = setInterval(() => {
      if (!popUpWindow || popUpWindow.closed) {
        window.removeEventListener('message', listener);
        clearInterval(id);
      }
    }, 500);

    setTimeout(() => {
      clearInterval(id);
      window.removeEventListener('message', listener);
      if (popUpWindow && !popUpWindow.closed) {
        popUpWindow.close();
        reject(new Error('Authentication timeout.'));
      }
    }, 60000); // 60 seconds timeout
  });

  return data;
};

export const initOAuth2MailSignin = async provider => {
  const openPopupWindowCentered = (url, windowName, w, h) => {
    const x = window.outerWidth / 2 + window.screenX - w / 2;
    const y = window.outerHeight / 2 + window.screenY - h / 2;
    const features = `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`;
    return window.open(url, windowName, features);
  };

  let popUpWindow = null;

  const handleLogin = async () => {
    const url = `/api/smtp-oauth2/${provider === 'google' ? 'google' : 'microsoft'}.php?startFlow=1`;
    popUpWindow = openPopupWindowCentered(url, `smtp-oauth2-${provider}`, 600, 680);
    if (!popUpWindow) {
      throw new Error('Pop-up window could not be opened. It might be blocked.');
    }
  };

  const handlePostMessage = (event, resolve) => {
    if (event.origin !== window.location.origin) {
      return; // Only accept messages from the same origin
    }

    const { data: messageData = '' } = event;

    if (!messageData) {
      resolve(new Error('No message data received.'));
      return;
    }

    if (messageData?.source === `smtp_oauth2_${provider}`) {
      if (popUpWindow) {
        popUpWindow.close();
        popUpWindow = null;
      }

      const res = { authToken: messageData?.authToken };
      resolve(res);
    }
  };

  await handleLogin();

  const data = await new Promise((resolve, reject) => {
    const listener = event => handlePostMessage(event, resolve);

    window.addEventListener('message', listener);

    const id = setInterval(() => {
      if (!popUpWindow || popUpWindow.closed) {
        window.removeEventListener('message', listener);
        clearInterval(id);
      }
    }, 500);

    setTimeout(() => {
      clearInterval(id);
      window.removeEventListener('message', listener);
      if (popUpWindow && !popUpWindow.closed) {
        popUpWindow.close();
        reject(new Error('Authentication timeout.'));
      }
    }, 60000); // 60 seconds timeout
  });

  return data;
};
