import { ResponseInterceptor } from '../../types';

// if rejected it returns AxiosResponse instead AxiosResponse.data
// it is implemented to refactor projects that use RequestManager
const requestManagerResponseNormalizer: ResponseInterceptor = ctx => {
  if (ctx.interceptorConfig?.disableResponseNormalizer) {
    return;
  }

  const { resp } = ctx;

  if (resp && resp.data && typeof resp.data.responseCode === 'number'
        && (resp.data.responseCode < 200 || resp.data.responseCode > 299)) {
    ctx.result = Promise.reject(ctx.resp);
    return;
  }

  if (resp && resp.data) {
    ctx.result = resp.data?.content || resp.data;
  }
};

const returnAxiosData: ResponseInterceptor = ctx => {
  // it is implemented for reqwest only
  if (ctx.engineName === 'AXIOS') {
    ctx.result = (new Promise(resolve => {
      if ('data' in ctx.resp) {
        resolve(ctx.resp.data);
      }
    }));
  }
};

export const Interceptors = {
  requestManagerResponseNormalizer,
  returnAxiosData
};
