import Adapter from './Adapter';
import CheckboxAdapter from './CheckboxAdapter';
import RadioAdapter from './RadioAdapter';
import InputTableAdapter from './InputTableAdapter';
import FillinBlanksAdapter from './FillinBlanksAdapter';
import FileAdapter from './FileAdapter';
import PaymentAdapter from './PaymentAdapter';
import DropdownAdapter from './DropdownAdapter';
import ImageChoiceAdapter from './ImageChoiceAdapter';
import MixedAdapter from './MixedAdapter';

export function getAdapter(type, id) {
  switch (type) {
    case 'control_matrix':
      return new InputTableAdapter(id, type);
    case 'control_radio':
      return new RadioAdapter(id, type);
    case 'control_checkbox':
      return new CheckboxAdapter(id, type);
    case 'control_inline':
      return new FillinBlanksAdapter(id, type);
    case 'control_dropdown':
      return new DropdownAdapter(id, type);
    case 'control_fileupload':
      return new FileAdapter(id, type);
    case 'control_imagechoice':
      return new ImageChoiceAdapter(id, type);
    case window.JotForm.paymentFields.includes(type) && type:
      return new PaymentAdapter(id, type);
    case 'control_mixed':
      return new MixedAdapter(id, type);
    default:
      return new Adapter(id, type);
  }
}
