/* eslint-disable max-len */
import React, { forwardRef } from 'react';
import Styled from 'styled-components';
import { string, bool, func } from 'prop-types';
import { t } from '@jotforminc/translation';

import { IconLockFilled } from '@jotforminc/svg-icons';

export const ScToggle = Styled.div`
  ${props => (props.squareMode ? `
    padding: 0 8px;
    border-radius: 4px;
    height: 35px;
    background-color: #56687B;
    display: inline-flex;
    align-items: center;`
    : `
    width: 48px;
    height: 24px;
    border-radius: 12px;
    background-color: #a0a1b2;`)
}
  position: relative;
  cursor: pointer;

  &:after, &:before {
    content: "";
    display: block;
    position: absolute;
    border-radius: ${props => (props.squareMode ? '4px' : '50%')};
    box-sizing: border-box;
  }

  &:after {
    ${props => (props.squareMode ? `
      width: 22px;
      height: 22px;
      top: 6px;
      left: 8px;
      border: 1px solid #a3aebb;
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='14' height='20' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.353 1.742v16.91M7 1.742v16.91m5.647-16.91v16.91' stroke='%23C4CED9' stroke-width='2' fill='none' fill-rule='evenodd' stroke-linecap='square'/%3E%3C/svg%3E");
      background-size: 10px;
      background-repeat: no-repeat;
      background-position: center;
      html[dir="rtl"] & {
        left: 0;
        right: 8px;
      }
      `
    : `
      box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.11);
      width: 28px;
      height: 28px;
      top: -2px;
      left: -2px;
      border: 1px solid #f4f4f6;
      html[dir="rtl"] & {
        left: 0;
        right: -2px;
      }
      `)
}
    background-color: #fff;
    z-index: 1;
  }

  /* for loading */

  @keyframes spinz { to { transform: rotate(360deg); } }
  @keyframes appearz { to { opacity: 1; } }

  &.isLoading:before {
    ${props => (props.squareMode ? `
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: rgba(255, 255, 255, .8);
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' display='block' preserveAspectRatio='xMidYMid' viewBox='0 0 100 100'%3E%3Ccircle cx='50' cy='50' r='35' fill='none' stroke='%237b7f88' stroke-dasharray='164.93361431346415 56.97787143782138' stroke-width='10'%3E%3CanimateTransform attributeName='transform' dur='1s' keyTimes='0;1' repeatCount='indefinite' type='rotate' values='0 50 50;360 50 50'/%3E%3C/circle%3E%3C/svg%3E");
      background-size: 10px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 20px;`
    : `
      opacity: 0;
      width: 26px;
      height: 26px;
      top: -1px;
      left: -1px;
      border-width: 3px;
      border-style: solid;
      border-color: #a0a1b2 #a0a1b2 #a0a1b2 transparent;
      animation: spinz 1s cubic-bezier(0.49, 0.95, 0.46, 0.08) infinite forwards, appearz .15s 1 forwards .15s;`)
}
    z-index: 2;
  }

  &, &:after, &:before { transition: all .25s ease; }
`;

const ScToggleText = Styled.div`
  font-size: 15px;
  font-weight: 500;
  color: #fff;
  opacity: 0;
  transition: opacity .25s ease;
  user-select: none;
  line-height: 1rem;

  &:fist-child { opacity: 0; }
  &:last-child { opacity: 1; }
  & + & {
    margin-left: 8px;
    html[dir="rtl"] & {
      margin-left: 0;
      margin-right: 8px;
    }
  }
`;

const ScDefaultSizeText = Styled.span`
    opacity: 0;
    font-size: 15px;
    font-weight: 500;
    max-width: 27px;
`;

const ScToggleIcon = Styled.div`
  display: flex;
  align-items: center;
  position: relative;
  user-select: none;

  & + & {
    margin-left: 8px;
    html[dir="rtl"] & {
      margin-left: 0;
      margin-right: 8px;
    }
  }

  // somehow storybook requires svg with/height styling
  & > svg {
    opacity: .5;
    color: #fff;
    width: 18px;
    height: 18px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  &:first-of-type > svg { left: 4px; }
  &:last-of-type > svg { right: 4px; }

  & > span {
    opacity: 0;
    font-size: 15px;
    font-weight: 500;
  }
`;

const ScLine = Styled.button`
  border-width: 0;
  display: flex;
  font-size: 1em;
  line-height: 1.5;
  background-color: transparent;
  padding: 0;
  margin: 0;

  .t-content { flex: 1 1 0; }
  .t-label { color: #0A1551; font-weight: ${p => (p.multiline === true ? '700' : '400')}; ${p => (p.isTitleUppercase === true ? 'text-transform: uppercase' : '')}; }
  .t-desc { font-size: .875em; font-weight: 400; color: #6C73A8; }
  .t-desc span,
  .t-label span { font-weight: 700; }


  &[data-disabled=true] {
    opacity: .5;
  }

  /* selected */
  &[data-selected=true] > ${ScToggle} {
    background-color: ${({ branding21 }) => (branding21 ? '#0099FF' : '#4277ff')};

    ${ScToggleText}:first-child { opacity: 1; }
    ${ScToggleText}:last-child { opacity: 0; }

    &:after {
      ${props => (props.squareMode ? `
        left: calc(100% - 30px);`
    : `
        transform: translateX(25px);`)
}
      html[dir="rtl"] & {
        ${props => (props.squareMode ? `
          left: 0;
          right: calc(100% - 30px);`
    : `
          transform: translateX(-25px);`)
}
      }
    }
    ${props => !props.squareMode && '&.isLoading:before { margin-left: 25px; }'}
  }

  /* mixed-value */
  &[data-mixed=true] > ${ScToggle} {
    background-color: #7b7f88;
    ${ScToggleText}:first-child { opacity: 0;}
    ${ScToggleText}:last-child { opacity: 0;}

    &:after {
      ${props => (props.squareMode ? `
        left: 50%;
        transform: translate(-50%);`
    : '')
}
    }
  }

  /* hover */
  &:not([data-selected=true]):not([data-disabled=true]):hover > ${ScToggle} {
  }

  /* focus */
  &:focus { outline: 0; }
  &:focus > ${ScToggle} {
  }
`;

const Toggle = forwardRef(({
  text, value, desc, isSelected, onClick, isLoading, isMultiLine, isTitleUppercase, onFieldError, squareMode, onText, offText, isMixed, readOnly, ariaLabel, noTextMode, ...props
}, ref) => {
  const handleOnClick = e => {
    if (readOnly) return;
    onClick(e);
  };
  const branding21 = true;
  return (
    <ScLine
      type='button'
      onClick={handleOnClick}
      data-value={value}
      data-selected={isSelected}
      multiline={isMultiLine}
      isTitleUppercase={isTitleUppercase}
      squareMode={squareMode}
      tabIndex={0}
      data-mixed={isMixed}
      ref={ref}
      branding21={branding21}
      {...props}
      ariaLabel={t(ariaLabel)}
    >
      {(text || desc) && (
      <div className="t-content">
        {text && <div className="t-label">{text}</div>}
        {desc && <div className="t-desc">{desc}</div>}
      </div>
      )}
      <ScToggle className={`toggle ${isLoading ? 'isLoading' : ''}`} squareMode={squareMode}>
        {(squareMode && !readOnly) && (
        <>
          <ScToggleText>{t(onText)}</ScToggleText>
          <ScToggleText>{t(offText)}</ScToggleText>
        </>
        )}

        {(squareMode && readOnly)
      && (
        <>
          <ScToggleIcon>
            {noTextMode ? <ScDefaultSizeText>{t(onText)}</ScDefaultSizeText> : <span>{t(onText)}</span>}
            <IconLockFilled />
          </ScToggleIcon>
          <ScToggleIcon>
            {noTextMode ? <ScDefaultSizeText>{t(offText)}</ScDefaultSizeText> : <span>{t(offText)}</span>}
            <IconLockFilled />
          </ScToggleIcon>
        </>
      )}
      </ScToggle>
    </ScLine>
  );
});

Toggle.propTypes = {
  text: string,
  value: string,
  desc: string,
  isLoading: bool,
  isSelected: bool,
  isMultiLine: bool,
  isTitleUppercase: bool,
  onClick: func,
  onFieldError: func,
  squareMode: bool,
  isMixed: bool,
  readOnly: bool,
  offText: string,
  onText: string,
  ariaLabel: string,
  noTextMode: bool
};

Toggle.defaultProps = {
  text: '',
  value: '',
  desc: '',
  isLoading: false,
  isSelected: false,
  isMultiLine: false,
  isTitleUppercase: true,
  onClick: f => f,
  onFieldError: f => f,
  squareMode: false,
  offText: 'OFF',
  onText: 'ON',
  isMixed: false,
  readOnly: false,
  ariaLabel: 'toggle button',
  noTextMode: false
};

export default Toggle;
