import React, {
  useEffect, useRef, useState
} from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import cx from 'classnames';
import { VerificationInput } from '@jotforminc/verification-input';
import { t, translationRenderer } from '@jotforminc/translation';
import { PieTimer } from '@jotforminc/pie-timer';

import { Button } from '@jotforminc/magnet';
import { TEXTS } from '../constants';
import { validateOTP } from '../api';
import { maskEmail } from '../utils';
import ErrorBadge from './UI/ErrorBadge';

const OTP = ({
  user, callback, texts, project, open = false
}) => {
  const timerRef = useRef();
  const [otpStatus, setOtpStatus] = useState();
  const [codeExpiration, setCodeExpiration] = useState();
  const [code, setCode] = useState();
  const [error, setError] = useState();
  const [timerRunning, setTimerRunning] = useState(false);
  const [isFirstSent, setIsFirstSent] = useState(false);
  const [loading, setLoading] = useState({
    code: false,
    validate: false,
    resend: false
  });

  const handleUpdateLoading = (key, val) => setLoading(prev => ({
    ...prev,
    [key]: val
  }));

  const handleVerifyOTP = async () => {
    setError();
    handleUpdateLoading('validate', true);

    try {
      const res = await validateOTP({ username: user?.username, project: project, code });

      if (res !== true) {
        throw new Error('Invalid code');
      }

      callback();
    } catch (err) {
      setError(err?.message);
    } finally {
      handleUpdateLoading('validate', false);
    }
  };

  const sendEmail = async () => {
    setError();
    handleUpdateLoading('resend', true);

    try {
      const { otpToken: status } = await validateOTP({ username: user?.username, project: project });
      setOtpStatus(status);
    } catch (err) {
      setError(err?.message);
    } finally {
      handleUpdateLoading('resend', false);
    }
  };

  useEffect(() => {
    (async () => {
      if (!open) return;

      handleUpdateLoading('code', true);
      await sendEmail();
      handleUpdateLoading('code', false);
    })();
  }, [open]);

  useEffect(() => {
    if (otpStatus?.expires_at) {
      dayjs.extend(utc);
      dayjs.extend(tz);
      const unixTimestamp = otpStatus?.expires_at;
      const parseDate = dayjs.unix(unixTimestamp).tz('America/Toronto').local();
      setCodeExpiration(parseDate.toDate());
    }
  }, [otpStatus]);

  useEffect(() => {
    if (error) {
      setError();
    }

    if (code?.length === 6 && !isFirstSent) {
      handleVerifyOTP();
      setIsFirstSent(true);
    }
  }, [code, isFirstSent]);

  useEffect(() => {
    if (codeExpiration) {
      const remaining = codeExpiration.getTime() - Date.now();

      if (remaining > 0) {
        timerRef.current?.start(Math.round(remaining / 1000), 5 * 60);
      } else {
        timerRef.current?.start(0, 5 * 60);
        timerRef.current?.stop();
      }
    } else {
      timerRef.current?.stop();
    }
  }, [codeExpiration]);

  return (
    <div className='color-navy-700 flex flex-col items-center justify-center gap-6'>
      <h1 className='text-2xl line-height-3xl font-bold text-center'>{t(texts?.title || TEXTS.VERIFY_ITS_YOU)}</h1>
      <p className='text-sm line-height-md max-w-120 text-center'>
        {translationRenderer(TEXTS.WE_SENT_EMAIL)({
          renderer1: () => <strong>{maskEmail(user?.email)}</strong>
        })}
      </p>
      <div className='otpfields max-w-120 w-full'>
        <label className='font-semibold mb-1' htmlFor='otpFields'>
          {t(TEXTS.AUTH_CODE)}
          <VerificationInput
            inputId='otpinput'
            validChars='0-9'
            onCodeChange={val => setCode(val.toUpperCase())}
            onEnterPress={handleVerifyOTP}
            disabled={Object.values(loading).some(status => status === true)}
            characterClassName={cx('character', error && 'hasError')}
          />
        </label>
        {error && (
        <ErrorBadge className='max-w-120 mt-2' text={error} />
        )}
        <Button
          className='mt-4'
          colorStyle='success'
          fullWidth={true}
          onClick={handleVerifyOTP}
          loader={loading.validate}
          disabled={loading.resend || loading.code || (code?.length !== 6)}
        >
          {t(TEXTS.VERIFY)}
        </Button>
      </div>
      <div className='w-full max-w-120 flex flex-row justify-between items-center'>
        <PieTimer
          startCallback={() => setTimerRunning(true)}
          stopCallback={() => setTimerRunning(false)}
          ref={timerRef}
        />
        <Button
          size='medium'
          variant='ghost'
          onClick={sendEmail}
          disabled={timerRunning || loading.validate || loading.code}
          loader={loading.resend}
        >
          {t(TEXTS.RESEND_THE_EMAIL)}
        </Button>
      </div>
    </div>
  );
};

OTP.propTypes = {
  user: PropTypes.object.isRequired,
  project: PropTypes.string.isRequired,
  callback: PropTypes.func.isRequired,
  texts: PropTypes.object.isRequired,
  open: PropTypes.bool
};

export default OTP;
