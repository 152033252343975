import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { t } from '@jotforminc/translation';
import {
  IconPaperPlaneFilled, IconCheckCircleFilled, IconFloppyDisk, IconEnvelopeClosedFilled, IconArrowLeft
} from '@jotforminc/svg-icons';
import {
  isEmail,
  listenEnter,
  emailAnswerFinder
} from '@jotforminc/utils';

import { Texts } from '../constants';
import SharableLinkButton from './SharableLinkButton.jsx';

const Guest = ({
  sendEmail, generateLink, onBack
}) => {
  const [emailError, setEmailError] = useState(false);
  const [email, setEmail] = useState(emailAnswerFinder() || '');

  const handleEmailChange = e => {
    const { value } = e.target;
    const _email = value.trim();
    setEmail(_email);
  };

  const handleSendEmail = () => {
    if (isEmail(email)) {
      sendEmail(email);
    } else {
      setEmailError(true);
      setTimeout(() => {
        setEmailError(false);
      }, 3000);
    }
  };

  const handleKeyDown = e => {
    listenEnter(e, handleSendEmail);
  };

  const shouldRenderCloseButton = window.JFForm.requireLogin !== false;

  return (
    <>
      {shouldRenderCloseButton ? (
        <button
          aria-label="Back modal"
          className="sacl-modal__header-btn sacl-modal__header-btn--back"
          onClick={onBack}
          type="button"
        >
          <IconArrowLeft />
        </button>
      ) : null}
      <div>
        <div className='sacl-modal__center pb-32 pt-44'>
          <div className='sacl-modal__logo'>
            <IconCheckCircleFilled className='sacl-modal--success otherIcon' />
            <IconFloppyDisk />
          </div>
        </div>
        <div className="sacl-modal__texts mb-24">
          <h1>{t(Texts.DRAFT_SAVED)}</h1>
          <span>{t(Texts.CONTINUE_FORM_LATER)}</span>
        </div>
        <div className="pb-40">

          <div className="sacl-modal__center sacl-modal__input-container mb-24">
            <div className='sacl-modal__input'>
              <span>
                <IconEnvelopeClosedFilled />
                {`${t(Texts.TO_KEY)}:`}
              </span>
              <input
                className={`${emailError ? 'errored' : ''}`}
                type="email"
                name="email"
                placeholder={t(Texts.ENTER_EMAIL_ADDRESS)}
                onChange={handleEmailChange}
                onKeyDown={handleKeyDown}
                value={email}
              />
            </div>
            <button className="sacl-btn sacl-btn--error sacl-btn--send" type="button" onClick={handleSendEmail}>
              <IconPaperPlaneFilled />
              {t(Texts.SEND)}
              {emailError
                ? (
                  <span className="sacl-modal__tooltip sacl-modal__tooltip--error">
                    {t(Texts.PLEASE_ENTER_VALID_EMAIL)}
                  </span>
                ) : null}
            </button>
          </div>

          <div className='sacl-modal__hr' />

          <div className="sacl-modal__center">
            <SharableLinkButton buttonMode="standard" generateLink={generateLink} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Guest;

Guest.propTypes = {
  sendEmail: PropTypes.func.isRequired,
  generateLink: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired
};
