import { keyGrapper } from '../utils';
import Adapter from './Adapter';

export default class RadioAdapter extends Adapter {
  getValue(values) {
    const _values = keyGrapper(values);
    const ar = Object.entries(_values);
    if (ar.find(([key, value]) => key === 'other' && value !== '')) {
      return { data: { other: _values.other } };
    }
    return { data: ar[0][1] };
  }

  setValue(value) {
    return super.setValue(value);
  }
}
