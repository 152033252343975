/* eslint-disable react/prop-types */
import React from 'react';
import ReactVerificationInput from 'react-verification-input';
import { listenEnter } from '@jotforminc/utils';

export const VerificationInput = ({
  validChars = '0-9',
  onCodeChange,
  onEnterPress,
  characterClassName = 'character',
  inputId = 'verificationInput',
  inputType = '',
  handleKeyDown = () => {},
  inputProps = null,
  ...props
}) => (
  <ReactVerificationInput
    validChars={validChars}
    onChange={onCodeChange}
    inputProps={{
      onKeyDown: e => {
        handleKeyDown(e);
        listenEnter(e, onEnterPress);
      },
      ...(inputType ? { inputMode: inputType } : {}),
      type: 'text',
      autoComplete: 'off',
      id: inputId,
      ...(inputProps || {})
    }}
    placeholder=''
    removeDefaultStyles
    autoFocus
    classNames={{
      container: 'container',
      character: characterClassName,
      characterInactive: 'character--inactive',
      characterSelected: 'character--selected'
    }}
    {...props}
  />
);
