/* eslint-disable max-statements */
/* eslint-disable complexity */
import React, {
  useCallback, useEffect, useRef, useState
} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import isEmpty from 'lodash/isEmpty';
import ReCAPTCHA from 'react-google-recaptcha';
import { t, translationRenderer } from '@jotforminc/translation';
import { Texts } from '@jotforminc/constants';
import { IconChevronLeft, IconExclamationCircleFilled } from '@jotforminc/svg-icons';
import { isEnterprise } from '@jotforminc/enterprise-utils';
import { listenEnter, validateEmail } from '@jotforminc/utils';
import { getEnterpriseLogo } from '@jotforminc/request-layer';

import '../styles/signup-with-social-opts.scss';
import '../styles/style.scss';

import {
  BACK,
  CONTINUE,
  CONTINUE_SIGNUP,
  CREATE_ACCOUNT,
  DESCRIPTION_MESSAGE,
  EMAIL,
  EMAIL_ADDRESS,
  ENTER_EMAIL_ADDRESS,
  ENTER_FULL_NAME,
  OR,
  OR_SIGN_UP_WITH,
  SIGNING_UP_WITH,
  SIGN_UP_WITH,
  TITLE_MESSAGE,
  errorMessages
} from '../constants';
import RenderSocialButtons from './SocialButtons';
import Password from './Password';
import TermsAndConditionsUrls from './TermsAndConditionsUrls';
import { isTurkishLanguage } from '../utils/loginSignup';
import {
  dispatchAction,
  formUserCreate,
  formUserPretendCreate,
  isEmailEligibleForRegister,
  registerNewUser
} from '../api';
import { openOFACModal } from './OFACModal';
import { errorNormalizer } from '../utils/helper';

const SignupWithSocialOpts = ({
  onAppleLoginClick,
  onMicrosoftLoginClick,
  onFBLoginClick,
  onSalesforceLoginClick,
  onGoogleLoginClick,
  onNavigationChangeRequest,
  user,
  isHIPAA,
  enforceHIPAARuleSet,
  includeConfirm,
  appName,
  buttonNames,
  signupPredefinedEmail,
  forceUser,
  verificationHash,
  ofacModalRef,
  onLoginSuccess,
  shrinkConfirmOnMobile,
  shrinkConfirmHeight,
  disableEmail,
  changeActiveScreen,
  builderShowSlides,
  productType,
  redirectURL,
  initiator,
  showLogoOnSignupOptions,
  enterpriseLogo,
  hideGreetingMessageOnSignupOptions,
  greetingMessage,
  greetingDescription,
  logoSource,
  thereSocialError,
  thereErrorMessage,
  // A/B Test: signupOnboardingIV
  isOnboardingFlow,
  isOnboardingTestVariantReversed
}) => {
  const isEnterpriseEnv = isEnterprise();
  const isTurkish = isTurkishLanguage(user);
  const termsRef = useRef();
  const nameRef = useRef();
  const passwordRef = useRef();
  const emailInputRef = useRef();
  const captchaRef = useRef();
  let invalidFieldRefs = {};

  const [step, setStep] = useState('initial');
  const [isAgreedTerms, setIsAgreedTerms] = useState(false);
  const [termsError, setTermsError] = useState('');
  const [securePasswordErrors, setSecurePasswordErrors] = useState([]);
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [emailErrorMsg, setEmailErrorMsg] = useState('');
  const [nameErrorMsg, setNameErrorMsg] = useState('');
  const [signupErrorMsg, setSignupErrorMsg] = useState('');
  const [emailIsLoading, setEmailIsLoading] = useState(false);
  const [signupIsLoading, setSignupIsLoading] = useState(false);

  let openerEmail = '';
  let openerName = '';

  try {
    openerEmail = window.opener?.signUpDefaultEmail;
    openerName = window.opener?.signUpDefaultName;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('CORS', error);
  }

  const [email, setEmail] = useState(signupPredefinedEmail || openerEmail || '');
  const [name, setName] = useState(openerName || '');
  const [screenHeight] = useState(window.innerHeight);
  const [showRecaptcha, setShowRecaptcha] = useState(false);
  const [recaptcha, setRecaptcha] = useState(null);
  const invalidFieldFocusOrder = ['name', 'password', 'terms'];

  const enterpriseEnv = isEnterprise();

  const jfLogoSource = logoSource || 'https://cdn.jotfor.ms/assets/img/v4/logo-branding21.png';
  const jfLogo = enterpriseEnv ? getEnterpriseLogo() : jfLogoSource;

  // A/B Test: signupOnboardingIV
  useEffect(() => {
    if (isOnboardingFlow && window.innerWidth > 480) {
      emailInputRef?.current?.focus?.();
    } else {
      emailInputRef?.current?.blur?.();
    }
  }, [isOnboardingFlow]);

  const resetPasswordErrors = () => {
    setSecurePasswordErrors([]);
    setPasswordError('');
  };

  const handleChangeTerms = () => {
    setIsAgreedTerms(!isAgreedTerms);
    setTermsError((termsError && !isAgreedTerms) ? '' : termsError);
  };

  const focusFirstInvalidField = () => {
    let firstInvalidFieldByOrder = '';
    invalidFieldFocusOrder.forEach(fieldName => {
      if (!firstInvalidFieldByOrder && invalidFieldRefs[fieldName]) {
        firstInvalidFieldByOrder = fieldName;
      }
    });

    if (firstInvalidFieldByOrder && firstInvalidFieldByOrder === 'password') {
      invalidFieldRefs.password.current.inputRef.current.focus();
    } else if (firstInvalidFieldByOrder) {
      invalidFieldRefs[firstInvalidFieldByOrder]?.current?.focus();
    }

    invalidFieldRefs = {};
  };

  const handleSignupFormValidations = useCallback(async () => {
    if (nameErrorMsg) {
      invalidFieldRefs.name = nameRef;
    }

    if (!isEnterpriseEnv && !isAgreedTerms) {
      invalidFieldRefs.terms = termsRef;
      setTermsError(errorMessages.termsRequired);
    }

    const { isValid, password } = await passwordRef.current.validate();
    if (!isValid) {
      invalidFieldRefs.password = passwordRef;
    }

    focusFirstInvalidField();

    return { isValid, password };
  });

  const handleValidateEmail = async () => {
    if (!email) {
      return setEmailErrorMsg(errorMessages.required);
    }

    if (!validateEmail(email)) {
      return setEmailErrorMsg(errorMessages.validEmail);
    }

    try {
      setEmailIsLoading(true);

      const response = await isEmailEligibleForRegister({ email, verificationHash });

      if (response.status === 200) {
        setStep('fields');
      }
    } catch (error) {
      if (error?.message === "Sorry, you can't signup from this country.") {
        if (appName === 'formBuilder' && typeof builderShowSlides === 'function') {
          builderShowSlides(false);
        }

        return changeActiveScreen('ofacModal');
      }

      setEmailErrorMsg(error?.message);
    } finally {
      setEmailIsLoading(false);
    }
  };

  const forceUserSignup = async (password, recaptchaHash) => {
    const defaultButtonName = appName ? `emailSignup-${appName}` : '';
    const buttonName = buttonNames.emailSignup ? buttonNames.emailSignup : defaultButtonName;

    try {
      const response = await registerNewUser({
        email,
        password,
        name,
        isAgreedTerms: isAgreedTerms ? '1' : '0',
        buttonName,
        refApp: appName,
        recaptcha: recaptchaHash
      });

      response.data.newUser = true;
      onLoginSuccess(response, true);
      setSignupIsLoading(false);
    } catch (error) {
      if (captchaRef) {
        captchaRef.current?.reset();
      }

      if (error?.message === "Sorry, you can't signup from this country.") {
        return openOFACModal(ofacModalRef, email);
      }

      const passwordWarning = error?.message?.includes('PasswordWarning::');
      if (passwordWarning || error?.cause === 'password') {
        passwordRef?.current?.focus();
        return setPasswordError(passwordWarning ? error?.message?.replace('PasswordWarning::', '') : error?.message);
      }

      if (error?.message === 'REQUIRE_CAPTCHA') {
        dispatchAction({
          actor: window?.user?.username || window?.__formInfo?.username || 'unknown',
          target: 'swso',
          action: 'captcha-required'
        });
        return setShowRecaptcha(true);
      }

      setSignupErrorMsg(error?.message);
    } finally {
      setSignupIsLoading(false);
    }
  };

  // User is already created, just need to set their password with this.
  const formUserPretendSignup = async password => {
    try {
      const response = await formUserPretendCreate({
        email,
        password,
        name,
        isAgreedTerms: isAgreedTerms ? '1' : '0',
        verificationHash,
        isHIPAA: isHIPAA ? '1' : ''
      });

      onLoginSuccess(response);
    } catch (error) {
      if (error?.message === "Sorry, you can't signup from this country.") {
        return openOFACModal(ofacModalRef, email);
      }

      if (error?.message?.includes('Name')) {
        nameRef?.current?.focus();
        return setNameErrorMsg(error?.message);
      }

      setSignupErrorMsg(error?.message);
    } finally {
      setSignupIsLoading(false);
    }
  };

  const formUserSignup = async password => {
    try {
      const response = await formUserCreate({
        email,
        password,
        name,
        isAgreedTerms: isAgreedTerms ? '1' : '0',
        initiator,
        redirectURL,
        appName,
        sendVerification: productType === 'form' ? '1' : '0'
      });

      const { content: createdUser } = response;

      if (window?.JFAppsManager?.isOpenedInPortal()) {
        window.JFAppsManager.userChanged(createdUser);
      }

      onLoginSuccess(response);
    } catch (error) {
      if (error?.message === "Sorry, you can't signup from this country.") {
        return openOFACModal(ofacModalRef, email);
      }

      setSignupErrorMsg(error?.message);
    } finally {
      setSignupIsLoading(false);
    }
  };

  const handleEmailSignup = async () => {
    setSignupIsLoading(true);
    setSignupErrorMsg('');
    setNameErrorMsg('');

    const { isValid: isPasswordValid, password } = await handleSignupFormValidations();

    if (!isPasswordValid || !isAgreedTerms || !isEmpty(securePasswordErrors) || confirmPasswordError) {
      return setSignupIsLoading(false);
    }

    if (forceUser) {
      return forceUserSignup(password, recaptcha);
    }

    if (signupPredefinedEmail && verificationHash) {
      return formUserPretendSignup(password);
    }

    return formUserSignup(password);
  };

  const handleSubmitCaptcha = hash => {
    if (!hash) {
      dispatchAction({
        actor: window?.user?.username || window?.__formInfo?.username || 'unknown',
        target: 'swso',
        action: 'captcha-expired'
      });
      return setSignupIsLoading(false);
    }

    dispatchAction({
      actor: window?.user?.username || window?.__formInfo?.username || 'unknown',
      target: 'swso',
      action: 'captcha-passed'
    });

    setRecaptcha(hash);
  };

  const handleSignupFormKeypress = event => {
    if (event.key === 'Enter' && step === 'fields') {
      handleEmailSignup();
    }

    if (event.key !== 'Enter' && step === 'initial') {
      setEmailErrorMsg();
    }
  };

  useEffect(() => {
    if (name && nameErrorMsg) {
      setNameErrorMsg('');
    }
  }, [name, nameErrorMsg]);

  useEffect(() => {
    window.addEventListener('keydown', handleSignupFormKeypress);

    return () => window.removeEventListener('keydown', handleSignupFormKeypress);
  }, [step, termsError, isAgreedTerms]);

  return (
    <div
      id='sws' className={cx('sws xcl-content xcl-nb-content', {
        formBuildSws: appName === 'formBuilder',
        commonModalSws: appName !== 'formBuilder',
        isOnboardingFlow,
        isOnboardingTestVariantReversed
      })}
    >
      {showLogoOnSignupOptions && step !== 'fields' && <img src={enterpriseLogo === null ? jfLogo : enterpriseLogo} alt="Logo" className={cx('xcl-companyLogo', logoSource && 'logoSource')} />}
      {!enterpriseEnv && !hideGreetingMessageOnSignupOptions && step !== 'fields' && (<h2 className="m1t isGreeting title-message">{t(greetingMessage)}</h2>)}
      {!!greetingDescription && step !== 'fields' && (<p className='isDescription description-message'>{t(greetingDescription)}</p>)}
      {step === 'initial' && (
        <div id='swsContent' className='sws-content'>
          <div className='social-buttons'>
            {!isOnboardingFlow && <p className='text'>{t(SIGN_UP_WITH)}</p>}
            <div className='social-buttons-container'>
              <RenderSocialButtons
                showSalesforceLogin={!isEnterpriseEnv}
                onAppleLoginClick={onAppleLoginClick}
                onMicrosoftLoginClick={onMicrosoftLoginClick}
                onFBLoginClick={onFBLoginClick}
                onSalesforceLoginClick={onSalesforceLoginClick}
                onGoogleLoginClick={onGoogleLoginClick}
                isOnboardingFlow={isOnboardingFlow}
              />
            </div>
            {thereSocialError && (
              <div className='sws-error-msg social-buttons-error'>
                <IconExclamationCircleFilled />
                {t(errorNormalizer(thereErrorMessage))}
              </div>
            )}
          </div>
          <div className='sws-divider'>
            <span className='divider-text'>{t(!isOnboardingFlow ? OR : OR_SIGN_UP_WITH)}</span>
          </div>
          <div>
            <div className={cx('sws-input-field', { 'isOnboardingFlow-input-field': isOnboardingFlow })}>
              <label htmlFor={EMAIL}>{t(!isOnboardingFlow ? EMAIL : EMAIL_ADDRESS)}</label>
              <input
                ref={emailInputRef}
                id={EMAIL}
                type='email'
                value={email}
                readOnly={disableEmail}
                onKeyDown={e => listenEnter(e, handleValidateEmail)}
                onChange={e => setEmail(e.target.value)}
                className={emailErrorMsg && 'input-error'}
                placeholder={isOnboardingFlow ? t(ENTER_EMAIL_ADDRESS) : ''}
              />
              {emailErrorMsg && (
                <div className='sws-error-msg'>
                  <IconExclamationCircleFilled />
                  {t(emailErrorMsg)}
                </div>
              )}
            </div>
            <button
              className='sws-button' type='button' disabled={emailIsLoading}
              onClick={() => handleValidateEmail()}
            >
              {emailIsLoading && (
                <span className='sws-button-loader'>
                  <div className='loading' />
                  {t('Loading')}
                  ...
                </span>
              )}
              {!emailIsLoading && t(!isOnboardingFlow ? CONTINUE : 'Continue with Email')}
            </button>
            {!isOnboardingFlow && (
            <div className="sws-login-text">
              {t(Texts.ALREADY_HAVE_ACCOUNT)}
              {' '}
              <button
                type="button"
                className="sws-login-button"
                onClick={() => onNavigationChangeRequest('loginOptions')}
                id="login-link"
              >
                {t(Texts.LOGIN_TEXT)}
              </button>
            </div>
            )}
          </div>
        </div>
      )}
      {step === 'fields' && (
        <>
          <div className='sws-fields-back-button'>
            <button className='back-button' type='button' onClick={() => setStep('initial')}>
              <IconChevronLeft />
              {t(BACK)}
            </button>
          </div>
          <div id='swsContentFields' className='sws-content'>
            <h1 className='title-message'>{t(CONTINUE_SIGNUP)}</h1>
            <p className='description-message fields'>
              {translationRenderer(SIGNING_UP_WITH)({
                renderer1: () => (
                  <span key="signupWithEmail">
                    {email}
                  </span>
                )
              })}
            </p>
            <div>
              <div className={cx('sws-input-field', { 'isOnboardingFlow-input-field': isOnboardingFlow })}>
                <label htmlFor='fullname'>{t(Texts.NAME_TEXT)}</label>
                <input
                  ref={nameRef}
                  id='fullname'
                  type='text'
                  value={name}
                  onChange={e => setName(e.target.value)}
                  className={nameErrorMsg && 'input-error'}
                  placeholder={isOnboardingFlow ? t(ENTER_FULL_NAME) : ''}
                />
                {nameErrorMsg && (
                  <div className='sws-error-msg name-error'>
                    {t(nameErrorMsg)}
                  </div>
                )}
              </div>
              <div className={cx('password-fields-container', { 'sws-input-field isOnboardingFlow-input-field': isOnboardingFlow })}>
                <Password
                  ref={passwordRef}
                  isOnboardingFlow={isOnboardingFlow}
                  secure={isHIPAA || enforceHIPAARuleSet}
                  hasSecureTextIcon={isHIPAA || enforceHIPAARuleSet}
                  passwordErrorServer={passwordError}
                  resetPasswordError={resetPasswordErrors}
                  setSecurePaswordError={errorCodes => setSecurePasswordErrors(errorCodes)}
                  callbackConfirmPassError={confirmPassErrorCode => setConfirmPasswordError(confirmPassErrorCode)}
                  checkReuse={false}
                  includeConfirm={includeConfirm && !(shrinkConfirmOnMobile && screenHeight < shrinkConfirmHeight)}
                />
              </div>
              {!isEnterpriseEnv && (
                <div className="xcl-field-wr" id="termsField">
                  <div className={cx('xcl-terms-wr noTranslate', { 'isOnboardingFlow-terms': isOnboardingFlow })}>
                    <input
                      ref={termsRef}
                      type="checkbox"
                      id="terms"
                      className="terms-checkbox"
                      checked={isAgreedTerms}
                      onChange={() => handleChangeTerms()}
                      disabled={signupIsLoading}
                      aria-label={isEnterpriseEnv && !isAgreedTerms ? t(Texts.AGREE_TERMS_CONDITIONS) : null}
                    />
                    <TermsAndConditionsUrls
                      isTurkish={isTurkish}
                      LabelRenderer={({ children }) => <label className="noTranslate sacl-terms" htmlFor="terms">{children}</label>}
                    />
                  </div>
                  <div role="alert" className={cx('xcl-lbl-err', termsError && 'forTerms isVisible')}>
                    {termsError ? t(termsError) : null}
                  </div>
                </div>
              )}
              {signupErrorMsg && (
                <div className='sws-error-msg signup-error'>
                  <IconExclamationCircleFilled />
                  {t(signupErrorMsg)}
                </div>
              )}
              {showRecaptcha && (
                <div id="recaptcha-container" className='lf-recaptcha-container'>
                  <ReCAPTCHA
                    ref={captchaRef}
                    isolated={true}
                    sitekey="6LdU3CgUAAAAAB0nnFM3M3T0sy707slYYU51RroJ"
                    onChange={hash => handleSubmitCaptcha(hash)}
                  />
                </div>
              )}
              <button
                className='sws-button' disabled={signupIsLoading} type='button'
                onClick={() => handleEmailSignup()}
              >
                {signupIsLoading && (
                  <span className='sws-button-loader'>
                    <div className='loading' />
                    {t('Loading')}
                    ...
                  </span>
                )}
                {!signupIsLoading && (!isOnboardingFlow ? t(Texts.SIGN_UP) : t(CREATE_ACCOUNT))}
              </button>
              {!isOnboardingFlow && (
                <div className="sws-login-text">
                  {t(Texts.ALREADY_HAVE_ACCOUNT)}
                  {' '}
                  <button
                    type="button"
                    className="sws-login-button"
                    onClick={() => onNavigationChangeRequest('loginOptions')}
                    id="login-link"
                  >
                    {t(Texts.LOGIN_TEXT)}
                  </button>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

SignupWithSocialOpts.propTypes = {
  onNavigationChangeRequest: PropTypes.func.isRequired,
  user: PropTypes.shape(),
  isHIPAA: PropTypes.bool,
  enforceHIPAARuleSet: PropTypes.bool,
  includeConfirm: PropTypes.bool,
  shrinkConfirmOnMobile: PropTypes.bool,
  shrinkConfirmHeight: PropTypes.number,
  onAppleLoginClick: PropTypes.func.isRequired,
  onMicrosoftLoginClick: PropTypes.func.isRequired,
  onFBLoginClick: PropTypes.func.isRequired,
  onSalesforceLoginClick: PropTypes.func.isRequired,
  onGoogleLoginClick: PropTypes.func.isRequired,
  onLoginSuccess: PropTypes.func.isRequired,
  changeActiveScreen: PropTypes.func.isRequired,
  builderShowSlides: PropTypes.func,
  verificationHash: PropTypes.string,
  signupPredefinedEmail: PropTypes.string,
  ofacModalRef: PropTypes.object,
  buttonNames: PropTypes.shape({
    google: PropTypes.string,
    facebook: PropTypes.string,
    apple: PropTypes.string,
    microsoft: PropTypes.string,
    emailLogin: PropTypes.string,
    emailSignup: PropTypes.string
  }),
  appName: PropTypes.string,
  productType: PropTypes.string,
  redirectURL: PropTypes.string,
  forceUser: PropTypes.bool,
  disableEmail: PropTypes.bool,
  showLogoOnSignupOptions: PropTypes.bool,
  enterpriseLogo: PropTypes.string,
  hideGreetingMessageOnSignupOptions: PropTypes.bool,
  greetingMessage: PropTypes.string,
  greetingDescription: PropTypes.string,
  logoSource: PropTypes.string,
  thereSocialError: PropTypes.bool,
  thereErrorMessage: PropTypes.string,
  isOnboardingFlow: PropTypes.bool,
  initiator: PropTypes.string,
  isOnboardingTestVariantReversed: PropTypes.bool
};

SignupWithSocialOpts.defaultProps = {
  user: null,
  isHIPAA: false,
  enforceHIPAARuleSet: false,
  includeConfirm: false,
  shrinkConfirmOnMobile: false,
  shrinkConfirmHeight: 750,
  verificationHash: '',
  signupPredefinedEmail: '',
  ofacModalRef: {},
  buttonNames: {},
  appName: '',
  productType: '',
  redirectURL: '',
  forceUser: false,
  disableEmail: false,
  builderShowSlides: f => f,
  showLogoOnSignupOptions: false,
  enterpriseLogo: null,
  hideGreetingMessageOnSignupOptions: false,
  greetingMessage: TITLE_MESSAGE,
  greetingDescription: DESCRIPTION_MESSAGE,
  logoSource: '',
  thereSocialError: false,
  thereErrorMessage: errorMessages.tryAgain,
  isOnboardingFlow: false,
  initiator: '',
  isOnboardingTestVariantReversed: false
};

export default SignupWithSocialOpts;
