import React, {
  useState,
  forwardRef,
  useRef,
  useImperativeHandle,
  useEffect
} from 'react';
import { func } from 'prop-types';
import { Hooks } from '@jotforminc/uikit';
import { ScPieTimerWrapper } from './sc/scPieTimer';

export const PieTimer = forwardRef(({
  startCallback,
  stopCallback
}, ref) => {
  const [alpha, setAlpha] = useState(0);
  const [counter, setCounter] = useState(0);
  const interval = useRef(null);
  const counterRef = useRef(null);

  const calculateTimeInterval = (totalTime = 60) => {
    return (totalTime / 360) * 1000;
  };

  Hooks.useEffectIgnoreFirst(() => {
    if (counter > 0) {
      counterRef.current = setTimeout(() => setCounter(prev => prev - 1), 1000);
      return;
    }
    stopCallback();
    return () => clearInterval(interval.current);
  }, [counter]);

  useEffect(() => {
    if (alpha >= 359) clearInterval(interval.current);
  }, [alpha]);

  useImperativeHandle(ref, () => ({
    start: (duration, totalTime) => {
      startCallback();
      clearTimeout(counterRef.current);
      const initialAlpha = totalTime ? 360 - (duration / totalTime) * 360 : 0;
      setAlpha(initialAlpha);
      setCounter(Number(duration));
      clearInterval(interval.current);
      interval.current = setInterval(() => {
        setAlpha(prev => prev + 1);
      }, calculateTimeInterval(totalTime));
    },
    stop: () => {
      stopCallback();
      clearInterval(interval.current);
      clearTimeout(counterRef.current);
    }
  }));

  return (
    <ScPieTimerWrapper>
      <div>
        <svg viewBox="0 0 20 20" fill="none">
          <circle cx="10" cy="10" r="9.5" />
          <path d="M10 0v10" stroke="#CFD8DC" />
          {alpha < 359 && (
            <>
              <circle
                cx="10"
                cy="10"
                r="10"
                fill="#2E69FF"
              />
              <path
                d={`M 0 0 v -10 A 10 10 1 ${(alpha > 180) ? 1 : 0} 1 ${Math.sin((alpha * Math.PI) / 180) * 10} ${Math.cos((alpha * Math.PI) / 180) * -10} z`}
                id="loader"
                transform="translate(10,10)"
              />
            </>
          )}
        </svg>
      </div>
      <p>
        {`${Math.floor(counter / 60)}`.padStart(2, '0')}
        :
        {`${counter % 60}`.padStart(2, '0')}
      </p>
    </ScPieTimerWrapper>
  );
});

PieTimer.propTypes = {
  startCallback: func,
  stopCallback: func
};

PieTimer.defaultProps = {
  startCallback: f => f,
  stopCallback: f => f
};
