import React from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';
import { t } from '@jotforminc/translation';
import { Texts } from '@jotforminc/constants';

import DiscardSVG from '../assets/svg/iconDiscardDraft.svg';
import UseSVG from '../assets/svg/iconUseDraft.svg';
import '../assets/style.scss';

export default class DraftSelectionModal extends React.Component {
  onClose = () => {
    console.log('close this pls');
  };

  render() {
    const { onContinueWithDraft, onContinueEmpty } = this.props;

    return (
      <ReactModal
        key="draftSelectionModal"
        isOpen={true}
        overlayClassName="draftSelectionModalOverlay"
        className="draftSelectionModalContainer"
        role="dialog"
        ariaHideApp={true}
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
        onRequestClose={this.onClose}
        aria={{
          labelledby: 'heading',
          describedby: 'full_description'
        }}
      >

        <div className="draftSelectionModalContent">
          <div className="draftSelectionTexts">
            <h4 className="locale">{t(Texts.SAVED_DRAFT_SUBMISSION)}</h4>
            <h5 className="locale">{t(Texts.DISCARD_START_BLANK)}</h5>
          </div>
          <div className="line" />
          <div className="draftSelectionActionButtons">
            <button onClick={onContinueEmpty} type="button">
              <DiscardSVG />
              {t(decodeURIComponent(Texts.START_OVER))}
            </button>
            <button onClick={onContinueWithDraft} type="button">
              <UseSVG />
              {t(Texts.CONTINUE_DRAFT)}
            </button>
          </div>
        </div>
      </ReactModal>
    );
  }
}

DraftSelectionModal.propTypes = {
  onContinueWithDraft: PropTypes.func.isRequired,
  onContinueEmpty: PropTypes.func.isRequired
};
