/* eslint-disable react/jsx-one-expression-per-line, react/sort-comp */

import React, { createRef } from 'react';
import {
  bool,
  func,
  string,
  shape,
  number,
  object
} from 'prop-types';
import Axios from 'axios';
import qs from 'querystring';
import ReCAPTCHA from 'react-google-recaptcha';
import { t, translationRenderer } from '@jotforminc/translation';
import { isEnterprise } from '@jotforminc/enterprise-utils';
import { INITIATOR_TYPES, Texts } from '@jotforminc/constants';
import {
  listenEnter,
  validateEmail,
  getPossibleTimeZone
} from '@jotforminc/utils';

import BackIcon from '../temporary-duplicate/assets/svg/icon_back3.svg';
import Password from './Password';
import {
  isTurkishLanguage,
  getScreenResolution,
  handleSignupCompletedEvents,
  pushDataLayerEvent
} from '../utils/loginSignup';
import TermsAndConditionsUrls from './TermsAndConditionsUrls';
import ErrorAlert from './ErrorAlert';
import { openOFACModal } from './OFACModal';
import { objToQueryString } from '../utils/helper';
import { errorMessages } from '../constants';
import { dispatchAction } from '../api';

const securePasswordTestEnabled = true;

class SignupWithEmail extends React.Component {
  constructor(props) {
    super(props);

    try {
      if (window.opener) {
        this.signUpDefaultName = window.opener.signUpDefaultName || '';
        this.signUpDefaultEmail = window.opener.signUpDefaultEmail || '';
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('CORS', error);
    }

    this.isTurkish = isTurkishLanguage(props.user);
    this.captchaRef = createRef();
    this.state = {
      name: props.signupPredefinedName || this.signUpDefaultName || '',
      email: props.signupPredefinedEmail || this.signUpDefaultEmail || '',
      isAggreedTerms: false,
      isSignupLoading: false,
      screenHeight: window.innerHeight,
      showRecaptcha: false,
      recaptcha: null
    };
    this.emailRef = React.createRef();
    this.passwordRef = React.createRef();
    this.termsRef = React.createRef();
    this.isEnterpriseEnv = isEnterprise();
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

    // Accessibility validation controls
    this.invalidFieldRefs = {};
    this.invalidFieldFocusOrder = ['email', 'password', 'terms'];
  }

  componentDidMount() {
    const { shrinkConfirmOnMobile, registrationType } = this.props;
    if (shrinkConfirmOnMobile) {
      window.addEventListener('resize', this.updateWindowDimensions);
    }

    if (registrationType === 'oauth') {
      setTimeout(() => {
        window.dispatchEvent(new CustomEvent('resizeWindow', {
          bubbles: false
        }));
      }, 200);
    }
  }

  componentWillUnmount() {
    const { shrinkConfirmOnMobile } = this.props;
    if (shrinkConfirmOnMobile) window.removeEventListener('resize', this.updateWindowDimensions);
  }

  handleFieldChange = propName => e => {
    const { value } = e.target;
    const erroredName = `${propName}Error`;
    const showRequiredMessage = propName !== 'name' && !value;
    this.setState({
      [propName]: value,
      [erroredName]: showRequiredMessage ? t(errorMessages.required) : ''
    });
  };

  handleTermsChange = () => {
    const { isAggreedTerms, termsError } = this.state;
    this.setState({
      isAggreedTerms: !isAggreedTerms,
      termsError: (termsError && !isAggreedTerms) ? '' : termsError
    });
  };

  resetPasswordError = () => {
    this.setState({ passwordError: '', securePassErrors: [] });
  };

  setSecurePaswordError = errorCodes => {
    this.setState({ securePassErrors: errorCodes });
  };

  callbackConfirmPassError = confirmPassErrorCode => {
    this.setState({ confirmPassErrorCode });
  };

  focusFirstInvalidField = () => {
    let firstInvalidFieldByOrder = '';
    this.invalidFieldFocusOrder.forEach(fieldName => {
      if (!firstInvalidFieldByOrder && this.invalidFieldRefs[fieldName]) {
        firstInvalidFieldByOrder = fieldName;
      }
    });

    if (firstInvalidFieldByOrder && firstInvalidFieldByOrder === 'password') {
      this.invalidFieldRefs.password.current.inputRef.current.focus();
    } else if (firstInvalidFieldByOrder) {
      this.invalidFieldRefs[firstInvalidFieldByOrder]?.current?.focus();
    }
    this.invalidFieldRefs = {};
  };

  makeAllValidations = () => {
    return new Promise(resolve => {
      const {
        isAggreedTerms, email
      } = this.state;
      const nextState = {};
      let emailErrCode = '';
      if (!email) {
        nextState.emailError = t(errorMessages.required);
        emailErrCode = 'emailRequired';
        this.invalidFieldRefs.email = this.emailRef;
      } else if (!validateEmail(email)) {
        nextState.emailError = t(errorMessages.validEmail);
        emailErrCode = 'emailInvalid';
        this.invalidFieldRefs.email = this.emailRef;
      }
      nextState.emailErrorCode = emailErrCode;

      if (!this.isEnterpriseEnv && !isAggreedTerms) { // Aytekin's Request, hiding terms on enterprise signup flow.
        nextState.termsError = t(errorMessages.termsRequired);
        this.invalidFieldRefs.terms = this.termsRef;
      }

      this.passwordRef.current.validate().then(({ isValid }) => {
        if (!isValid) {
          this.invalidFieldRefs.password = this.passwordRef;
        }
      }).then(() => this.focusFirstInvalidField());

      this.setState({
        ...nextState
      }, resolve);
    }).then(() => this.passwordRef.current.validate());
  };

  handleKeyDown = e => listenEnter(e, this.handleSubmit);

  handleCaptchaSubmit = hash => {
    if (!hash) {
      dispatchAction({
        actor: window?.user?.username || window?.__formInfo?.username || 'unknown',
        target: 'swe',
        action: 'captcha-expired'
      });
      return this.setState({ isSignupLoading: false });
    }

    this.setState({
      recaptcha: hash
    });

    dispatchAction({
      actor: window?.user?.username || window?.__formInfo?.username || 'unknown',
      target: 'swe',
      action: 'captcha-passed'
    });
  };

  forceUserSignup = async (name, email, password, isAggreedTerms, recaptcha) => {
    const {
      onLoginSuccess,
      appName,
      buttonNames,
      changeActiveScreen,
      builderShowSlides
    } = this.props;

    const defaultButtonName = appName ? `emailSignup-${appName}` : '';
    const buttonName = buttonNames.emailSignup ? buttonNames.emailSignup : defaultButtonName;

    try {
      const response = await Axios.post(
        '/server.php',
        qs.stringify({
          action: 'registerNewUser',
          email,
          password,
          name,
          timezone: getPossibleTimeZone(),
          screen_resolution: getScreenResolution(),
          location: global.location.pathname,
          username: '',
          button_name: buttonName,
          ref_app: appName,
          return: 1,
          isAgreedTerms: isAggreedTerms ? '1' : '0',
          recaptcha
        }),
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      );

      if (response.status === 200) {
        handleSignupCompletedEvents(response.data.user.username);
        response.data.newUser = true;
        onLoginSuccess(response, true);
      } else {
        this.setState({
          emailError: t('Something went wrong'),
          isSignupLoading: false,
          passwordError: ''
        });
      }
    } catch (error) {
      const { response: { data: { error: message, error_type: errorType } = {} } = {} } = error;
      const hasPasswordWarning = message.includes('PasswordWarning::');
      if (message && securePasswordTestEnabled && (hasPasswordWarning || errorType === 'password')) {
        const removedErrorCode = hasPasswordWarning ? message.replace('PasswordWarning::', '') : message;
        this.setState({
          passwordError: t(removedErrorCode),
          isSignupLoading: false
        });
      } else {
        if (this.captchaRef) {
          this.captchaRef.current?.reset();
        }

        if (message === "Sorry, you can't signup from this country.") {
          builderShowSlides(false);
          changeActiveScreen('ofacModal');
        }

        if (message === 'REQUIRE_CAPTCHA') {
          dispatchAction({
            actor: window?.user?.username || window?.__formInfo?.username || 'unknown',
            target: 'swe',
            action: 'captcha-required'
          });
          return this.setState({
            isSignupLoading: false,
            showRecaptcha: true
          });
        }

        this.setState({
          emailError: t(message || 'Something went wrong, please try again.'),
          isSignupLoading: false,
          passwordError: ''
        });
      }
    }
  };

  handleSubmit = e => {
    if (e) {
      e.preventDefault();
    }

    pushDataLayerEvent({
      event: 'Signup',
      eventAction: 'Click'
    });

    const {
      name, email, isAggreedTerms, recaptcha
    } = this.state;
    const {
      apiURL,
      forceUser,
      isHIPAA,
      onLoginSuccess,
      signupPredefinedEmail,
      verificationHash,
      ofacModalRef
    } = this.props;

    this.handleFieldChange('name')({ target: { value: name } });
    // eslint-disable-next-line complexity
    this.makeAllValidations().then(({ isValid: isPasswordValid, password }) => {
      if (securePasswordTestEnabled) {
        const {
          emailError, emailErrorCode, termsError, confirmPassErrorCode, securePassErrors
        } = this.state;
        const currentErrors = [
          { emailError: emailError !== '' ? emailErrorCode : '' },
          { termsError: (termsError !== '' && termsError) ? 'termsRequired' : '' },
          { confirmPassErrorCode }
        ];
        securePassErrors.forEach(errCode => {
          currentErrors.push({ passwordError: errCode });
        });
        currentErrors.forEach(type => {
          const errObj = Object.entries(type)[0];
          if (errObj[1] && errObj[1] !== '') {
            if (typeof window.JotFormActions === 'function') {
              const actor = window?.user?.username || window?.__formInfo?.username || 'unknown';
              const logger = new window.JotFormActions('strongPasswordonSignupsWithNew');
              logger.tick({ actor, action: 'signUpClick', target: errObj[1] });
            }
          }
        });
      }

      if (!isPasswordValid) return;

      const errorProps = ['emailError', 'nameError', 'termsError'];
      const firstError = errorProps.find(errorProp => {
        // eslint-disable-next-line react/destructuring-assignment
        const error = this.state[errorProp];
        return !!error;
      });
      if (firstError || !isPasswordValid) {
        this.setState({ isSignupLoading: false });
        return;
      }
      this.setState({ isSignupLoading: true });
      if (forceUser) {
        this.forceUserSignup(name, email, password, isAggreedTerms, recaptcha);
      } else if (signupPredefinedEmail && verificationHash) { // TODO: need another prop
        Axios.post(
          `${apiURL}/formuser/pretendCreate?master=1`,
          qs.stringify({
            email,
            password,
            name,
            isAgreedTerms: isAggreedTerms ? '1' : '0',
            hash: verificationHash,
            isHIPAA: isHIPAA ? '1' : ''
          }),
          {
            withCredentials: true,
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          }
        ).then(({ data: resp }) => {
          if (resp && resp.responseCode === 200) {
            onLoginSuccess(resp);
          } else if (resp && resp.errorType) {
            if (resp.errorType === 'E_TYPE_EMAIL') {
              this.setState({ emailError: resp.message });
            }

            if (resp?.message === "Sorry, you can't signup from this country.") {
              openOFACModal(ofacModalRef, email);
            }

            this.setState({ isSignupLoading: false });
          } else if (resp && typeof resp.message === 'string') {
            if (resp?.message?.includes('Name')) {
              return this.setState({ nameError: resp?.message?.trim(), isSignupLoading: false });
            }

            // Shitty error handling
            // we need to change api's error results to human readable format.
            const splitted = resp.message.split('-');
            const lastPart = splitted[splitted.length - 1];
            this.setState({ emailError: lastPart.trim(), isSignupLoading: false });
          }
        });
      } else {
        const {
          productType, appName, redirectURL: redirectUrl, initiator
        } = this.props;
        const isProductForm = productType === 'form' ? '1' : '0';
        const encodedEmail = encodeURIComponent(email);
        const encodedPw = encodeURIComponent(password);
        const queryParamsObj = {
          email: encodedEmail,
          password: encodedPw,
          confirm_password: encodedPw,
          disablePasswordConstraints: '1',
          name,
          sendVerification: isProductForm,
          ...(isHIPAA) && { isHIPAA: '1' },
          ...(appName === 'portal-apps' && window) && { jotform_pwa: '1', referrerURL: window.location.href },
          ...(redirectUrl && window) && { redirectUrl },
          ...(initiator && { initiator }),
          saclSignup: initiator === INITIATOR_TYPES.SACL,
          isAgreedTerms: isAggreedTerms ? '1' : '0'
        };
        Axios.post(`${apiURL}/formuser/register?master=1`,
          objToQueryString(queryParamsObj),
          {
            withCredentials: true,
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          }).then(({ data: resp }) => {
          if (resp && resp.responseCode === 200) {
            const { content: user } = resp;
            if (window?.JFAppsManager?.isOpenedInPortal()) {
              window.JFAppsManager.userChanged(user);
            }

            onLoginSuccess(resp);
          } else if (resp && resp.errorType) {
            if (resp.errorType === 'E_TYPE_EMAIL') {
              let newRespMessage = (resp.message.split(':'));
              newRespMessage = t(`${newRespMessage[0]}${newRespMessage[1] ? ':' : ''}`).concat(newRespMessage[1] ? newRespMessage[1] : '').toString();
              this.setState({ emailError: newRespMessage });
            }

            if (resp?.message === "Sorry, you can't signup from this country.") {
              openOFACModal(ofacModalRef, email);
            }

            this.setState({ isSignupLoading: false });
          }
        });
      }
    });
  };

  updateWindowDimensions() {
    this.setState({ screenHeight: window.innerHeight });
  }

  // eslint-disable-next-line complexity
  render() {
    const {
      onNavigationChangeRequest, isHIPAA, enforceHIPAARuleSet, includeConfirm, signupButtonText, greetingMessageOnSignupWithEmail,
      showLogoOnSignupWithEmail, shrinkConfirmOnMobile, shrinkConfirmHeight, disableEmail, isSwsoTestVariant, initiator
    } = this.props;
    const {
      email, name, emailError, nameError, isAggreedTerms, termsError, isSignupLoading, screenHeight, passwordError, showRecaptcha
    } = this.state;

    const jfLogoSource = 'https://cdn.jotfor.ms/assets/img/v4/logo-branding21.png';

    const showLoginWarningOption = (this.isEnterpriseEnv && emailError === Texts.EMAIL_ERROR_ENTERPRISE) || emailError === Texts.EMAIL_ERROR;

    return (
      <form className="xcl-content xcl-nb-content" onSubmit={this.handleSubmit} noValidate>
        <div className="xcl-content-header">
          {showLogoOnSignupWithEmail && <img src={jfLogoSource} alt="Logo" className="xcl-companyLogo" />}
          <h3 className="m2at">{t(greetingMessageOnSignupWithEmail)}</h3>
          {
            (!isEnterprise() || initiator === INITIATOR_TYPES.SACL)
            && (
              <button
                type="button"
                className="xcl-button-back"
                onClick={() => onNavigationChangeRequest(!isSwsoTestVariant ? 'signupOptions' : 'signupWithSocialOpts')}
              >
                <BackIcon />
                {t(Texts.OTHER_OPTIONS)}
              </button>
            )
          }
        </div>
        <div className="xcl-cw">
          <div className="xcl-form" id="registrationForm">
            <div className="xcl-field-wr" id="fullnameField">
              <label className="xcl-lbl" htmlFor="fullname">{t(Texts.NAME_TEXT)}</label>
              <input
                className={`xcl-inp ${nameError ? 'errored' : ''}`}
                type="text"
                name="name"
                onChange={this.handleFieldChange('name')}
                onKeyDown={this.handleKeyDown}
                value={name}
                id="fullname"
              />
              {nameError && <ErrorAlert message={nameError} /> }
            </div>

            <div className="xcl-field-wr" id="emailField">
              <label className="xcl-lbl" htmlFor="suEmail">{t(Texts.EMAIL_TEXT)}</label>
              <input
                ref={this.emailRef}
                className={`xcl-inp ${emailError ? 'errored' : ''}`}
                type="email"
                name="email"
                value={email}
                onKeyDown={this.handleKeyDown}
                readOnly={disableEmail}
                onChange={this.handleFieldChange('email')}
                id="suEmail"
                aria-label={(!email || !validateEmail(email)) ? t(Texts.VALID_EMAIL) : null}
              />
              <div role="alert" className={`xcl-lbl-err ${emailError ? 'isVisible' : null}`} id="emailFieldMessage">
                {emailError ? t(emailError) : null}
                {showLoginWarningOption && (
                  <>
                    &nbsp;
                    {translationRenderer(Texts.PLEASE_LOGIN)({
                      renderer1: text => (<a className='error-login-button' onClick={() => onNavigationChangeRequest('loginOptions')}>{text}</a>)
                    })}
                  </>
                )}
              </div>
            </div>

            <Password
              ref={this.passwordRef}
              secure={isHIPAA || enforceHIPAARuleSet}
              hasSecureTextIcon={isHIPAA || enforceHIPAARuleSet}
              checkReuse={false}
              onKeyDown={this.handleKeyDown}
              passwordErrorServer={passwordError}
              resetPasswordError={this.resetPasswordError}
              setSecurePaswordError={this.setSecurePaswordError}
              callbackConfirmPassError={this.callbackConfirmPassError}
              includeConfirm={includeConfirm && !(shrinkConfirmOnMobile && screenHeight < shrinkConfirmHeight)}
              className="login-password-wrapper"
            />

            {!this.isEnterpriseEnv && (
            <div className="xcl-field-wr" id="termsField">
              <div className="xcl-terms-wr noTranslate">
                <input
                  ref={this.termsRef}
                  type="checkbox"
                  id="terms"
                  className="terms-checkbox"
                  checked={isAggreedTerms}
                  onChange={this.handleTermsChange}
                  aria-label={!this.isEnterpriseEnv && !isAggreedTerms ? t(Texts.AGREE_TERMS_CONDITIONS) : null}
                />
                <TermsAndConditionsUrls
                  isTurkish={this.isTurkish}
                  LabelRenderer={({ children }) => <label className="noTranslate sacl-terms" htmlFor="terms">{children}</label>}
                />
              </div>
              <div role="alert" className={`xcl-lbl-err ${termsError ? 'forTerms isVisible' : null}`}>
                {termsError || null}
              </div>
            </div>
            ) }
            {showRecaptcha && (
              <div id="recaptcha-container" className='lf-recaptcha-container'>
                <ReCAPTCHA
                  ref={this.captchaRef}
                  isolated={true}
                  sitekey="6LdU3CgUAAAAAB0nnFM3M3T0sy707slYYU51RroJ"
                  onChange={hash => this.handleCaptchaSubmit(hash)}
                />
              </div>
            )}
            <button
              className="xcl-button-gr button-signup test_signupRegular" disabled={isSignupLoading} type="submit"
              id="signupButton"
            >
              {isSignupLoading
                ? (
                  <div className="button-loader">
                    <div className="button-loader-item" />
                    <div className="button-loader-item" />
                    <div className="button-loader-item" />
                  </div>
                )
                : t(signupButtonText)}
            </button>
            <div className="m1b alignBottom">
              {t(Texts.ALREADY_HAVE_ACCOUNT)}
              <button
                type="button"
                className="test_loginButton"
                id="signinButton"
                onClick={() => onNavigationChangeRequest('loginOptions')}
              >
                {t(Texts.LOGIN_TEXT)}
              </button>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

SignupWithEmail.propTypes = {
  onNavigationChangeRequest: func.isRequired,
  onLoginSuccess: func.isRequired,
  apiURL: string.isRequired,
  signupPredefinedEmail: string,
  signupPredefinedName: string,
  disableEmail: bool,
  verificationHash: string,
  productType: string,
  // resourceID: string,
  isHIPAA: bool,
  enforceHIPAARuleSet: bool,
  appName: string,
  forceUser: bool,
  includeConfirm: bool,
  shrinkConfirmOnMobile: bool,
  shrinkConfirmHeight: number,
  user: shape(),
  signupButtonText: string,
  greetingMessageOnSignupWithEmail: string,
  showLogoOnSignupWithEmail: bool,
  buttonNames: shape({
    google: string,
    facebook: string,
    apple: string,
    emailLogin: string,
    emailSignup: string
  }),
  changeActiveScreen: func,
  ofacModalRef: object,
  builderShowSlides: func,
  redirectURL: string,
  initiator: string,
  isSwsoTestVariant: bool,
  registrationType: string
};

SignupWithEmail.defaultProps = {
  productType: 'form',
  // resourceID: '',
  signupPredefinedEmail: '',
  signupPredefinedName: '',
  disableEmail: false,
  verificationHash: '',
  isHIPAA: false,
  enforceHIPAARuleSet: false,
  appName: '',
  forceUser: false,
  includeConfirm: false,
  user: null,
  signupButtonText: Texts.CREATE_MY_ACCOUNT,
  greetingMessageOnSignupWithEmail: Texts.SIGN_UP_EMAIL,
  showLogoOnSignupWithEmail: false,
  shrinkConfirmOnMobile: false,
  shrinkConfirmHeight: 750,
  buttonNames: {},
  changeActiveScreen: f => f,
  ofacModalRef: null,
  builderShowSlides: f => f,
  redirectURL: '',
  initiator: '',
  isSwsoTestVariant: false,
  registrationType: ''
};

export default SignupWithEmail;
