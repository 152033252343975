/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { t } from '@jotforminc/translation';
import { IconArrowRotateRight, IconCheckCircleFilled, IconFloppyDisk } from '@jotforminc/svg-icons';
import { emailAnswerFinder } from '@jotforminc/utils';
import SharableLinkButton from './SharableLinkButton.jsx';
import { Texts } from '../constants';

const Success = ({
  generateLink, sendEmail, email
}) => {
  const [isResendActive, setResendActive] = useState(true);

  const RESEND_DELAY = 3000;

  useEffect(() => {
    if (!window.JFForm?.draftID) {
      sendEmail(email || '');
    }
  }, []);

  const resendEmail = () => {
    if (isResendActive) {
      sendEmail(email || '');
      setResendActive(false);
      setTimeout(() => {
        setResendActive(true);
      }, RESEND_DELAY);
    }
  };

  return (
    <>
      <div className='sacl-modal__center pb-32 pt-44'>
        <div className='sacl-modal__logo'>
          <IconCheckCircleFilled className='sacl-modal--success otherIcon' />
          <IconFloppyDisk />
        </div>
      </div>
      <div className="sacl-modal__texts">
        <h1>{t(Texts.DRAFT_SAVED)}</h1>
        <span>{t(Texts.CONTINUE_LATER)}</span>
      </div>
      <div className="sacl-modal__center sacl-btn--container pb-40 pt-24" style={{ gap: '24px' }}>
        <button className={`sacl-btn sacl-btn--first ${!isResendActive ? 'isActive' : ''}`} type="button" onClick={resendEmail}>
          <IconArrowRotateRight />
          <span>{t(Texts.RE_EMAIL)}</span>
          {!isResendActive && (
            <span className="sacl-modal__tooltip">
              {`${t(Texts.SENT_TO)} ${emailAnswerFinder() || email}`}
            </span>
          )}
        </button>
        <SharableLinkButton buttonMode="standard" generateLink={generateLink} />
      </div>
    </>
  );
};

export default Success;

Success.propTypes = {
  generateLink: PropTypes.func.isRequired,
  sendEmail: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired
};
