import isEqual from 'lodash/isEqual';

import { persistentKeyList } from './constants';

class StorageHelper {
  static instance = null;

  static testStorage = type => {
    const testItem = 'test';
    const testKey = 'jfStorageHelper_test';
    const storage = StorageHelper.getStorage(type);

    try {
      if (!storage) {
        return false;
      }
      storage.setItem(testKey, testItem);
      const result = storage.getItem(testKey) === testItem;
      storage.removeItem(testKey);
      return result;
    } catch (e) {
      console.error(`Error in ${type} storage helper initialization`, e);
      return false;
    }
  };

  static get isSupported() {
    return StorageHelper.testStorage('local') && StorageHelper.testStorage('session');
  }

  static getStorage = type => {
    const storageBase = typeof window !== 'undefined' ? window : global;
    return type === 'session' ? storageBase.sessionStorage : storageBase.localStorage;
  };

  static getLocalStorageItem = ({ key, defaultValue }) => {
    return StorageHelper.getItem({ key, defaultValue, type: 'local' });
  };

  static getSessionStorageItem = ({ key, defaultValue }) => {
    return StorageHelper.getItem({ key, defaultValue, type: 'session' });
  };

  static getItem = ({ key, type = 'local', defaultValue = null }) => {
    const storage = StorageHelper.getStorage(type);
    if (!StorageHelper.isSupported || !storage) {
      return defaultValue;
    }

    const itemString = storage.getItem(key);
    try {
      if (!itemString) {
        return defaultValue;
      }

      const { value, expiry } = JSON.parse(itemString) || {};
      if (expiry && (new Date()).getTime() > expiry) {
        StorageHelper.removeItem({ key, type });
        return defaultValue;
      }

      return value;
    } catch (e) {
      console.error('Error in getting item from storage', e);
      return itemString || defaultValue;
    }
  };

  static setLocalStorageItem = ({ key, value, ttl }) => {
    return StorageHelper.setItem({
      key,
      ttl,
      value,
      type: 'local'
    });
  };

  static setSessionStorageItem = ({ key, value, ttl }) => {
    return StorageHelper.setItem({
      key,
      ttl,
      value,
      type: 'session'
    });
  };

  static setItem = ({
    key, value, type = 'local', ttl
  }) => {
    const storage = StorageHelper.getStorage(type);
    if (!StorageHelper.isSupported || !storage) return false;
    try {
      const itemString = JSON.stringify({ value, expiry: ttl ? (new Date()).getTime() + ttl : undefined });
      storage.setItem(key, itemString);
      return true;
    } catch (e) {
      console.error('Error in setting item in storage', e);
      return false;
    }
  };

  static removeLocalStorageItem = ({ key }) => {
    return StorageHelper.removeItem({ key, type: 'local' });
  };

  static removeSessionStorageItem = ({ key }) => {
    return StorageHelper.removeItem({ key, type: 'session' });
  };

  static removeItem = ({ key, type = 'local' }) => {
    const storage = StorageHelper.getStorage(type);
    if (!StorageHelper.isSupported || !storage) return false;
    try {
      storage.removeItem(key);
      return true;
    } catch (e) {
      console.error('Error in removing item from storage', e);
      return false;
    }
  };

  static clearLocalStorage = ({ force = false } = {}) => {
    return StorageHelper.clear({ type: 'local', force });
  };

  static clear = ({ type = 'local', force } = {}) => {
    const storage = StorageHelper.getStorage(type);
    if (!StorageHelper.isSupported || !storage) {
      return false;
    }

    for (let i = storage.length - 1; i >= 0; i--) {
      const key = storage.key(i);
      if (!key) {
        continue;
      }

      if (!force && StorageHelper.isPersistantKey(key)) {
        continue;
      }

      storage.removeItem(key);
    }
  };

  static incrementCounter = ({ key, maxCount, type = 'local' }) => {
    const counter = StorageHelper.getItem({ key, type });
    if (counter) {
      const numericVal = parseInt(counter, 10);
      if (numericVal >= maxCount) {
        return;
      }

      StorageHelper.setItem({ key, type, value: numericVal + 1 });
      return;
    }

    StorageHelper.setItem({ key, type, value: 1 });
  };

  static hasKeyValuePair = ({ key, value, type = 'local' }) => {
    const val = StorageHelper.getItem({ key, type });
    return isEqual(val, value);
  };

  static isPersistantKey = key => {
    return persistentKeyList.some(keyOrPattern => {
      switch (true) {
        case keyOrPattern instanceof RegExp:
          return keyOrPattern.test(key);
        case typeof keyOrPattern === 'string':
          return keyOrPattern === key;
        default:
          return false;
      }
    });
  };
}

export { StorageHelper };
