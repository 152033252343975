/* eslint-disable react/prop-types */
import React, { forwardRef } from 'react';
import isEqual from 'lodash/isEqual';
import { SelectionGroup as UIKitOptionGroup } from '@jotforminc/uikit';
import { IconCheckCircleFilled } from '@jotforminc/svg-icons';
import {
  ScOption,
  ScOptionList,
  ScCheckbox,
  ScRadio
} from './StyledComponents';

// eslint-disable-next-line react/prop-types
export const Option = ({
  option: {
    text, value, description, desc, icon, fillColor, backgroundColor, borderColor, disabled: optionDisabled, isSelected: optionSelected
  }, isSelected, disabled, isMultiSelect, isMultiLine, children, onClick, className, useMouseDown = false
}) => {
  const clickProps = { [useMouseDown ? 'onMouseDown' : 'onClick']: onClick };
  return (
    <ScOption
      data-value={value}
      data-selected={isSelected || optionSelected}
      data-disabled={disabled || optionDisabled}
      multiline={!!isMultiLine}
      tabIndex={(disabled || optionDisabled) ? -1 : 0}
      {...clickProps}
      fillColor={fillColor}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      className={className}
    >
      {icon || (isMultiSelect ? <ScCheckbox /> : <ScRadio />)}
      <div className="o-wrapper">
        <div className="o-label">{text}</div>
        <div className="o-desc">{description || desc}</div>
        {children}
      </div>
      {icon && <IconCheckCircleFilled className="optionIcon" />}
    </ScOption>
  );
};

const OptionGroup = forwardRef((props, ref) => {
  const {
    isMultiSelect, isMultiLine, disabled, narrowMargins, className, containerRendererProps
  } = props;

  return (
    <UIKitOptionGroup
      ref={ref}
      ContainerRenderer={({ children }) => (
        <ScOptionList
          {...(containerRendererProps || {})}
          className={className}
          narrowMargins={narrowMargins}
          multiline={Boolean(isMultiLine)}
        >
          {children}
        </ScOptionList>
      )}
      OptionRenderer={optionProps => (
        <Option
          {...optionProps}
          isMultiLine={isMultiLine}
          isMultiSelect={isMultiSelect}
          disabled={optionProps.disabled || optionProps.disabled === 'Yes' || disabled}
        />
      )}
      {...props}
    />
  );
});

export default React.memo(OptionGroup, (prev, next) => {
  if (isEqual(prev.options, next.options)) {
    return true;
  }
  return false;
});
