import { string } from 'prop-types';
import React from 'react';

import { t } from '@jotforminc/translation';

import { IconExclamationCircleFilled } from '@jotforminc/svg-icons';

const ErrorMessage = ({ text }) => {
  return (
    <div className='error-message'>
      <IconExclamationCircleFilled />
      {' '}
      {t(text)}
    </div>
  );
};

ErrorMessage.propTypes = {
  text: string.isRequired
};

export default ErrorMessage;
