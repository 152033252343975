import React from 'react';
import { cx } from 'class-variance-authority';

import '@jotforminc/jotform.css';
// eslint-disable-next-line no-restricted-imports
import '@jotforminc/jotform.css/animation.css';

import { loaderCVA, loaderSpinnerCVA, loaderTrackCVA } from './loader.cva';
import { LoaderProps, loaderDefaultProps } from './loader.types';

export const Loader = (props: LoaderProps):JSX.Element => {
  const {
    className, size, theme, ...rest
  } = { ...loaderDefaultProps, ...props };

  const loaderClassName = cx(className, loaderCVA({
    size,
    theme
  }));

  return (
    <div className={loaderClassName} {...rest}>
      <span className={loaderSpinnerCVA({ theme })} />
      <div className={loaderTrackCVA({ theme })} />
    </div>
  );
};
