import { cva } from 'class-variance-authority';
import { primary } from '../../tokens/colors';
import { CVAType } from '../../types/system.types';
import { LoaderProps } from './loader.types';

// CVA Types
type LoaderCVAType = CVAType<Pick<LoaderProps, 'size' | 'theme'>>;
type LoaderElementsCVAType = CVAType<Pick<LoaderProps, 'theme'>>;

// Classes
export const loaderCVA = cva<LoaderCVAType>(
  'magnet-loader relative', {
    variants: {
      size: {
        small: 'w-4 h-4',
        medium: 'w-5 h-5',
        large: 'w-6 h-6'
      },
      theme: {}
    }
  }
);

export const loaderTrackCVA = cva<LoaderElementsCVAType>('opacity-20 absolute inset-px radius-full border-2', {
  variants: {
    theme: {
      current: 'border-current',
      dark: primary.border.darkest.base,
      light: 'border-white'
    }
  }
});

export const loaderSpinnerCVA = cva<LoaderElementsCVAType>(
  'absolute inset-px radius-full border-2 border-t-transparent border-b-transparent border-l-transparent anim anim-700 spin-loader timing-linear', {
    variants: {
      theme: {
        current: 'border-r-current',
        dark: 'border-r-navy-700',
        light: 'border-r-white'
      }
    }
  }
);
