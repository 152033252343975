import { LANG_CODES } from '@jotforminc/constants';

export const partialTranslations = {
  'animal-shelter': path => /(jotform.com|jform.co.kr|jotform.pro)(\/){1}(animal-shelter){1}/g.test(path)
};

/**
 * Returns Jotform translation files suffix (locale/locale_SUFFIX.js)
 * @param {string} localeCode - lang-[country] (en-US, pt-BR, en)
 * @return {string} A value from LANG_CODES object.
 */
export const getLangFileSuffix = localeCode => {
  const lang = localeCode.split('-')[0];
  return LANG_CODES[localeCode] || LANG_CODES[lang];
};

export const DEFAULT_LANGUAGE = 'en-US';
