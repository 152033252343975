import React, { forwardRef } from 'react';
import {
  arrayOf, oneOfType, string, number, shape, elementType, bool, func
} from 'prop-types';

import Option from '../Option';

const OptionGroup = forwardRef(({
  options, setSelected, checkOptionSelectionState, ContainerRenderer, OptionContainerRenderer, OptionRenderer, disabled, GroupRenderer, text, OptionContainerRendererProps, groupIcon,
  NoResultsRenderer, onClearFilter, inputValue, containerID
}, ref) => {
  if (inputValue && !options.length && NoResultsRenderer) {
    return <NoResultsRenderer onClearFilter={onClearFilter} />;
  }
  return (
    <ContainerRenderer
      ref={ref}
      id={containerID}
      text={text}
      groupIcon={groupIcon}
    >
      {options.map(option => {
        if (option.type === 'group') {
          return (
            <OptionGroup
              text={option.text}
              groupIcon={option.groupIcon}
              disabled={disabled}
              options={option.options}
              setSelected={setSelected}
              GroupRenderer={GroupRenderer}
              OptionRenderer={OptionRenderer}
              ContainerRenderer={GroupRenderer}
              OptionContainerRenderer={OptionContainerRenderer}
              OptionContainerRendererProps={OptionContainerRendererProps}
              checkOptionSelectionState={checkOptionSelectionState}
              role="radiogroup"
              tabIndex="0"
            />
          );
        }

        return (
          <Option
            option={option}
            key={option.value}
            Renderer={option.Renderer || OptionRenderer}
            OptionContainerRendererProps={OptionContainerRendererProps}
            setSelected={setSelected}
            disabled={disabled || option.disabled}
            ContainerRenderer={OptionContainerRenderer}
            isSelected={checkOptionSelectionState(option)}
            aria-checked={checkOptionSelectionState(option)}
          />
        );
      })}
    </ContainerRenderer>
  );
});

OptionGroup.prototype = {
  containerID: string.isRequired,
  disabled: bool,
  setSelected: func,
  onClearFilter: func,
  GroupRenderer: elementType,
  OptionRenderer: elementType,
  ContainerRenderer: elementType,
  OptionContainerRenderer: elementType,
  OptionContainerRendererProps: oneOfType([
    func,
    shape({})
  ]),
  NoResultsRenderer: elementType,
  inputValue: string,
  checkOptionSelectionState: func,
  options: arrayOf(shape({ text: string, value: oneOfType([string, number]) })),
  selectedOptions: arrayOf(shape({ text: string, value: oneOfType([string, number]) }))
};

/* eslint react/prop-types: "off" */
OptionGroup.defaultProps = {
  options: [],
  selectedOptions: [],
  disabled: false,
  setSelected: f => f,
  checkOptionSelectionState: f => f,
  onClearFilter: f => f,
  GroupRenderer: ({ text, children, ...props }) => (
    <div {...props}>
      <b>{text}</b>
      <div>{children}</div>
    </div>
  ),
  ContainerRenderer: forwardRef(({ children }, ref) => <ul ref={ref}>{children}</ul>),
  OptionContainerRenderer: props => <li {...props} />,
  OptionContainerRendererProps: {},
  OptionRenderer: ({ option: { text, value }, isSelected }) => <div data-value={value} data-selected={isSelected}>{text}</div>,
  NoResultsRenderer: null,
  inputValue: ''
};

export default OptionGroup;
