import TTLCache from '../request-cache';
import { argumentParser } from '../utils/argumentParser';
import { constructInterceptorConfig } from '../utils/constructInterceptorConfig';
import { setInterceptors } from '../utils/setInterceptors';
import { EngineNames, ENGINES } from './engines/constants';
import { Interceptors } from './interceptors/types';
import {
  CustomConfig,
  DefaultInterceptorsConfig,
  Engine,
  Methods,
  RequestArgs,
  RequestContext,
  RequestFunction,
  ResponseContext,
  ResponseResult,
  Responses
} from './types';
import { mapAxiosResponse } from './utils';

export class RequestLayer {
  engineInstance: Engine;

  engineName: EngineNames;

  interceptors?: Interceptors;

  configs?: {engineConfig?: CustomConfig, interceptorConfig?: DefaultInterceptorsConfig};

  ttlCache?: TTLCache;

  baseURL?: string;

  get: <T>(...args: RequestArgs) => Promise<ResponseResult<T> | T>;

  post: <T>(...args: RequestArgs) => Promise<ResponseResult<T> | T>;

  delete: <T>(...args: RequestArgs) => Promise<ResponseResult<T> | T>;

  put: <T>(...args: RequestArgs) => Promise<ResponseResult<T> | T>;

  constructor(
    baseURL?: string,
    configs?: {engineConfig?: CustomConfig, interceptorConfig?: DefaultInterceptorsConfig},
    engineName: EngineNames = 'AXIOS'

  ) {
    this.configs = configs;
    this.engineInstance = new ENGINES[engineName](baseURL || '', { withCredentials: true, ...configs?.engineConfig });
    this.interceptors = setInterceptors(configs?.interceptorConfig);
    this.engineName = engineName;
    this.baseURL = baseURL;

    if (configs?.interceptorConfig?.shouldCache) {
      this.ttlCache = new TTLCache(configs?.interceptorConfig?.cacheTTL);
    }

    this.get = this.createMethod('get');

    this.post = this.createMethod('post');

    this.delete = this.createMethod('delete');

    this.put = this.createMethod('put');
  }

  createMethod(method: Methods): <T>(...args: RequestArgs) => Promise<ResponseResult<T> | T> {
    return async <T>(...args: RequestArgs): Promise<ResponseResult<T> | T> => {
      const { url, config, data } = argumentParser(method, args);

      // it enables to override request layer's interceptor configs
      // so configs are prioritized for endpoints, not for layer configs
      const modifiedInterceptorConfig = config
        ? constructInterceptorConfig(config, this.configs?.interceptorConfig)
        : this.configs?.interceptorConfig;

      const req: RequestContext<T> = {
        url,
        config: { method, ...config },
        data: (data as T),
        interceptorConfig: modifiedInterceptorConfig,
        engineName: this.engineName
      };

      const shouldCacheRun = this.ttlCache && !config?.disableCache && modifiedInterceptorConfig?.shouldCache;

      if (!modifiedInterceptorConfig?.disableRequestInterceptors) {
        this.interceptors?.preRequest?.run(req);
      }

      const cacheRequest: RequestContext = {
        ...req,
        data: req.data instanceof FormData ? Object.fromEntries(req.data as unknown as Iterable<readonly [PropertyKey, unknown]>) : req.data,
        interceptorConfig: {
          ...req.interceptorConfig,
          customRequestInterceptors: [],
          customResponseInterceptors: []
        }
      };

      if (shouldCacheRun) {
        const cachedResponse = await this.ttlCache?.get(cacheRequest);
        if (cachedResponse) {
          return cachedResponse as ResponseResult<T>;
        }
      }

      let resp: Responses<T>;
      let ctx: ResponseContext<T>;

      switch (method) {
        case 'get':
        case 'delete':
        case 'post':
        case 'put': {
          let engineResponse;
          try {
            engineResponse = await this.engineInstance[method]<T>(req.url, req.config, req.data);
          } catch (e) {
            resp = this.mapEngineResponse<T>(e as Awaited<ReturnType<RequestFunction>>);
            ctx = {
              resp, config: req.config, interceptorConfig: modifiedInterceptorConfig, engineName: this.engineName, result: resp
            };
            this.interceptors?.postFailedRequest?.run(ctx);

            throw e;
          }

          resp = this.mapEngineResponse<T>(engineResponse);

          ctx = {
            resp,
            config: req.config,
            interceptorConfig: modifiedInterceptorConfig,
            engineName: this.engineName,
            result: resp
          };
          break;
        }

        default: {
          const customMethod = this.engineInstance.createRequestMethod(method);
          resp = await customMethod<T>(url, config, data as T) as Responses<T>;
          ctx = {
            resp, config: req.config, interceptorConfig: modifiedInterceptorConfig, engineName: this.engineName, result: resp
          };
        }
      }

      if (!modifiedInterceptorConfig?.disableResponseInterceptors) {
        this.interceptors?.postRequest?.run(ctx);
      }

      if (shouldCacheRun) {
        this.ttlCache?.add(cacheRequest, ctx.result);
      }

      return ctx.result;
    };
  }

  setTeamID(teamID: number): void {
    this.configs = {
      ...this.configs,
      interceptorConfig: {
        ...this.configs?.interceptorConfig,
        teamID
      }
    };
  }

  getTeamID(): DefaultInterceptorsConfig['teamID'] {
    return this.configs?.interceptorConfig?.teamID;
  }

  private mapEngineResponse = <T>(
    awaitedEngineResp: Awaited<ReturnType<RequestFunction>>
  ): Responses<T> => {
    switch (this.engineName) {
      default:
        return mapAxiosResponse(awaitedEngineResp) as Responses<T>;
    }
  };
}
