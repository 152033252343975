import { isSalesforceEnv, handleCustomNavigation } from '@jotforminc/utils';
import { RequestInterceptor, ResponseInterceptor } from '../../../types';

export const salesforceAuthFailed: ResponseInterceptor = ctx => {
  if (`${ctx?.resp?.config?.baseURL}/${ctx?.resp?.config?.url}`.startsWith('/API/salesforce/')) {
    if (ctx?.resp?.data?.responseCode === 400 && ctx?.resp?.data?.message === 'Please reauthenticate your account.') {
      handleCustomNavigation(`/platform/salesforce/platform-pages/reauth?rp=${window.location}`, '_self', true);
    }
  }
};

const salesforceRequestInterceptors: RequestInterceptor[] = [];
const salesforceResponseSuccessInterceptors: ResponseInterceptor[] = [];
const salesforceResponseFailedInterceptors: ResponseInterceptor[] = [];

let isSalesforcePlatform = false;
try {
  isSalesforcePlatform = isSalesforceEnv();
} catch (e) {
  console.log('Error :: salesforceInterceptors -> ', e);
  isSalesforcePlatform = false;
}
if (isSalesforcePlatform) {
  salesforceResponseFailedInterceptors.push(salesforceAuthFailed);
}

export {
  salesforceRequestInterceptors,
  salesforceResponseSuccessInterceptors,
  salesforceResponseFailedInterceptors
};
