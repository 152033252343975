import { createStore, compose } from 'redux';

const makeFeatureReducer = initialState => {
  return (state = initialState, action) => {
    if (!action.type) return state;
    if (action.type === 'ADD_NEW_FEATURE') {
      return {
        ...state,
        [action.payload.featureName]: {
          name: action.payload.featureName,
          value: action.payload.featureValue
        }
      };
    }
    if (action.type === 'ADD_FEATURE_FLAG_LIST') {
      return {
        ...state,
        ...action.payload.featureFlagList
      };
    }
    if (action.type === 'RENEW_FEATURE_FLAG') {
      return {
        ...action.payload.featureFlagList
      };
    }
    if (action.type === 'RESET_FEATURE_FLAG') {
      return {};
    }
    if (!state[action.type]) return state;
    return {
      ...state,
      [action.type]: {
        ...state[action.type],
        value: !state[action.type].value
      }
    };
  };
};

const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ name: 'JF Feature Flag Reducer' })) || compose;
class FeatureFlag {
  store = createStore(makeFeatureReducer({}), composeEnhancers());

  constructor() {
    if (global.FeatureFlag) return global.FeatureFlag;
    global.FeatureFlag = this;
  }

  init = featureList => {
    this.store.dispatch({ type: 'ADD_FEATURE_FLAG_LIST', payload: { featureFlagList: featureList } });
  };

  getStore = () => {
    // TODO: return singleton store;
    return this.store;
  };

  addNewFeature = (featureName, featureValue) => {
    this.store.dispatch({ type: 'ADD_NEW_FEATURE', payload: { featureName, featureValue } });
  };

  getList = () => {
    return this.store.getState();
  };

  getFeature = feature => {
    const featureName = this.getFeatureName(feature);
    const featureValue = this.store.getState()[featureName]?.value;
    if (typeof featureValue === 'undefined') {
      console.log(JSON.stringify(feature), ': feature is not defined.');
      return false;
    }
    return featureValue;
  };

  toggleFeature = feature => {
    const featureName = this.getFeatureName(feature);
    this.store.dispatch({ type: featureName });
  };

  getFeatureName = feature => {
    if (typeof feature === 'undefined') return null;
    if (typeof feature === 'string') return feature;
    return feature.name;
  };
}

export default new FeatureFlag();
