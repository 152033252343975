const text = {
  lightest: {
    base: 'color-gray-100',
    hover: 'hover:color-gray-100'
  },
  light: {
    base: 'color-gray-200',
    hover: 'hover:color-gray-200'
  },
  medium: {
    base: 'color-gray-300',
    hover: 'hover:color-gray-300'
  },
  dark: {
    base: 'color-gray-500',
    hover: 'hover:color-gray-500'
  },
  darkest: {
    base: 'color-gray-700',
    hover: 'hover:color-gray-700'
  }
} as const;

const background = {
  lightest: {
    base: 'bg-gray-400',
    hover: 'hover:bg-gray-400'
  },
  light: {
    base: 'bg-gray-500',
    hover: 'hover:bg-gray-500'
  },
  medium: {
    base: 'bg-gray-600',
    hover: 'hover:bg-gray-600'
  },
  dark: {
    base: 'bg-gray-700',
    hover: 'hover:bg-gray-700'
  },
  darkest: {
    base: 'bg-gray-800',
    hover: 'hover:bg-gray-800'
  }
} as const;

const border = {
  light: {
    base: 'border-gray-200',
    hover: 'hover:border-gray-200'
  },
  medium: {
    base: 'border-gray-500',
    hover: 'hover:border-gray-500'
  },
  dark: {
    base: 'border-gray-700',
    hover: 'hover:border-gray-700'
  }
} as const;

export default {
  text,
  background,
  border
};
