import React, { Component } from 'react';
import { string, func, bool } from 'prop-types';
import Axios from 'axios';
import { t } from '@jotforminc/translation';
import { Texts } from '@jotforminc/constants';
import { listenEnter } from '@jotforminc/utils';
import cx from 'classnames';

import { IconChevronLeft } from '@jotforminc/svg-icons';
import BackIcon from '../temporary-duplicate/assets/svg/icon_back3.svg';
import ErrorAlert from './ErrorAlert';

const invalidInputText = Texts.FIELD_REQUIRED;

export default class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      usernameValid: true,
      resetErrorMessage: ''
    };
  }

  handleInputChange = input => e => {
    const { value } = e.target;
    this.setState({ [input]: value }, () => {
      this.validate(input);
    });
  };

  handleResetClick = async () => {
    const { onNavigationChangeRequest } = this.props;
    const validationResult = await this.validate('username');
    if (!validationResult) return;

    const result = await this.sendEmail()
      .catch(err => {
        // eslint-disable-next-line no-console
        console.error(err);
        this.setState({
          resetErrorMessage: 'An error occurred. Please try again'
        });
      });
    if (result) {
      onNavigationChangeRequest('resetSuccess');
    }
  };

  handleKeyDown = e => listenEnter(e, this.handleResetClick);

  sendEmail = () => {
    const { apiURL } = this.props;
    const { username } = this.state;
    return Axios.get(`${apiURL}/passwordreset?resetData=${encodeURIComponent(username)}`);
  };

  validate = input => {
    const { [input]: value } = this.state;
    this.setState({
      [`${input}Valid`]: !!value // inputs shouldn't be empty
    });
    return Promise.resolve(!!value);
  };

  render() {
    const { onNavigationChangeRequest, isOnboardingFlow } = this.props;

    const {
      username,
      usernameValid,
      resetErrorMessage
    } = this.state;

    const inputValid = usernameValid && !resetErrorMessage;
    return (
      <div className={cx('xcl-content xcl-nb-content', { isOnboardingFlowForgot: isOnboardingFlow })} id="loginForm">
        {isOnboardingFlow && (
          <div className='sws-fields-back-button'>
            <button
              className="back-button" onClick={() => onNavigationChangeRequest('loginOptions')} type="button"
              id="returnLoginBox"
            >
              <IconChevronLeft />
              {t(Texts.GO_BACK_TEXT)}
            </button>
          </div>
        )}
        <h3 className="m2bt forForgotTitle">{t(Texts.FORGOT_PASSWORD)}</h3>
        <div className="xcl-cw" id="resetPassForm">
          <div className="xcl-form forLogin js-loginForm" id="forgotPasswordDiv">
            <div className="xcl-field-wr" id="forgotPasswordField">
              <label className="xcl-lbl" htmlFor="resetData" id="resetDataField">{t(Texts.ENTER_USERNAME_EMAIL)}</label>
              <input
                className={`xcl-inp${(inputValid ? '' : ' errored')}`}
                type="username"
                name="username"
                value={username}
                onKeyDown={this.handleKeyDown}
                onChange={this.handleInputChange('username')}
                id="resetData"
              />
              <div role="alert" className={`xcl-lbl-err ${usernameValid ? null : 'isVisible'}`} id="password-error-box">
                {usernameValid ? null : t(invalidInputText)}
              </div>
              {resetErrorMessage && <ErrorAlert message={resetErrorMessage} />}
            </div>
            <button
              className={cx('xcl-button-gr forReset', { isOnboardingFlowBtn: isOnboardingFlow })}
              onClick={this.handleResetClick}
              type="button"
              id="passResetButton"
            >
              {t(Texts.SEND_INSTRUCTIONS)}
            </button>
          </div>
          {!isOnboardingFlow && (
          <button
            className="xcl-button-back forForgotPass" onClick={() => onNavigationChangeRequest('loginOptions')} type="button"
            id="returnLoginBox"
          >
            <BackIcon />
            {t(Texts.GO_BACK_TEXT)}
          </button>
          )}
        </div>
      </div>
    );
  }
}

ForgotPassword.propTypes = {
  onNavigationChangeRequest: func.isRequired,
  isOnboardingFlow: bool,
  apiURL: string.isRequired
};

ForgotPassword.defaultProps = {
  isOnboardingFlow: false
};
