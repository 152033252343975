import { v4 as uuid } from 'uuid';
import cloneDeep from 'lodash/cloneDeep';
import isUndefined from 'lodash/isUndefined';
import { t } from '@jotforminc/translation';

import { getQuestionByPlaceholder, preparePillObjectFromQuestion } from './questions';
import {
  isEmail,
  isTeamEmail,
  generateIsErroredFromValidations
} from './validation';
import { sanitizeSingleEmailInputValue } from './sanitize';

export const pillValidator = (email, questions) => {
  return (Boolean(isEmail(email) || isTeamEmail(email) || getQuestionByPlaceholder(email, questions)));
};

export const preparePillObjectFromEmail = email => ({
  id: uuid(),
  value: email,
  text: email,
  isValid: pillValidator(email),
  isQuestion: false
});

export const sanitizeEmailList = (emailList, questions) => emailList.map(email => {
  if (typeof email === 'object') {
    return email;
  }

  const question = getQuestionByPlaceholder(email, questions);
  return question ? preparePillObjectFromQuestion(question) : preparePillObjectFromEmail(email);
});

export const emailAnswerFinder = () => {
  const { JFFormUserHelper: { user: { email = '' } = {} } = {} } = window;
  if (email) return email;

  const emailSentTo = document.querySelector('input#jfFormUserSCL_emailSentTo');
  if (emailSentTo && emailSentTo.value) return emailSentTo.value;

  const emailSentToElementFindWithName = document.querySelector('input[name="jfFormUserSCL_emailSentTo"]');
  if (emailSentToElementFindWithName && emailSentToElementFindWithName.value) return emailSentToElementFindWithName.value;

  // use form fields
  const emailInput = [...window.document.forms[0].querySelectorAll('.form-line:not(.form-field-hidden):not(.always-hidden) input[type="email"]')]
    .find(input => input.value && input.value.trim());

  if (!emailInput) return '';

  return emailInput.value;
};

// TODO: remoe this shit
export const singleEmailInputEmailErrored = (value, isRequired = false) => {
  const stringVal = sanitizeSingleEmailInputValue(value);
  if (!stringVal && !isRequired) {
    return false;
  }

  const validations = isRequired ? ['required', 'email'] : ['email'];
  return generateIsErroredFromValidations(validations)(stringVal);
};

export const removeHtmlTags = content => {
  const parser = (new global.DOMParser()).parseFromString(content, 'text/html').body;
  return parser.textContent || parser.innerText || '';
};

export const selectText = question => {
  let text = removeHtmlTags(question.text);
  if (text === '' || text === ' ' || isUndefined(text)) {
    if (question.hint === '' || question.hint === ' ' || isUndefined(question.hint)) {
      // eslint-disable-next-line prefer-destructuring
      text = question.type.split('_')[1];
      text = t(`${text.substr(0, 1).toUpperCase() + text.substr(1)} Field`);
    } else {
      text = question.hint;
    }
  }
  return text;
};

export const computeQuestions = (questions, isHIPAA) => {
  const target = isHIPAA ? questions.filter(q => q.protected !== 'Yes') : questions;
  return target.map(question => {
    const newQuestion = cloneDeep(question);
    newQuestion.text = selectText(question);
    return newQuestion;
  });
};
